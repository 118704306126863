import { AppLayout } from '@/components/app-layout.component';
import { AuthCheck } from '@/components/auth-check.component';
import { Head } from '@/components/head.component';
import { FileManagerContainer } from '@/containers/$workspaceId/file-manager/file-manager-container.component';
import { FileManagerExplorer } from '@/containers/$workspaceId/file-manager/file-manager-explorer.component';
import { FileManagerNavigationLayout } from '@/containers/$workspaceId/file-manager/file-manager-navigation-layout.component';
import { useTranslation } from '@/lib/i18n';
import {
  getWorkspaceDiskUsage,
  getWorkspaceEntities,
  getWorkspaceMembers,
  getWorkspaceMetadata,
  getWorkspacePlan,
  setLastOpenedWorkspace,
  setSelectedEntityIds,
  useAppDispatch,
} from '@/lib/store';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { useEffect } from 'react';

export const Route = createFileRoute('/$workspaceId/file-manager/')({
  component: RouteComponent,
});

function RouteComponent() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { workspaceId } = useParams({ strict: false });

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(setLastOpenedWorkspace(workspaceId));
    dispatch(getWorkspaceEntities(workspaceId));
    dispatch(getWorkspaceMembers(workspaceId));
    dispatch(getWorkspaceMetadata(workspaceId));
    dispatch(getWorkspaceDiskUsage(workspaceId));
    dispatch(getWorkspacePlan(workspaceId));
    dispatch(setSelectedEntityIds([]));
  }, [workspaceId]);

  return (
    <>
      <Head heading={t('file-manager:FILE_MANAGER.TITLE')} />
      <AuthCheck>
        <AppLayout>
          <FileManagerContainer>
            <FileManagerNavigationLayout entityFinder displayTypeFilter>
              <FileManagerExplorer />
            </FileManagerNavigationLayout>
          </FileManagerContainer>
        </AppLayout>
      </AuthCheck>
    </>
  );
}
