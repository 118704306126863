/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { PlanInformation } from "./PlanInformation";
import {
  PlanInformationFromJSON,
  PlanInformationFromJSONTyped,
  PlanInformationToJSON,
  PlanInformationToJSONTyped,
} from "./PlanInformation";

/**
 *
 * @export
 * @interface ResultPlanInformation
 */
export interface ResultPlanInformation {
  /**
   *
   * @type {Array<PlanInformation>}
   * @memberof ResultPlanInformation
   */
  items?: Array<PlanInformation>;
}

/**
 * Check if a given object implements the ResultPlanInformation interface.
 */
export function instanceOfResultPlanInformation(
  value: object,
): value is ResultPlanInformation {
  return true;
}

export function ResultPlanInformationFromJSON(
  json: any,
): ResultPlanInformation {
  return ResultPlanInformationFromJSONTyped(json, false);
}

export function ResultPlanInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultPlanInformation {
  if (json == null) {
    return json;
  }
  return {
    items:
      json["items"] == null
        ? undefined
        : (json["items"] as Array<any>).map(PlanInformationFromJSON),
  };
}

export function ResultPlanInformationToJSON(json: any): ResultPlanInformation {
  return ResultPlanInformationToJSONTyped(json, false);
}

export function ResultPlanInformationToJSONTyped(
  value?: ResultPlanInformation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    items:
      value["items"] == null
        ? undefined
        : (value["items"] as Array<any>).map(PlanInformationToJSON),
  };
}
