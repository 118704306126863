import {
  ChargeInfo,
  UpdateCCardRequest,
} from '@oproma/prividox-orchestration-open-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { groupBy, mapValues, orderBy } from 'lodash';
import { toast } from 'sonner';
import { extractApiError, financeProvider } from '../api';
import { AppState } from '../store';
import {
  FinanceErrorCodesEnum,
  IFinanceState,
  PaymentTransactionsPayload,
} from './types';

const initialState: IFinanceState = {
  pricingPlans: {},
  creditCards: [],
  creditCardCount: null,
  chosenCreditCard: null,
  paymentTransactions: [],
  editedCreditCard: false,
  createdCreditCard: false,
  deletedCreditCard: false,
  displayEditCreditCardModal: false,
  displayOnboardCreditCardModal: false,
  loading: false,
  error: null,
};

export const getPricingPlans = createAsyncThunk(
  '@@finance/getPricingPlans',
  async (_, { rejectWithValue }) => {
    try {
      const plans = (await financeProvider.fetchPricingPlans()).items ?? [];
      const groupedByPlanType = groupBy(plans, 'interval');
      const nestedGroupedByName = mapValues(groupedByPlanType, (group) => {
        return mapValues(
          groupBy(
            group,
            (entry) => entry.meta?.['package-i18n-name'].split('-')[0],
          ),
          (innerGroup) => {
            return orderBy(
              innerGroup,
              [(item) => parseInt(item.meta?.['members-limit'] ?? '0')],
              ['asc'],
            );
          },
        );
      });

      return nestedGroupedByName;
    } catch (e) {
      console.error(e);
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const getCreditCards = createAsyncThunk(
  '@@finance/getCreditCards',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { financeWebtoken } = (getState() as AppState).auth;
      if (!financeWebtoken) return rejectWithValue('No finance webtoken');
      const response = await financeProvider.fetchCreditCards({
        token: financeWebtoken,
      });
      return response.items ?? [];
    } catch (e) {
      console.error(e);
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const getCreditCardCount = createAsyncThunk(
  '@@finance/getCreditCardCount',
  async (_, { rejectWithValue }) => {
    try {
      return await financeProvider.fetchCreditCardCount();
    } catch (e) {
      console.error(e);
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const getPaymentTransactions = createAsyncThunk(
  '@@finance/getPaymentTransactions',
  async (
    payload: PaymentTransactionsPayload,
    { getState, rejectWithValue },
  ) => {
    try {
      const { financeWebtoken } = (getState() as AppState).auth;
      if (!financeWebtoken)
        return rejectWithValue(
          "Failed to fetch finance webtoken, can't fetch payment transactions without it.",
        );

      const transactions: ChargeInfo[] = [];
      let start = payload.start;
      let end = payload.end;
      let more = false;

      // Fetching every transaction. TODO: This is not efficient and has to be refactored on the backend.
      const response = await financeProvider.fetchTransactions({
        token: financeWebtoken,
        start,
      });

      transactions.push(...(response.invoices ?? []));
      // TODO: Change to true
      if (response.more) more = false;

      while (more) {
        start = `${parseInt(start ?? '0', 10) + 5}`;
        end = `${parseInt(end ?? '5', 10) + 5}`;
        const response = await financeProvider.fetchTransactions({
          token: financeWebtoken,
          start,
          end,
        });
        transactions.push(...(response.invoices ?? []));
        if (!response.more) more = false;
      }
      return transactions;
    } catch (e) {
      console.error(e);
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const createCreditCard = createAsyncThunk(
  '@@finance/createCreditCard',
  async (token: string, { getState, dispatch, rejectWithValue }) => {
    try {
      const { financeWebtoken } = (getState() as AppState).auth;
      if (!financeWebtoken)
        return rejectWithValue(
          "Failed to fetch finance webtoken, can't create credit card without it.",
        );
      const response = await financeProvider.putCreditCard({
        token: financeWebtoken,
        tokenSpec: {
          id: token,
        },
      });
      dispatch(getCreditCardCount());
      dispatch(getCreditCards());
      toast.success('Credit card creation complete.');
      return response;
    } catch (e) {
      console.error(e);
      toast.error('Failed to create credit card. Please try again.');
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const editCreditCard = createAsyncThunk(
  '@@finance/editCreditCard',
  async (
    card: Omit<UpdateCCardRequest, 'token'>,
    { getState, dispatch, rejectWithValue },
  ) => {
    try {
      const { financeWebtoken } = (getState() as AppState).auth;
      if (!financeWebtoken)
        return rejectWithValue(
          "Failed to fetch finance webtoken, can't edit credit card without it.",
        );
      const response = await financeProvider.patchCreditCard({
        token: financeWebtoken,
        ...card,
      });
      dispatch(getCreditCardCount());
      dispatch(getCreditCards());
      toast.success('Credit card edited.');
      return response;
    } catch (e) {
      console.error(e);
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const deleteCreditCard = createAsyncThunk(
  '@@finance/deleteCreditCard',
  async (card: string, { getState, dispatch, rejectWithValue }) => {
    try {
      const { financeWebtoken } = (getState() as AppState).auth;
      if (!financeWebtoken)
        return rejectWithValue(
          "Failed to fetch finance webtoken, can't delete credit card without it.",
        );
      const response = await financeProvider.deleteCreditCard({
        token: financeWebtoken,
        card,
      });
      dispatch(getCreditCardCount());
      dispatch(getCreditCards());
      toast.success('Credit card deleted.');
      return response;
    } catch (e) {
      console.error(e);
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const financeSlice = createSlice({
  name: '@@finance',
  initialState,
  reducers: {
    toggleDisplayEditCreditCardModal: (state) => {
      state.displayEditCreditCardModal = !state.displayEditCreditCardModal;
    },
    toggleDisplayOnboardCreditCardModal: (state) => {
      state.displayOnboardCreditCardModal =
        !state.displayOnboardCreditCardModal;
    },
    setChosenCreditCard: (state, action) => {
      state.chosenCreditCard = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPricingPlans.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPricingPlans.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FinanceErrorCodesEnum.GET_PRICING_PLANS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getPricingPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.pricingPlans = action.payload;
        state.error = null;
      });
    builder
      .addCase(getCreditCardCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCreditCardCount.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FinanceErrorCodesEnum.GET_CREDIT_CARD_COUNT_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getCreditCardCount.fulfilled, (state, action) => {
        state.loading = false;
        state.creditCardCount = action.payload;
        state.error = null;
      });
    builder
      .addCase(getCreditCards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCreditCards.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FinanceErrorCodesEnum.GET_CREDIT_CARDS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getCreditCards.fulfilled, (state, action) => {
        state.loading = false;
        state.creditCards = action.payload;
        state.error = null;
      });
    builder
      .addCase(createCreditCard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCreditCard.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FinanceErrorCodesEnum.CREATE_CREDIT_CARD_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(createCreditCard.fulfilled, (state, action) => {
        state.loading = false;
        state.createdCreditCard = action.payload;
        state.error = null;
      });
    builder
      .addCase(editCreditCard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editCreditCard.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FinanceErrorCodesEnum.EDIT_CREDIT_CARD_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(editCreditCard.fulfilled, (state, action) => {
        state.loading = false;
        state.editedCreditCard = action.payload;
        state.error = null;
      });
    builder
      .addCase(deleteCreditCard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCreditCard.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FinanceErrorCodesEnum.DELETE_CREDIT_CARD_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(deleteCreditCard.fulfilled, (state, action) => {
        state.loading = false;
        state.deletedCreditCard = action.payload;
        state.error = null;
      });
    builder
      .addCase(getPaymentTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPaymentTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FinanceErrorCodesEnum.GET_PAYMENT_TRANSACTIONS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getPaymentTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentTransactions = action.payload;
        state.error = null;
      });
  },
});

export const {
  toggleDisplayEditCreditCardModal,
  toggleDisplayOnboardCreditCardModal,
  setChosenCreditCard,
} = financeSlice.actions;

export const financeReducer = financeSlice.reducer;
