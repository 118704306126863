import { GroupRow, useAppSelector } from "@/lib/store";
import { useEffect, useState } from "react";

export const useGroupsFiltering = (initialGroups: GroupRow[]) => {
  const [filteredGroups, setFilteredGroups] = useState(initialGroups);
  const { groupQuery, groupSortOrder, permissionLevelFilter } = useAppSelector(
    (state) => state.groups
  );

  // Handle search/query filtering
  useEffect(() => {
    let result = [...initialGroups];

    // Apply search filter
    if (groupQuery) {
      result = result.filter((group) =>
        group.name?.toLowerCase().includes(groupQuery.toLowerCase())
      );
    }

    // Apply permission filter
    if (permissionLevelFilter !== null) {
      result = result.filter(
        (group) => group.isDefaultGroup === permissionLevelFilter
      );
    }

    // Apply sorting
    if (groupSortOrder) {
      result.sort((a, b) => {
        if (!a.name || !b.name) return 0;
        return groupSortOrder === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      });
    }

    setFilteredGroups(result);
  }, [initialGroups, groupQuery, groupSortOrder, permissionLevelFilter]);

  return filteredGroups;
};
