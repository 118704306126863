/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DiskUsageSpec
 */
export interface DiskUsageSpec {
  /**
   *
   * @type {number}
   * @memberof DiskUsageSpec
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof DiskUsageSpec
   */
  folderCount?: number;
  /**
   *
   * @type {number}
   * @memberof DiskUsageSpec
   */
  fileCount?: number;
}

/**
 * Check if a given object implements the DiskUsageSpec interface.
 */
export function instanceOfDiskUsageSpec(value: object): value is DiskUsageSpec {
  return true;
}

export function DiskUsageSpecFromJSON(json: any): DiskUsageSpec {
  return DiskUsageSpecFromJSONTyped(json, false);
}

export function DiskUsageSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DiskUsageSpec {
  if (json == null) {
    return json;
  }
  return {
    size: json["size"] == null ? undefined : json["size"],
    folderCount: json["folderCount"] == null ? undefined : json["folderCount"],
    fileCount: json["fileCount"] == null ? undefined : json["fileCount"],
  };
}

export function DiskUsageSpecToJSON(json: any): DiskUsageSpec {
  return DiskUsageSpecToJSONTyped(json, false);
}

export function DiskUsageSpecToJSONTyped(
  value?: DiskUsageSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    size: value["size"],
    folderCount: value["folderCount"],
    fileCount: value["fileCount"],
  };
}
