/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { ChildPermission } from "./ChildPermission";
import {
  ChildPermissionFromJSON,
  ChildPermissionFromJSONTyped,
  ChildPermissionToJSON,
  ChildPermissionToJSONTyped,
} from "./ChildPermission";

/**
 *
 * @export
 * @interface ResultChildPermission
 */
export interface ResultChildPermission {
  /**
   *
   * @type {Array<ChildPermission>}
   * @memberof ResultChildPermission
   */
  items?: Array<ChildPermission>;
}

/**
 * Check if a given object implements the ResultChildPermission interface.
 */
export function instanceOfResultChildPermission(
  value: object,
): value is ResultChildPermission {
  return true;
}

export function ResultChildPermissionFromJSON(
  json: any,
): ResultChildPermission {
  return ResultChildPermissionFromJSONTyped(json, false);
}

export function ResultChildPermissionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultChildPermission {
  if (json == null) {
    return json;
  }
  return {
    items:
      json["items"] == null
        ? undefined
        : (json["items"] as Array<any>).map(ChildPermissionFromJSON),
  };
}

export function ResultChildPermissionToJSON(json: any): ResultChildPermission {
  return ResultChildPermissionToJSONTyped(json, false);
}

export function ResultChildPermissionToJSONTyped(
  value?: ResultChildPermission | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    items:
      value["items"] == null
        ? undefined
        : (value["items"] as Array<any>).map(ChildPermissionToJSON),
  };
}
