/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ProviderKeySpec
 */
export interface ProviderKeySpec {
  /**
   * The API key/url to use with the notification provider. Not required for emails.
   * @type {string}
   * @memberof ProviderKeySpec
   */
  key: string;
}

/**
 * Check if a given object implements the ProviderKeySpec interface.
 */
export function instanceOfProviderKeySpec(
  value: object,
): value is ProviderKeySpec {
  if (!("key" in value) || value["key"] === undefined) return false;
  return true;
}

export function ProviderKeySpecFromJSON(json: any): ProviderKeySpec {
  return ProviderKeySpecFromJSONTyped(json, false);
}

export function ProviderKeySpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProviderKeySpec {
  if (json == null) {
    return json;
  }
  return {
    key: json["key"],
  };
}

export function ProviderKeySpecToJSON(json: any): ProviderKeySpec {
  return ProviderKeySpecToJSONTyped(json, false);
}

export function ProviderKeySpecToJSONTyped(
  value?: ProviderKeySpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    key: value["key"],
  };
}
