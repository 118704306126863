import {
  createGroupMember,
  getGroupMembers,
  getWorkspaceGroups,
  getWorkspaceMembers,
  setDisplayAssignGroupMemberModal,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { useParams } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { UserPlus } from 'lucide-react';

interface AssignMemberToGroupModalProps {
  groupId: string;
}

export const AssignMemberToGroupModal = ({
  groupId,
}: AssignMemberToGroupModalProps) => {
  const { displayAssignGroupMemberModal } = useAppSelector(
    (state) => state.groups,
  );
  const dispatch = useAppDispatch();
  const { workspaceId } = useParams({ strict: false });

  const [selectedMemberId, setSelectedMemberId] = useState<string>('');

  const { members } = useAppSelector((state) => state.members);
  const { groupMembers } = useAppSelector((state) => state.groups);

  // Filter out members that are already in the group
  const availableMembers = members.filter(
    (member) =>
      !groupMembers.some((groupMember) => groupMember.id === member.id),
  );

  // Fetch workspace members and group members when the modal opens
  useEffect(() => {
    if (displayAssignGroupMemberModal && workspaceId) {
      dispatch(getWorkspaceMembers(workspaceId));
      dispatch(getGroupMembers(groupId));
    }
  }, [dispatch, groupId, workspaceId, displayAssignGroupMemberModal]);

  // Handle assigning a member to the group
  const handleAssignMember = () => {
    if (!selectedMemberId) {
      toast.error('Please select a member to assign');
      return;
    }

    dispatch(
      createGroupMember({
        groupId,
        body: selectedMemberId,
      }),
    )
      .unwrap()
      .then(() => {
        toast.success('Member has been successfully assigned to the group');
        // Refresh the group members list
        dispatch(getGroupMembers(groupId));
        dispatch(getWorkspaceGroups({ workspaceId }));
        handleClose();
      })
      .catch((error) => {
        toast.error(`Failed to assign member: ${error}`);
      });
  };

  const handleClose = () => {
    setSelectedMemberId('');
    dispatch(setDisplayAssignGroupMemberModal(false));
  };

  return (
    <Dialog open={displayAssignGroupMemberModal} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <div className="relative">
            {/* Background Pattern */}
            <div
              className="absolute inset-0 bg-gradient-to-br from-primary/10 via-background to-background"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-[linear-gradient(to_right,transparent_0%,#ffffff08_50%,transparent_100%)] h-[1px] top-1/2" />
            </div>

            {/* Content */}
            <div className="relative px-6 py-8 space-y-4">
              {/* Title Section */}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="p-2.5 rounded-xl bg-primary/10 backdrop-blur-sm">
                    <UserPlus className="h-5 w-5 text-primary" />
                  </div>
                  <div>
                    <DialogTitle className="text-lg font-semibold">
                      Assign Member to Group
                    </DialogTitle>
                    <p className="text-sm text-muted-foreground">
                      Add a member to this group to grant them associated
                      permissions
                    </p>
                  </div>
                </div>
              </div>

              {/* Divider */}
              <Separator className="bg-border/40" />
            </div>
          </div>
        </DialogHeader>

        <div className="space-y-6 px-6">
          {availableMembers.length === 0 ? (
            <div className="text-center py-4 text-muted-foreground">
              No available members to assign. All workspace members are already
              in this group.
            </div>
          ) : (
            <>
              <div className="space-y-4">
                <div>
                  <label className="text-sm font-medium mb-2 block">
                    Choose Member
                  </label>
                  <Select
                    value={selectedMemberId}
                    onValueChange={setSelectedMemberId}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Choose a member to assign" />
                    </SelectTrigger>
                    <SelectContent>
                      {availableMembers.map((member) => (
                        <SelectItem key={member.id} value={member.id || ''}>
                          {member.name || member.email}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <div className="flex justify-end gap-3 pt-4">
                <Button variant="outline" onClick={handleClose}>
                  Cancel
                </Button>
                <Button onClick={handleAssignMember}>Assign</Button>
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
