import {
  AppApi,
  AuthenticationApi,
  Configuration,
  FilesApi,
  FinanceApi,
  GroupsApi,
  MetadataApi,
  NotificationsApi,
  PermissionsApi,
  ReportsApi,
  TasksApi,
  UserApi,
  WorkspaceApi,
} from "@oproma/prividox-orchestration-open-api";

export const baseConfig = new Configuration({
  basePath: import.meta.env.VITE_PRIVIDOX_API_BASE_PATH ?? "/api/v1",
});

export const authenticationApi = new AuthenticationApi(baseConfig);
export const userApi = new UserApi(baseConfig);
export const notificationsApi = new NotificationsApi(baseConfig);
export const metadataApi = new MetadataApi(baseConfig);
export const tasksApi = new TasksApi(baseConfig);
export const workspaceApi = new WorkspaceApi(baseConfig);
export const financeApi = new FinanceApi(baseConfig);
export const filesApi = new FilesApi(baseConfig);
export const reportsApi = new ReportsApi(baseConfig);
export const permissionsApi = new PermissionsApi(baseConfig);
export const groupsApi = new GroupsApi(baseConfig);
export const appApi = new AppApi(baseConfig);
