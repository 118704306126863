import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { CommentsTab } from '@/containers/$workspaceId/file-manager/preview/comments-tab.component';
import { PreviewTab } from '@/containers/$workspaceId/file-manager/preview/preview-tab.component';
import { VersionsTab } from '@/containers/$workspaceId/file-manager/preview/versions-tab.component';
import { useFilePreview } from '@/lib/hooks/use-file-preview.hook';
import { useStarredEntities } from '@/lib/hooks/use-starred-entities.hook';
import { PdfFilePreviewer } from '@/lib/pdfjs';
import {
  entityUnsubscribe,
  useAppDispatch,
  userEntitySubscribe,
} from '@/lib/store';
import { cn, prettyBytes } from '@/lib/utils';
import { useParams } from '@tanstack/react-router';
import {
  Bell,
  BellOff,
  Download,
  Eye,
  FileText,
  History,
  Info,
  MessageSquare,
  ScanEye,
  Share2,
  Star,
  Tag,
} from 'lucide-react';
import { memo, useMemo } from 'react';
import { toast } from 'sonner';
import { ActivityStreamTab } from '../$workspaceId/file-manager/preview/activity-stream-tab.component';
import { ExtractedMetadataTab } from '../$workspaceId/file-manager/preview/extracted-metadata-tab.component';
import { MetadataTab } from '../$workspaceId/file-manager/preview/metadata-tab.component';
import { MediaPreview } from '../../components/preview/media-preview.component';
import { TextPreview } from '../../components/preview/text-preview.component';
import { Badge } from '../../components/ui/badge';

export const FileManagerPreviewModal = memo(() => {
  const dispatch = useAppDispatch();
  const {
    displayPreviewModal,
    lastOpenedEntity,
    openedWorkspace,
    isPDF,
    isOfficeDocument,
    isTextDocument,
    isImage,
    togglePreviewModal,
    handleDownload,
    handleShare,
  } = useFilePreview();
  const { workspaceId } = useParams({ strict: false });
  const { starredEntities, toggleStar: toggleStarred } = useStarredEntities(
    workspaceId as string,
  );

  const handleSubscriptionToggle = () => {
    if (!lastOpenedEntity?.id) return;

    if (lastOpenedEntity.subscribed) {
      dispatch(entityUnsubscribe({ subscribed: lastOpenedEntity.id }));
      toast.success('Unsubscribed from file updates');
    } else {
      dispatch(userEntitySubscribe({ body: lastOpenedEntity.id }));
      toast.success('Subscribed to file updates');
    }
  };

  const StarButton = useMemo(() => {
    const isStarred = starredEntities.some(
      (s) => s.id === lastOpenedEntity?.id,
    );

    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => toggleStarred(lastOpenedEntity?.id)}
              className={cn(
                'h-9 w-9 transition-colors duration-200',
                'hover:bg-muted focus-visible:ring-1 focus-visible:ring-ring',
                isStarred && 'bg-yellow-100/50 dark:bg-yellow-900/20',
              )}
            >
              <Star
                className={cn(
                  'w-4 h-4 transition-colors duration-200',
                  isStarred
                    ? 'fill-yellow-400 text-yellow-400'
                    : 'text-muted-foreground hover:text-yellow-400',
                )}
              />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="bottom">
            {isStarred ? 'Remove from favorites' : 'Add to favorites'}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }, [lastOpenedEntity?.id, starredEntities, toggleStarred]);

  const SubscribeButton = useMemo(() => {
    const isSubscribed = lastOpenedEntity?.subscribed;

    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              size="sm"
              onClick={handleSubscriptionToggle}
              className={cn(
                'transition-colors duration-200',
                'hover:bg-muted focus-visible:ring-1 focus-visible:ring-ring',
                isSubscribed && 'bg-blue-100/50 dark:bg-blue-900/20',
              )}
            >
              {isSubscribed ? (
                <BellOff className="w-4 h-4 mr-2" />
              ) : (
                <Bell className="w-4 h-4 mr-2" />
              )}
              {isSubscribed ? 'Unwatch' : 'Watch'}
            </Button>
          </TooltipTrigger>
          <TooltipContent side="bottom">
            {isSubscribed
              ? 'Stop receiving notifications for this file'
              : 'Receive notifications when this file changes'}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }, [lastOpenedEntity?.subscribed, handleSubscriptionToggle]);

  const ActionButtons = useMemo(
    () => (
      <div className="flex items-center gap-1">
        <Button variant="ghost" size="sm">
          <Eye className="w-4 h-4" />
          Publish
        </Button>

        {SubscribeButton}

        {StarButton}

        <Button
          variant="ghost"
          size="sm"
          onClick={handleDownload}
          className={cn(
            'transition-colors duration-200',
            'hover:bg-muted focus-visible:ring-1 focus-visible:ring-ring',
          )}
        >
          <Download className="w-4 h-4" />
        </Button>

        <Button
          variant="ghost"
          size="sm"
          onClick={handleShare}
          className={cn(
            'transition-colors duration-200',
            'hover:bg-muted focus-visible:ring-1 focus-visible:ring-ring',
          )}
        >
          <Share2 className="w-4 h-4" />
        </Button>
      </div>
    ),
    [handleDownload, handleShare, StarButton, SubscribeButton],
  );

  if (!openedWorkspace || !lastOpenedEntity) return null;

  return (
    <Dialog open={displayPreviewModal} onOpenChange={togglePreviewModal}>
      <DialogContent className="max-w-full h-screen p-0 gap-0">
        {/* Header */}
        <div className="mt-4 flex items-center justify-between p-4 border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <div className="flex items-center gap-4">
            <div
              className={cn(
                'p-3 rounded-lg',
                lastOpenedEntity.type === 'folder'
                  ? 'bg-orange-100'
                  : 'bg-blue-100',
              )}
            >
              <FileText
                className={cn(
                  'w-6 h-6',
                  lastOpenedEntity.type === 'folder'
                    ? 'text-orange-600'
                    : 'text-blue-600',
                )}
              />
            </div>

            <div>
              <div className="flex items-center gap-2">
                <h2 className="text-lg font-semibold text-foreground">
                  {lastOpenedEntity.name}
                </h2>
                <Badge variant="secondary">
                  v{lastOpenedEntity?.numversion ?? 0}
                </Badge>
              </div>
              <p className="text-sm text-muted-foreground">
                {lastOpenedEntity.type?.toUpperCase()} •{' '}
                {prettyBytes(lastOpenedEntity?.size ?? 0)}
              </p>
            </div>
          </div>

          {ActionButtons}
        </div>

        {/* Main Content */}
        <div className="flex flex-1 h-[calc(100vh-4rem)]">
          {/* Left Side - Content */}
          <div className="flex-1">
            <div className="h-full">
              {isPDF || isOfficeDocument ? (
                <PdfFilePreviewer
                  entity={lastOpenedEntity}
                  style={{ width: '100%', height: '100%' }}
                  redactions
                />
              ) : isTextDocument ? (
                <TextPreview entity={lastOpenedEntity} />
              ) : isImage ? (
                <MediaPreview entity={lastOpenedEntity} />
              ) : (
                <div className="flex items-center justify-center h-full">
                  <p className="text-muted-foreground">Preview not available</p>
                </div>
              )}
            </div>
          </div>

          <div className="w-94 border-l">
            <Tabs defaultValue="preview" orientation="vertical">
              <div className="flex h-full">
                <TabsList className="h-full w-16 flex flex-col items-center justify-start gap-2 py-4 bg-muted/50">
                  <TabsTrigger value="preview" className="w-12 h-12">
                    <Info className="w-5 h-5" />
                  </TabsTrigger>
                  <TabsTrigger value="comments" className="w-12 h-12">
                    <MessageSquare className="w-5 h-5" />
                  </TabsTrigger>
                  <TabsTrigger value="versions" className="w-12 h-12">
                    <History className="w-5 h-5" />
                  </TabsTrigger>
                  <TabsTrigger value="activity" className="w-12 h-12">
                    <Bell className="w-5 h-5" />
                  </TabsTrigger>
                  <TabsTrigger value="metadata" className="w-12 h-12">
                    <Tag className="w-5 h-5" />
                  </TabsTrigger>
                  <TabsTrigger value="extracted-metadata" className="w-12 h-12">
                    <ScanEye className="w-5 h-5" />
                  </TabsTrigger>
                </TabsList>

                <div className="flex-1">
                  <TabsContent value="preview" className="m-0 h-full">
                    <ScrollArea className="h-full">
                      <div className="p-4 space-y-6">
                        <PreviewTab entity={lastOpenedEntity} />
                      </div>
                    </ScrollArea>
                  </TabsContent>

                  <TabsContent value="comments" className="m-0 h-full">
                    <CommentsTab entity={lastOpenedEntity} />
                  </TabsContent>

                  <TabsContent value="versions" className="m-0 h-full">
                    <VersionsTab entity={lastOpenedEntity} />
                  </TabsContent>

                  <TabsContent value="activity" className="m-0 h-full">
                    <ActivityStreamTab entity={lastOpenedEntity} />
                  </TabsContent>

                  <TabsContent value="metadata" className="m-0 h-full">
                    <MetadataTab entity={lastOpenedEntity} />
                  </TabsContent>

                  <TabsContent
                    value="extracted-metadata"
                    className="m-0 h-full"
                  >
                    <ExtractedMetadataTab entity={lastOpenedEntity} />
                  </TabsContent>
                </div>
              </div>
            </Tabs>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
});
