/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface NotificationMessage
 */
export interface NotificationMessage {
  /**
   *
   * @type {string}
   * @memberof NotificationMessage
   */
  entityId?: string;
  /**
   *
   * @type {number}
   * @memberof NotificationMessage
   */
  time?: number;
  /**
   *
   * @type {string}
   * @memberof NotificationMessage
   */
  agent?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationMessage
   */
  agentName?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationMessage
   */
  target?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationMessage
   */
  projectId?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationMessage
   */
  projectName?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationMessage
   */
  eventType?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof NotificationMessage
   */
  meta?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof NotificationMessage
   */
  entityType?: string;
}

/**
 * Check if a given object implements the NotificationMessage interface.
 */
export function instanceOfNotificationMessage(
  value: object,
): value is NotificationMessage {
  return true;
}

export function NotificationMessageFromJSON(json: any): NotificationMessage {
  return NotificationMessageFromJSONTyped(json, false);
}

export function NotificationMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): NotificationMessage {
  if (json == null) {
    return json;
  }
  return {
    entityId: json["entityId"] == null ? undefined : json["entityId"],
    time: json["time"] == null ? undefined : json["time"],
    agent: json["agent"] == null ? undefined : json["agent"],
    agentName: json["agentName"] == null ? undefined : json["agentName"],
    target: json["target"] == null ? undefined : json["target"],
    projectId: json["projectId"] == null ? undefined : json["projectId"],
    projectName: json["projectName"] == null ? undefined : json["projectName"],
    eventType: json["eventType"] == null ? undefined : json["eventType"],
    meta: json["meta"] == null ? undefined : json["meta"],
    entityType: json["entityType"] == null ? undefined : json["entityType"],
  };
}

export function NotificationMessageToJSON(json: any): NotificationMessage {
  return NotificationMessageToJSONTyped(json, false);
}

export function NotificationMessageToJSONTyped(
  value?: NotificationMessage | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    entityId: value["entityId"],
    time: value["time"],
    agent: value["agent"],
    agentName: value["agentName"],
    target: value["target"],
    projectId: value["projectId"],
    projectName: value["projectName"],
    eventType: value["eventType"],
    meta: value["meta"],
    entityType: value["entityType"],
  };
}
