import {
  GroupRow,
  setGroupPaginatedPage,
  useAppDispatch,
  useAppSelector,
} from "@/lib/store";
import { useMemo } from "react";

export const useGroupsPagination = (items: GroupRow[]) => {
  const dispatch = useAppDispatch();
  const { currentPaginatedPage, entriesPerPaginatedPage } = useAppSelector(
    (state) => state.groups
  );

  const paginatedItems = useMemo(() => {
    const startIndex = (currentPaginatedPage - 1) * entriesPerPaginatedPage;
    const endIndex = startIndex + entriesPerPaginatedPage;
    return items.slice(startIndex, endIndex);
  }, [items, currentPaginatedPage, entriesPerPaginatedPage]);

  return {
    paginatedItems,
    currentPage: currentPaginatedPage,
    totalPages: Math.ceil(items.length / entriesPerPaginatedPage),
    setPage: (page: number) => dispatch(setGroupPaginatedPage(page)),
  };
};
