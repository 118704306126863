import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { NAMESPACES, useTranslation } from '@/lib/i18n';
import {
  LanguageEnum,
  setLanguage,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { ChevronUpIcon } from 'lucide-react';
import { memo } from 'react';

export const footerLinks = [
  {
    title: 'FOOTER.DOCUMENTATION',
    href: 'https://kb.prividox.com/',
  },
  {
    title: 'FOOTER.PRIVACY_POLICY',
    href: 'https://oproma.com/privacy',
  },
  {
    title: 'FOOTER.WEBSITE',
    href: 'https://oproma.com/prividox/',
  },
];

const EnglishFlagPng = '/flags/uk.png';
const FrenchFlagPng = '/flags/french.png';

export const Footer = memo(() => {
  const { t, i18n } = useTranslation(NAMESPACES.FOOTER);
  const dispatch = useAppDispatch();
  const { language } = useAppSelector((state) => state.layout);
  const year = new Date().getFullYear();

  const changeLanguage = (newLanguage: LanguageEnum) => {
    dispatch(setLanguage(newLanguage));
    const lang = newLanguage === LanguageEnum.EN ? 'en-US' : 'fr-FR';
    i18n.changeLanguage(lang);
  };

  return (
    <footer className="w-full py-6 px-4 border-t bg-background/95 supports-[backdrop-filter]:bg-background/60">
      <div className="container mx-auto max-w-7xl">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-center">
          {/* Copyright Text - Left on Desktop */}
          <div className="order-2 lg:order-1 text-center lg:text-left">
            <p className="text-sm text-muted-foreground">
              {t('footer:FOOTER.COPYRIGHT', {
                year,
                version: import.meta.env.VITE_APP_VERSION ?? '0.1',
              })}
            </p>
          </div>

          {/* Navigation and Language - Right on Desktop */}
          <nav className="order-1 lg:order-2">
            <ul className="flex flex-wrap items-center justify-center lg:justify-end gap-x-4 gap-y-2">
              {/* Footer Links */}
              {footerLinks.map((link) => (
                <li key={link.title}>
                  <a
                    href={link.href}
                    className="text-sm text-muted-foreground hover:text-primary transition-colors"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t(link.title, link.title)}
                  </a>
                </li>
              ))}

              {/* Language Selector */}
              <li>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="flex items-center gap-2"
                    >
                      <span>
                        {language === LanguageEnum.EN ? 'English' : 'Français'}
                      </span>
                      <ChevronUpIcon className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-[150px]">
                    <DropdownMenuItem
                      onClick={() => changeLanguage(LanguageEnum.EN)}
                      className="flex items-center gap-2"
                    >
                      <img
                        src={EnglishFlagPng}
                        alt="English"
                        className="h-4 w-4 rounded-full object-cover"
                      />
                      <span>English</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => changeLanguage(LanguageEnum.FR)}
                      className="flex items-center gap-2"
                    >
                      <img
                        src={FrenchFlagPng}
                        alt="Français"
                        className="h-4 w-4 rounded-full object-cover"
                      />
                      <span>Français</span>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
});
