import { NotificationMessage } from "@oproma/prividox-orchestration-open-api";

export function getMemberActivityContent(
  notifications: NotificationMessage[],
): [string, NotificationMessage][] {
  const memberActivityContent: [string, NotificationMessage][] =
    notifications.map((notification) => {
      const key = getTranslationKey(notification);
      return [key, notification];
    });
  return memberActivityContent;
}

function getTranslationKey(notification: NotificationMessage) {
  let key = "DEFAULT";
  switch (notification.eventType) {
    case "CREATED":
      key = `${notification.eventType}_${notification.entityType}`;
      break;
    case "CREATED_TASK":
      key = `${notification.eventType}_${notification.entityType}`;
      break;
    case "CHILD_REMOVED":
      if (
        notification.meta?.CHILD_NAME?.startsWith(
          `/${notification.projectName}/.Trash/`,
        )
      ) {
        key = "CHILD_TRASHED";
      }
    // fallthrough
    case "CHILD_ADDED":
      if (!key) {
        key = `${notification.eventType}_${notification.entityType}`;
      }
      if (notification.entityType === "FOLDER") {
        key += `_${notification.meta?.CHILD_TYPE}`;
      }
      break;
    case "COMMENTED":
    case "METADATA_SET":
      key = notification.meta?.OLD_VALUE ? "METADATA_SET_OLD" : "METADATA_SET";
      break;
    case "METADATA_DELETED":
    case "EMAIL_CHANGED":
    case "UPDATED_DISPLAY_NAME":
    case "RENAMED":
      key = `${notification.eventType}_${notification.entityType}`;
      break;
    case "MOVED":
      key = `${notification.eventType}_${notification.entityType}`;
      break;
    case "DEFAULT_GROUP_SET":
    case "COPIED":
    case "RESTORED":
      key = `${notification.eventType}_${notification.entityType}`;
      break;
    case "CUSTOM_ENTITY_ADDED":
    case "CUSTOM_ENTITY_REMOVED":
    case "MEMBER_ADDED":
      key = notification.eventType;
      break;
    case "MEMBER_REMOVED":
      key = notification.eventType;
      break;
    case "DOWNLOADED":
    case "PREVIEWED":
    case "DELETED":
    case "TRASHED":
    case "LOCKED":
    case "PUBLISHED":
    case "UNLOCKED": {
      const childType = notification.meta?.CHILD_TYPE || "";
      if (childType !== "") {
        key = `${notification.eventType}_${childType}`;
      }
      break;
    }
    case "CARD_ADDED":
    case "CARD_REMOVED":
    case "DEFAULT_CARD_CHANGED":
    case "PASSWORD_RESET_REQUESTED":
    case "TEST":
    case "SCRIPT_NOTIFICATION":
    case "CARD_UPDATED":
    case "NOTIFICATION_TYPE_TOGGLED":
    case "LOGIN":
    case "LOGOUT":
    case "SESSION_REFRESHED":
    case "ACCOUNT_CREATED":
    case "ACCOUNT_ACTIVATED":
    case "EMAIL_CHANGE_REQUESTED":
    case "UPDATED_PASSWORD":
    case "NOTIFICATION_PROVIDER_ADDED":
    case "NOTIFICATION_PROVIDER_REMOVED":
    case "APPLICATION_ACCESS_GRANTED":
    case "APPLICATION_ACCESS_REVOKED":
    case "PAYMENT_PLAN_UPDATED":
    case "PROJECT_LEFT":
    case "TRASH_EMPTIED":
    case "REDACTED":
    default:
  }
  return key ?? undefined;
}
