/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { Redaction } from "./Redaction";
import {
  RedactionFromJSON,
  RedactionFromJSONTyped,
  RedactionToJSON,
  RedactionToJSONTyped,
} from "./Redaction";

/**
 *
 * @export
 * @interface RedactSpec
 */
export interface RedactSpec {
  /**
   * The set of redactions to apply to the document
   * @type {Array<Redaction>}
   * @memberof RedactSpec
   */
  redactions: Array<Redaction>;
  /**
   * The set of pages from the original document to include in the redacted version.
   * @type {Array<number>}
   * @memberof RedactSpec
   */
  pages: Array<number>;
  /**
   * The name to give to the redaction version
   * @type {string}
   * @memberof RedactSpec
   */
  name: string;
  /**
   * The text of tbe watermark to apply to the redacted document.
   * @type {string}
   * @memberof RedactSpec
   */
  watermark: string;
  /**
   * The type of redaction zone to apply to the document.
   * @type {string}
   * @memberof RedactSpec
   */
  style: RedactSpecStyleEnum;
  /**
   * Whether the PriviDox metadata should be copied from the old document to the new one.
   * @type {boolean}
   * @memberof RedactSpec
   */
  copy: boolean;
  /**
   * The password needed to open the original document.
   * @type {string}
   * @memberof RedactSpec
   */
  sourcePass?: string;
  /**
   * The password to apply to the redacted version of the document.
   * @type {string}
   * @memberof RedactSpec
   */
  destPass?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RedactSpecStyleEnum {
  normal = "normal",
  white = "white",
}

/**
 * Check if a given object implements the RedactSpec interface.
 */
export function instanceOfRedactSpec(value: object): value is RedactSpec {
  if (!("redactions" in value) || value["redactions"] === undefined)
    return false;
  if (!("pages" in value) || value["pages"] === undefined) return false;
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("watermark" in value) || value["watermark"] === undefined) return false;
  if (!("style" in value) || value["style"] === undefined) return false;
  if (!("copy" in value) || value["copy"] === undefined) return false;
  return true;
}

export function RedactSpecFromJSON(json: any): RedactSpec {
  return RedactSpecFromJSONTyped(json, false);
}

export function RedactSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RedactSpec {
  if (json == null) {
    return json;
  }
  return {
    redactions: (json["redactions"] as Array<any>).map(RedactionFromJSON),
    pages: json["pages"],
    name: json["name"],
    watermark: json["watermark"],
    style: json["style"],
    copy: json["copy"],
    sourcePass: json["sourcePass"] == null ? undefined : json["sourcePass"],
    destPass: json["destPass"] == null ? undefined : json["destPass"],
  };
}

export function RedactSpecToJSON(json: any): RedactSpec {
  return RedactSpecToJSONTyped(json, false);
}

export function RedactSpecToJSONTyped(
  value?: RedactSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    redactions: (value["redactions"] as Array<any>).map(RedactionToJSON),
    pages: value["pages"],
    name: value["name"],
    watermark: value["watermark"],
    style: value["style"],
    copy: value["copy"],
    sourcePass: value["sourcePass"],
    destPass: value["destPass"],
  };
}
