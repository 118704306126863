/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ProjectInformation
 */
export interface ProjectInformation {
  /**
   *
   * @type {string}
   * @memberof ProjectInformation
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectInformation
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof ProjectInformation
   */
  bytesLimit?: number;
  /**
   *
   * @type {number}
   * @memberof ProjectInformation
   */
  filesLimit?: number;
  /**
   *
   * @type {number}
   * @memberof ProjectInformation
   */
  foldersLimit?: number;
  /**
   *
   * @type {number}
   * @memberof ProjectInformation
   */
  membersLimit?: number;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  subscribed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  zipExpand?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  filePublish?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  forceSecurity?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  allowProduction?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  allowRedaction?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  allowReview?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  allowConversions?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProjectInformation
   */
  root?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  amOwner?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProjectInformation
   */
  level?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectInformation
   */
  access?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ProjectInformation
   */
  metadata?: { [key: string]: string };
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  admin?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectInformation
   */
  isAdmin?: boolean;
}

/**
 * Check if a given object implements the ProjectInformation interface.
 */
export function instanceOfProjectInformation(
  value: object,
): value is ProjectInformation {
  return true;
}

export function ProjectInformationFromJSON(json: any): ProjectInformation {
  return ProjectInformationFromJSONTyped(json, false);
}

export function ProjectInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProjectInformation {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"] == null ? undefined : json["id"],
    name: json["name"] == null ? undefined : json["name"],
    bytesLimit: json["bytesLimit"] == null ? undefined : json["bytesLimit"],
    filesLimit: json["filesLimit"] == null ? undefined : json["filesLimit"],
    foldersLimit:
      json["foldersLimit"] == null ? undefined : json["foldersLimit"],
    membersLimit:
      json["membersLimit"] == null ? undefined : json["membersLimit"],
    enabled: json["enabled"] == null ? undefined : json["enabled"],
    subscribed: json["subscribed"] == null ? undefined : json["subscribed"],
    zipExpand: json["zipExpand"] == null ? undefined : json["zipExpand"],
    filePublish: json["filePublish"] == null ? undefined : json["filePublish"],
    forceSecurity:
      json["forceSecurity"] == null ? undefined : json["forceSecurity"],
    allowProduction:
      json["allowProduction"] == null ? undefined : json["allowProduction"],
    allowRedaction:
      json["allowRedaction"] == null ? undefined : json["allowRedaction"],
    allowReview: json["allowReview"] == null ? undefined : json["allowReview"],
    allowConversions:
      json["allowConversions"] == null ? undefined : json["allowConversions"],
    root: json["root"] == null ? undefined : json["root"],
    amOwner: json["amOwner"] == null ? undefined : json["amOwner"],
    level: json["level"] == null ? undefined : json["level"],
    access: json["access"] == null ? undefined : json["access"],
    metadata: json["metadata"] == null ? undefined : json["metadata"],
    admin: json["admin"] == null ? undefined : json["admin"],
    isAdmin: json["isAdmin"] == null ? undefined : json["isAdmin"],
  };
}

export function ProjectInformationToJSON(json: any): ProjectInformation {
  return ProjectInformationToJSONTyped(json, false);
}

export function ProjectInformationToJSONTyped(
  value?: ProjectInformation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    name: value["name"],
    bytesLimit: value["bytesLimit"],
    filesLimit: value["filesLimit"],
    foldersLimit: value["foldersLimit"],
    membersLimit: value["membersLimit"],
    enabled: value["enabled"],
    subscribed: value["subscribed"],
    zipExpand: value["zipExpand"],
    filePublish: value["filePublish"],
    forceSecurity: value["forceSecurity"],
    allowProduction: value["allowProduction"],
    allowRedaction: value["allowRedaction"],
    allowReview: value["allowReview"],
    allowConversions: value["allowConversions"],
    root: value["root"],
    amOwner: value["amOwner"],
    level: value["level"],
    access: value["access"],
    metadata: value["metadata"],
    admin: value["admin"],
    isAdmin: value["isAdmin"],
  };
}
