import {
  Child,
  CopyToRequest,
  CreateFolderRequest,
  FolderInformation,
  ResultNotificationMessage,
  UploadRequest,
} from '@oproma/prividox-orchestration-open-api';
import { TFunction } from 'i18next';

export interface Entity extends Child {
  // custom fields
  id: string;
  svg: string;
  metadata?: EntityMetadata;
}

export enum FolderTypeEnum {
  GENERAL = 'general',
  CALENDAR = 'calendar',
  GALLERY = 'gallery',
}

export interface CreateFolderWithTypeRequest extends CreateFolderRequest {
  type: FolderTypeEnum;
}

export interface CopyToRequestWithId extends CopyToRequest {
  folderId: string | undefined;
}

export interface EntityMetadata {
  SHA1: string;
  importance: number;
  type: string;
  subscribed: boolean;
  modified: number;
  creatorname: string;
  uploading: boolean;
  id: string;
  contentType: string;
  editor: string;
  creator: string;
  comments: number;
  level: string;
  created: number;
  author: string;
  produce: boolean;
  'time::creation': string;
  tsize: number;
  SHA256: string;
  deleted: boolean;
  encrypted: boolean;
  size: number;
  numversion: number;
  name: string;
  locktype: string;
  MD5: string;
  editorname: string;
  // custom metadata fields
  starred: string;
  parentId: string;
}

export enum FilesErrorCodesEnum {
  GET_ENTITY_COMMENTS_FAILED = 'GET_ENTITY_COMMENTS_FAILED',
  GET_ENTITY_VERSIONS_FAILED = 'GET_ENTITY_VERSIONS_FAILED',
  GET_ENTITIES_FAILED = 'GET_ENTITIES_FAILED',
  GET_CHILD_ENTITIES_FAILED = 'GET_CHILD_ENTITIES_FAILED',
  GET_ENTITY_PARENTS_FAILED = 'GET_ENTITY_PARENTS_FAILED',
  GET_ENTITY_PARENTS_FAILED_FOR_MOVE = 'GET_ENTITY_PARENTS_FAILED_FOR_MOVE',
  GET_ENTITY_PARENT_FAILED = 'GET_ENTITY_PARENT_FAILED',
  GET_ENTITIES_METADATA_FAILED = 'GET_ENTITIES_METADATA_FAILED',
  GET_TRASH_ENTITIES_FAILED = 'GET_TRASH_ENTITIES_FAILED',
  GET_ENTITY_FAILED = 'GET_ENTITY_FAILED',
  GET_ENTITY_EXTRACTED_METADATA_FAILED = 'GET_ENTITY_EXTRACTED_METADATA_FAILED',
  CREATE_ENTITY_FAILED = 'CREATE_ENTITY_FAILED',
  EDIT_ENTITY_METADATA_FAILED = 'EDIT_ENTITY_METADATA_FAILED',
  COPY_ENTITY_FAILED = 'COPY_ENTITY_FAILED',
  MOVE_ENTITY_FAILED = 'MOVE_ENTITY_FAILED',
  STAR_ENTITY_FAILED = 'STAR_ENTITY_FAILED',
  DELETE_ENTITY_FAILED = 'DELETE_ENTITY_FAILED',
  RESTORE_ENTITY_FAILED = 'RESTORE_ENTITY_FAILED',
  EMPTY_TRASH_FAILED = 'EMPTY_TRASH_FAILED',
  NAME_EXISTS_FAILED = 'NAME_EXISTS_FAILED',
  GET_COVERTED_DOC_FAILED = 'GET_COVERTED_DOC_FAILED',
  SET_PROTECTION_LEVEL_FAILED = 'SET_PROTECTION_LEVEL_FAILED',
  GET_ENTITIES_EXPORT_FAILED = `GET_ENTITIES_EXPORT_FAILED`,
}

export interface IFileManagerState {
  readonly entityParents: FolderInformation[];
  readonly entityParentsForMove: FolderInformation[];
  readonly entities: Entity[];
  readonly entityParent: string | null;
  readonly entityCreationProgress: Record<string, number>;
  readonly uploadStates: {
    [processUid: string]: UploadState;
  };
  readonly isMinimized: boolean;
  readonly plan: string;
  readonly protected: string;
  readonly entityQuery: string;
  readonly trashEntities: Entity[];
  readonly bulkAction: boolean;
  readonly bulkPaste: boolean;
  readonly previewEntities: Entity[];
  readonly displayBulkDeleteEntityModal: boolean;
  readonly displayBulkDistributeModal: boolean;
  readonly convertedDocument: string;
  readonly queuedFiles: FileWithPath[];
  readonly displayType: 'grid' | 'group' | 'list';
  readonly lastOpenedEntity: Entity | null;
  readonly changedEntityName: boolean;
  readonly displayQuickNavigation: boolean;
  readonly displayMobileFinder: boolean;
  readonly displayMobileNavbar: boolean;
  readonly displayRemoveEntityModal: boolean;
  readonly displayMobileRecoveryFilter: boolean;
  readonly displayCreateFileModal: boolean;
  readonly selectedEntityIds: string[];
  readonly lastSelectedEntityId: string | null;
  readonly displayCreateFolderModal: boolean;
  readonly displayMetadataModal: boolean;
  readonly displayMoveEntityModal: boolean;
  readonly displayPreviewFileModal: boolean;
  readonly changedSecurityLevel: boolean;
  readonly displayDistributeEntityModal: boolean;
  readonly displayRenameEntityModal: boolean;
  readonly displayConvertConfirmModal: boolean;
  readonly entityNotifications: ResultNotificationMessage;
  readonly entityVersions: { [key: string]: { [key: string]: object } } | null;
  readonly entityExtractedMetadata: { [key: string]: string } | null;
  readonly loading: boolean;
  readonly fileUploading: { [key: string]: boolean };
  readonly error: {
    code: FilesErrorCodesEnum;
    message: string;
  } | null;
}

export interface UploadState {
  progress: number;
  status: 'idle' | 'uploading' | 'completed' | 'error' | 'cancelled';
  error?: string;
}

export interface GetEntitiesPayload {
  entityId: string;
  previewEntities?: boolean;
}

export interface EditEntityMetadataPayload {
  entityId: string;
  key: string;
  value: string;
}

export interface CreateFilePayload {
  processUid: string;
  file: FileWithPath;
  metadata: UploadRequest;
  signal?: AbortSignal;
  t?: TFunction;
}

export interface FileWithPath extends File {
  filepath?: string;
}
