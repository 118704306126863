export const retrieveApiErrorMessage = (error: string) => {
  let errorKey, errorType;

  try {
    if (!error) return "ERROR.Unknown";
    const parsedError = JSON.parse(error);
    [errorKey, errorType] = parsedError.error.split("::");
  } catch {
    [errorKey, errorType] = error.split("::");
  }

  return `${errorKey}.${errorType}`;
};
