import { FileWithPath } from "@/lib/store";

let didShowInfo = false;

export const fetchFilesRecursively = async (
  dataTransferItems: DataTransferItemList,
  options = { raw: false }
): Promise<FileWithPath[]> => {
  const checkErr = (err: Error) => {
    if (didShowInfo) return;
    if (err.name !== "EncodingError") return;
    didShowInfo = true;
    const infoMsg =
      `${err.name} occured within datatransfer-files-promise module\n` +
      `Error message: "${err.message}"\n` +
      "Try serving html over http if currently you are running it from the filesystem.";
    console.warn(infoMsg);
  };

  const readFile = (entry: FileSystemFileEntry, path = "") => {
    return new Promise((resolve, reject) => {
      entry.file(
        (file) => {
          if (!options.raw) (file as FileWithPath).filepath = path + file.name;
          resolve(file);
        },
        (err: Error) => {
          checkErr(err);
          reject(err);
        }
      );
    });
  };

  const dirReadEntries = (
    dirReader: FileSystemDirectoryReader,
    path: string
  ): Promise<FileWithPath[]> => {
    return new Promise((resolve, reject) => {
      dirReader.readEntries(
        async (entries) => {
          let files: FileWithPath[] = [];
          for (const entry of entries) {
            const itemFiles = await getFilesFromEntry(entry, path);
            files = files.concat(itemFiles);
          }
          resolve(files);
        },
        (err) => {
          checkErr(err);
          reject(err);
        }
      );
    });
  };

  const readDir = async (entry: FileSystemDirectoryEntry, path: string) => {
    const dirReader = entry.createReader();
    const newPath = path + entry.name + "/";
    let files: FileWithPath[] = [];
    let newFiles: FileWithPath[] = [];
    do {
      newFiles = await dirReadEntries(dirReader, newPath);
      files = files.concat(newFiles);
    } while (newFiles.length > 0);
    return files;
  };

  const getFilesFromEntry = async (
    entry: FileSystemEntry | null,
    path = ""
  ): Promise<FileWithPath[]> => {
    if (entry?.isFile) {
      const file = (await readFile(
        entry as FileSystemFileEntry,
        path
      )) as FileWithPath;
      return [file];
    }
    if (entry?.isDirectory) {
      const files = await readDir(entry as FileSystemDirectoryEntry, path);
      return files;
    }
    throw new Error(
      "Entry not isFile and not isDirectory - unable to get files"
    );
  };

  let files: FileWithPath[] = [];
  const entries = [];

  // Pull out all entries before reading them
  for (let i = 0, ii = dataTransferItems.length; i < ii; i++) {
    const newEntry = dataTransferItems[i].webkitGetAsEntry();
    entries.push(newEntry);
  }

  // Recursively read through all entries
  for (const entry of entries) {
    const newFiles: FileWithPath[] = await getFilesFromEntry(entry);
    files = files.concat(newFiles);
  }

  return files;
};
