import {
  GetMemberActivityRequest,
  GetWorkspaceActivityRequest,
} from "@oproma/prividox-orchestration-open-api";
import { reportsApi } from "./config";

const fetchMemberActivity = (payload: GetMemberActivityRequest) => {
  return reportsApi.getMemberActivity(payload);
};

const fetchDiskUsage = (workspaceId: string) => {
  return reportsApi.getDiskUsageTotal({
    project: workspaceId,
  });
};

const fetchWorkspaceActivity = (payload: GetWorkspaceActivityRequest) => {
  return reportsApi.getWorkspaceActivity(payload);
};

export const reportsService = {
  fetchMemberActivity,
  fetchDiskUsage,
  fetchWorkspaceActivity,
};
