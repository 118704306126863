/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface LRUInfo
 */
export interface LRUInfo {
  /**
   *
   * @type {string}
   * @memberof LRUInfo
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof LRUInfo
   */
  modified?: number;
  /**
   *
   * @type {number}
   * @memberof LRUInfo
   */
  lastAccessed?: number;
  /**
   *
   * @type {string}
   * @memberof LRUInfo
   */
  path?: string;
}

/**
 * Check if a given object implements the LRUInfo interface.
 */
export function instanceOfLRUInfo(value: object): value is LRUInfo {
  return true;
}

export function LRUInfoFromJSON(json: any): LRUInfo {
  return LRUInfoFromJSONTyped(json, false);
}

export function LRUInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LRUInfo {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"] == null ? undefined : json["id"],
    modified: json["modified"] == null ? undefined : json["modified"],
    lastAccessed:
      json["lastAccessed"] == null ? undefined : json["lastAccessed"],
    path: json["path"] == null ? undefined : json["path"],
  };
}

export function LRUInfoToJSON(json: any): LRUInfo {
  return LRUInfoToJSONTyped(json, false);
}

export function LRUInfoToJSONTyped(
  value?: LRUInfo | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    modified: value["modified"],
    lastAccessed: value["lastAccessed"],
    path: value["path"],
  };
}
