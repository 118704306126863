/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CommentSpec
 */
export interface CommentSpec {
  /**
   * The text of the comment to add. Will notify users whose emails have been tagged in the comment. E.g. "@test@example.com".
   * @type {string}
   * @memberof CommentSpec
   */
  comment: string;
}

/**
 * Check if a given object implements the CommentSpec interface.
 */
export function instanceOfCommentSpec(value: object): value is CommentSpec {
  if (!("comment" in value) || value["comment"] === undefined) return false;
  return true;
}

export function CommentSpecFromJSON(json: any): CommentSpec {
  return CommentSpecFromJSONTyped(json, false);
}

export function CommentSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CommentSpec {
  if (json == null) {
    return json;
  }
  return {
    comment: json["comment"],
  };
}

export function CommentSpecToJSON(json: any): CommentSpec {
  return CommentSpecToJSONTyped(json, false);
}

export function CommentSpecToJSONTyped(
  value?: CommentSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    comment: value["comment"],
  };
}
