/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface LoginSpec
 */
export interface LoginSpec {
  /**
   * The email belonging to the authenticating user.
   * @type {string}
   * @memberof LoginSpec
   */
  email: string;
  /**
   * The password belonging to the authenticating user. Minimum length to be increased to 12.
   * @type {string}
   * @memberof LoginSpec
   */
  pass: string;
  /**
   * A fingerprint to associate with the environment to identify logins on new devices.
   * @type {string}
   * @memberof LoginSpec
   */
  fingerprint: string;
  /**
   * The TOTP token to use to authenticate the user.
   * @type {string}
   * @memberof LoginSpec
   */
  token?: string;
  /**
   * The session inactivity timeout value.
   * @type {number}
   * @memberof LoginSpec
   */
  exp?: number;
}

/**
 * Check if a given object implements the LoginSpec interface.
 */
export function instanceOfLoginSpec(value: object): value is LoginSpec {
  if (!("email" in value) || value["email"] === undefined) return false;
  if (!("pass" in value) || value["pass"] === undefined) return false;
  if (!("fingerprint" in value) || value["fingerprint"] === undefined)
    return false;
  return true;
}

export function LoginSpecFromJSON(json: any): LoginSpec {
  return LoginSpecFromJSONTyped(json, false);
}

export function LoginSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LoginSpec {
  if (json == null) {
    return json;
  }
  return {
    email: json["email"],
    pass: json["pass"],
    fingerprint: json["fingerprint"],
    token: json["token"] == null ? undefined : json["token"],
    exp: json["exp"] == null ? undefined : json["exp"],
  };
}

export function LoginSpecToJSON(json: any): LoginSpec {
  return LoginSpecToJSONTyped(json, false);
}

export function LoginSpecToJSONTyped(
  value?: LoginSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    email: value["email"],
    pass: value["pass"],
    fingerprint: value["fingerprint"],
    token: value["token"],
    exp: value["exp"],
  };
}
