import { useTranslation } from '@/lib/i18n';
import {
  LanguageEnum,
  setLanguage,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { Check } from 'lucide-react';
import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';

const EnglishFlagPng = '/flags/uk.png';
const FrenchFlagPng = '/flags/french.png';

interface LanguageOption {
  value: LanguageEnum;
  label: string;
  flag: string;
  locale: string;
}

const languages: LanguageOption[] = [
  {
    value: LanguageEnum.EN,
    label: 'English',
    flag: EnglishFlagPng,
    locale: 'en-US',
  },
  {
    value: LanguageEnum.FR,
    label: 'Français',
    flag: FrenchFlagPng,
    locale: 'fr-FR',
  },
];

export const LanguageChanger = () => {
  const dispatch = useAppDispatch();
  const { language } = useAppSelector((state) => state.layout);
  const { i18n } = useTranslation();

  const currentLanguage =
    languages.find((lang) => lang.value === language) || languages[0];

  const changeLanguage = (newLanguage: LanguageOption) => {
    dispatch(setLanguage(newLanguage.value));
    i18n.changeLanguage(newLanguage.locale);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="relative w-6 h-6 rounded-full overflow-hidden"
        >
          <img
            src={currentLanguage.flag}
            alt={currentLanguage.label}
            className="object-cover w-full h-full"
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[180px]">
        {languages.map((lang) => (
          <DropdownMenuItem
            key={lang.value}
            onClick={() => changeLanguage(lang)}
            className="flex items-center gap-2 px-2 py-2 cursor-pointer"
          >
            <div className="flex items-center gap-2 flex-1">
              <div className="w-5 h-5 rounded-full overflow-hidden">
                <img
                  src={lang.flag}
                  alt={lang.label}
                  className="w-full h-full object-cover"
                />
              </div>
              <span className="flex-1">{lang.label}</span>
            </div>
            {language === lang.value && (
              <Check className="h-4 w-4 text-primary" />
            )}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
