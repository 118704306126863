import { Alert, AlertDescription } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { useFileManagerDistributionEntityForm } from '@/lib/hooks/use-file-manager-distribute-entity-form.hook';
import { useTranslation } from '@/lib/i18n';
import {
  getEntityParent,
  getWorkspaceMembers,
  useAppDispatch,
} from '@/lib/store';
import { cn, prettyBytes } from '@/lib/utils';
import { useParams } from '@tanstack/react-router';
import { AnimatePresence, motion } from 'framer-motion';
import {
  AlertTriangle,
  Copy,
  FileIcon,
  Globe,
  Mail,
  Mail as MailIcon,
  Share,
} from 'lucide-react';
import { useEffect } from 'react';
import { toast } from 'sonner';
import { MicrosoftSvg } from '../../components/svg/microsoft.svg.component';

export const DistributeModal = () => {
  const { t } = useTranslation();
  const { entityId } = useParams({ strict: false });
  const dispatch = useAppDispatch();
  const {
    entity,
    toggle,
    errors,
    workspaceId,
    membersOptions,
    distributionLink,
    lastOpenedEntity,
    handleSelectChange,
    handleDispatchClick,
    displayEmailButtons,
    handleNativeEmailing,
    handleEmailWithGmail,
    handleEmailShareYahoo,
    handleEmailWithOutlook,
    setDisplayEmailButtons,
    displayBulkDistributeModal,
    displayDistributeEntityModal,
  } = useFileManagerDistributionEntityForm();

  const noMembers = membersOptions.length < 1;

  useEffect(() => {
    if (!workspaceId || !entityId) return;
    dispatch(getWorkspaceMembers(workspaceId));
    dispatch(getEntityParent(entityId));
  }, [workspaceId, entityId]);

  return (
    <Dialog
      open={displayDistributeEntityModal || displayBulkDistributeModal}
      onOpenChange={toggle}
    >
      <DialogContent className="sm:max-w-[600px]">
        {/* Header */}
        <div className="flex items-center justify-between pb-4">
          {displayDistributeEntityModal ? (
            <div className="flex items-center gap-4">
              <div
                className={cn(
                  'p-3 rounded-lg',
                  lastOpenedEntity?.type === 'folder'
                    ? 'bg-orange-100'
                    : 'bg-blue-100',
                )}
              >
                <FileIcon
                  className={cn(
                    'w-6 h-6',
                    lastOpenedEntity?.type === 'folder'
                      ? 'text-orange-600'
                      : 'text-blue-600',
                  )}
                />
              </div>
              <div>
                <h2 className="text-lg font-semibold">{entity?.name}</h2>
                <p className="text-sm text-muted-foreground">
                  {prettyBytes(entity?.size ?? 0)}
                </p>
              </div>
            </div>
          ) : (
            <h2 className="text-lg font-semibold">
              {t('file-manager:FILE_MANAGER.DISTRIBUTE.SHARE')}
            </h2>
          )}
        </div>

        <Separator />

        {/* Warning for no members */}
        {noMembers && (
          <Alert variant="default">
            <AlertTriangle className="h-4 w-4" />
            <AlertDescription>
              {t('file-manager:FILE_MANAGER.DISTRIBUTE.NO_MEMBERS')}
            </AlertDescription>
          </Alert>
        )}

        {/* Share Link Section */}
        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <Input value={distributionLink} readOnly className="flex-1" />
            <Button
              variant="outline"
              onClick={() => {
                if (distributionLink) {
                  navigator.clipboard.writeText(distributionLink);
                  toast.success(
                    t('file-manager:FILE_MANAGER.DISTRIBUTE.TOAST'),
                  );
                } else {
                  toast.error(
                    t('file-manager:FILE_MANAGER.DISTRIBUTE.TOAST_ERROR'),
                  );
                }
              }}
            >
              <Copy className="h-4 w-4 mr-2" />
              {t('file-manager:FILE_MANAGER.DISTRIBUTE.COPY_LINK')}
            </Button>
          </div>

          <div className="flex items-center gap-2">
            <Button
              variant="outline"
              onClick={handleNativeEmailing}
              disabled={
                noMembers ||
                !(
                  navigator.canShare &&
                  navigator.canShare({ url: distributionLink })
                )
              }
            >
              <Share className="h-4 w-4 mr-2" />
              {t('file-manager:FILE_MANAGER.DISTRIBUTE.EMAIL_NATIVELY')}
            </Button>

            <Button
              variant="outline"
              onClick={() => setDisplayEmailButtons(!displayEmailButtons)}
              disabled={noMembers}
            >
              <Mail className="h-4 w-4 mr-2" />
              {t('file-manager:FILE_MANAGER.DISTRIBUTE.EMAIL')}
            </Button>
          </div>
        </div>

        {/* Email Options */}
        <AnimatePresence>
          {displayEmailButtons && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="space-y-4"
            >
              <div className="space-y-2">
                <label className="text-sm font-medium">
                  {t('file-manager:FILE_MANAGER.DISTRIBUTE.RECIPIENT')}
                </label>
                <Select
                  value={membersOptions[0].value}
                  onValueChange={(value) => {
                    console.log(value);
                    const selectedOption = membersOptions.find(
                      (option) => option.value === value,
                    );
                    handleSelectChange([selectedOption], { action: 'select' });
                  }}
                >
                  <SelectTrigger>
                    <SelectValue
                      placeholder={t(
                        'file-manager:FILE_MANAGER.DISTRIBUTE.RECIPIENT_PLACEHOLDER',
                      )}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    {membersOptions.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        <span
                          dangerouslySetInnerHTML={{ __html: option.label }}
                        />
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {errors.members && (
                  <p className="text-sm text-destructive">
                    {errors.members.message}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-2">
                <Button
                  variant="outline"
                  onClick={handleEmailWithOutlook}
                  disabled={noMembers}
                >
                  <MicrosoftSvg className="h-4 w-4 mr-2" />
                  {t('file-manager:FILE_MANAGER.DISTRIBUTE.EMAIL_WITH_OUTLOOK')}
                </Button>

                <Button
                  variant="outline"
                  onClick={handleEmailWithGmail}
                  disabled={noMembers}
                >
                  <Globe className="h-4 w-4 mr-2" />
                  {t('file-manager:FILE_MANAGER.DISTRIBUTE.EMAIL_WITH_GMAIL')}
                </Button>

                <Button
                  variant="outline"
                  onClick={handleEmailShareYahoo}
                  disabled={noMembers}
                >
                  <MailIcon className="h-4 w-4 mr-2" />
                  {t('file-manager:FILE_MANAGER.DISTRIBUTE.EMAIL_WITH_YAHOO')}
                </Button>

                <Button
                  onClick={handleDispatchClick}
                  disabled={noMembers}
                  className="text-white"
                >
                  {t('file-manager:FILE_MANAGER.DISTRIBUTE.DISPATCH_BUTTON')}
                </Button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
};
