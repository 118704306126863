// components/ui/pagination.tsx
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { ChevronLeft, ChevronRight, MoreHorizontal } from 'lucide-react';

interface PaginationProps {
  page: number;
  count: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
  siblingCount?: number;
}

export function DashboardFeedPagination({
  page,
  count,
  pageSize,
  onPageChange,
  siblingCount = 1,
}: PaginationProps) {
  const totalPages = Math.ceil(count / pageSize);

  // Generate page numbers array
  const getPageNumbers = () => {
    const totalNumbers = siblingCount * 2 + 3; // Total numbers to display
    const totalBlocks = totalNumbers + 2; // Total blocks including dots

    if (totalPages <= totalBlocks) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    const leftSiblingIndex = Math.max(page - siblingCount, 1);
    const rightSiblingIndex = Math.min(page + siblingCount, totalPages);

    const showLeftDots = leftSiblingIndex > 2;
    const showRightDots = rightSiblingIndex < totalPages - 2;

    if (!showLeftDots && showRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      return [
        ...Array.from({ length: leftItemCount }, (_, i) => i + 1),
        '...',
        totalPages,
      ];
    }

    if (showLeftDots && !showRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      return [
        1,
        '...',
        ...Array.from(
          { length: rightItemCount },
          (_, i) => totalPages - rightItemCount + i + 1,
        ),
      ];
    }

    if (showLeftDots && showRightDots) {
      return [
        1,
        '...',
        ...Array.from(
          { length: rightSiblingIndex - leftSiblingIndex + 1 },
          (_, i) => leftSiblingIndex + i,
        ),
        '...',
        totalPages,
      ];
    }
  };

  return (
    <div className="flex items-center gap-1">
      <Button
        variant="outline"
        size="icon"
        className="h-8 w-8"
        disabled={page === 1}
        onClick={() => onPageChange(page - 1)}
      >
        <ChevronLeft className="h-4 w-4" />
      </Button>

      <div className="flex items-center gap-1">
        {getPageNumbers()?.map((pageNumber, i) =>
          pageNumber === '...' ? (
            <Button
              key={`dots-${i}`}
              variant="ghost"
              size="icon"
              className="h-8 w-8 cursor-default"
              disabled
            >
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          ) : (
            <Button
              key={pageNumber}
              variant={page === pageNumber ? 'default' : 'outline'}
              size="icon"
              className={cn(
                'h-8 w-8',
                page === pageNumber &&
                  'bg-primary text-primary-foreground text-white',
              )}
              onClick={() => onPageChange(pageNumber as number)}
            >
              {pageNumber}
            </Button>
          ),
        )}
      </div>

      <Button
        variant="outline"
        size="icon"
        className="h-8 w-8"
        disabled={page === totalPages}
        onClick={() => onPageChange(page + 1)}
      >
        <ChevronRight className="h-4 w-4" />
      </Button>
    </div>
  );
}
