import { useTranslation } from '@/lib/i18n';
import { emptyTrash, useAppDispatch, useAppSelector } from '@/lib/store';
import { useEffect, useMemo, useState } from 'react';

interface MemberOption {
  value: string;
  label: string;
}

export const useFileManagerTrash = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { trashEntities, displayMobileRecoveryFilter } = useAppSelector(
    (state) => state.fileManager,
  );
  const { members } = useAppSelector((state) => state.members);
  const { deletedEntities } = useAppSelector((state) => state.workspace);

  const [dates, setDates] = useState<{ from: Date | null; to: Date | null }>({
    from: new Date(new Date().setDate(new Date().getDate() - 14)),
    to: new Date(),
  });
  const [selectedMember, setSelectedMember] = useState<MemberOption | null>(
    null,
  );

  // Filtered trash entities
  const [filteredTrashEntities, setFilteredTrashEntities] =
    useState(trashEntities);

  useEffect(() => {
    if (trashEntities) {
      setFilteredTrashEntities(trashEntities);
    }
  }, [trashEntities]);

  const filterTrashEntities = () => {
    const filteredEntities = trashEntities.filter((entity) => {
      const entityDate = entity.modified ? new Date(entity.modified) : null;
      if (!entityDate) return false;
      const fromDate = dates.from
        ? new Date(dates.from.setHours(0, 0, 0, 0))
        : null;

      // filter by date
      const dateMatch =
        fromDate && dates.to
          ? entityDate >= fromDate && entityDate <= dates.to
          : true;

      // Filter by member
      const memberMatch = selectedMember
        ? deletedEntities.some(
            (deletion) =>
              deletion.entityId === entity.id &&
              deletion.userId === selectedMember.value,
          )
        : true;

      return dateMatch && memberMatch;
    });

    setFilteredTrashEntities(filteredEntities);
  };

  // Member options based on deleted entities
  const deletedUserIds = useMemo(() => {
    return new Set(deletedEntities.map((deletion) => deletion.userId));
  }, [deletedEntities]);

  const memberOptions = useMemo(() => {
    return members.reduce((options, member) => {
      if (member.id && deletedUserIds.has(member.id)) {
        const label = member.name || member.email;
        if (!label) return options;
        options.push({ label, value: member.id });
      }
      return options;
    }, [] as MemberOption[]);
  }, [members, deletedUserIds]);

  // Empty the trash
  const handleEmptyingTrash = async () => {
    await dispatch(emptyTrash());
  };

  // Reset filters
  const resetFilters = () => {
    setFilteredTrashEntities(trashEntities);
    handleSelectChange(null);
  };

  const handleSelectChange = (option: MemberOption | null) => {
    setSelectedMember(option);
  };

  return {
    t,
    dates,
    setDates,
    memberOptions,
    selectedMember,
    setSelectedMember,
    filteredTrashEntities,
    filterTrashEntities,
    handleEmptyingTrash,
    resetFilters,
    handleSelectChange,
    displayMobileRecoveryFilter,
  };
};
