// components/file-manager/quick-navigation.tsx
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { Tooltip, TooltipProvider } from '@/components/ui/tooltip';
import { useStarredEntities } from '@/lib/hooks/use-starred-entities.hook';
import { NAMESPACES, useTranslation } from '@/lib/i18n';
import {
  Entity,
  setLastOpenedEntity,
  toggleDisplayPreviewFileModal,
  toggleDisplayQuickNavigation,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { cn } from '@/lib/utils';
import { useNavigate, useParams } from '@tanstack/react-router';
import { AnimatePresence, motion } from 'framer-motion';
import {
  Calendar,
  ChevronsDownUp,
  File,
  Folder,
  Image,
  Loader2,
  Star,
  X,
} from 'lucide-react';

const iconMap = {
  file: File,
  folder: Folder,
  gallery: Image,
  calendar: Calendar,
};

interface QuickNavItemProps {
  entity: Entity;
  onClick: (entity: Entity) => void;
  onRemove: (entityId: string) => void;
}

const QuickNavItem = ({ entity, onClick, onRemove }: QuickNavItemProps) => {
  const Icon = iconMap[entity.type as keyof typeof iconMap] || File;

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      className={cn(
        'group relative flex items-center gap-3 p-2 rounded-lg',
        'border border-border/50 hover:border-primary/50',
        'transition-all duration-200 hover:bg-primary/5 cursor-pointer',
      )}
      onClick={() => onClick(entity)}
    >
      <div
        className={cn(
          'flex items-center justify-center w-10 h-10 rounded-lg',
          entity.type === 'folder' ? 'bg-orange-100' : 'bg-blue-100',
        )}
      >
        <Icon
          className={cn(
            'w-5 h-5',
            entity.type === 'folder' ? 'text-orange-600' : 'text-blue-600',
          )}
        />
      </div>

      <div className="flex-1 min-w-0">
        <p className="text-sm font-medium truncate">{entity.name}</p>
      </div>

      <Button
        variant="ghost"
        size="icon"
        className="h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity"
        onClick={(e) => {
          e.stopPropagation();
          onRemove(entity.id);
        }}
      >
        <X className="h-4 w-4" />
      </Button>
    </motion.div>
  );
};

export const FileManagerQuickNavigation = () => {
  const navigate = useNavigate();
  const { workspaceId } = useParams({
    strict: false,
  });
  const { starredEntities, toggleStar, loading } =
    useStarredEntities(workspaceId);
  const { t } = useTranslation(NAMESPACES.FILE_MANAGER);
  const dispatch = useAppDispatch();
  const { displayQuickNavigation } = useAppSelector(
    (state) => state.fileManager,
  );

  const handleEntityClick = (entity: Entity) => {
    if (['folder', 'gallery', 'calendar'].includes(entity.type as string)) {
      navigate({
        to: `/${workspaceId}/file-manager/entities/${entity.id}?entityType=${entity.type}`,
      });
    } else {
      dispatch(setLastOpenedEntity(entity));
      dispatch(toggleDisplayPreviewFileModal());
    }
  };

  const toggleNavigation = () => {
    dispatch(toggleDisplayQuickNavigation());
  };

  return (
    <Card className="relative bg-background/60 backdrop-blur-sm border-border/50">
      <CardHeader className="px-4 py-3">
        <div className="flex items-center justify-between">
          <CardTitle className="text-sm font-medium">
            <div className="flex items-center gap-2">
              <Star className="h-4 w-4" />
              {t('file-manager:FILE_MANAGER.QUICK_NAV.TITLE')}
            </div>
          </CardTitle>

          <TooltipProvider>
            <Tooltip>
              <Button
                variant="ghost"
                size="sm"
                className="h-8 w-8 p-0"
                onClick={toggleNavigation}
              >
                <ChevronsDownUp />
                <span className="sr-only">
                  {t('file-manager:FILE_MANAGER.QUICK_NAV.DISPLAY')}
                </span>
              </Button>
            </Tooltip>
          </TooltipProvider>
        </div>
      </CardHeader>

      <Separator />

      {displayQuickNavigation && (
        <CardContent className="p-4">
          <motion.div
            initial={false}
            animate={{ height: 'auto' }}
            className="space-y-2"
          >
            <AnimatePresence mode="popLayout">
              {loading ? (
                <Loader2 className="h-4 w-4 animate-spin mr-2" />
              ) : starredEntities.length > 0 ? (
                <ScrollArea className="h-[300px] pr-4">
                  <div className="space-y-2">
                    {starredEntities.map((entity) => (
                      <QuickNavItem
                        key={entity.id}
                        entity={entity}
                        onClick={handleEntityClick}
                        onRemove={() => toggleStar(entity.id)}
                      />
                    ))}
                  </div>
                </ScrollArea>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="flex flex-col items-center justify-center py-8 text-muted-foreground"
                >
                  <Folder className="h-10 w-10 mb-2 opacity-50" />
                  <p className="text-sm">No quick access items</p>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </CardContent>
      )}
    </Card>
  );
};
