import { config } from '@/lib/feature-toggles';
import { registerTranslations } from '@/lib/i18n';
import { persistor } from '@/lib/store';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import FlagProvider from '@unleash/proxy-client-react';
import 'highlight.js/styles/github-dark.css';
import { Loader } from 'lucide-react';
import { StrictMode } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';
import 'react-phone-number-input/style.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import { appProvider, store } from './lib/store';
import { routeTree } from './routeTree.gen';

// Create a new router instance
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

appProvider.fetchAppConfigs().then((keys) => {
  registerTranslations();

  const stripe = loadStripe(
    import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || keys.stripePubKey,
  );
  const options: StripeElementsOptions = {
    fonts: [
      {
        src: 'https://fonts.googleapis.com/css2?family=Lato:wght@400;500;600;700&display=swap',
        family: 'Lato',
      },
    ],
  };

  // Render the app
  const rootElement = document.getElementById('root')!;
  if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
      <StrictMode>
        <Provider {...{ store }}>
          <PersistGate {...{ persistor, loading: <Loader /> }}>
            <FlagProvider {...{ config }}>
              <Elements {...{ stripe, options }}>
                <DndProvider {...{ backend: HTML5Backend }}>
                  <RouterProvider router={router} />
                  <TanStackRouterDevtools router={router} />
                </DndProvider>
              </Elements>
            </FlagProvider>
          </PersistGate>
        </Provider>
      </StrictMode>,
    );
  }
});
