import {
  CardInfo,
  ChargeInfo,
  PlanInformation,
} from "@oproma/prividox-orchestration-open-api";
import { Dictionary } from "lodash";

export enum FinanceErrorCodesEnum {
  GET_PRICING_PLANS_FAILED = "GET_PRICING_PLANS_FAILED",
  GET_PAYMENT_TRANSACTIONS_FAILED = "GET_PAYMENT_TRANSACTIONS_FAILED",
  GET_CREDIT_CARDS_FAILED = "GET_CREDIT_CARDS_FAILED",
  GET_CREDIT_CARD_COUNT_FAILED = "GET_CREDIT_CARD_COUNT_FAILED",
  CREATE_CREDIT_CARD_FAILED = "CREATE_CREDIT_CARD_FAILED",
  EDIT_CREDIT_CARD_FAILED = "EDIT_CREDIT_CARD_FAILED",
  DELETE_CREDIT_CARD_FAILED = "DELETE_CREDIT_CARD_FAILED",
}

export interface PaymentTransactionsPayload {
  start?: string;
  end?: string;
}

export interface IFinanceState {
  readonly pricingPlans: Record<string, Dictionary<PlanInformation[]>>;
  readonly paymentTransactions: ChargeInfo[];
  readonly creditCardCount: number | null;
  readonly creditCards: CardInfo[];
  readonly chosenCreditCard: CardInfo | null;
  readonly createdCreditCard: boolean;
  readonly editedCreditCard: boolean;
  readonly deletedCreditCard: boolean;
  readonly displayEditCreditCardModal: boolean;
  readonly displayOnboardCreditCardModal: boolean;
  readonly loading: boolean;
  readonly error: {
    code: FinanceErrorCodesEnum;
    message: string;
  } | null;
}
