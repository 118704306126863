/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FolderInformation
 */
export interface FolderInformation {
  /**
   *
   * @type {string}
   * @memberof FolderInformation
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof FolderInformation
   */
  name?: string;
}

/**
 * Check if a given object implements the FolderInformation interface.
 */
export function instanceOfFolderInformation(
  value: object,
): value is FolderInformation {
  return true;
}

export function FolderInformationFromJSON(json: any): FolderInformation {
  return FolderInformationFromJSONTyped(json, false);
}

export function FolderInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FolderInformation {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"] == null ? undefined : json["id"],
    name: json["name"] == null ? undefined : json["name"],
  };
}

export function FolderInformationToJSON(json: any): FolderInformation {
  return FolderInformationToJSONTyped(json, false);
}

export function FolderInformationToJSONTyped(
  value?: FolderInformation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    name: value["name"],
  };
}
