import { Logo } from '@/components/logo.component';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useNavbarNavigation } from '@/lib/hooks/use-navbar-navigation.hook';
import { useWorkspaces } from '@/lib/hooks/use-workspaces.hook';
import { MenuItem as MenuItemType } from '@/lib/store';
import { cn } from '@/lib/utils';
import { Link } from '@tanstack/react-router';
import { AnimatePresence, motion } from 'framer-motion';
import {
  Boxes,
  ChevronDown,
  FolderOpen,
  LayoutDashboard,
  ListTodo,
  Search,
  Settings2,
  Shield,
  Star,
  Users,
  UserSquare2,
} from 'lucide-react';
import { HighlightedText } from './highlighted-text.component';
import { Input } from './ui/input';

type MenuItemProps = {
  item: MenuItemType | SubMenu;
  isActive: boolean;
  onClick: () => void;
  icon: React.ComponentType<{ className?: string }>;
  isCollapsed: boolean;
  hasSubmenu?: boolean;
  onPin?: () => void;
  isPinned?: boolean;
  expandedItem: string | null;
};

interface SubMenu {
  id: string;
  text: string;
  link?: string;
  icon?: string;
  theme?: string;
  newTab?: boolean;
  active?: boolean;
  subMenu?: SubMenu[];
}

const MenuItem = ({
  item,
  isActive,
  onClick,
  icon: Icon,
  isCollapsed,
  hasSubmenu,
  onPin,
  isPinned,
  expandedItem,
}: MenuItemProps) => {
  const handleClick = (e: React.MouseEvent) => {
    // If it's a submenu, handle expansion
    if (hasSubmenu) {
      e.preventDefault();
      onClick();
    }
    // If it has an onClick handler but no submenu, execute it
    else if (onClick) {
      onClick();
    }
  };

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          <div className="relative group">
            <Button
              variant="ghost"
              className={cn(
                'w-full justify-between gap-2',
                isActive && 'bg-white/10 text-white',
                item.active && 'bg-primary/50 text-white',
                !isActive && 'text-gray-400 hover:bg-white/5 hover:text-white',
                isCollapsed ? 'px-2' : 'px-4',
              )}
              onClick={handleClick}
            >
              <div className="flex items-center gap-2">
                {Icon && <Icon className="h-4 w-4" />}
                {!isCollapsed && <span>{item.text}</span>}
              </div>
              {!isCollapsed && (
                <div className="flex items-center gap-2">
                  {onPin && (
                    <Button
                      variant="ghost"
                      size="sm"
                      className={cn(
                        'h-6 w-6 p-0 opacity-0 group-hover:opacity-100 transition-opacity',
                        isPinned && 'opacity-100 text-yellow-500',
                      )}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onPin();
                      }}
                    >
                      <Star className="h-4 w-4" data-icon="star" />
                    </Button>
                  )}
                  {hasSubmenu && (
                    <ChevronDown
                      className={cn(
                        'h-4 w-4 transition-transform',
                        isActive && expandedItem === item.id && 'rotate-180',
                      )}
                    />
                  )}
                </div>
              )}
            </Button>
          </div>
        </TooltipTrigger>
        {isCollapsed && (
          <TooltipContent side="right" className="flex items-center gap-4">
            <div className="flex items-center gap-2">{item.text}</div>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};

function renderMenuItem(
  item: MenuItemType | SubMenu,
  index: string,
  isCollapsed: boolean,
  expandedItem: string | null,
  toggleExpanded: (index: string) => void,
  level: number = 0,
  onPin?: () => void,
  isPinned?: boolean,
) {
  if (!item.text) return null;

  const Icon = item.icon ? iconMap[item.icon as keyof typeof iconMap] : Boxes;
  const isExpanded = expandedItem === index;
  const hasSubmenu = item.subMenu && item.subMenu.length > 0;

  const MenuItemContent = (
    <MenuItem
      key={index}
      item={item}
      isActive={isExpanded || item.active}
      onClick={() => hasSubmenu && toggleExpanded(index)}
      icon={Icon}
      isCollapsed={isCollapsed}
      hasSubmenu={hasSubmenu}
      onPin={onPin}
      isPinned={isPinned}
      expandedItem={expandedItem}
    />
  );

  return (
    <div key={index} className={cn('mb-2', level > 0 && 'ml-4')}>
      {item.link ? (
        <Link to={item.link} className="block">
          {MenuItemContent}
        </Link>
      ) : (
        MenuItemContent
      )}
      {hasSubmenu && isExpanded && !isCollapsed && (
        <motion.div
          key={index}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          className="border-l border-white/10 pl-3 mt-1"
        >
          {item.subMenu?.map((subItem) =>
            renderMenuItem(
              subItem as MenuItemType | SubMenu,
              (subItem as MenuItemType | SubMenu).id!,
              isCollapsed,
              expandedItem,
              toggleExpanded,
              level + 1,
            ),
          )}
        </motion.div>
      )}
    </div>
  );
}

function processMenuItems(
  items: MenuItemType[],
  searchQuery: string,
  isCollapsed: boolean,
): MenuItemType[] {
  if (!searchQuery) return items;

  return items.map((item) => {
    if (!item.text) return item;

    const newItem = { ...item };

    if (item.text) {
      newItem.text = isCollapsed ? (
        item.text
      ) : (
        <HighlightedText text={item.text as string} search={searchQuery} />
      );
    }

    if (item.subMenu) {
      newItem.subMenu = item.subMenu.map((subItem) => ({
        ...subItem,
        text: isCollapsed ? (
          subItem.text
        ) : (
          <HighlightedText text={subItem.text as string} search={searchQuery} />
        ),
      }));
    }

    return newItem;
  });
}

export function Navbar() {
  const {
    pinnedWorkspaces,
    togglePinnedWorkspace,
    workspaceMenuQuery: searchQuery,
    setWorkspaceMenuQuery: setSearchQuery,
    workspaceMenuQueryResults: searchResults,
  } = useWorkspaces();
  const { expandedItem, isCollapsed, toggleWorkspace } = useNavbarNavigation();

  const processedResults = processMenuItems(
    searchResults,
    searchQuery,
    isCollapsed,
  );

  const pinnedWorkspaceItems = processedResults.filter(
    (item) => !item.heading && pinnedWorkspaces.includes(item.id as string),
  );

  const unpinnedWorkspaceItems = processedResults.filter(
    (item) => !item.heading && !pinnedWorkspaces.includes(item.id as string),
  );

  return (
    <motion.div
      layout
      className={cn(
        'bg-[#111827] text-white transition-all duration-300',
        isCollapsed ? 'w-[80px]' : 'w-[280px]',
      )}
    >
      <div className="h-[60px] px-6 flex items-center justify-between border-b border-white/10">
        {!isCollapsed ? <Logo className="h-8" /> : <Logo iconOnly />}
      </div>

      <ScrollArea className="h-[calc(100vh-60px)]">
        <div className="p-3">
          <div className="space-y-4">
            {!isCollapsed && (
              <div className="relative">
                <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
                <Input
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder={isCollapsed ? '' : 'Find workspaces'}
                  className="pl-8 bg-white/5 border-white/10 text-white placeholder:text-gray-400"
                />
              </div>
            )}
            {/* Quick Access Section */}
            {pinnedWorkspaceItems.length > 0 && (
              <div className="bg-white/5 rounded-xl p-4">
                <h3
                  className={cn(
                    'text-xs font-medium text-gray-400 uppercase mb-3',
                    isCollapsed && 'text-center',
                  )}
                >
                  {!isCollapsed && 'Favourites'}
                </h3>
                <div className="space-y-1">
                  <AnimatePresence>
                    {pinnedWorkspaceItems.map((item) => (
                      <motion.div
                        key={item.link}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                      >
                        {renderMenuItem(
                          item,
                          item.id!,
                          isCollapsed,
                          expandedItem,
                          toggleWorkspace,
                          0,
                          () => togglePinnedWorkspace(item.id!),
                          true,
                        )}
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </div>
              </div>
            )}

            {/* Bento Grid Layout */}
            <div className="grid gap-4">
              {/* Workspaces Section */}
              <div className="bg-white/5 rounded-xl p-4">
                <h3
                  className={cn(
                    'text-xs font-medium text-gray-400 uppercase mb-3',
                    isCollapsed && 'text-center',
                  )}
                >
                  {!isCollapsed && 'Menu'}
                </h3>
                <div className="space-y-1">
                  {unpinnedWorkspaceItems.map((item) =>
                    renderMenuItem(
                      item,
                      item.id!,
                      isCollapsed,
                      expandedItem,
                      toggleWorkspace,
                      0,
                      () => togglePinnedWorkspace(item.id!),
                      false,
                    ),
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollArea>
    </motion.div>
  );
}

// Icon mapping
const iconMap = {
  dashboard: LayoutDashboard,
  folder: FolderOpen,
  template: ListTodo,
  users: Users,
  'user-list': UserSquare2,
  shield: Shield,
  'setting-alt': Settings2,
};
