import { useTranslation } from '@/lib/i18n';
import {
  createGroup,
  setDisplayOnboardGroupsModal,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Separator } from '@/components/ui/separator';
import { UsersRound } from 'lucide-react';

// Schema for form validation
const onboardGroupSchema = yup
  .object({
    name: yup
      .string()
      .required('Group name is required')
      .trim()
      .notOneOf([''], 'Name cannot be blank'),
  })
  .required();

type OnboardGroupFormValues = yup.InferType<typeof onboardGroupSchema>;

export const OnboardGroupModal = () => {
  const { workspaceId } = useParams({ strict: false });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { displayOnboardGroupsModal } = useAppSelector((state) => state.groups);

  // Initialize form with default values
  const form = useForm<OnboardGroupFormValues>({
    resolver: yupResolver(onboardGroupSchema),
    defaultValues: {
      name: '',
    },
  });

  // Handle form submission
  const onSubmit = (values: OnboardGroupFormValues) => {
    if (!workspaceId) return;

    dispatch(
      createGroup({
        workspaceId,
        nameSpec: {
          name: values.name,
        },
      }),
    );

    handleClose();
  };

  // Handle modal close
  const handleClose = () => {
    dispatch(setDisplayOnboardGroupsModal(false));
    form.reset();
  };

  return (
    <Dialog open={displayOnboardGroupsModal} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <div className="relative">
            {/* Background Pattern */}
            <div
              className="absolute inset-0 bg-gradient-to-br from-primary/10 via-background to-background"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-[linear-gradient(to_right,transparent_0%,#ffffff08_50%,transparent_100%)] h-[1px] top-1/2" />
            </div>

            {/* Content */}
            <div className="relative px-6 py-8 space-y-4">
              {/* Title Section */}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="p-2.5 rounded-xl bg-primary/10 backdrop-blur-sm">
                    <UsersRound className="h-5 w-5 text-primary" />
                  </div>
                  <div>
                    <DialogTitle className="text-lg font-semibold">
                      {t('translation:GROUPS.CREATE_GROUP.TITLE')}
                    </DialogTitle>
                    <p className="text-sm text-muted-foreground">
                      Create a new group for your workspace
                    </p>
                  </div>
                </div>
              </div>

              {/* Divider */}
              <Separator className="bg-border/40" />
            </div>
          </div>
        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-6 px-6"
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('translation:GROUPS.CREATE_GROUP.NAME')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t(
                        'translation:GROUPS.CREATE_GROUP.NAME_PLACEHOLDER',
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end gap-3 pt-4">
              <Button variant="outline" onClick={handleClose}>
                {t('translation:GROUPS.CREATE_GROUP.CANCEL')}
              </Button>
              <Button type="submit" className="text-white">
                {t('translation:GROUPS.CREATE_GROUP.CONFIRM')}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
