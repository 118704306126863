import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Collapsible, CollapsibleContent } from '@/components/ui/collapsible';
import { ScrollArea } from '@/components/ui/scroll-area';
import { filesProvider, useAppSelector } from '@/lib/store';
import { Entity } from '@/lib/store/file-manager/types';
import { cn } from '@/lib/utils';
import { Link, useParams } from '@tanstack/react-router';
import { ChevronRight, Folder, FolderOpen, FolderTree } from 'lucide-react';
import { useEffect, useState } from 'react';

interface TreeItemProps {
  entity: Entity;
  level: number;
  activeId?: string;
}

const TreeItem = ({ entity, level, activeId }: TreeItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [children, setChildren] = useState<Entity[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasChildFolders, setHasChildFolders] = useState<boolean | null>(null);
  const workspaceId = useParams({ strict: false });
  const isActive = activeId === entity.id;

  // Check for child folders when component mounts
  useEffect(() => {
    // Only check for children if we haven't determined yet if there are child folders
    if (hasChildFolders === null) {
      checkForChildFolders();
    }
  }, []);

  // Fetch children when component mounts or when isOpen changes to true
  useEffect(() => {
    if (isOpen && children.length === 0 && hasChildFolders) {
      fetchChildren();
    }
  }, [isOpen, hasChildFolders]);

  // Also fetch children when component first renders if this is the active folder
  useEffect(() => {
    if (isActive && children.length === 0 && hasChildFolders === null) {
      checkForChildFolders();
    }

    if (isActive && children.length === 0 && hasChildFolders) {
      fetchChildren();
      setIsOpen(true);
    }
  }, [isActive]);

  // First check if there are any child folders before fetching them all
  const checkForChildFolders = async () => {
    if (!entity.id) return;

    setIsLoading(true);
    try {
      const response = await filesProvider.fetchChildEntities(entity.id);

      // Check if there are any child folders
      const hasFolders = (response.items || []).some(
        (item) =>
          item.type === 'folder' ||
          item.type === 'calendar' ||
          item.type === 'gallery',
      );

      setHasChildFolders(hasFolders);

      // If this is the active folder and it has children, we might as well store them
      if (isActive && hasFolders) {
        const folderEntities =
          response.items?.filter(
            (item) =>
              item.type === 'folder' ||
              item.type === 'calendar' ||
              item.type === 'gallery',
          ) ?? [];

        const mappedEntities: Entity[] = folderEntities.map((item) => ({
          ...item,
          id: item.folderId!,
          svg: item.type ?? 'no-icon',
        }));

        setChildren(mappedEntities);
      }
    } catch (error) {
      console.error('Error checking for child folders:', error);
      setHasChildFolders(false);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchChildren = async () => {
    if (!entity.id) return;

    setIsLoading(true);
    try {
      const response = await filesProvider.fetchChildEntities(entity.id);
      // Filter to only include folders
      const folderEntities =
        response.items?.filter(
          (item) =>
            item.type === 'folder' ||
            item.type === 'calendar' ||
            item.type === 'gallery',
        ) ?? [];

      // Map to Entity type
      const mappedEntities: Entity[] = folderEntities.map((item) => ({
        ...item,
        id: item.folderId!,
        svg: item.type ?? 'no-icon',
      }));

      setChildren(mappedEntities);

      // Update hasChildFolders based on result
      setHasChildFolders(mappedEntities.length > 0);
    } catch (error) {
      console.error('Error fetching child entities:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleFolderClick = (e: React.MouseEvent) => {
    e.preventDefault();
    // Only try to open the folder if it has children
    if (hasChildFolders) {
      setIsOpen(true);
    }
  };

  return (
    <div className="flex flex-col">
      <div
        className={cn(
          'flex items-center gap-2 py-1 px-2 rounded-md hover:bg-accent/50 transition-colors',
          isActive && 'bg-accent',
          level > 0 && 'ml-4',
        )}
        style={{ paddingLeft: `${level * 12}px` }}
        onClick={handleFolderClick}
      >
        {/* Only show chevron for folders with children or when still checking */}
        {hasChildFolders === true || isLoading ? (
          <div
            className={cn(
              'p-1 rounded-sm hover:bg-accent transition-transform',
              isOpen && 'transform rotate-90',
            )}
            onClick={handleToggle}
          >
            <ChevronRight className="h-4 w-4" />
          </div>
        ) : (
          <div className="w-6"></div> // Spacer for alignment when no chevron
        )}

        <Link
          to={`/${workspaceId}/file-manager/entities/${entity.id}`}
          className="flex items-center gap-2 flex-1 min-w-0"
        >
          {isOpen ? (
            <FolderOpen className="h-4 w-4 shrink-0 text-primary" />
          ) : (
            <Folder className="h-4 w-4 shrink-0 text-primary" />
          )}
          <span className="truncate text-sm">{entity.name}</span>
        </Link>
      </div>

      <Collapsible open={isOpen} onOpenChange={setIsOpen}>
        <CollapsibleContent>
          {isLoading ? (
            <div className="flex justify-center items-center py-2 ml-6">
              <div className="animate-spin h-4 w-4 border-2 border-primary border-t-transparent rounded-full" />
            </div>
          ) : (
            <div className="mt-1">
              {children.map((child) => (
                <TreeItem
                  key={child.id}
                  entity={child}
                  level={level + 1}
                  activeId={activeId}
                />
              ))}
            </div>
          )}
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
};

export const FileTree = () => {
  const { metadata } = useAppSelector((state) => state.workspace);
  const [rootFolders, setRootFolders] = useState<Entity[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { workspaceId } = useParams({
    strict: false,
  });
  const { entityId: activeEntityId } = useParams({
    strict: false,
  });

  useEffect(() => {
    const fetchRootFolders = async () => {
      if (!metadata?.root) return;

      try {
        const response = await filesProvider.fetchChildEntities(metadata.root);
        // Filter to only include folders
        const folderEntities =
          response.items?.filter(
            (item) =>
              item.type === 'folder' ||
              item.type === 'calendar' ||
              item.type === 'gallery',
          ) ?? [];

        // Map to Entity type
        const mappedEntities: Entity[] = folderEntities.map((item) => ({
          ...item,
          id: item.folderId!,
          svg: item.type ?? 'no-icon',
        }));

        setRootFolders(mappedEntities);
      } catch (error) {
        console.error('Error fetching root folders:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRootFolders();
  }, [metadata?.root, workspaceId]);

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle className="text-sm flex items-center gap-2">
            <FolderTree className="h-4 w-4" />
            <span className="font-normal">Folders</span>
          </CardTitle>
        </CardHeader>
        <CardContent className="flex justify-center items-center py-8">
          <div className="animate-spin h-6 w-6 border-2 border-primary border-t-transparent rounded-full" />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-sm flex items-center gap-2">
          <FolderTree className="h-4 w-4" />
          <span className="font-normal">Folders</span>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[300px] pr-4">
          {rootFolders.length > 0 ? (
            rootFolders.map((folder) => (
              <TreeItem
                key={folder.id}
                entity={folder}
                level={0}
                activeId={activeEntityId}
              />
            ))
          ) : (
            <div className="text-sm text-muted-foreground p-2">
              No folders found
            </div>
          )}
        </ScrollArea>
      </CardContent>
    </Card>
  );
};
