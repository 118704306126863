import {
  ActivateAccountRequest,
  CreateAccountSpec,
  LoginSpec,
  ResetPasswordSpec,
  SubmitEmailChangeRequest,
  ValidateLoginSpec,
  ValidatePasswordResetSpec,
} from "@oproma/prividox-orchestration-open-api";
import { AuthenticationStatusEnum } from "../auth/types";
import { authenticationApi, userApi } from "./config";

// Authentication
const loginUser = async (loginSpec: LoginSpec) => {
  const response = await authenticationApi.loginRaw({
    loginSpec,
  });

  return {
    uid: (await response.raw.text()).replace(/^"|"$/g, ""),
  };
};

const pingUser = async () => {
  return await authenticationApi.testSession();
};

const reauthenticateForFinances = async (payload: ValidateLoginSpec) => {
  return await authenticationApi.validateSession({
    validateLoginSpec: payload,
  });
};

const disableMFA = async (password: string) => {
  const response = await userApi.removeSecretCodeRaw({
    passwordSpec: {
      password,
    },
  });
  return response.raw.ok;
};

const refreshAuth = async () => {
  const response = await authenticationApi.pingSession();
  return response;
};

const requestEmailChange = async () => {
  return await authenticationApi.requestEmailChange();
};

const confirmEmailChange = async (payload: SubmitEmailChangeRequest) => {
  return await authenticationApi.submitEmailChange(payload);
};

const logoutUser = async () => {
  const response = await authenticationApi.logoutRaw({
    logoutSpec: {},
  });
  return response.raw.ok;
};

const registerUser = (registerSpec: CreateAccountSpec) => {
  return authenticationApi.createAccount({
    createAccountSpec: registerSpec,
  });
};

const activateUser = async (payload: ActivateAccountRequest) => {
  const response = await authenticationApi.activateAccountRaw(payload);
  return response.raw.ok;
};

const confirmRegistration = async (payload: ActivateAccountRequest) => {
  const response = await authenticationApi.activateAccountRaw(payload);

  return response.raw.ok
    ? {
        status: AuthenticationStatusEnum.COMPLETED,
      }
    : {
        status: AuthenticationStatusEnum.FAILED,
      };
};

const resetPassword = async (resetPasswordSpec: ResetPasswordSpec) => {
  const response = await userApi.requestPasswordResetRaw({
    resetPasswordSpec,
  });
  return response.raw.ok
    ? {
        status: AuthenticationStatusEnum.COMPLETED,
      }
    : {
        status: AuthenticationStatusEnum.FAILED,
      };
};

const confirmResetPassword = async (
  validatePasswordResetSpec: ValidatePasswordResetSpec,
) => {
  const response = await userApi.validatePasswordResetRaw({
    validatePasswordResetSpec,
  });

  return response.raw.ok
    ? {
        status: AuthenticationStatusEnum.COMPLETED,
      }
    : {
        status: AuthenticationStatusEnum.FAILED,
      };
};

export const authenticationProvider = {
  pingUser,
  loginUser,
  disableMFA,
  logoutUser,
  refreshAuth,
  registerUser,
  activateUser,
  resetPassword,
  confirmEmailChange,
  requestEmailChange,
  confirmRegistration,
  confirmResetPassword,
  reauthenticateForFinances,
};
