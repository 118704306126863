/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { CardInfo } from "./CardInfo";
import {
  CardInfoFromJSON,
  CardInfoFromJSONTyped,
  CardInfoToJSON,
  CardInfoToJSONTyped,
} from "./CardInfo";

/**
 *
 * @export
 * @interface ResultCardInfo
 */
export interface ResultCardInfo {
  /**
   *
   * @type {Array<CardInfo>}
   * @memberof ResultCardInfo
   */
  items?: Array<CardInfo>;
}

/**
 * Check if a given object implements the ResultCardInfo interface.
 */
export function instanceOfResultCardInfo(
  value: object,
): value is ResultCardInfo {
  return true;
}

export function ResultCardInfoFromJSON(json: any): ResultCardInfo {
  return ResultCardInfoFromJSONTyped(json, false);
}

export function ResultCardInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultCardInfo {
  if (json == null) {
    return json;
  }
  return {
    items:
      json["items"] == null
        ? undefined
        : (json["items"] as Array<any>).map(CardInfoFromJSON),
  };
}

export function ResultCardInfoToJSON(json: any): ResultCardInfo {
  return ResultCardInfoToJSONTyped(json, false);
}

export function ResultCardInfoToJSONTyped(
  value?: ResultCardInfo | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    items:
      value["items"] == null
        ? undefined
        : (value["items"] as Array<any>).map(CardInfoToJSON),
  };
}
