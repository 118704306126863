/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FileInfo
 */
export interface FileInfo {
  /**
   *
   * @type {string}
   * @memberof FileInfo
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof FileInfo
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof FileInfo
   */
  tsize?: number;
  /**
   *
   * @type {string}
   * @memberof FileInfo
   */
  path?: string;
}

/**
 * Check if a given object implements the FileInfo interface.
 */
export function instanceOfFileInfo(value: object): value is FileInfo {
  return true;
}

export function FileInfoFromJSON(json: any): FileInfo {
  return FileInfoFromJSONTyped(json, false);
}

export function FileInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FileInfo {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"] == null ? undefined : json["id"],
    size: json["size"] == null ? undefined : json["size"],
    tsize: json["tsize"] == null ? undefined : json["tsize"],
    path: json["path"] == null ? undefined : json["path"],
  };
}

export function FileInfoToJSON(json: any): FileInfo {
  return FileInfoToJSONTyped(json, false);
}

export function FileInfoToJSONTyped(
  value?: FileInfo | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    size: value["size"],
    tsize: value["tsize"],
    path: value["path"],
  };
}
