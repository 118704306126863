import { AmexSvg } from "@/components/svg/amex.svg.component";
import { DiscoverySvg } from "@/components/svg/discovery.svg.component";
import { MastercardSvg } from "@/components/svg/mastercard.svg.component";
import { VisaSvg } from "@/components/svg/visa.svg.component";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useWizard } from "@/lib/hooks/use-wizard.hook";
import {
  setCreateWorkpaceFormField,
  toggleDisplayOnboardCreditCardModal,
  useAppDispatch,
  useAppSelector,
} from "@/lib/store";
import { cn } from "@/lib/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Link } from "@tanstack/react-router";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowLeft, CreditCard, Gift, Plus } from "lucide-react";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

interface OnboardWorkspaceCCFormValues {
  paymentMethod?: "cc" | "coupon";
  couponCode?: string;
}

const getCardIcon = (brand: string | undefined) => {
  switch (brand?.toLowerCase()) {
    case "visa":
      return <VisaSvg className="h-10 w-10" />;
    case "mastercard":
      return <MastercardSvg className="h-10 w-10" />;
    case "amex":
      return <AmexSvg className="h-10 w-10" />;
    case "discover":
      return <DiscoverySvg className="h-10 w-10" />;
    default:
      return <CreditCard className="h-10 w-10" />;
  }
};

export const OnboardWorkspaceCCForm = () => {
  const { metadata } = useAppSelector((state) => state.workspace);
  const { creditCards, creditCardCount } = useAppSelector(
    (state) => state.finance
  );
  const defaultCreditCard = creditCards.find((cc) => cc.def);
  const { next, setCurrent, prev, currentProcess } = useWizard();
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [selectedCard, setSelectedCard] = useState(
    defaultCreditCard ?? creditCards[0]
  );
  const [elementError, setElementError] = useState<{
    complete: boolean;
    message?: string;
  }>();

  const formSchema = yup.object<OnboardWorkspaceCCFormValues>().shape({
    paymentMethod: yup
      .string()
      .oneOf(["cc", "coupon"])
      .required("Payment method is required"),
    couponCode: yup.string().when("paymentMethod", {
      is: "coupon",
      then: (schema) => schema.required("Please enter a coupon code"),
      otherwise: (schema) => schema.optional(),
    }),
  });

  const form = useForm<OnboardWorkspaceCCFormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      paymentMethod: "cc",
    },
  });

  const selectedMethod = form.watch("paymentMethod");

  const onFormSubmit: SubmitHandler<OnboardWorkspaceCCFormValues> = async (
    values
  ) => {
    if (values.paymentMethod === "cc") {
      if (!stripe || !elements) {
        console.error("Stripe.js has not loaded yet.");
        return;
      }

      let token: string | undefined = undefined;

      if (creditCardCount && creditCardCount >= 1) {
        token = selectedCard.cardToken;
      } else {
        if (!elementError?.complete || elementError?.message) {
          return;
        }

        const cardElement = elements.getElement(CardElement);
        if (!cardElement) return;

        const tokenResponse = await stripe.createToken(cardElement);
        token = tokenResponse.token?.id;
      }

      dispatch(setCreateWorkpaceFormField({ token }));
      next();
    } else if (values.paymentMethod === "coupon" && values.couponCode) {
      setCurrent(4);
    }
  };

  return (
    <div className="space-y-6">
      {/* Back Button */}
      <div className="flex items-center gap-2 mb-2">
        <Button
          variant="ghost"
          size="icon"
          onClick={prev}
          className="h-9 w-9 rounded-full hover:bg-primary/10 text-muted-foreground hover:text-primary"
        >
          <ArrowLeft className="h-5 w-5" />
          <span className="sr-only">Go back</span>
        </Button>
        <span className="text-sm text-muted-foreground">Back</span>
      </div>
      <Form {...form}>
        <form
          id={`workspace-form-${currentProcess}`}
          onSubmit={form.handleSubmit(onFormSubmit)}
          className="space-y-6"
        >
          {/* Payment Method Selection */}
          <Card className="overflow-hidden border bg-gradient-to-br from-background to-muted/50">
            <CardContent className="p-6">
              <div className="flex items-start gap-3 mb-4">
                <div className="rounded-xl bg-primary/10 p-2.5 shrink-0">
                  <CreditCard className="h-5 w-5 text-primary" />
                </div>
                <div className="space-y-1 flex-1">
                  <h3 className="text-base font-medium leading-none">
                    Payment Method
                  </h3>
                  <p className="text-sm text-muted-foreground">
                    Pick a payment method to onboard your workspace with
                  </p>
                </div>
              </div>

              <FormField
                control={form.control}
                name="paymentMethod"
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <SelectTrigger className="bg-background/50 border-muted-foreground/20">
                        <SelectValue placeholder="Select payment method" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem
                          value="cc"
                          className="flex items-center gap-2"
                        >
                          <div className="flex items-center gap-2">
                            <CreditCard className="h-4 w-4" />
                            <span>Credit Card</span>
                          </div>
                        </SelectItem>
                        <SelectItem
                          value="coupon"
                          className="flex items-center gap-2"
                        >
                          <div className="flex items-center gap-2">
                            <Gift className="h-4 w-4" />
                            <span>Coupon Code</span>
                          </div>
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
            </CardContent>
          </Card>

          {/* Payment Details */}
          <AnimatePresence mode="wait">
            {selectedMethod === "cc" && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="space-y-4"
              >
                <Card className="overflow-hidden border bg-gradient-to-br from-background to-muted/50">
                  <CardContent className="p-6">
                    <div className="flex items-start gap-3 mb-6">
                      <div className="rounded-xl bg-primary/10 p-2.5 shrink-0">
                        <CreditCard className="h-5 w-5 text-primary" />
                      </div>
                      <div className="space-y-1 flex-1">
                        <h3 className="text-base font-medium leading-none">
                          Card Details
                        </h3>
                        <p className="text-sm text-muted-foreground">
                          {creditCardCount && creditCardCount >= 1
                            ? "Choose a persisted card or use a new one"
                            : "Provide card details"}
                        </p>
                      </div>
                    </div>

                    {creditCardCount && creditCardCount >= 1 ? (
                      <div className="space-y-4">
                        <div className="grid grid-cols-1 gap-3">
                          {creditCards.map((card) => (
                            <button
                              key={card.cardToken}
                              onClick={() => setSelectedCard(card)}
                              className={cn(
                                "flex items-center justify-between p-4 rounded-lg border",
                                "transition-all duration-200",
                                card.cardToken === selectedCard?.cardToken
                                  ? "border-primary bg-primary/10"
                                  : "border-border hover:border-primary/50"
                              )}
                            >
                              <div className="flex items-center gap-3">
                                <span className="text-lg">
                                  {getCardIcon(card.brand)}
                                </span>
                                <div className="text-left">
                                  <p className="font-medium">
                                    •••• {card.last4}
                                  </p>
                                  <p className="text-sm text-muted-foreground">
                                    Expires {card.expMonth}/{card.expYear}
                                  </p>
                                </div>
                              </div>
                            </button>
                          ))}
                        </div>

                        <Link
                          to={`/${metadata?.id}/options`}
                          onClick={() =>
                            dispatch(toggleDisplayOnboardCreditCardModal())
                          }
                          className={cn(
                            "flex items-center gap-2 p-4 rounded-lg border border-dashed",
                            "text-muted-foreground hover:text-foreground",
                            "transition-colors duration-200"
                          )}
                        >
                          <Plus className="h-4 w-4" />
                          <span>Card</span>
                        </Link>
                      </div>
                    ) : (
                      <div className="space-y-4">
                        <div className="bg-background/50 rounded-lg p-4 border border-border">
                          <CardElement
                            options={{
                              style: {
                                base: {
                                  fontSize: "14px",
                                  color: "black",
                                  fontFamily: "Lato, sans-serif",
                                  "::placeholder": {
                                    color: "#6e7191",
                                  },
                                },
                              },
                            }}
                            onChange={({ error, complete }) =>
                              setElementError({
                                complete,
                                message: error?.message,
                              })
                            }
                          />
                        </div>
                        {elementError?.message && (
                          <p className="text-sm text-destructive">
                            {elementError.message}
                          </p>
                        )}
                      </div>
                    )}
                  </CardContent>
                </Card>
              </motion.div>
            )}

            {selectedMethod === "coupon" && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
              >
                <Card className="overflow-hidden border bg-gradient-to-br from-background to-muted/50">
                  <CardContent className="p-6">
                    <div className="flex items-start gap-3 mb-6">
                      <div className="rounded-xl bg-primary/10 p-2.5 shrink-0">
                        <Gift className="h-5 w-5 text-primary" />
                      </div>
                      <div className="space-y-1 flex-1">
                        <h3 className="text-base font-medium leading-none">
                          Coupon Code
                        </h3>
                        <p className="text-sm text-muted-foreground">
                          Enter your coupon code to apply a discount
                        </p>
                      </div>
                    </div>

                    <FormField
                      control={form.control}
                      name="couponCode"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              {...field}
                              placeholder="Enter coupon code"
                              className="bg-background/50 border-muted-foreground/20"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </CardContent>
                </Card>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Footer */}

          <div className="flex justify-between mt-6 pt-4 border-t">
            <Button
              type="submit"
              form={`workspace-form-${currentProcess}`}
              className="text-white w-full"
              disabled={
                (selectedMethod === "cc" &&
                  !creditCardCount &&
                  (!elementError?.complete || !!elementError?.message)) ||
                form.formState.isSubmitting
              }
            >
              Confirm
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
