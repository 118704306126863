/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { NotificationMessage } from "./NotificationMessage";
import {
  NotificationMessageFromJSON,
  NotificationMessageFromJSONTyped,
  NotificationMessageToJSON,
  NotificationMessageToJSONTyped,
} from "./NotificationMessage";

/**
 *
 * @export
 * @interface ResultNotificationMessage
 */
export interface ResultNotificationMessage {
  /**
   *
   * @type {Array<NotificationMessage>}
   * @memberof ResultNotificationMessage
   */
  items?: Array<NotificationMessage>;
}

/**
 * Check if a given object implements the ResultNotificationMessage interface.
 */
export function instanceOfResultNotificationMessage(
  value: object,
): value is ResultNotificationMessage {
  return true;
}

export function ResultNotificationMessageFromJSON(
  json: any,
): ResultNotificationMessage {
  return ResultNotificationMessageFromJSONTyped(json, false);
}

export function ResultNotificationMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultNotificationMessage {
  if (json == null) {
    return json;
  }
  return {
    items:
      json["items"] == null
        ? undefined
        : (json["items"] as Array<any>).map(NotificationMessageFromJSON),
  };
}

export function ResultNotificationMessageToJSON(
  json: any,
): ResultNotificationMessage {
  return ResultNotificationMessageToJSONTyped(json, false);
}

export function ResultNotificationMessageToJSONTyped(
  value?: ResultNotificationMessage | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    items:
      value["items"] == null
        ? undefined
        : (value["items"] as Array<any>).map(NotificationMessageToJSON),
  };
}
