/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { PriceSpec } from "./PriceSpec";
import {
  PriceSpecFromJSON,
  PriceSpecFromJSONTyped,
  PriceSpecToJSON,
  PriceSpecToJSONTyped,
} from "./PriceSpec";

/**
 *
 * @export
 * @interface ResultPriceSpec
 */
export interface ResultPriceSpec {
  /**
   *
   * @type {Array<PriceSpec>}
   * @memberof ResultPriceSpec
   */
  items?: Array<PriceSpec>;
}

/**
 * Check if a given object implements the ResultPriceSpec interface.
 */
export function instanceOfResultPriceSpec(
  value: object,
): value is ResultPriceSpec {
  return true;
}

export function ResultPriceSpecFromJSON(json: any): ResultPriceSpec {
  return ResultPriceSpecFromJSONTyped(json, false);
}

export function ResultPriceSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultPriceSpec {
  if (json == null) {
    return json;
  }
  return {
    items:
      json["items"] == null
        ? undefined
        : (json["items"] as Array<any>).map(PriceSpecFromJSON),
  };
}

export function ResultPriceSpecToJSON(json: any): ResultPriceSpec {
  return ResultPriceSpecToJSONTyped(json, false);
}

export function ResultPriceSpecToJSONTyped(
  value?: ResultPriceSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    items:
      value["items"] == null
        ? undefined
        : (value["items"] as Array<any>).map(PriceSpecToJSON),
  };
}
