/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface LogoutSpec
 */
export interface LogoutSpec {
  /**
   * The session token to expire. Defaults to the current session if not specified.
   * @type {string}
   * @memberof LogoutSpec
   */
  session?: string;
}

/**
 * Check if a given object implements the LogoutSpec interface.
 */
export function instanceOfLogoutSpec(value: object): value is LogoutSpec {
  return true;
}

export function LogoutSpecFromJSON(json: any): LogoutSpec {
  return LogoutSpecFromJSONTyped(json, false);
}

export function LogoutSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LogoutSpec {
  if (json == null) {
    return json;
  }
  return {
    session: json["session"] == null ? undefined : json["session"],
  };
}

export function LogoutSpecToJSON(json: any): LogoutSpec {
  return LogoutSpecToJSONTyped(json, false);
}

export function LogoutSpecToJSONTyped(
  value?: LogoutSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    session: value["session"],
  };
}
