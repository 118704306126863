/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SubmitEmailUpdateSpec
 */
export interface SubmitEmailUpdateSpec {
  /**
   * The old/current email address of the user being updated.
   * @type {string}
   * @memberof SubmitEmailUpdateSpec
   */
  old: string;
  /**
   * The new email address of to associate with the user.
   * @type {string}
   * @memberof SubmitEmailUpdateSpec
   */
  email: string;
}

/**
 * Check if a given object implements the SubmitEmailUpdateSpec interface.
 */
export function instanceOfSubmitEmailUpdateSpec(
  value: object,
): value is SubmitEmailUpdateSpec {
  if (!("old" in value) || value["old"] === undefined) return false;
  if (!("email" in value) || value["email"] === undefined) return false;
  return true;
}

export function SubmitEmailUpdateSpecFromJSON(
  json: any,
): SubmitEmailUpdateSpec {
  return SubmitEmailUpdateSpecFromJSONTyped(json, false);
}

export function SubmitEmailUpdateSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SubmitEmailUpdateSpec {
  if (json == null) {
    return json;
  }
  return {
    old: json["old"],
    email: json["email"],
  };
}

export function SubmitEmailUpdateSpecToJSON(json: any): SubmitEmailUpdateSpec {
  return SubmitEmailUpdateSpecToJSONTyped(json, false);
}

export function SubmitEmailUpdateSpecToJSONTyped(
  value?: SubmitEmailUpdateSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    old: value["old"],
    email: value["email"],
  };
}
