/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { MemberInformation } from "./MemberInformation";
import {
  MemberInformationFromJSON,
  MemberInformationFromJSONTyped,
  MemberInformationToJSON,
  MemberInformationToJSONTyped,
} from "./MemberInformation";

/**
 *
 * @export
 * @interface ResultMemberInformation
 */
export interface ResultMemberInformation {
  /**
   *
   * @type {Array<MemberInformation>}
   * @memberof ResultMemberInformation
   */
  items?: Array<MemberInformation>;
}

/**
 * Check if a given object implements the ResultMemberInformation interface.
 */
export function instanceOfResultMemberInformation(
  value: object,
): value is ResultMemberInformation {
  return true;
}

export function ResultMemberInformationFromJSON(
  json: any,
): ResultMemberInformation {
  return ResultMemberInformationFromJSONTyped(json, false);
}

export function ResultMemberInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultMemberInformation {
  if (json == null) {
    return json;
  }
  return {
    items:
      json["items"] == null
        ? undefined
        : (json["items"] as Array<any>).map(MemberInformationFromJSON),
  };
}

export function ResultMemberInformationToJSON(
  json: any,
): ResultMemberInformation {
  return ResultMemberInformationToJSONTyped(json, false);
}

export function ResultMemberInformationToJSONTyped(
  value?: ResultMemberInformation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    items:
      value["items"] == null
        ? undefined
        : (value["items"] as Array<any>).map(MemberInformationToJSON),
  };
}
