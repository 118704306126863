import {
  DeleteTaskRequest,
  SetTaskCompletedRequest,
  UpdateTaskRequest,
} from "@oproma/prividox-orchestration-open-api";
import { CreateTaskPayload, TaskMetadata } from "../tasks/types";
import { metadataApi, tasksApi, workspaceApi } from "./config";

// Tasks
const fetchTasks = (workspaceId: string) => {
  return tasksApi.getTasks({
    workspaceId,
    all: true,
    sort: "name",
    desc: false,
  });
};

const createTask = async (payload: CreateTaskPayload) => {
  const task = await tasksApi.createTask(payload);
  const metadata: TaskMetadata = {
    columnId: payload.columnId,
    columnName: payload.columnName,
    columnOrder: payload.columnOrder,
    order: payload.order,
  };
  if (payload.columnTheme) {
    metadata.columnTheme = payload.columnTheme;
  }

  await metadataApi.setMultipleMetadata({
    entity: task,
    requestBody: metadata as unknown as Record<string, string>,
  });
  return task;
};

const patchTaskMetadata = (taskId: string, metadata: TaskMetadata) => {
  return metadataApi.setMultipleMetadata({
    entity: taskId,
    requestBody: metadata as unknown as Record<string, string>,
  });
};

const patchTask = async (payload: UpdateTaskRequest) => {
  const response = await workspaceApi.updateTaskRaw(payload);
  return response.raw.ok;
};

const deleteTask = async (payload: DeleteTaskRequest) => {
  const response = await workspaceApi.deleteTaskRaw(payload);
  return response.raw.ok;
};

const completeTask = async (payload: SetTaskCompletedRequest) => {
  const response = await workspaceApi.setTaskCompletedRaw(payload);
  return response.raw.ok;
};

export const tasksService = {
  patchTask,
  fetchTasks,
  createTask,
  deleteTask,
  completeTask,
  patchTaskMetadata,
};
