import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { useTranslation } from '@/lib/i18n';
import { BookOpen, FileText } from 'lucide-react';
import { useEffect, useState } from 'react';

interface QueryDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const quickLinks = [
  {
    title: 'Documentation',
    icon: BookOpen,
    href: 'https://docs.example.com',
  },
  {
    title: 'Changelog',
    icon: FileText,
    href: 'https://changelog.example.com',
  },
];

export const QueryDialog = ({ open, onOpenChange }: QueryDialogProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  // Reset search when dialog closes
  useEffect(() => {
    if (!open) {
      setSearch('');
    }
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="gap-0 p-0 outline-none">
        <Command className="rounded-lg border-0">
          <CommandInput
            placeholder={t('GLOBAL_TITLE', 'Find documentation...')}
            value={search}
            onValueChange={setSearch}
            className="h-12"
          />
          <CommandList>
            <CommandGroup heading="Quick Links">
              {quickLinks.map((link) => (
                <CommandItem
                  key={link.title}
                  value={link.title}
                  onSelect={() => {
                    window.open(link.href, '_blank');
                    onOpenChange(false);
                  }}
                  className="flex items-center gap-2 px-4 py-2"
                >
                  <link.icon className="h-4 w-4" />
                  <span>{link.title}</span>
                </CommandItem>
              ))}
            </CommandGroup>
            <CommandGroup heading="Results" className="h-full max-h-96">
              {/* Add your search results here */}
            </CommandGroup>
          </CommandList>
        </Command>
      </DialogContent>
    </Dialog>
  );
};
