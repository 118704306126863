import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Skeleton } from "@/components/ui/skeleton";
import {
  useNotificationContent,
  useNotificationState,
} from "@/lib/hooks/use-notification.hook";
import { NotificationMessages, useAppSelector } from "@/lib/store";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { Bell } from "lucide-react";
import { useState } from "react";

interface NotificationItemProps {
  notification: NotificationMessages;
  id: string;
}

const NotificationSkeleton = () => (
  <div className="flex items-start gap-4 p-4">
    <Skeleton className="h-8 w-8 rounded-full" />
    <div className="space-y-2 flex-1">
      <Skeleton className="h-4 w-[80%]" />
      <Skeleton className="h-3 w-[50%]" />
    </div>
  </div>
);

const NotificationItem = ({ notification, id }: NotificationItemProps) => {
  const state = useNotificationState(notification);
  const notificationContent = useNotificationContent(notification, state);

  // Extract background color based on notification type
  const getBgColor = () => {
    switch (notification.eventType) {
      case "LOGIN":
      case "LOGOUT":
        return "bg-blue-500";
      case "CREATED":
        return "bg-green-500";
      case "DELETED":
        return "bg-red-500";
      default:
        return "bg-gray-500";
    }
  };

  return (
    <div
      className="flex items-start gap-4 p-4 hover:bg-accent/50 rounded-lg transition-colors"
      key={id}
    >
      <div
        className={cn(
          "h-8 w-8 rounded-full flex items-center justify-center text-white",
          getBgColor()
        )}
      >
        <Bell className="h-4 w-4" />
      </div>
      <div className="space-y-1 flex-1">
        <p
          className="text-sm text-foreground leading-tight"
          dangerouslySetInnerHTML={{ __html: notificationContent as string }}
        />
        <p className="text-xs text-muted-foreground">
          {format(new Date(notification.time!), "PPp")}
        </p>
      </div>
    </div>
  );
};

export const Notifications = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { notifications: rawNotifications, loading } = useAppSelector(
    (state) => state.notifications
  );

  // Filter and reduce notifications
  const notifications = rawNotifications
    .filter((notification) => notification.eventType !== "TRASHED")
    .reduce<NotificationMessages[]>((acc, current) => {
      const previous = acc[acc.length - 1];
      if (
        previous &&
        (current.eventType === "LOGIN" || current.eventType === "LOGOUT") &&
        previous.eventType === current.eventType
      ) {
        return acc;
      }
      return [...acc, current as NotificationMessages];
    }, []);

  const unreadCount = notifications.length;

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="relative">
          <Bell className="h-5 w-5" />
          {unreadCount > 0 && (
            <Badge
              variant="secondary"
              className="absolute -top-1 -right-1 h-5 w-5 rounded-full p-0 flex items-center justify-center"
            >
              {unreadCount}
            </Badge>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[380px]">
        <div className="flex items-center justify-between px-4 py-2 border-b">
          <h4 className="font-semibold text-sm">Notifications</h4>
          <Button variant="ghost" size="sm" className="text-xs">
            Mark all as read
          </Button>
        </div>
        <ScrollArea className="h-[calc(100vh-20rem)] py-2">
          {loading ? (
            <div className="space-y-2">
              <NotificationSkeleton />
              <NotificationSkeleton />
              <NotificationSkeleton />
            </div>
          ) : notifications.length > 0 ? (
            <div className="grid gap-1">
              {notifications.map((item, i) => (
                <NotificationItem key={i} id={`${i}`} notification={item} />
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-32 text-muted-foreground">
              <p className="text-sm">No notifications</p>
            </div>
          )}
        </ScrollArea>
        {notifications.length > 0 && (
          <div className="p-2 border-t">
            <Button variant="ghost" className="w-full text-xs">
              More
            </Button>
          </div>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
