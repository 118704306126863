import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import {
  getActivityContent,
  getActivityState,
  useNotificationContent,
  useNotificationState,
} from "@/lib/hooks/use-notification.hook";
import { NAMESPACES, useTranslation } from "@/lib/i18n";
import {
  getWorkspaceActivity,
  setDashboardPaginatedPage,
  setDashboardQuery,
  setDashboardSortOrder,
  useAppDispatch,
  useAppSelector,
} from "@/lib/store";
import { NotificationMessage } from "@oproma/prividox-orchestration-open-api";
import { format, isToday } from "date-fns";
import { motion } from "framer-motion";
import {
  AlertCircle,
  ArrowDown,
  ArrowUp,
  Bell,
  Calendar,
  Clock,
  Search,
  Users,
} from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { DashboardFeedPagination } from "./dashboard-feed-pagination.component";

const ActivityCard = ({ activity }: { activity: NotificationMessage }) => {
  const state = useNotificationState(activity);
  const content = useNotificationContent(activity, state);
  const { timeFormat } = useAppSelector((state) => state.user);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="p-4 rounded-xl  hover:bg-gray-100 border border-gray-200 shadow-sm transition-colors"
    >
      <div className="flex items-start gap-4">
        <Avatar className="mt-1 border-2 border-primary/10">
          <AvatarFallback className="bg-primary/10 text-primary font-medium">
            {activity.agentName?.charAt(0).toUpperCase()}
          </AvatarFallback>
        </Avatar>
        <div className="flex-1 min-w-0 space-y-1">
          <div
            className="text-sm text-gray-700"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <div className="flex items-center gap-2 text-xs text-gray-500">
            <Bell className="h-3 w-3" />
            {format(
              new Date(activity.time || 0),
              timeFormat === "HH:mm" ? "PPpp" : "PP HH:mm:ss"
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

type DashboardFeedProps = {
  workspaceId: string | undefined;
};
// TODO: Add translations for the dashboard feed
export const DashboardFeed = ({ workspaceId }: DashboardFeedProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(NAMESPACES.DEFAULT);
  const [fetchCount, setFetchCount] = useState<number>(100);
  const { activity, loading } = useAppSelector((state) => state.workspace);
  const {
    dashboardQuery,
    dashboardSortOrder,
    currentPaginatedPage,
    entriesPerPaginatedPage,
  } = useAppSelector((state) => state.dashboard);

  const [translatedActivities, setTranslatedActivities] = useState<
    Array<NotificationMessage & { activityContent: string }>
  >([]);

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(getWorkspaceActivity({ workspaceId, count: fetchCount }));
  }, [workspaceId, fetchCount]);

  useEffect(() => {
    const prepopulateTranslations = () => {
      const activitiesWithContent = activity.map((item) => {
        const state = getActivityState(item);
        const { key, state: translationState } = getActivityContent(
          item,
          state
        );
        const activityContent = t(
          `NOTIFICATION_BODIES.${key}`,
          `NOTIFICATION_BODIES.${key}`,
          translationState
        );
        return { ...item, activityContent };
      });
      setTranslatedActivities(activitiesWithContent);
    };

    if (activity.length > 0) {
      prepopulateTranslations();
    }
  }, [activity]);

  // Keep existing filtering and sorting logic
  const { paginatedActivities, totalItems, todayActivities } = useMemo(() => {
    let filteredActivities = translatedActivities.filter((item) => {
      if (!dashboardQuery) return true;
      return item.activityContent
        .toLowerCase()
        .includes(dashboardQuery.toLowerCase());
    });

    if (dashboardSortOrder === "asc" || dashboardSortOrder === "desc") {
      filteredActivities = filteredActivities.sort((a, b) => {
        const dateA = new Date(a.time || 0).getTime();
        const dateB = new Date(b.time || 0).getTime();
        return dashboardSortOrder === "asc" ? dateA - dateB : dateB - dateA;
      });
    }

    const startIndex = (currentPaginatedPage - 1) * entriesPerPaginatedPage;
    return {
      paginatedActivities: filteredActivities.slice(
        startIndex,
        startIndex + entriesPerPaginatedPage
      ),
      totalItems: filteredActivities.length,
      todayActivities: filteredActivities.filter((item) => {
        return isToday(new Date(item.time || 0));
      }).length,
    };
  }, [
    translatedActivities,
    dashboardQuery,
    dashboardSortOrder,
    currentPaginatedPage,
    entriesPerPaginatedPage,
  ]);

  const onFilterActivityStream = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDashboardQuery(e.target.value));
  };

  const onChangeSortOrder = () => {
    dispatch(
      setDashboardSortOrder(dashboardSortOrder === "asc" ? "desc" : "asc")
    );
  };

  const latestActivityTime = useMemo(() => {
    if (activity.length === 0) return null;
    return new Date(Math.max(...activity.map((a) => a.time || 0)));
  }, [activity]);

  return (
    <div className="grid gap-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Activity Stats Card */}
        {loading ? (
          <div className="p-6 rounded-2xl border border-gray-200 shadow-sm">
            <Skeleton className="h-10 w-full" />
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="p-6 rounded-2xl border border-gray-200 shadow-sm"
          >
            <div className="flex items-center gap-4">
              <div className="p-3 rounded-xl bg-primary/10">
                <Clock className="h-6 w-6 text-primary" />
              </div>
              <div>
                <div className="text-2xl font-semibold text-gray-900">
                  {latestActivityTime
                    ? format(latestActivityTime, "MMM Do, HH:mm a")
                    : "No activity"}
                </div>
                <div className="text-sm text-gray-900 font-medium">
                  Last Operation
                </div>
              </div>
            </div>
          </motion.div>
        )}
        {/* Recent Activity Card */}
        {loading ? (
          <div className="p-6 rounded-2xl border border-gray-200 shadow-sm">
            <Skeleton className="h-10 w-full" />
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="p-6 rounded-2xl border border-gray-200 shadow-sm"
          >
            <div className="flex items-center gap-4">
              <div className="p-3 rounded-xl bg-primary/10">
                <Calendar className="h-6 w-6 text-primary" />
              </div>
              <div>
                <div className="text-2xl font-semibold text-gray-900">
                  {todayActivities}
                </div>
                <div className="text-sm text-gray-600">Today's Operations</div>
              </div>
            </div>
          </motion.div>
        )}
        {/* Active Users Card */}
        {loading ? (
          <div className="p-6 rounded-2xl border border-gray-200 shadow-sm">
            <Skeleton className="h-10 w-full" />
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="p-6 rounded-2xl  border border-gray-200 shadow-sm"
          >
            <div className="flex items-center gap-4">
              <div className="p-3 rounded-xl bg-primary/10">
                <Users className="h-6 w-6 text-primary" />
              </div>
              <div>
                <div className="text-2xl font-semibold text-gray-900">
                  {new Set(activity?.map((a) => a.agentName)).size}
                </div>
                <div className="text-sm text-gray-600">User(s)</div>
              </div>
            </div>
          </motion.div>
        )}
      </div>

      {/* Activity Feed Section */}
      <div className="rounded-2xl bg-white border border-gray-200 shadow-sm p-6">
        <div className="flex items-center justify-between gap-4 mb-6">
          <div className="space-y-1">
            <h2 className="text-xl font-semibold text-gray-900">
              Operation Feed
            </h2>
            <p className="text-sm text-gray-500">Track workspace operations</p>
          </div>

          <div className="flex items-center gap-2">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
              <Input
                value={dashboardQuery}
                onChange={onFilterActivityStream}
                placeholder="Filter operations..."
                className="pl-9 w-[300px]  border-gray-200 text-gray-900 placeholder:text-gray-400"
              />
            </div>

            <Select
              value={fetchCount.toString()}
              onValueChange={(value) => setFetchCount(Number(value))}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select fetch count" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="50">Last 50 operations</SelectItem>
                <SelectItem value="100">Last 100 operations</SelectItem>
                <SelectItem value="200">Last 200 operations</SelectItem>
                <SelectItem value="500">Last 500 operations</SelectItem>
                <SelectItem value="1000">Last 1000 operations</SelectItem>
              </SelectContent>
            </Select>

            <Button
              variant="outline"
              size="icon"
              onClick={onChangeSortOrder}
              className="border-gray-200 text-gray-700 hover:text-gray-900 hover:"
            >
              {dashboardSortOrder === "asc" ? (
                <ArrowUp className="h-4 w-4" />
              ) : (
                <ArrowDown className="h-4 w-4" />
              )}
            </Button>
          </div>
        </div>

        {loading ? (
          <div className="p-6 rounded-2xl border border-gray-200 shadow-sm">
            <Skeleton className="h-10 w-full" />
          </div>
        ) : (
          <>
            {/* Activity List */}
            {paginatedActivities.length > 0 ? (
              <div className="space-y-4">
                {paginatedActivities.map((activity, index) => (
                  <ActivityCard key={index} activity={activity} />
                ))}
              </div>
            ) : (
              <div className="flex items-center justify-center h-32  rounded-xl border border-gray-200">
                <div className="text-center space-y-2">
                  <AlertCircle className="h-6 w-6 text-gray-400 mx-auto" />
                  <p className="text-sm text-gray-500">No activities found</p>
                </div>
              </div>
            )}
          </>
        )}

        {/* Pagination */}
        {paginatedActivities.length > 0 && (
          <div className="flex justify-center mt-6">
            <DashboardFeedPagination
              page={currentPaginatedPage}
              count={totalItems}
              pageSize={entriesPerPaginatedPage}
              onPageChange={(newPage) =>
                dispatch(setDashboardPaginatedPage(newPage))
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};
