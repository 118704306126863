import { OnboardGroupModal } from '@/containers/modals/onboard-group.modal.component';
import { OnboardMemberModal } from '@/containers/modals/onboard-member.modal.component';
import { CreateFileModal } from '../containers/modals/create-file.modal.component';
import { CreateFolderModal } from '../containers/modals/create-folder-modal.component';
import { CreateWorkspaceModal } from '../containers/modals/create-workspace.modal.component';
import { DeleteWorkspaceModal } from '../containers/modals/delete-workspace.modal.component';
import { DistributeModal } from '../containers/modals/distribute-modal.component';
import { FileManagerPreviewModal } from '../containers/modals/file-manager-preview-modal.component';
import { Header } from './header/header.component';
import { Navbar } from './navbar.component';

export const AppLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex">
      <Navbar />
      <div className="flex-1 bg-[#111827] p-6">
        <div className="min-h-[calc(100vh-3rem)] rounded-2xl bg-white">
          <div className="flex flex-col h-full">
            <Header />
            {children}

            {/* Create */}
            <CreateWorkspaceModal />
            <CreateFolderModal />
            <CreateFileModal />

            {/* Onboarding */}
            <OnboardMemberModal />
            <OnboardGroupModal />

            {/* Universal */}
            <FileManagerPreviewModal />
            <DistributeModal />

            {/* Delete */}
            <DeleteWorkspaceModal />
          </div>
        </div>
      </div>
    </div>
  );
};
