/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { Result } from "./Result";
import {
  ResultFromJSON,
  ResultFromJSONTyped,
  ResultToJSON,
  ResultToJSONTyped,
} from "./Result";

/**
 *
 * @export
 * @interface PermissionReportRow
 */
export interface PermissionReportRow {
  /**
   *
   * @type {string}
   * @memberof PermissionReportRow
   */
  name?: string;
  /**
   *
   * @type {{ [key: string]: Result; }}
   * @memberof PermissionReportRow
   */
  verbs?: { [key: string]: Result };
  /**
   *
   * @type {Array<PermissionReportRow>}
   * @memberof PermissionReportRow
   */
  children?: Array<PermissionReportRow>;
}

/**
 * Check if a given object implements the PermissionReportRow interface.
 */
export function instanceOfPermissionReportRow(
  value: object,
): value is PermissionReportRow {
  return true;
}

export function PermissionReportRowFromJSON(json: any): PermissionReportRow {
  return PermissionReportRowFromJSONTyped(json, false);
}

export function PermissionReportRowFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PermissionReportRow {
  if (json == null) {
    return json;
  }
  return {
    name: json["name"] == null ? undefined : json["name"],
    verbs:
      json["verbs"] == null
        ? undefined
        : mapValues(json["verbs"], ResultFromJSON),
    children:
      json["children"] == null
        ? undefined
        : (json["children"] as Array<any>).map(PermissionReportRowFromJSON),
  };
}

export function PermissionReportRowToJSON(json: any): PermissionReportRow {
  return PermissionReportRowToJSONTyped(json, false);
}

export function PermissionReportRowToJSONTyped(
  value?: PermissionReportRow | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value["name"],
    verbs:
      value["verbs"] == null
        ? undefined
        : mapValues(value["verbs"], ResultToJSON),
    children:
      value["children"] == null
        ? undefined
        : (value["children"] as Array<any>).map(PermissionReportRowToJSON),
  };
}
