/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TaskSpec
 */
export interface TaskSpec {
  /**
   * The title of the task.
   * @type {string}
   * @memberof TaskSpec
   */
  name: string;
  /**
   * The ID of the user or group or users to whom the task is assigned.
   * @type {string}
   * @memberof TaskSpec
   */
  user: string;
  /**
   * The due date of the task.
   * @type {number}
   * @memberof TaskSpec
   */
  expiry?: number;
  /**
   * A description of the task.
   * @type {string}
   * @memberof TaskSpec
   */
  description?: string;
  /**
   * The ID of the files attached to the task.
   * @type {Array<string>}
   * @memberof TaskSpec
   */
  attachments: Array<string>;
}

/**
 * Check if a given object implements the TaskSpec interface.
 */
export function instanceOfTaskSpec(value: object): value is TaskSpec {
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("user" in value) || value["user"] === undefined) return false;
  if (!("attachments" in value) || value["attachments"] === undefined)
    return false;
  return true;
}

export function TaskSpecFromJSON(json: any): TaskSpec {
  return TaskSpecFromJSONTyped(json, false);
}

export function TaskSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TaskSpec {
  if (json == null) {
    return json;
  }
  return {
    name: json["name"],
    user: json["user"],
    expiry: json["expiry"] == null ? undefined : json["expiry"],
    description: json["description"] == null ? undefined : json["description"],
    attachments: json["attachments"],
  };
}

export function TaskSpecToJSON(json: any): TaskSpec {
  return TaskSpecToJSONTyped(json, false);
}

export function TaskSpecToJSONTyped(
  value?: TaskSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value["name"],
    user: value["user"],
    expiry: value["expiry"],
    description: value["description"],
    attachments: value["attachments"],
  };
}
