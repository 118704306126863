/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ResultMapStringObject
 */
export interface ResultMapStringObject {
  /**
   *
   * @type {Array<{ [key: string]: object; }>}
   * @memberof ResultMapStringObject
   */
  items?: Array<{ [key: string]: object }>;
}

/**
 * Check if a given object implements the ResultMapStringObject interface.
 */
export function instanceOfResultMapStringObject(
  value: object,
): value is ResultMapStringObject {
  return true;
}

export function ResultMapStringObjectFromJSON(
  json: any,
): ResultMapStringObject {
  return ResultMapStringObjectFromJSONTyped(json, false);
}

export function ResultMapStringObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultMapStringObject {
  if (json == null) {
    return json;
  }
  return {
    items: json["items"] == null ? undefined : json["items"],
  };
}

export function ResultMapStringObjectToJSON(json: any): ResultMapStringObject {
  return ResultMapStringObjectToJSONTyped(json, false);
}

export function ResultMapStringObjectToJSONTyped(
  value?: ResultMapStringObject | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    items: value["items"],
  };
}
