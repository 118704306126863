import { SetNotificationTypeStatusRequest } from "@oproma/prividox-orchestration-open-api";
import { notificationsApi } from "./config";

// Notifications
const fetchNotifications = () => {
  return notificationsApi.listActivities({
    before: new Date().valueOf(),
  });
};

const fetchNotificationPreferences = () => {
  return notificationsApi.listNotificationTypes();
};

const patchEmailNotificationProvider = async (body: boolean) => {
  const response = await notificationsApi.addNotificationProviderRaw({
    provider: "email",
    providerKeySpec: {
      key: "",
    },
  });
  return response.raw.ok;
};

const deleteEmailNotificationProvider = async () => {
  const response = await notificationsApi.removeNotificationProviderRaw({
    provider: "email",
  });
  return response.raw.ok;
};

const patchNotificationPreference = async (
  payload: SetNotificationTypeStatusRequest,
) => {
  const response = await notificationsApi.setNotificationTypeStatusRaw(payload);
  return response.raw.ok;
};

export const notificationsService = {
  fetchNotifications,
  patchEmailNotificationProvider,
  deleteEmailNotificationProvider,
  patchNotificationPreference,
  fetchNotificationPreferences,
};
