/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { Project } from "./Project";
import {
  ProjectFromJSON,
  ProjectFromJSONTyped,
  ProjectToJSON,
  ProjectToJSONTyped,
} from "./Project";

/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  passwordHash?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  active?: boolean;
  /**
   *
   * @type {Set<string>}
   * @memberof User
   */
  fingerprints?: Set<string>;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  twoFARequired?: boolean;
  /**
   *
   * @type {Set<number>}
   * @memberof User
   */
  scratchCodes?: Set<number>;
  /**
   *
   * @type {Date}
   * @memberof User
   */
  lastPasswordReset?: Date;
  /**
   *
   * @type {Date}
   * @memberof User
   */
  lastLogin?: Date;
  /**
   *
   * @type {number}
   * @memberof User
   */
  loginCount?: number;
  /**
   *
   * @type {number}
   * @memberof User
   */
  failedLoginCount?: number;
  /**
   *
   * @type {Date}
   * @memberof User
   */
  failedLoginTimeStamp?: Date;
  /**
   *
   * @type {string}
   * @memberof User
   */
  customerId?: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  ignoringAbsoluteTimeout?: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  passwordResetId?: string;
  /**
   *
   * @type {Date}
   * @memberof User
   */
  passwordResetDate?: Date;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof User
   */
  notificationProviders?: { [key: string]: string };
  /**
   *
   * @type {{ [key: string]: boolean; }}
   * @memberof User
   */
  notificationTypes?: { [key: string]: boolean };
  /**
   *
   * @type {number}
   * @memberof User
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  gauthSecret?: string;
  /**
   *
   * @type {Project}
   * @memberof User
   */
  project?: Project;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): value is User {
  return true;
}

export function UserFromJSON(json: any): User {
  return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): User {
  if (json == null) {
    return json;
  }
  return {
    name: json["name"] == null ? undefined : json["name"],
    passwordHash:
      json["passwordHash"] == null ? undefined : json["passwordHash"],
    email: json["email"] == null ? undefined : json["email"],
    active: json["active"] == null ? undefined : json["active"],
    fingerprints:
      json["fingerprints"] == null ? undefined : new Set(json["fingerprints"]),
    twoFARequired:
      json["twoFARequired"] == null ? undefined : json["twoFARequired"],
    scratchCodes:
      json["scratchCodes"] == null ? undefined : new Set(json["scratchCodes"]),
    lastPasswordReset:
      json["lastPasswordReset"] == null
        ? undefined
        : new Date(json["lastPasswordReset"]),
    lastLogin:
      json["lastLogin"] == null ? undefined : new Date(json["lastLogin"]),
    loginCount: json["loginCount"] == null ? undefined : json["loginCount"],
    failedLoginCount:
      json["failedLoginCount"] == null ? undefined : json["failedLoginCount"],
    failedLoginTimeStamp:
      json["failedLoginTimeStamp"] == null
        ? undefined
        : new Date(json["failedLoginTimeStamp"]),
    customerId: json["customerId"] == null ? undefined : json["customerId"],
    ignoringAbsoluteTimeout:
      json["ignoringAbsoluteTimeout"] == null
        ? undefined
        : json["ignoringAbsoluteTimeout"],
    passwordResetId:
      json["passwordResetId"] == null ? undefined : json["passwordResetId"],
    passwordResetDate:
      json["passwordResetDate"] == null
        ? undefined
        : new Date(json["passwordResetDate"]),
    notificationProviders:
      json["notificationProviders"] == null
        ? undefined
        : json["notificationProviders"],
    notificationTypes:
      json["notificationTypes"] == null ? undefined : json["notificationTypes"],
    version: json["version"] == null ? undefined : json["version"],
    id: json["id"] == null ? undefined : json["id"],
    gauthSecret: json["gauthSecret"] == null ? undefined : json["gauthSecret"],
    project:
      json["project"] == null ? undefined : ProjectFromJSON(json["project"]),
  };
}

export function UserToJSON(json: any): User {
  return UserToJSONTyped(json, false);
}

export function UserToJSONTyped(
  value?: User | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value["name"],
    passwordHash: value["passwordHash"],
    email: value["email"],
    active: value["active"],
    fingerprints:
      value["fingerprints"] == null
        ? undefined
        : Array.from(value["fingerprints"] as Set<any>),
    twoFARequired: value["twoFARequired"],
    scratchCodes:
      value["scratchCodes"] == null
        ? undefined
        : Array.from(value["scratchCodes"] as Set<any>),
    lastPasswordReset:
      value["lastPasswordReset"] == null
        ? undefined
        : value["lastPasswordReset"].toISOString(),
    lastLogin:
      value["lastLogin"] == null ? undefined : value["lastLogin"].toISOString(),
    loginCount: value["loginCount"],
    failedLoginCount: value["failedLoginCount"],
    failedLoginTimeStamp:
      value["failedLoginTimeStamp"] == null
        ? undefined
        : value["failedLoginTimeStamp"].toISOString(),
    customerId: value["customerId"],
    ignoringAbsoluteTimeout: value["ignoringAbsoluteTimeout"],
    passwordResetId: value["passwordResetId"],
    passwordResetDate:
      value["passwordResetDate"] == null
        ? undefined
        : value["passwordResetDate"].toISOString(),
    notificationProviders: value["notificationProviders"],
    notificationTypes: value["notificationTypes"],
    version: value["version"],
    id: value["id"],
    gauthSecret: value["gauthSecret"],
    project: ProjectToJSON(value["project"]),
  };
}
