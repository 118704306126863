/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ResetPasswordSpec
 */
export interface ResetPasswordSpec {
  /**
   * The new email address to associate with the current user.
   * @type {string}
   * @memberof ResetPasswordSpec
   */
  email: string;
}

/**
 * Check if a given object implements the ResetPasswordSpec interface.
 */
export function instanceOfResetPasswordSpec(
  value: object,
): value is ResetPasswordSpec {
  if (!("email" in value) || value["email"] === undefined) return false;
  return true;
}

export function ResetPasswordSpecFromJSON(json: any): ResetPasswordSpec {
  return ResetPasswordSpecFromJSONTyped(json, false);
}

export function ResetPasswordSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResetPasswordSpec {
  if (json == null) {
    return json;
  }
  return {
    email: json["email"],
  };
}

export function ResetPasswordSpecToJSON(json: any): ResetPasswordSpec {
  return ResetPasswordSpecToJSONTyped(json, false);
}

export function ResetPasswordSpecToJSONTyped(
  value?: ResetPasswordSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    email: value["email"],
  };
}
