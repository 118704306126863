import { Entity, baseConfig } from "@/lib/store";
import { IFrame } from "./iframe.component";

type PdfFilePreviewerProps = {
  entity: Entity;
  style: React.CSSProperties;
  redactions: boolean;
};

const createPreviewFileLink = (entity: Entity) => {
  if (entity.contentType === "application/pdf") {
    return `${baseConfig.basePath}/download/${entity.id}?preview=true`;
  }
  return `${baseConfig.basePath}/files/${entity.id}/contents`;
};

export const PdfFilePreviewer = ({
  entity,
  style,
  redactions,
}: PdfFilePreviewerProps) => {
  const fileUrl = createPreviewFileLink(entity);
  const viewerUrl = `../pdfviewer/web/viewer.html?file=${encodeURIComponent(
    fileUrl
  )}#locale=en-CA&name=${encodeURIComponent(
    entity.name as string
  )}&redactions=${redactions}&id=${entity.id}`;

  switch (entity?.contentType) {
    case "application/pdf":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case "application/msword":
    case "application/vnd.ms-excel":
    case "application/vnd.ms-powerpoint":
      return (
        <div style={{ ...style, display: "flex", flexDirection: "column" }}>
          <IFrame
            id="pdf-previewer"
            ariaLabel="document"
            allowFullScreen
            url={viewerUrl}
            styles={{
              border: "none",
              width: "100%",
              height: "100% !important",
              flexGrow: 1,
            }}
          />
        </div>
      );
    default:
      return <>Preview couldn't be generated</>;
  }
};
