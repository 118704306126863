import {
  ChangeMemberAccessLevelRequest,
  GetMemberActivityRequest,
  InviteWorkspaceMemberRequest,
  RemoveWorkspaceMemberRequest,
} from '@oproma/prividox-orchestration-open-api';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  extractApiError,
  reportsService,
  userService,
  workspacesService,
} from '../api';
import { getMemberActivityContent } from './helpers';
import {
  IMembersState,
  MembersErrorCodesEnum,
  SortOrder,
  WorkspaceMember,
} from './types';

export enum MemberSortOptionEnum {
  Name = 'name',
  Email = 'email',
  WorkspaceOwner = 'workspaceOwner',
}

const initialState: IMembersState = {
  member: null,
  members: [],
  memberQuery: '',
  onboardedMembers: [],
  memberReport: [],
  memberActivityContent: [],
  lastOpenedMember: null,
  memberSortOrder: 'asc',
  currentPaginatedPage: 1,
  entriesPerPaginatedPage: 10,
  deletedMemberMessage: false,
  permissionLevelFilter: null,
  displayMobileMembersTable: false,
  displayOnboardMembersModal: false,
  displayEditMemberModal: false,
  displayRemoveMemberModal: false,
  displayBulkRemoveMemberModal: false,
  displayMemberFinder: false,
  toastMessage: null,
  loading: false,
  error: null,
  memberSortValue: MemberSortOptionEnum.Name,
};

export const requestPasswordReset = createAsyncThunk(
  '@@members/requestPasswordReset',
  async (email: string, { rejectWithValue }) => {
    try {
      return await userService.requestPasswordReset({ email });
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const getWorkspaceMember = createAsyncThunk(
  '@@members/getWorkspaceMember',
  async (userId: string, { rejectWithValue }) => {
    try {
      return await userService.fetchUser(userId);
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to get workspace user.');
    }
  },
);

export const getWorkspaceMembers = createAsyncThunk(
  '@@members/getWorkspaceMembers',
  async (workspaceId: string, { rejectWithValue }) => {
    try {
      const rawMembers = await workspacesService.fetchWorkspaceMembers({
        workspaceId,
      });

      if (!rawMembers) {
        return rejectWithValue('Failed to fetch workspace members.');
      }

      const members: WorkspaceMember[] =
        rawMembers.list?.map((member) => ({
          ...member,
          thumbnailBackground: 'purple',
          thumbnail: `https://ui-avatars.com/api/?name=${encodeURIComponent(
            member.name || '',
          )}`,
          checked: false,
        })) || [];

      return members;
    } catch (e) {
      console.error((e as Error).message);
      return rejectWithValue('Failed to fetch workspace members.');
    }
  },
);

export const getWorkspaceMemberReport = createAsyncThunk(
  '@@members/getWorkspaceMemberReport',
  async (payload: GetMemberActivityRequest, { rejectWithValue }) => {
    try {
      return await reportsService.fetchMemberActivity(payload);
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const onboardWorkspaceMember = createAsyncThunk(
  '@@members/onboardWorkspaceMember',
  async (
    { workspaceId, inviteMemberSpec }: InviteWorkspaceMemberRequest,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await workspacesService.createWorkspaceMember({
        workspaceId,
        inviteMemberSpec,
      });

      dispatch(getWorkspaceMembers(workspaceId));
      return response;
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const editWorkspaceMember = createAsyncThunk(
  '@@members/editWorkspaceMember',
  async (
    { workspaceId, user, accessLevelSpec }: ChangeMemberAccessLevelRequest,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await workspacesService.patchWorkspaceMember({
        workspaceId,
        user,
        accessLevelSpec,
      });
      dispatch(getWorkspaceMembers(workspaceId));
      return response;
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const deleteWorkspaceMember = createAsyncThunk(
  '@@members/deleteWorkspaceMember',
  async (
    { workspaceId, user }: RemoveWorkspaceMemberRequest,
    { rejectWithValue, dispatch },
  ) => {
    try {
      await workspacesService.deleteWorkspaceMember({
        workspaceId,
        user,
      });
      dispatch(getWorkspaceMembers(workspaceId));
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

const membersSlice = createSlice({
  name: '@@members',
  initialState,
  reducers: {
    setLastOpenedMember: (state, action: PayloadAction<string | null>) => {
      state.lastOpenedMember = action.payload;
    },
    setDisplayOnboardMembersModal: (state, action: PayloadAction<boolean>) => {
      state.displayOnboardMembersModal = action.payload;
    },
    setDisplayEditMemberModal: (state, action: PayloadAction<boolean>) => {
      state.displayEditMemberModal = action.payload;
    },
    setDisplayRemoveMemberModal: (state, action: PayloadAction<boolean>) => {
      state.displayRemoveMemberModal = action.payload;
    },
    setOpenBulkDeleteMemberModal: (state, action: PayloadAction<boolean>) => {
      state.displayBulkRemoveMemberModal = action.payload;
    },

    setDisplayMemberFinder: (state, action: PayloadAction<boolean>) => {
      state.displayMemberFinder = action.payload;
    },
    setDisplayMobileMembersTable: (state, action: PayloadAction<boolean>) => {
      state.displayMobileMembersTable = action.payload;
    },
    setMemberQuery: (state, action: PayloadAction<string>) => {
      state.memberQuery = action.payload;
    },
    setPaginatedPage: (state, action: PayloadAction<number>) => {
      state.currentPaginatedPage = action.payload;
    },
    setMemberEntriesPerPaginatedPage: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.entriesPerPaginatedPage = action.payload;
    },
    setMemberSortOrder: (state, action: PayloadAction<SortOrder>) => {
      state.memberSortOrder = action.payload;
    },
    setMemberSortValue: (state, action: PayloadAction<string>) => {
      state.memberSortValue = action.payload;
    },
    setPermissionLevelFilter: (state, action: PayloadAction<number | null>) => {
      state.permissionLevelFilter = action.payload;
    },
    setMemberCheckedStatus: (
      state,
      action: PayloadAction<{ memberId: string | undefined; checked: boolean }>,
    ) => {
      const { memberId, checked } = action.payload;
      const memberIndex = state.members.findIndex(
        (member) => member.id === memberId,
      );
      if (memberIndex !== -1) {
        state.members[memberIndex] = {
          ...state.members[memberIndex],
          checked: checked,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkspaceMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkspaceMember.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: MembersErrorCodesEnum.GET_WORKSPACE_MEMBER_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getWorkspaceMember.fulfilled, (state, action) => {
        state.loading = false;
        state.member = action.payload;
        state.error = null;
      });
    builder
      .addCase(getWorkspaceMembers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkspaceMembers.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: MembersErrorCodesEnum.GET_WORKSPACE_MEMBERS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getWorkspaceMembers.fulfilled, (state, action) => {
        //   Object.assign(state, action.payload);
        state.members = action.payload;
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(getWorkspaceMemberReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkspaceMemberReport.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: MembersErrorCodesEnum.GET_WORKSPACE_MEMBER_REPORT_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getWorkspaceMemberReport.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.items) {
          state.memberReport = action.payload.items;
          state.memberActivityContent = getMemberActivityContent(
            action.payload.items,
          );
          state.error = null;
        } else {
          state.error = {
            code: MembersErrorCodesEnum.GET_WORKSPACE_MEMBER_REPORT_FAILED,
            message: 'Failed to fetch member report. Please try again.',
          };
        }
      });
    builder
      .addCase(onboardWorkspaceMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(onboardWorkspaceMember.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: MembersErrorCodesEnum.CREATE_WORKSPACE_MEMBERS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(onboardWorkspaceMember.fulfilled, (state, action) => {
        state.onboardedMembers = action.payload;
        state.toastMessage = 'Member onboarded successfully!';
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(editWorkspaceMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editWorkspaceMember.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: MembersErrorCodesEnum.EDIT_WORKSPACE_MEMBER_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(editWorkspaceMember.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      });

    builder
      .addCase(deleteWorkspaceMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteWorkspaceMember.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: MembersErrorCodesEnum.DELETE_WORKSPACE_MEMBER_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(deleteWorkspaceMember.fulfilled, (state) => {
        state.loading = false;
        state.deletedMemberMessage = true;
        state.error = null;
      });
  },
});

export const {
  setLastOpenedMember,
  setDisplayEditMemberModal,
  setDisplayOnboardMembersModal,
  setDisplayMemberFinder,
  setDisplayRemoveMemberModal,
  setOpenBulkDeleteMemberModal,
  setDisplayMobileMembersTable,
  setMemberQuery,
  setMemberEntriesPerPaginatedPage,
  setPaginatedPage,
  setMemberSortOrder,
  setMemberSortValue,
  setPermissionLevelFilter,
  setMemberCheckedStatus,
} = membersSlice.actions;

export const membersReducer = membersSlice.reducer;
