/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CompletedSpec
 */
export interface CompletedSpec {
  /**
   * Whether the task is completed or not.
   * @type {boolean}
   * @memberof CompletedSpec
   */
  completed: boolean;
}

/**
 * Check if a given object implements the CompletedSpec interface.
 */
export function instanceOfCompletedSpec(value: object): value is CompletedSpec {
  if (!("completed" in value) || value["completed"] === undefined) return false;
  return true;
}

export function CompletedSpecFromJSON(json: any): CompletedSpec {
  return CompletedSpecFromJSONTyped(json, false);
}

export function CompletedSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CompletedSpec {
  if (json == null) {
    return json;
  }
  return {
    completed: json["completed"],
  };
}

export function CompletedSpecToJSON(json: any): CompletedSpec {
  return CompletedSpecToJSONTyped(json, false);
}

export function CompletedSpecToJSONTyped(
  value?: CompletedSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    completed: value["completed"],
  };
}
