import {
  AppState,
  Entity,
  EntityMetadata,
  filesProvider,
  metadataService,
} from '@/lib/store';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StarredEntitiesState, StarredEntity } from './types';

const initialState: StarredEntitiesState = {
  starred: [],
};

export const fetchStarredEntities = createAsyncThunk(
  '@@starredEntities/fetchStarredEntities',
  async (workspaceId: string, { getState }) => {
    const { starred } = (getState() as AppState).starredEntities;
    const workspaceStarred = starred.filter(
      (s: StarredEntity) => s.workspaceId === workspaceId,
    );

    const entities = await Promise.all(
      workspaceStarred.map(async (s: StarredEntity) => {
        // Fetch metadata and parent ID in parallel
        const [metadata, parentId] = await Promise.all([
          metadataService.fetchMetadata(s.entityId) as Promise<EntityMetadata>,
          filesProvider.fetchEntityParent(s.entityId),
        ]);

        return {
          ...metadata,
          created: metadata.created.toString(),
          id: s.entityId,
          svg:
            metadata.type === 'folder'
              ? 'folder'
              : metadata.type === 'calendar'
                ? 'calendar'
                : metadata.type === 'gallery'
                  ? 'gallery'
                  : metadata.contentType,
          metadata: {
            ...metadata,
            starred: 'true',
            parentId: parentId,
          },
        } as Entity;
      }),
    );

    return entities;
  },
);

export const starredEntitiesSlice = createSlice({
  name: '@@starredEntities',
  initialState,
  reducers: {
    addStarredEntity: (
      state,
      action: PayloadAction<{ entityId: string; workspaceId: string }>,
    ) => {
      const { entityId, workspaceId } = action.payload;
      const exists = state.starred.some(
        (s) => s.entityId === entityId && s.workspaceId === workspaceId,
      );

      if (!exists) {
        state.starred.push({
          entityId,
          workspaceId,
          timestamp: Date.now(),
        });
      }
    },
    removeStarredEntity: (
      state,
      action: PayloadAction<{ entityId: string; workspaceId: string }>,
    ) => {
      const { entityId, workspaceId } = action.payload;
      state.starred = state.starred.filter(
        (s) => !(s.entityId === entityId && s.workspaceId === workspaceId),
      );
    },
  },
});

export const { addStarredEntity, removeStarredEntity } =
  starredEntitiesSlice.actions;

export const starredEntitiesReducer = starredEntitiesSlice.reducer;
