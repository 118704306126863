import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useGroupsFiltering } from '@/lib/hooks/use-groups-filtering.hook';
import { useGroupsPagination } from '@/lib/hooks/use-groups-pagination.hook';
import {
  deleteGroup,
  setDisplayEditGroupModal,
  setDisplayOnboardGroupsModal,
  setGroupPermissionLevelFilter,
  setGroupQuery,
  setLastOpenedGroup,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { cn } from '@/lib/utils';
import { useParams } from '@tanstack/react-router';
import { motion } from 'framer-motion';
import {
  ChevronDown,
  ChevronUp,
  Lock,
  Pencil,
  Search,
  Settings,
  Shield,
  Trash2,
  User,
  UserMinus,
  UserPlus,
  Users,
} from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';
import { GroupMembers } from './group-members.component';

export const GroupsContainer = () => {
  const { workspaceId } = useParams({ strict: false });
  const dispatch = useAppDispatch();

  const [expandedGroup, setExpandedGroup] = useState<string | null>(null);
  const [groupToDelete, setGroupToDelete] = useState<string | null>(null);
  const [selectedGroupsForBulkDelete, setSelectedGroupsForBulkDelete] =
    useState<string[]>([]);

  const { groups, permissionLevelFilter, groupQuery, displayEditGroupModal } =
    useAppSelector((state) => state.groups);

  const filteredGroups = useGroupsFiltering(groups);
  const { paginatedItems: paginatedGroups } =
    useGroupsPagination(filteredGroups);

  const onEditGroup = (groupId: string | null) => {
    dispatch(setLastOpenedGroup(groupId));
    dispatch(setDisplayEditGroupModal(!displayEditGroupModal));
  };

  const handleDeleteGroup = (groupId: string) => {
    if (!workspaceId) return;

    dispatch(
      deleteGroup({
        workspaceId,
        group: groupId,
      }),
    )
      .unwrap()
      .then(() => {
        toast.success('Group has been deleted successfully.');
        setGroupToDelete(null);
      })
      .catch((error) => {
        toast.error(`Failed to delete group: ${error}`);
      });
  };

  const handleBulkDeleteGroups = () => {
    if (!workspaceId || selectedGroupsForBulkDelete.length === 0) return;

    // Process each group deletion in sequence
    Promise.all(
      selectedGroupsForBulkDelete.map((groupId) =>
        dispatch(
          deleteGroup({
            workspaceId,
            group: groupId,
          }),
        ).unwrap(),
      ),
    )
      .then(() => {
        toast.success(
          `${selectedGroupsForBulkDelete.length} groups deleted successfully.`,
        );
        setSelectedGroupsForBulkDelete([]);
      })
      .catch((error) => {
        toast.error(`Failed to delete some groups: ${error}`);
      });
  };

  const handleGroupSelection = (groupId: string, checked: boolean) => {
    if (checked) {
      setSelectedGroupsForBulkDelete((prev) => [...prev, groupId]);
    } else {
      setSelectedGroupsForBulkDelete((prev) =>
        prev.filter((id) => id !== groupId),
      );
    }
  };

  const isGroupChecked = (groupId: string) => {
    return selectedGroupsForBulkDelete.includes(groupId);
  };

  const handleOnboardGroup = () => {
    dispatch(setDisplayOnboardGroupsModal(true));
  };

  return (
    <div className="p-6 space-y-6">
      {/* Top Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="p-6 rounded-2xl bg-white border border-gray-200 shadow-sm"
        >
          <div className="flex items-center gap-4">
            <div className="p-3 rounded-xl bg-primary/10">
              <Users className="h-6 w-6 text-primary" />
            </div>
            <div>
              <div className="text-2xl font-semibold text-gray-900">
                {groups.length}
              </div>
              <div className="text-sm text-gray-600">Total Groups</div>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="p-6 rounded-2xl bg-white border border-gray-200 shadow-sm"
        >
          <div className="flex items-center gap-4">
            <div className="p-3 rounded-xl bg-primary/10">
              <Lock className="h-6 w-6 text-primary" />
            </div>
            <div>
              <div className="text-2xl font-semibold text-gray-900">
                {groups.filter((g) => g.isDefaultGroup).length}
              </div>
              <div className="text-sm text-gray-600">Default Groups</div>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="p-6 rounded-2xl bg-white border border-gray-200 shadow-sm"
        >
          <div className="flex items-center gap-4">
            <div className="p-3 rounded-xl bg-primary/10">
              <User className="h-6 w-6 text-primary" />
            </div>
            <div>
              <div className="text-2xl font-semibold text-gray-900">
                {groups.reduce(
                  (acc, group) => acc + (group.memberCount || 0),
                  0,
                )}
              </div>
              <div className="text-sm text-gray-600">Total Members</div>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Bulk Actions Bar (when groups are selected) */}
      {selectedGroupsForBulkDelete.length > 0 && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="flex items-center justify-between p-4 rounded-xl bg-primary/5 border border-primary/20"
        >
          <div className="text-sm font-medium">
            {selectedGroupsForBulkDelete.length} groups selected
          </div>
          <div className="flex gap-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                // Clear all selections
                setSelectedGroupsForBulkDelete([]);
              }}
            >
              Clear
            </Button>
            <Button
              variant="destructive"
              size="sm"
              onClick={handleBulkDeleteGroups}
            >
              <UserMinus className="h-4 w-4 mr-2" />
              Delete Selected
            </Button>
          </div>
        </motion.div>
      )}

      {/* Main Content Grid */}
      <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
        {/* Groups List - Spans 8 columns */}
        <div className="md:col-span-8 space-y-4">
          {/* Groups Header */}
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold text-gray-900">Groups</h2>
            <Button onClick={handleOnboardGroup} className="text-white">
              <UserPlus className="h-4 w-4 mr-2" />
              Onboard Group
            </Button>
          </div>

          {/* Groups Cards */}
          <div className="space-y-4">
            {paginatedGroups.map((group, index) => (
              <motion.div
                key={group.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.05 }}
                className={cn(
                  'p-4 rounded-xl border transition-all',
                  expandedGroup === group.id
                    ? 'bg-gray-50 border-primary'
                    : 'bg-white border-gray-200 hover:border-primary/50',
                )}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <Checkbox
                      className="text-white"
                      checked={isGroupChecked(group.id!)}
                      disabled={group.isDefaultGroup}
                      onCheckedChange={(checked) => {
                        handleGroupSelection(group.id!, !!checked);
                      }}
                    />
                    <Avatar className="h-10 w-10 border-2 border-primary/10">
                      <AvatarFallback className="bg-primary/10 text-primary">
                        {group.name?.charAt(0).toUpperCase()}
                      </AvatarFallback>
                    </Avatar>
                    <div>
                      <div className="font-medium text-gray-900">
                        {group.name}
                      </div>
                      <div className="text-sm text-gray-500 flex items-center gap-2">
                        <Users className="h-3 w-3" />
                        {group.memberCount} members
                      </div>
                    </div>
                    {group.isDefaultGroup && (
                      <Badge variant="secondary" className="ml-2">
                        <Shield className="h-3 w-3 mr-1" />
                        Default Group
                      </Badge>
                    )}
                  </div>

                  <div className="flex items-center gap-2">
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() =>
                        setExpandedGroup(
                          expandedGroup === group.id ? null : group.id!,
                        )
                      }
                    >
                      {expandedGroup === group.id ? (
                        <ChevronUp className="h-4 w-4" />
                      ) : (
                        <ChevronDown className="h-4 w-4" />
                      )}
                    </Button>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="sm">
                          <Settings className="h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuItem
                          onClick={() => onEditGroup(group.id!)}
                        >
                          <Pencil className="h-4 w-4 mr-2" />
                          Edit Group
                        </DropdownMenuItem>
                        {!group.isDefaultGroup && (
                          <DropdownMenuItem
                            onClick={() => setGroupToDelete(group.id)}
                            className="text-destructive focus:text-destructive"
                          >
                            <Trash2 className="h-4 w-4 mr-2" />
                            Delete Group
                          </DropdownMenuItem>
                        )}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>

                {/* Expanded Content */}
                {expandedGroup === group.id && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    className="mt-4 pt-4 border-t border-gray-200"
                  >
                    <GroupMembers
                      groupId={group.id!}
                      defaultGroup={group.isDefaultGroup}
                    />
                  </motion.div>
                )}
              </motion.div>
            ))}
          </div>
        </div>

        {/* Right Sidebar - Spans 4 columns */}
        <div className="md:col-span-4 space-y-4">
          {/* Search & Filters */}
          <div className="p-6 rounded-2xl bg-white border border-gray-200 shadow-sm">
            <div className="space-y-4">
              <h3 className="font-medium text-gray-900">Filters</h3>
              <div className="relative">
                <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
                <Input
                  placeholder="Find groups..."
                  className="pl-9"
                  value={groupQuery}
                  onChange={(e) => dispatch(setGroupQuery(e.target.value))}
                />
              </div>
              <Select
                value={permissionLevelFilter?.toString() || 'every'}
                onValueChange={(value) =>
                  dispatch(
                    setGroupPermissionLevelFilter(
                      value === 'every' ? null : value === 'true',
                    ),
                  )
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Filter by type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="every">Every Group</SelectItem>
                  <SelectItem value="true">Default Groups</SelectItem>
                  <SelectItem value="false">Custom Groups</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
      </div>
      {/* Delete Group Confirmation Dialog */}
      <AlertDialog
        open={!!groupToDelete}
        onOpenChange={(open) => !open && setGroupToDelete(null)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Group</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this group? This action cannot be
              undone, and any members will lose associated permissions.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
              onClick={() => groupToDelete && handleDeleteGroup(groupToDelete)}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};
