import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setPinnedWorkspaces } from "../store/user";

export const usePinEntities = () => {
  const dispatch = useAppDispatch();
  const { pinnedWorkspaces } = useAppSelector((state) => state.user);

  const togglePinnedWorkspace = (workspaceId: string) => {
    const newPinnedWorkspaces = pinnedWorkspaces.includes(workspaceId)
      ? pinnedWorkspaces.filter((id) => id !== workspaceId)
      : [...pinnedWorkspaces, workspaceId];
    dispatch(setPinnedWorkspaces(newPinnedWorkspaces));
  };

  return {
    pinnedWorkspaces,
    togglePinnedWorkspace,
  };
};
