/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ValidateCouponSpec
 */
export interface ValidateCouponSpec {
  /**
   * The coupon code to validate.
   * @type {string}
   * @memberof ValidateCouponSpec
   */
  code: string;
}

/**
 * Check if a given object implements the ValidateCouponSpec interface.
 */
export function instanceOfValidateCouponSpec(
  value: object,
): value is ValidateCouponSpec {
  if (!("code" in value) || value["code"] === undefined) return false;
  return true;
}

export function ValidateCouponSpecFromJSON(json: any): ValidateCouponSpec {
  return ValidateCouponSpecFromJSONTyped(json, false);
}

export function ValidateCouponSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ValidateCouponSpec {
  if (json == null) {
    return json;
  }
  return {
    code: json["code"],
  };
}

export function ValidateCouponSpecToJSON(json: any): ValidateCouponSpec {
  return ValidateCouponSpecToJSONTyped(json, false);
}

export function ValidateCouponSpecToJSONTyped(
  value?: ValidateCouponSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    code: value["code"],
  };
}
