/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CreateTrialSpec
 */
export interface CreateTrialSpec {
  /**
   * The name to give to the workspace.
   * @type {string}
   * @memberof CreateTrialSpec
   */
  name: string;
  /**
   * The coupon code to use to activate the workspace.
   * @type {string}
   * @memberof CreateTrialSpec
   */
  coupon: string;
}

/**
 * Check if a given object implements the CreateTrialSpec interface.
 */
export function instanceOfCreateTrialSpec(
  value: object,
): value is CreateTrialSpec {
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("coupon" in value) || value["coupon"] === undefined) return false;
  return true;
}

export function CreateTrialSpecFromJSON(json: any): CreateTrialSpec {
  return CreateTrialSpecFromJSONTyped(json, false);
}

export function CreateTrialSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateTrialSpec {
  if (json == null) {
    return json;
  }
  return {
    name: json["name"],
    coupon: json["coupon"],
  };
}

export function CreateTrialSpecToJSON(json: any): CreateTrialSpec {
  return CreateTrialSpecToJSONTyped(json, false);
}

export function CreateTrialSpecToJSONTyped(
  value?: CreateTrialSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value["name"],
    coupon: value["coupon"],
  };
}
