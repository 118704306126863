/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { Child } from "./Child";
import {
  ChildFromJSON,
  ChildFromJSONTyped,
  ChildToJSON,
  ChildToJSONTyped,
} from "./Child";

/**
 *
 * @export
 * @interface ResultChild
 */
export interface ResultChild {
  /**
   *
   * @type {Array<Child>}
   * @memberof ResultChild
   */
  items?: Array<Child>;
}

/**
 * Check if a given object implements the ResultChild interface.
 */
export function instanceOfResultChild(value: object): value is ResultChild {
  return true;
}

export function ResultChildFromJSON(json: any): ResultChild {
  return ResultChildFromJSONTyped(json, false);
}

export function ResultChildFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultChild {
  if (json == null) {
    return json;
  }
  return {
    items:
      json["items"] == null
        ? undefined
        : (json["items"] as Array<any>).map(ChildFromJSON),
  };
}

export function ResultChildToJSON(json: any): ResultChild {
  return ResultChildToJSONTyped(json, false);
}

export function ResultChildToJSONTyped(
  value?: ResultChild | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    items:
      value["items"] == null
        ? undefined
        : (value["items"] as Array<any>).map(ChildToJSON),
  };
}
