import { useTranslation } from '@/lib/i18n';
import {
  WorkspaceMember,
  onboardWorkspaceMember,
  setDisplayOnboardMembersModal,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from '@tanstack/react-router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import * as yup from 'yup';

import { Alert, AlertDescription } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { AlertCircle, UserPlus } from 'lucide-react';

// Define the permission levels and languages
const permissionLevels = [
  {
    value: '-127',
    label: 'translation:MEMBERS.ONBOARDING.PERMISSIONS.ADMINISTRATOR' as const,
  },
  {
    value: '0',
    label: 'translation:MEMBERS.ONBOARDING.PERMISSIONS.OWNER' as const,
  },
  {
    value: '7',
    label: 'translation:MEMBERS.ONBOARDING.PERMISSIONS.REGULAR' as const,
  },
  {
    value: '63',
    label: 'translation:MEMBERS.ONBOARDING.PERMISSIONS.RESTRICTED' as const,
  },
  {
    value: '126',
    label: 'translation:MEMBERS.ONBOARDING.PERMISSIONS.SECURE' as const,
  },
];

const languages = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'Français' },
  { value: 'en_fr', label: 'English / Français' },
  { value: 'fr_en', label: 'Français / English' },
];

// Schema for form validation
const onboardMemberSchema = yup
  .object({
    email: yup.string().email().required(),
    permission: yup.string().required(),
    language: yup.string().oneOf(['en', 'fr', 'en_fr', 'fr_en']).required(),
  })
  .required();

type OnboardMemberFormValues = yup.InferType<typeof onboardMemberSchema>;

// Helper function to check if member exists
const memberExists = (
  members: WorkspaceMember[],
  email: string | undefined,
): boolean => {
  return members.some((member) => member.email === email);
};

export const OnboardMemberModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams({ strict: false });
  const { displayOnboardMembersModal, members } = useAppSelector(
    (state) => state.members,
  );
  const [userExists, setUserExists] = useState<boolean>(false);

  // Translate permission levels
  const translatedPermissionLevels = permissionLevels.map((option) => ({
    value: option.value,
    label: t(option.label),
  }));

  // Initialize form with default values
  const form = useForm<OnboardMemberFormValues>({
    resolver: yupResolver(onboardMemberSchema),
    defaultValues: {
      permission: '-127',
      language: 'en',
      email: '',
    },
  });

  // Handle modal close
  const handleClose = () => {
    dispatch(setDisplayOnboardMembersModal(false));
    setUserExists(false);
    form.reset();
  };

  // Handle form submission
  const onSubmit = (values: OnboardMemberFormValues) => {
    if (memberExists(members, values.email)) {
      setUserExists(true);
      toast.error(t('translation:MEMBERS.ONBOARDING.ERROR.USER_EXISTS'));
      return;
    }

    if (!workspaceId) return;

    dispatch(
      onboardWorkspaceMember({
        workspaceId,
        inviteMemberSpec: [
          {
            access: values.permission,
            email: values.email,
            lang: values.language,
          },
        ],
      }),
    );

    // TODO: Translation needed
    toast.success('Onboarding process has been started.');
    handleClose();
  };

  return (
    <Dialog open={displayOnboardMembersModal} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <div className="relative">
            {/* Background Pattern */}
            <div
              className="absolute inset-0 bg-gradient-to-br from-primary/10 via-background to-background"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-[linear-gradient(to_right,transparent_0%,#ffffff08_50%,transparent_100%)] h-[1px] top-1/2" />
            </div>

            {/* Content */}
            <div className="relative px-6 py-8 space-y-4">
              {/* Title Section */}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="p-2.5 rounded-xl bg-primary/10 backdrop-blur-sm">
                    <UserPlus className="h-5 w-5 text-primary" />
                  </div>
                  <div>
                    <DialogTitle className="text-lg font-semibold">
                      {t('translation:MEMBERS.ONBOARDING.TITLE')}
                    </DialogTitle>
                    <p className="text-sm text-muted-foreground">
                      Onboard a new member to your workspace
                    </p>
                  </div>
                </div>
              </div>

              {/* Divider */}
              <Separator className="bg-border/40" />
            </div>
          </div>
        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-6 px-6"
          >
            {userExists && (
              <Alert variant="destructive" className="mb-4">
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>
                  {t('translation:MEMBERS.ONBOARDING.ERROR.USER_EXISTS')}
                </AlertDescription>
              </Alert>
            )}

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('translation:MEMBERS.ONBOARDING.EMAIL')}
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Enter email address" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="permission"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('translation:MEMBERS.ONBOARDING.PERMISSIONS.TITLE')}
                  </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select permission level" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {translatedPermissionLevels.map((level) => (
                        <SelectItem key={level.value} value={level.value}>
                          {level.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="language"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('translation:MEMBERS.ONBOARDING.LANGUAGE')}
                  </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select language" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {languages.map((language) => (
                        <SelectItem key={language.value} value={language.value}>
                          {language.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end gap-3 pt-4">
              <Button variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit">
                {t('translation:MEMBERS.ONBOARDING.CREATE_BUTTON')}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
