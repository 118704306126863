import {
  CreateProjectSpec,
  ValueSpec,
} from '@oproma/prividox-orchestration-open-api';

import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'sonner';
import {
  extractApiError,
  metadataApi,
  reportsService,
  workspacesService,
} from '../api';
import {
  CreateWorkspaceForm,
  IWorkspacesState,
  WorkspacesErrorCodesEnum,
} from './types';

const initialState: IWorkspacesState = {
  error: null,
  workspaces: [],
  loading: false,
  workspaceName: null,
  createdWorkspace: null,
  displayWorkspaceModal: false,
  fetchingDiskMetrics: false,
  lastOpenedWorkspace: null,
  displayOnboardWorkspaceModal: false,
  createWorkspaceForm: null,
};

const defaultMetadata = JSON.stringify([
  [
    {
      name: 'title',
      values: ['Sample Dashboard Title - Click the edit button to modify'],
    },
  ],
  [
    {
      name: 'paragraph',
      values: ['Sample description - Click the edit button to modify.'],
    },
  ],
]);

export const getWorkspaces = createAsyncThunk(
  '@@workspaces/getWorkspaces',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const fetchedWorkspaces = await workspacesService.fetchWorkspaces();
      if (!fetchedWorkspaces.items?.length) {
        return [];
      }

      // First, fetch access levels for all workspaces
      const accessLevelResults = await Promise.allSettled(
        fetchedWorkspaces.items.map(async (workspace) => ({
          workspace,
          accessLevel: await workspacesService.fetchAccessLevelForWorkspace(
            workspace.id!,
          ),
        })),
      );

      // Process results, using a default access level of 0 for failed fetches
      const workspacesWithPermissions = accessLevelResults.map(
        (result, index) => {
          const workspace = fetchedWorkspaces.items![index];
          if (result.status === 'fulfilled') {
            return {
              ...workspace,
              accessLevel: parseInt(result.value.accessLevel, 10),
            };
          }
          console.warn(
            `Failed to fetch access level for workspace ${workspace.id}:`,
            result.reason,
          );
          return {
            ...workspace,
            accessLevel: 0, // Default to no access on failure
          };
        },
      );

      // Fetch extra metrics for workspaces with sufficient access level
      const enrichmentResults = await Promise.allSettled(
        workspacesWithPermissions.map(async (workspace) => {
          if (workspace.accessLevel < 126) {
            return {
              ...workspace,
              usage: undefined,
              folderCount: undefined,
              fileCount: undefined,
            };
          }

          dispatch(setFetchingDiskMetrics(true));
          try {
            const diskUsage = await reportsService.fetchDiskUsage(
              workspace.id!,
            );
            return {
              ...workspace,
              usage: diskUsage.size,
              folderCount: diskUsage.folderCount,
              fileCount: diskUsage.fileCount,
            };
          } finally {
            dispatch(setFetchingDiskMetrics(false));
          }
        }),
      );

      // Process results, preserving workspaces even if metrics fetch fails
      const enrichedWorkspaces = enrichmentResults.map((result, index) => {
        if (result.status === 'fulfilled') {
          return result.value;
        }
        console.warn(
          `Failed to fetch metrics for workspace ${workspacesWithPermissions[index].id}:`,
          result.reason,
        );
        return {
          ...workspacesWithPermissions[index],
          usage: undefined,
          folderCount: undefined,
          fileCount: undefined,
        };
      });

      // Sort workspaces by name
      return enrichedWorkspaces
        .filter((workspace) => workspace.enabled)
        .sort((a, b) => {
          if (!a.name || !b.name) return 0;
          return a.name.localeCompare(b.name);
        });
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const createWorkspace = createAsyncThunk(
  '@@workspaces/createWorkspace',
  async (
    createProjectSpec: CreateProjectSpec,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const workspace =
        await workspacesService.createWorkspace(createProjectSpec);

      // Set the default metadata for the workspace
      const valueSpec: ValueSpec = {
        value: defaultMetadata,
      };

      await metadataApi.setMetadata({
        entity: workspace,
        key: 'dashboard',
        valueSpec: valueSpec,
      });

      toast.success(
        `Created a ${workspace} workspace by the name of ${createProjectSpec?.name}`,
      );
      dispatch(getWorkspaces());
      return workspace;
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

const workspacesSlice = createSlice({
  name: '@@workspaces',
  initialState,
  reducers: {
    setCreateWorkpaceFormField: (
      state,
      action: PayloadAction<CreateWorkspaceForm>,
    ) => {
      state.createWorkspaceForm = {
        ...state.createWorkspaceForm,
        ...action.payload,
      };
    },
    setLastOpenedWorkspace: (state, action: PayloadAction<string>) => {
      state.lastOpenedWorkspace = action.payload;
    },
    setFetchingDiskMetrics: (state, action: PayloadAction<boolean>) => {
      state.fetchingDiskMetrics = action.payload;
    },
    setDisplayDeleteWorkspaceModal: (state, action: PayloadAction<boolean>) => {
      state.displayWorkspaceModal = action.payload;
    },
    toggleDisplayOnboardWorkspaceModal: (state) => {
      state.displayOnboardWorkspaceModal = !state.displayOnboardWorkspaceModal;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkspaces.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkspaces.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: WorkspacesErrorCodesEnum.GET_WORKSPACES_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getWorkspaces.fulfilled, (state, action) => {
        //   Object.assign(state, action.payload);
        state.workspaces = action.payload;
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(createWorkspace.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createWorkspace.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: WorkspacesErrorCodesEnum.CREATE_WORKSPACE_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(createWorkspace.fulfilled, (state, action) => {
        //   Object.assign(state, action.payload);
        state.createdWorkspace = action.payload;
        state.loading = false;
        state.error = null;
      });
  },
});

export const {
  setLastOpenedWorkspace,
  setFetchingDiskMetrics,
  setCreateWorkpaceFormField,
  setDisplayDeleteWorkspaceModal,
  toggleDisplayOnboardWorkspaceModal,
} = workspacesSlice.actions;

export const workspacesReducer = workspacesSlice.reducer;
