/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { NotificationProviderInfo } from "./NotificationProviderInfo";
import {
  NotificationProviderInfoFromJSON,
  NotificationProviderInfoFromJSONTyped,
  NotificationProviderInfoToJSON,
  NotificationProviderInfoToJSONTyped,
} from "./NotificationProviderInfo";

/**
 *
 * @export
 * @interface ResultNotificationProviderInfo
 */
export interface ResultNotificationProviderInfo {
  /**
   *
   * @type {Array<NotificationProviderInfo>}
   * @memberof ResultNotificationProviderInfo
   */
  items?: Array<NotificationProviderInfo>;
}

/**
 * Check if a given object implements the ResultNotificationProviderInfo interface.
 */
export function instanceOfResultNotificationProviderInfo(
  value: object,
): value is ResultNotificationProviderInfo {
  return true;
}

export function ResultNotificationProviderInfoFromJSON(
  json: any,
): ResultNotificationProviderInfo {
  return ResultNotificationProviderInfoFromJSONTyped(json, false);
}

export function ResultNotificationProviderInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultNotificationProviderInfo {
  if (json == null) {
    return json;
  }
  return {
    items:
      json["items"] == null
        ? undefined
        : (json["items"] as Array<any>).map(NotificationProviderInfoFromJSON),
  };
}

export function ResultNotificationProviderInfoToJSON(
  json: any,
): ResultNotificationProviderInfo {
  return ResultNotificationProviderInfoToJSONTyped(json, false);
}

export function ResultNotificationProviderInfoToJSONTyped(
  value?: ResultNotificationProviderInfo | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    items:
      value["items"] == null
        ? undefined
        : (value["items"] as Array<any>).map(NotificationProviderInfoToJSON),
  };
}
