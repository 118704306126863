import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useWizard } from '@/lib/hooks/use-wizard.hook';
import {
  toggleDisplayOnboardWorkspaceModal,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { cn } from '@/lib/utils';
import { Separator } from '@radix-ui/react-separator';
import { motion } from 'framer-motion';
import {
  CheckCircle2,
  CreditCard,
  FolderOpenDot,
  FolderPlus,
  Package,
} from 'lucide-react';
import { OnboardWorkspaceBasicsForm } from '../$workspaceId/dashboard/forms/onboard-workspace-basics-form.component';
import { OnboardWorkspaceCCForm } from '../$workspaceId/dashboard/forms/onboard-workspace-cc-form.component';
import { OnboardWorkspacePricingPlansForm } from '../$workspaceId/dashboard/forms/onboard-workspace-pricing-plans-form.component';
import { OnboardWorkspaceCompletedBanner } from '../$workspaceId/dashboard/onboard-workspace-completed-banner.component';
import { Processes } from '../../components/wizard.component';

const steps = [
  {
    id: 1,
    title: 'Basics',
    icon: FolderOpenDot,
  },
  {
    id: 2,
    title: 'Credit Card',
    icon: CreditCard,
  },
  {
    id: 3,
    title: 'Pricing Plan',
    icon: Package,
  },
  {
    id: 4,
    title: 'Complete',
    icon: CheckCircle2,
  },
] as const;

export const CreateWorkspaceModal = () => {
  const dispatch = useAppDispatch();
  const { currentProcess } = useWizard();
  const { displayOnboardWorkspaceModal } = useAppSelector(
    (state) => state.workspaces,
  );

  const toggle = () => {
    dispatch(toggleDisplayOnboardWorkspaceModal());
  };

  return (
    <Dialog open={displayOnboardWorkspaceModal} onOpenChange={toggle}>
      <DialogTitle></DialogTitle>
      <DialogContent className="sm:max-w-[640px]">
        <DialogHeader>
          <div className="relative">
            {/* Background Pattern */}
            <div
              className="absolute inset-0 bg-gradient-to-br from-primary/10 via-background to-background"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-[linear-gradient(to_right,transparent_0%,#ffffff08_50%,transparent_100%)] h-[1px] top-1/2" />
            </div>

            {/* Content */}
            <div className="relative px-6 py-8 space-y-4">
              {/* Title Section */}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="p-2.5 rounded-xl bg-primary/10 backdrop-blur-sm">
                    <FolderPlus className="h-5 w-5 text-primary" />
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold">
                      Create New Workspace
                    </h2>
                    <p className="text-sm text-muted-foreground">
                      Set up your workspace in a few simple steps
                    </p>
                  </div>
                </div>
              </div>

              {/* Divider */}
              <Separator className="bg-border/40" />
            </div>
          </div>
        </DialogHeader>

        {/* Stepper */}
        <div className="mb-6">
          <div className="relative">
            {/* Progress Bar */}
            <div className="absolute top-[22px] left-0 h-[2px] w-full bg-border">
              <div
                className="h-full bg-primary transition-all duration-500"
                style={{
                  width: `${((currentProcess - 1) / (steps.length - 1)) * 100}%`,
                }}
              />
            </div>

            {/* Steps */}
            <div className="relative z-10 flex justify-between">
              {steps.map((step) => {
                const StepIcon = step.icon;
                const isActive = currentProcess >= step.id;

                return (
                  <div
                    key={step.id}
                    className="flex flex-col items-center"
                    style={{ width: '80px' }}
                  >
                    <motion.div
                      className={cn(
                        'flex h-11 w-11 items-center justify-center rounded-full border-2',
                        'transition-colors duration-200',
                        isActive
                          ? 'border-primary bg-primary text-primary-foreground'
                          : 'border-border bg-background text-muted-foreground',
                      )}
                      initial={false}
                      animate={{
                        scale: currentProcess === step.id ? 1.1 : 1,
                      }}
                    >
                      <StepIcon
                        className={cn('h-5 w-5', isActive && 'text-white')}
                      />
                    </motion.div>
                    <span
                      className={cn(
                        'mt-2 text-xs font-medium text-center',
                        isActive ? 'text-foreground' : 'text-muted-foreground',
                      )}
                    >
                      {step.title}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="flex-1 min-h-0">
          <Processes>
            <OnboardWorkspaceBasicsForm />
            <OnboardWorkspaceCCForm />
            <OnboardWorkspacePricingPlansForm />
            <OnboardWorkspaceCompletedBanner />
          </Processes>
        </div>
      </DialogContent>
    </Dialog>
  );
};
