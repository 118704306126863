import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { toggleDisplayEditDescriptionModal } from "@/lib/store";
import { useAppDispatch, useAppSelector } from "@/lib/store/hooks";
import { useParams } from "@tanstack/react-router";
import { Edit3 } from "lucide-react";

export const DashboardHeader = () => {
  const { workspaceId } = useParams({ strict: false });
  const { dashboardTitle, dashboardDescription, loading } = useAppSelector(
    (state) => state.workspace
  );
  const { workspaces } = useAppSelector((state) => state.workspaces);
  const owner = workspaces.find(
    (workspace) => workspace.id === workspaceId
  )?.amOwner;
  const dispatch = useAppDispatch();

  const toggleModal = () => {
    dispatch(toggleDisplayEditDescriptionModal());
  };

  return (
    <div className="container mx-auto p-6 space-y-6">
      {/* Header Section */}
      <div className="flex justify-between items-start">
        <div className="space-y-1">
          <h1 className="text-2xl font-bold">
            {loading ? (
              <Skeleton className="h-8 w-48" />
            ) : (
              dashboardTitle || "Dashboard"
            )}
          </h1>
          <p className="text-muted-foreground">
            {loading ? <Skeleton className="h-4 w-96" /> : dashboardDescription}
          </p>
        </div>
        {owner && (
          <Button variant="ghost" size="icon" onClick={toggleModal}>
            <Edit3 className="h-4 w-4" />
          </Button>
        )}
      </div>
    </div>
  );
};
