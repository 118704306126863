import { NAMESPACES, useTranslation } from "@/lib/i18n";
import {
  MenuItem,
  setPinnedWorkspaces,
  useAppDispatch,
  useAppSelector,
} from "@/lib/store";
import { ProjectInformation } from "@oproma/prividox-orchestration-open-api";
import { useLocation } from "@tanstack/react-router";
import Fuse from "fuse.js";
import { useMemo, useState } from "react";

export const useWorkspaces = () => {
  const [workspaceMenuQuery, setWorkspaceMenuQuery] = useState("");
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { workspaces } = useAppSelector((state) => state.workspaces);
  const { pinnedWorkspaces } = useAppSelector((state) => state.user);
  const { t } = useTranslation(NAMESPACES.COMMON);

  const createMenuFromWorkspaces = (
    workspaces: ProjectInformation[]
  ): MenuItem[] => {
    const menu = workspaces.map((workspace, index) => {
      const payload = {
        id: workspace.id,
        key: index,
        icon: "dashboard",
        text: workspace.name,
        link: `/${workspace.id}/file-manager`,
        active: pathname.includes(`/${workspace.id}`),
        subMenu: [
          {
            text: t("common:COMMON.DASHBOARD"),
            icon: "dashboard",
            link: `/${workspace.id}/dashboard`,
          },
          {
            text: t("common:COMMON.FILES"),
            icon: "folder",
            link: `/${workspace.id}/file-manager`,
          },
          {
            text: t("common:COMMON.TASKS"),
            icon: "template",
            link: `/${workspace.id}/tasks`,
          },
          {
            text: t("common:COMMON.MEMBERS"),
            icon: "users",
            link: `/${workspace.id}/members`,
          },
          {
            text: t("common:COMMON.GROUPS"),
            icon: "user-list",
            link: `/${workspace.id}/groups`,
          },
        ],
      };

      if (workspace.amOwner || workspace.isAdmin) {
        payload.subMenu.push(
          {
            text: t("common:COMMON.PERMISSIONS"),
            icon: "shield",
            link: `/${workspace.id}/permissions`,
          },
          {
            text: t("common:COMMON.OPTIONS"),
            icon: "setting-alt",
            link: `/${workspace.id}/options`,
          }
        );
      }

      return payload;
    });
    return [
      {
        heading: t("common:COMMON.MENU"),
        badge: undefined,
      },
      ...menu,
    ];
  };

  const togglePinnedWorkspace = (workspaceId: string) => {
    const newPinnedWorkspaces = pinnedWorkspaces.includes(workspaceId)
      ? pinnedWorkspaces.filter((id) => id !== workspaceId)
      : [...pinnedWorkspaces, workspaceId];
    dispatch(setPinnedWorkspaces(newPinnedWorkspaces));
  };

  const workspacesMenu = createMenuFromWorkspaces(workspaces);

  const fuse = useMemo(() => {
    return new Fuse(workspacesMenu, {
      keys: ["text", "subMenu.text"],
      threshold: 0.3,
      includeMatches: true,
    });
  }, [workspacesMenu]);

  const workspaceMenuQueryResults = useMemo(() => {
    if (!workspaceMenuQuery) return workspacesMenu;

    const results = fuse.search(workspaceMenuQuery);
    return results.map((result) => ({
      ...result.item,
      matches: result.matches,
    }));
  }, [workspaceMenuQuery, workspacesMenu, fuse]);

  return {
    workspaces,
    pinnedWorkspaces,
    workspaceCount: workspaces.length,
    workspaceMenuQuery,
    setWorkspaceMenuQuery,
    workspaceMenuQueryResults,
    togglePinnedWorkspace,
  };
};
