// components/file-manager/file-grid.tsx
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useEntityDateFormat } from '@/lib/hooks/use-entity-date-format.hook';
import { useEntityDragDrop } from '@/lib/hooks/use-entity-drag-drop.hook';
import { useEntityNavigation } from '@/lib/hooks/use-entity-navigation.hook';
import { useEntitySelection } from '@/lib/hooks/use-entity-selection.hook';
import { useStarredEntities } from '@/lib/hooks/use-starred-entities.hook';
import { NAMESPACES, useTranslation } from '@/lib/i18n';
import { Entity, useAppSelector } from '@/lib/store';
import { cn, prettyBytes } from '@/lib/utils';
import { useParams } from '@tanstack/react-router';
import { motion } from 'framer-motion';
import { Clock, Database, Folder, Pencil, Shield, Star } from 'lucide-react';
import { memo, useMemo } from 'react';
import { FileManagerDropdown } from './file-manager-dropdown.component';
import {
  EntityThumbnail,
  getEntityThumbnail,
} from './svg-thumbnails.component';

// const iconMap = {
//   file: FileText,
//   folder: Folder,
//   gallery: Image,
//   calendar: Calendar,
// };

interface EntityItemProps {
  entity: Entity;
  entityDisplayType: string | undefined;
  layoutType?: 'trash';
  isSelected: boolean;
  onSelectEntity: (
    entityId: string,
    isShiftClick: boolean,
    isCtrlClick: boolean,
  ) => void;
  workspaceId: string;
  parentEntityId?: string;
}

export const EntityItem = memo(
  ({
    entity,
    entityDisplayType,
    layoutType,
    isSelected,
    onSelectEntity,
    workspaceId,
    parentEntityId,
  }: EntityItemProps) => {
    const { isDragging, canDrop, isOver, dragDropRef } = useEntityDragDrop(
      entity.id,
      entity.type === 'folder',
      parentEntityId,
    );
    const { handleEntityClick } = useEntityNavigation(workspaceId);
    const { starredEntities: starred, toggleStar: toggleStarred } =
      useStarredEntities(workspaceId);
    const { 'app.settings.dateFormat': dateFormat } = useAppSelector(
      (state) => state.user,
    );

    const { formatDateTime } = useEntityDateFormat();

    // const Icon = iconMap[entity.type as keyof typeof iconMap] || FileText;

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
      // Check if the click originated from the dropdown menu or control buttons
      const isControlClick = !!(
        (
          e.target instanceof Node &&
          ((e.target as HTMLElement).closest('[data-no-preview]') || // Checks for elements with data-no-preview
            (e.target as HTMLElement).closest('[role="menuitem"]') || // Checks for dropdown menu items
            (e.target as HTMLElement).closest('[role="menu"]') || // Checks for the dropdown menu itself
            (e.target as HTMLElement).closest('button'))
        ) // Checks for any buttons
      );

      if (!isControlClick) {
        handleEntityClick(e, entity);
      }
    };

    return (
      <motion.div
        ref={dragDropRef}
        layout
        initial={{ opacity: 0, scale: 0.96 }}
        animate={{
          opacity: 1,
          scale: 1,
          backgroundColor:
            isOver && canDrop ? 'var(--primary-50)' : 'transparent',
        }}
        whileHover={{ scale: 1.02 }}
        className={cn(
          'relative flex flex-col p-4 rounded-lg border border-border/50',
          'transition-colors duration-200',
          isSelected && 'border-primary bg-primary/5',
          isDragging && 'opacity-50',
          entityDisplayType === 'list'
            ? 'flex-row items-center'
            : 'items-center',
        )}
        onClick={handleClick}
      >
        {/* Top controls */}
        <div className="absolute top-2 left-2 z-10">
          <Checkbox
            checked={isSelected}
            onCheckedChange={() => onSelectEntity(entity.id, false, false)}
          />
        </div>

        <div className="absolute top-2 right-2 z-10 flex items-center gap-1">
          <Button
            variant="ghost"
            size="sm"
            className="h-7 w-7 p-0"
            onClick={(e) => {
              e.stopPropagation();
              toggleStarred(entity.id);
            }}
          >
            <Star
              className={cn(
                'w-4 h-4',
                starred.some((s) => s.id === entity.id)
                  ? 'fill-yellow-400 text-yellow-400'
                  : 'text-muted-foreground hover:text-yellow-400',
              )}
            />
          </Button>
          <FileManagerDropdown entity={entity} layoutType={layoutType} />
        </div>

        {/* Main content */}
        <div className="flex flex-col items-center gap-3 w-full pt-6">
          {/* Icon */}
          <div
            className={cn(
              'flex items-center justify-center',
              'w-16 h-16 rounded-lg',
              // entity.type === "folder" ? "bg-orange-100" : "bg-blue-100"
            )}
          >
            {/* <Icon
              className={cn(
                "w-8 h-8",
                entity.type === "folder" ? "text-orange-600" : "text-blue-600"
              )}
            /> */}
            {getEntityThumbnail(entity.svg as EntityThumbnail)}
          </div>

          {/* Name and badges */}
          <div className="flex flex-col items-center gap-2 w-full">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <h3 className="text-sm font-medium text-center truncate max-w-[200px]">
                    {entity.name}
                  </h3>
                </TooltipTrigger>
                <TooltipContent>{entity.name}</TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <div className="flex items-center gap-1.5">
              {entity.subscribed && (
                <Badge variant="secondary" className="h-5 text-xs">
                  Subscribed
                </Badge>
              )}

              {(entity.level === 'PROTECTED_A' ||
                entity.level === 'PROTECTED_B') && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Badge variant="outline" className="h-5 text-xs gap-1">
                        <Shield className="w-3 h-3 text-yellow-500" />
                        {entity.level}
                      </Badge>
                    </TooltipTrigger>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
          </div>

          {/* Metadata footer */}
          <div className="flex items-center justify-between w-full mt-auto pt-2 text-xs text-muted-foreground border-t border-border/50">
            <div className="flex items-center gap-3">
              <div className="flex items-center gap-1">
                <Clock className="w-3 h-3" />
                <span>{formatDateTime(entity.modified, dateFormat, null)}</span>
              </div>

              {entity.type !== 'gallery' && entity.type !== 'calendar' && (
                <div className="flex items-center gap-1">
                  <Database className="w-3 h-3" />
                  <span>{prettyBytes(entity.size || 0)}</span>
                </div>
              )}

              {entity.type === 'file' && entity.numversion && (
                <div className="flex items-center gap-1">
                  <Pencil className="w-3 h-3" />
                  <span className="truncate">v{entity.numversion}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    );
  },
);

interface FileManagerProps {
  entityDisplayType?: string;
  entities: Entity[];
  layoutType?: 'trash';
  parentEntityId?: string;
}

export const FileManagerFiles = ({
  entityDisplayType,
  entities,
  layoutType,
  parentEntityId,
}: FileManagerProps) => {
  const { workspaceId } = useParams({
    strict: false,
  });
  const { t } = useTranslation(NAMESPACES.FILE_MANAGER);
  const { selectedEntityIds, handleSelectEntity } =
    useEntitySelection(entities);

  const { folders, files } = useMemo(
    () => ({
      folders: entities.filter((e) =>
        ['folder', 'gallery', 'calendar'].includes(e.type as string),
      ),
      files: entities.filter((e) => e.type === 'file'),
    }),
    [entities],
  );

  const listHeaderItems = [
    { id: 'name', label: t('file-manager:FILE_MANAGER.FILES.FILENAME') },
    {
      id: 'modified',
      label: t('file-manager:FILE_MANAGER.FILES.LAST_MODIFIED'),
    },
    { id: 'owner', label: t('file-manager:FILE_MANAGER.FILES.OWNER') },
  ];

  if (entities.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-[300px] text-muted-foreground">
        <Folder className="w-12 h-12 mb-4" />
        <p>{t('file-manager:FILE_MANAGER.FILES.EMPTY')}</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {entityDisplayType === 'list' && (
        <div className="grid grid-cols-[1fr,150px,150px,48px] gap-4 px-4 py-2 border-b text-sm font-medium text-muted-foreground">
          {listHeaderItems.map((item) => (
            <div key={item.id}>{item.label}</div>
          ))}
          <div />
        </div>
      )}

      <div
        className={cn(
          'grid gap-4',
          entityDisplayType === 'list'
            ? 'grid-cols-1'
            : 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3',
        )}
      >
        {entityDisplayType === 'group' ? (
          <>
            {folders.length > 0 && (
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">
                  {t('file-manager:FILE_MANAGER.FILES.FOLDERS')}
                </h3>
                <div className="grid gap-4">
                  {folders.map((entity) => (
                    <EntityItem
                      key={entity.id}
                      entity={entity}
                      entityDisplayType={entityDisplayType}
                      layoutType={layoutType}
                      isSelected={selectedEntityIds.includes(entity.id)}
                      onSelectEntity={handleSelectEntity}
                      workspaceId={workspaceId as string}
                      parentEntityId={parentEntityId}
                    />
                  ))}
                </div>
              </div>
            )}

            {files.length > 0 && (
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">
                  {t('file-manager:FILE_MANAGER.FILES.FILES')}
                </h3>
                <div className="grid gap-4">
                  {files.map((entity) => (
                    <EntityItem
                      key={entity.id}
                      entity={entity}
                      entityDisplayType={entityDisplayType}
                      layoutType={layoutType}
                      isSelected={selectedEntityIds.includes(entity.id)}
                      onSelectEntity={handleSelectEntity}
                      workspaceId={workspaceId as string}
                      parentEntityId={parentEntityId}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        ) : (
          entities.map((entity) => (
            <EntityItem
              key={entity.id}
              entity={entity}
              entityDisplayType={entityDisplayType}
              layoutType={layoutType}
              isSelected={selectedEntityIds.includes(entity.id)}
              onSelectEntity={handleSelectEntity}
              workspaceId={workspaceId as string}
              parentEntityId={parentEntityId}
            />
          ))
        )}
      </div>
    </div>
  );
};
