import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Label } from '@/components/ui/label';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Textarea } from '@/components/ui/textarea';
import {
  createEntityComment,
  deleteEntityComment,
  Entity,
  getEntityNotifications,
  getWorkspaceMembers,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { NotificationMessage } from '@oproma/prividox-orchestration-open-api';
import { useNavigate, useParams } from '@tanstack/react-router';
import { formatDistanceToNow } from 'date-fns';
import { AtSign, FileText, Loader2, MoreVertical, Send, X } from 'lucide-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'sonner';

interface CommentsTabProps {
  entity: Entity;
}

interface CommentData {
  id: string;
  entityId: string;
  authorId: string;
  authorName: string;
  authorAvatar?: string;
  content: string;
  timestamp: Date;
}

interface WorkspaceMember {
  id?: string;
  email?: string;
  name?: string;
  thumbnail?: string;
  thumbnailBackground?: string;
}

export const CommentsTab = ({ entity }: CommentsTabProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { entityNotifications: entityComments, loading } = useAppSelector(
    (state) => state.fileManager,
  );
  const { members } = useAppSelector((state) => state.members);
  const { workspaceId } = useParams({ strict: false });
  const [newComment, setNewComment] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [showMentionMenu, setShowMentionMenu] = useState(false);
  const [mentionQuery, setMentionQuery] = useState('');
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (entity?.id) {
      dispatch(getEntityNotifications(entity.id));
    }
  }, [dispatch, entity?.id]);

  useEffect(() => {
    if (workspaceId) {
      dispatch(getWorkspaceMembers(workspaceId));
    }
  }, [dispatch, workspaceId]);

  const comments = parseCommentsFromNotifications(entityComments?.items || []);

  const handleFileAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    setAttachments((prev) => [...prev, ...files]);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setNewComment(value);

    const caretPos = e.target.selectionStart || 0;
    setCursorPosition(caretPos);

    // Check for @ mention
    const textBeforeCaret = value.slice(0, caretPos);
    const mentionMatch = textBeforeCaret.match(/@(\w*)$/);

    if (mentionMatch) {
      setMentionQuery(mentionMatch[1] || '');
      setShowMentionMenu(true);
    } else {
      setShowMentionMenu(false);
    }
  };

  // Track when the textarea is clicked to update cursor position
  const handleTextareaClick = () => {
    if (textareaRef.current) {
      setCursorPosition(textareaRef.current.selectionStart);
    }
  };

  const handleTextareaKeyDown = (
    e: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (e.key === '@') {
      if (textareaRef.current) {
        setCursorPosition(textareaRef.current.selectionStart + 1);
      }
    }
  };

  const insertMention = useCallback(
    (member: WorkspaceMember) => {
      if (!cursorPosition || !textareaRef.current) return;

      // Find the start of the current mention
      const textBeforeCursor = newComment.slice(0, cursorPosition);
      const mentionMatch = textBeforeCursor.match(/@(\w*)$/);

      if (!mentionMatch) return;

      const mentionStartPos = cursorPosition - mentionMatch[0].length;
      const beforeMention = newComment.slice(0, mentionStartPos);
      const afterMention = newComment.slice(cursorPosition);

      // Insert the email instead of the name with a space after it
      const mentionText = `@${member.email} `;
      const updatedComment = beforeMention + mentionText + afterMention;

      setNewComment(updatedComment);
      setShowMentionMenu(false);

      // Set cursor position after the inserted mention
      setTimeout(() => {
        if (textareaRef.current) {
          const newPosition = mentionStartPos + mentionText.length;
          textareaRef.current.focus();
          textareaRef.current.setSelectionRange(newPosition, newPosition);
          setCursorPosition(newPosition);
        }
      }, 0);
    },
    [cursorPosition, newComment],
  );

  const handleSubmitComment = () => {
    if (!newComment.trim() || !entity?.id) return;

    dispatch(
      createEntityComment({
        entity: entity.id,
        commentSpec: {
          comment: newComment,
        },
      }),
    )
      .unwrap()
      .then(() => {
        setNewComment('');
        setAttachments([]);
        toast.success('Comment added successfully');
      })
      .catch((error) => {
        console.error(error);
        toast.error('Failed to add comment');
      });
  };

  const handleDeleteComment = (commentId: string) => {
    if (!entity?.id) return;

    dispatch(
      deleteEntityComment({
        entityId: entity.id,
        entity: commentId,
      }),
    )
      .unwrap()
      .then(() => {
        toast.success('Comment deleted successfully');
      })
      .catch((error) => {
        console.error(error);
        toast.error('Failed to delete comment');
      });
  };

  // Filter members based on the mention query
  const filteredMembers = members.filter(
    (member) =>
      !mentionQuery ||
      member.name?.toLowerCase().includes(mentionQuery.toLowerCase()) ||
      member.email?.toLowerCase().includes(mentionQuery.toLowerCase()),
  );

  // Navigate to member details
  const navigateToMemberDetails = useCallback(
    (email: string) => {
      if (!workspaceId) return;

      // Find the member by email to get their ID
      const member = members.find((m) => m.email === email);

      if (member?.id) {
        navigate({
          to: `/${workspaceId}/members`,
        });
      } else {
        // If we can't find the specific member, go to the members list
        navigate({
          to: `/${workspaceId}/members`,
        });
      }
    },
    [workspaceId, members, navigate],
  );

  return (
    <div className="flex flex-col h-full w-full max-w-4xl mx-auto">
      {/* Header */}
      <div className="px-6 py-4 border-b">
        <h3 className="text-lg font-semibold">Comments</h3>
        <p className="text-sm text-muted-foreground">
          Share your thoughts on {entity?.name || 'this file'}
        </p>
      </div>

      {/* Comments List */}
      <ScrollArea className="flex-1 px-6">
        <div className="py-6 space-y-6">
          {loading ? (
            <div className="flex justify-center items-center py-12">
              <Loader2 className="w-8 h-8 animate-spin text-muted-foreground" />
            </div>
          ) : comments.length > 0 ? (
            comments.map((comment) => (
              <CommentItem
                key={comment.id}
                comment={comment}
                onDelete={() => handleDeleteComment(comment.entityId as string)}
                onMentionClick={navigateToMemberDetails}
              />
            ))
          ) : (
            <div className="text-center py-12 text-muted-foreground space-y-2">
              <FileText className="w-12 h-12 mx-auto opacity-20" />
              <p>No comments yet</p>
              <p className="text-sm">Be the first to comment on this file</p>
            </div>
          )}
        </div>
      </ScrollArea>

      {/* Comment Input */}
      <Card className="m-6 border rounded-xl shadow-sm">
        <div className="p-4 space-y-4 relative">
          <div className="relative">
            <Textarea
              ref={textareaRef}
              placeholder="Create a comment on this file"
              value={newComment}
              onChange={handleCommentChange}
              onClick={handleTextareaClick}
              onKeyDown={handleTextareaKeyDown}
              className="min-h-[120px] resize-none border-none focus-visible:ring-0 focus-visible:ring-offset-0 text-base"
            />

            {/* Mention Menu */}
            {showMentionMenu && (
              <div className="absolute z-50 mt-1 bg-background border rounded-md shadow-lg w-[300px] max-h-[200px] overflow-y-auto">
                <div className="p-2">
                  <div className="text-xs font-medium text-muted-foreground mb-2">
                    Mention a member
                  </div>
                  {filteredMembers.length === 0 ? (
                    <div className="text-sm text-muted-foreground text-center py-2">
                      No matching members
                    </div>
                  ) : (
                    filteredMembers.map((member) => (
                      <div
                        key={member.id}
                        className="flex items-center gap-2 p-2 hover:bg-muted rounded-md cursor-pointer"
                        onClick={() => insertMention(member)}
                      >
                        <Avatar className="h-6 w-6">
                          <AvatarImage src={member.thumbnail} />
                          <AvatarFallback className="bg-primary/10 text-primary text-xs">
                            {member.name?.[0]?.toUpperCase() || 'U'}
                          </AvatarFallback>
                        </Avatar>
                        <div className="flex-1 min-w-0">
                          <div className="text-sm font-medium truncate">
                            {member.name}
                          </div>
                          {member.email && (
                            <div className="text-xs text-muted-foreground truncate">
                              {member.email}
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Attachments Preview */}
          {attachments.length > 0 && (
            <div className="space-y-3 pt-2 border-t">
              <Label className="text-sm font-medium text-muted-foreground">
                Attachments ({attachments.length})
              </Label>
              <div className="space-y-2 max-h-48 overflow-y-auto">
                {attachments.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between p-3 rounded-md bg-muted/50"
                  >
                    <div className="flex items-center gap-3 overflow-hidden">
                      <FileText className="w-5 h-5 flex-shrink-0 text-blue-500" />
                      <span className="text-sm font-medium truncate">
                        {file.name}
                      </span>
                      <span className="text-xs text-muted-foreground ml-auto flex-shrink-0">
                        {(file.size / 1024).toFixed(0)} KB
                      </span>
                    </div>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="ml-2 flex-shrink-0"
                      onClick={() => {
                        setAttachments((prev) =>
                          prev.filter((_, i) => i !== index),
                        );
                      }}
                    >
                      <X className="w-4 h-4" />
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Actions */}
        <div className="flex items-center justify-between px-4 py-3 bg-muted/20 border-t rounded-b-xl">
          <div className="flex items-center gap-2">
            <input
              type="file"
              id="file-attachment"
              className="hidden"
              multiple
              onChange={handleFileAttachment}
            />
            <Button
              variant="ghost"
              size="sm"
              className="flex items-center text-muted-foreground hover:text-foreground transition-colors"
              onClick={() => {
                if (textareaRef.current) {
                  const cursorPos = textareaRef.current.selectionStart || 0;
                  const textBefore = newComment.substring(0, cursorPos);
                  const textAfter = newComment.substring(cursorPos);

                  setNewComment(`${textBefore}@${textAfter}`);

                  // Focus and set cursor position after @ symbol
                  setTimeout(() => {
                    if (textareaRef.current) {
                      const newPos = cursorPos + 1;
                      textareaRef.current.focus();
                      textareaRef.current.setSelectionRange(newPos, newPos);
                      setCursorPosition(newPos);
                      setShowMentionMenu(true);
                      setMentionQuery('');
                    }
                  }, 0);
                }
              }}
            >
              <AtSign className="h-4 w-4 mr-1" />
              <span className="text-sm">Mention</span>
            </Button>
          </div>
          <Button
            onClick={handleSubmitComment}
            disabled={!newComment || loading}
            className="text-white"
          >
            {loading ? (
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
            ) : (
              <Send className="w-4 h-4 mr-2" />
            )}
            Post comment
          </Button>
        </div>
      </Card>
    </div>
  );
};

interface CommentItemProps {
  comment: CommentData;
  onDelete: () => void;
  onMentionClick: (email: string) => void;
}

const CommentItem = ({
  comment,
  onDelete,
  onMentionClick,
}: CommentItemProps) => {
  return (
    <div className="flex gap-4 group">
      <Avatar className="w-10 h-10 border">
        <AvatarImage src={comment.authorAvatar} />
        <AvatarFallback className="bg-primary/10 text-primary">
          {comment.authorName[0]?.toUpperCase() || 'U'}
        </AvatarFallback>
      </Avatar>

      <div className="flex-1 space-y-2">
        <Card className="p-4 shadow-sm">
          <div className="flex items-start justify-between">
            <div>
              <div className="flex items-center">
                <p className="font-medium">{comment.authorName}</p>
                <span className="text-xs text-muted-foreground ml-2">
                  {formatDistanceToNow(comment.timestamp)} ago
                </span>
              </div>
              <div className="text-base mt-2 leading-relaxed">
                <CommentContent
                  content={comment.content}
                  onMentionClick={onMentionClick}
                />
              </div>
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  className="h-8 w-8 p-0 opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <MoreVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-40">
                <DropdownMenuItem
                  onClick={onDelete}
                  className="text-red-500 focus:text-red-500"
                >
                  Delete comment
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </Card>
      </div>
    </div>
  );
};

interface CommentContentProps {
  content: string;
  onMentionClick: (email: string) => void;
}

// New component to handle clickable mentions
const CommentContent = ({ content, onMentionClick }: CommentContentProps) => {
  // Improved regex to capture email mentions more accurately
  // This regex looks for @email patterns
  const emailMentionRegex = /@([\w._%+-]+@[\w.-]+\.\w{2,})/g;

  // Split by email mentions while preserving the mentions themselves
  const parts: React.ReactNode[] = [];
  let lastIndex = 0;
  let match;

  // Use regex.exec to find all matches with their positions
  while ((match = emailMentionRegex.exec(content)) !== null) {
    // Add text before the match
    if (match.index > lastIndex) {
      parts.push(
        <span key={`text-${lastIndex}`}>
          {content.substring(lastIndex, match.index)}
        </span>,
      );
    }

    // Add the matched email as a clickable element
    const email = match[1]; // The captured email without the @ symbol
    parts.push(
      <button
        key={`mention-${match.index}`}
        className="text-primary font-medium hover:underline focus:outline-none focus:ring-1 focus:ring-primary rounded"
        onClick={(e) => {
          e.preventDefault();
          onMentionClick(email);
        }}
      >
        @{email}
      </button>,
    );

    lastIndex = match.index + match[0].length;
  }

  // Add any remaining text
  if (lastIndex < content.length) {
    parts.push(
      <span key={`text-${lastIndex}`}>{content.substring(lastIndex)}</span>,
    );
  }

  return <>{parts}</>;
};

// Utility function to parse comments from notification messages
function parseCommentsFromNotifications(
  notifications: NotificationMessage[],
): CommentData[] {
  return notifications
    .filter((notification) => notification.eventType === 'COMMENTED')
    .map((notification) => ({
      id: notification.target || notification.entityId || '',
      entityId: notification.entityId || '',
      authorId: notification.agent || '',
      authorName: notification.agentName || 'Unknown User',
      content: notification.meta?.MESSAGE || '',
      timestamp: new Date(notification.time || Date.now()),
    }))
    .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()); // Sort newest first
}
