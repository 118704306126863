/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UpdateCardSpec
 */
export interface UpdateCardSpec {
  /**
   * The expiry month to associate with the card. Required when updating the expiry year.
   * @type {string}
   * @memberof UpdateCardSpec
   */
  expMonth?: string;
  /**
   * The expiry year to associate with the card. Required when updating the expiry month.
   * @type {string}
   * @memberof UpdateCardSpec
   */
  expYear?: string;
  /**
   * Marks the card as being the default payment method for the user.
   * @type {boolean}
   * @memberof UpdateCardSpec
   */
  def?: boolean;
}

/**
 * Check if a given object implements the UpdateCardSpec interface.
 */
export function instanceOfUpdateCardSpec(
  value: object,
): value is UpdateCardSpec {
  return true;
}

export function UpdateCardSpecFromJSON(json: any): UpdateCardSpec {
  return UpdateCardSpecFromJSONTyped(json, false);
}

export function UpdateCardSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdateCardSpec {
  if (json == null) {
    return json;
  }
  return {
    expMonth: json["exp_month"] == null ? undefined : json["exp_month"],
    expYear: json["exp_year"] == null ? undefined : json["exp_year"],
    def: json["def"] == null ? undefined : json["def"],
  };
}

export function UpdateCardSpecToJSON(json: any): UpdateCardSpec {
  return UpdateCardSpecToJSONTyped(json, false);
}

export function UpdateCardSpecToJSONTyped(
  value?: UpdateCardSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    exp_month: value["expMonth"],
    exp_year: value["expYear"],
    def: value["def"],
  };
}
