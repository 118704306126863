import { useRouterState } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { setDisplaySidebar, useAppDispatch, useAppSelector } from '../store';

export const useNavbarNavigation = () => {
  const dispatch = useAppDispatch();
  const [expandedItem, setExpandedItem] = useState<string | null>(null);
  const { displaySidebar: isCollapsed } = useAppSelector(
    (state) => state.layout,
  );

  const router = useRouterState();
  const pathname = router.location.pathname;

  useEffect(() => {
    setExpandedItem(null);
  }, [pathname]);

  const setIsCollapsed = (value: boolean) => {
    dispatch(setDisplaySidebar(value));
  };

  const toggleWorkspace = (index: string) => {
    setExpandedItem((prev) => (prev === index ? null : index));
  };

  return {
    expandedItem,
    isCollapsed,
    toggleWorkspace,
    setIsCollapsed,
  };
};
