/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { Project } from "./Project";
import {
  ProjectFromJSON,
  ProjectFromJSONTyped,
  ProjectToJSON,
  ProjectToJSONTyped,
} from "./Project";

/**
 *
 * @export
 * @interface IPrincipal
 */
export interface IPrincipal {
  /**
   *
   * @type {string}
   * @memberof IPrincipal
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof IPrincipal
   */
  id?: string;
  /**
   *
   * @type {Project}
   * @memberof IPrincipal
   */
  project?: Project;
}

/**
 * Check if a given object implements the IPrincipal interface.
 */
export function instanceOfIPrincipal(value: object): value is IPrincipal {
  return true;
}

export function IPrincipalFromJSON(json: any): IPrincipal {
  return IPrincipalFromJSONTyped(json, false);
}

export function IPrincipalFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): IPrincipal {
  if (json == null) {
    return json;
  }
  return {
    name: json["name"] == null ? undefined : json["name"],
    id: json["id"] == null ? undefined : json["id"],
    project:
      json["project"] == null ? undefined : ProjectFromJSON(json["project"]),
  };
}

export function IPrincipalToJSON(json: any): IPrincipal {
  return IPrincipalToJSONTyped(json, false);
}

export function IPrincipalToJSONTyped(
  value?: IPrincipal | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value["name"],
    id: value["id"],
    project: ProjectToJSON(value["project"]),
  };
}
