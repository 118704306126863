/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { DirectPermissionResult } from "./DirectPermissionResult";
import {
  DirectPermissionResultFromJSON,
  DirectPermissionResultFromJSONTyped,
  DirectPermissionResultToJSON,
  DirectPermissionResultToJSONTyped,
} from "./DirectPermissionResult";
import type { Child } from "./Child";
import {
  ChildFromJSON,
  ChildFromJSONTyped,
  ChildToJSON,
  ChildToJSONTyped,
} from "./Child";

/**
 *
 * @export
 * @interface ChildPermission
 */
export interface ChildPermission {
  /**
   *
   * @type {Child}
   * @memberof ChildPermission
   */
  child?: Child;
  /**
   *
   * @type {DirectPermissionResult}
   * @memberof ChildPermission
   */
  permission?: DirectPermissionResult;
}

/**
 * Check if a given object implements the ChildPermission interface.
 */
export function instanceOfChildPermission(
  value: object,
): value is ChildPermission {
  return true;
}

export function ChildPermissionFromJSON(json: any): ChildPermission {
  return ChildPermissionFromJSONTyped(json, false);
}

export function ChildPermissionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ChildPermission {
  if (json == null) {
    return json;
  }
  return {
    child: json["child"] == null ? undefined : ChildFromJSON(json["child"]),
    permission:
      json["permission"] == null
        ? undefined
        : DirectPermissionResultFromJSON(json["permission"]),
  };
}

export function ChildPermissionToJSON(json: any): ChildPermission {
  return ChildPermissionToJSONTyped(json, false);
}

export function ChildPermissionToJSONTyped(
  value?: ChildPermission | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    child: ChildToJSON(value["child"]),
    permission: DirectPermissionResultToJSON(value["permission"]),
  };
}
