import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
} from '@/components/ui/pagination';
import { AssignMemberToGroupModal } from '@/containers/modals/assign-member-to-group.modal.component';
import { generateGravatarUrl } from '@/lib/hooks/use-gravatar.hook';
import { NAMESPACES, useTranslation } from '@/lib/i18n';
import {
  getGroupMembers,
  getWorkspaceMembers,
  setDisplayAssignGroupMemberModal,
  setDisplayOnboardMembersModal,
  setGroupPaginatedPage,
  setLastOpenedMember,
  setOpenDeleteGroupMemberModal,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { cn, findUpper } from '@/lib/utils';
import { useParams } from '@tanstack/react-router';
import { motion } from 'framer-motion';
import { Mail, MoreHorizontal, PackageOpen, User, UserX } from 'lucide-react';
import { useEffect, useMemo } from 'react';

export const GroupMembers = ({
  groupId,
  defaultGroup,
}: {
  groupId: string;
  defaultGroup?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(NAMESPACES.DEFAULT);
  const { workspaceId } = useParams({
    strict: false,
  });
  const { id: userId } = useAppSelector((state) => state.user);
  const { groupMembers } = useAppSelector((state) => state.groups);
  const { currentPaginatedPage, entriesPerPaginatedPage } = useAppSelector(
    (state) => state.members,
  );

  // Fetch data
  useEffect(() => {
    if (!workspaceId) return;
    dispatch(getGroupMembers(groupId));
    dispatch(getWorkspaceMembers(workspaceId));
  }, [groupId, workspaceId, dispatch]);

  // Pagination
  const paginatedMembers = useMemo(() => {
    const startIndex = (currentPaginatedPage - 1) * entriesPerPaginatedPage;
    const endIndex = startIndex + entriesPerPaginatedPage;
    return groupMembers.slice(startIndex, endIndex);
  }, [groupMembers, currentPaginatedPage, entriesPerPaginatedPage]);

  // Handlers
  const handleDeleteMember = (memberId: string) => {
    dispatch(setLastOpenedMember(memberId));
    dispatch(setOpenDeleteGroupMemberModal(true));
  };

  const handleOnboardMember = () => {
    dispatch(setDisplayOnboardMembersModal(true));
  };

  const handleAssignMember = () => {
    dispatch(setDisplayAssignGroupMemberModal(true));
  };

  if (!groupMembers.length) {
    return (
      <>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex flex-col items-center justify-center py-6 text-center"
        >
          <PackageOpen className="h-12 w-12 text-gray-400 mb-3" />
          <p className="text-gray-500">
            {t('translation:GROUPS.MEMBER_TABLE.NO_MEMBER')}
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="flex items-center justify-between pt-4 mt-4 border-t border-gray-200"
        >
          <div className="text-sm text-gray-500">
            <User className="h-4 w-4 inline mr-1" />
            {groupMembers.length} {t('translation:GROUPS.MEMBER_TABLE.TITLE')}
          </div>
          <Button variant="outline" size="sm" onClick={handleAssignMember}>
            Assign Member
          </Button>
        </motion.div>
        <AssignMemberToGroupModal groupId={groupId} />
      </>
    );
  }

  return (
    <div className="space-y-4">
      {/* Members List */}
      <div className="space-y-2">
        {paginatedMembers.map((member, index) => (
          <motion.div
            key={member.id || index}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.05 }}
            className="p-4 rounded-lg border border-gray-200 bg-white hover:bg-gray-50 transition-colors"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <Avatar>
                  {member.thumbnail ? (
                    <AvatarImage
                      src={generateGravatarUrl(member.email!)}
                      alt={member.name}
                    />
                  ) : (
                    <AvatarFallback
                      className={cn(
                        'bg-primary/10 text-primary',
                        member.thumbnailBackground,
                      )}
                    >
                      {findUpper(member.name)}
                    </AvatarFallback>
                  )}
                </Avatar>

                <div className="min-w-0">
                  <div className="font-medium text-gray-900">
                    {member.name ||
                      t('translation:GROUPS.MEMBER_TABLE.UNREGISTERED')}
                  </div>
                  {member.email && (
                    <div className="flex items-center gap-1.5 text-sm text-gray-500">
                      <Mail className="h-3.5 w-3.5" />
                      {member.email}
                    </div>
                  )}
                </div>
              </div>

              {member.id !== userId && (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                      <MoreHorizontal className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem
                      className="text-red-600"
                      onClick={() => handleDeleteMember(member.id!)}
                    >
                      <UserX className="h-4 w-4 mr-2" />
                      {t('translation:GROUPS.MEMBER_TABLE.REMOVE')}
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </div>
          </motion.div>
        ))}
      </div>

      {/* Pagination */}
      {groupMembers.length > entriesPerPaginatedPage && (
        <div className="flex justify-center pt-4">
          <Pagination>
            <PaginationContent>
              {Array.from({
                length: Math.ceil(
                  groupMembers.length / entriesPerPaginatedPage,
                ),
              }).map((_, index) => (
                <PaginationItem key={index}>
                  <PaginationLink
                    isActive={currentPaginatedPage === index + 1}
                    onClick={() => dispatch(setGroupPaginatedPage(index + 1))}
                  >
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
            </PaginationContent>
          </Pagination>
        </div>
      )}

      {/* Quick Actions */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex items-center justify-between pt-4 mt-4 border-t border-gray-200"
      >
        <div className="text-sm text-gray-500">
          <User className="h-4 w-4 inline mr-1" />
          {groupMembers.length} {t('translation:GROUPS.MEMBER_TABLE.TITLE')}
        </div>
        {defaultGroup ? (
          <Button variant="outline" size="sm" onClick={handleOnboardMember}>
            Onboard Member
          </Button>
        ) : (
          <Button variant="outline" size="sm" onClick={handleAssignMember}>
            Assign Member
          </Button>
        )}
      </motion.div>

      <AssignMemberToGroupModal groupId={groupId} />
    </div>
  );
};
