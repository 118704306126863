/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UploadStatus
 */
export interface UploadStatus {
  /**
   *
   * @type {string}
   * @memberof UploadStatus
   */
  id?: string;
  /**
   *
   * @type {boolean}
   * @memberof UploadStatus
   */
  newFile?: boolean;
}

/**
 * Check if a given object implements the UploadStatus interface.
 */
export function instanceOfUploadStatus(value: object): value is UploadStatus {
  return true;
}

export function UploadStatusFromJSON(json: any): UploadStatus {
  return UploadStatusFromJSONTyped(json, false);
}

export function UploadStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UploadStatus {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"] == null ? undefined : json["id"],
    newFile: json["newFile"] == null ? undefined : json["newFile"],
  };
}

export function UploadStatusToJSON(json: any): UploadStatus {
  return UploadStatusToJSONTyped(json, false);
}

export function UploadStatusToJSONTyped(
  value?: UploadStatus | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    newFile: value["newFile"],
  };
}
