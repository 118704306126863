import { AppLayout } from '@/components/app-layout.component';
import { AuthCheck } from '@/components/auth-check.component';
import { Head } from '@/components/head.component';
import { MembersContainer } from '@/containers/$workspaceId/members/members-container.component';
import { NAMESPACES, useTranslation } from '@/lib/i18n';
import { getWorkspaceMembers, useAppDispatch } from '@/lib/store';
import { setDisplayMemberFinder } from '@/lib/store/members';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { useEffect } from 'react';

export const Route = createFileRoute('/$workspaceId/members')({
  component: RouteComponent,
});

function RouteComponent() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(NAMESPACES.DEFAULT);
  const { workspaceId } = useParams({
    strict: false,
  });

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(getWorkspaceMembers(workspaceId));
    return () => {
      dispatch(setDisplayMemberFinder(false));
    };
  }, [dispatch, workspaceId]);

  return (
    <>
      <Head heading={t('translation:MEMBERS.TITLE')} />
      <AuthCheck>
        <AppLayout>
          <MembersContainer />
        </AppLayout>
      </AuthCheck>
    </>
  );
}
