import { AppLayout } from '@/components/app-layout.component';
import { AuthCheck } from '@/components/auth-check.component';
import { Head } from '@/components/head.component';
import { FileManagerContainer } from '@/containers/$workspaceId/file-manager/file-manager-container.component';
import { FileManagerFiles } from '@/containers/$workspaceId/file-manager/file-manager-files.component';
import { FileManagerNavigationLayout } from '@/containers/$workspaceId/file-manager/file-manager-navigation-layout.component';
import { useTranslation } from '@/lib/i18n';
import {
  getWorkspaceDiskUsage,
  getWorkspaceEntities,
  getWorkspacePlan,
  setLastOpenedWorkspace,
  setSelectedEntityIds,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { useEffect } from 'react';

export const Route = createFileRoute('/$workspaceId/file-manager/entities/')({
  component: RouteComponent,
});

function RouteComponent() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams({
    strict: false,
  });
  const { entities, displayType } = useAppSelector(
    (state) => state.fileManager,
  );

  const filteredEntities = entities.filter((entity) => !entity.deleted);

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(getWorkspaceEntities(workspaceId as string));
    dispatch(getWorkspaceDiskUsage(workspaceId));
    dispatch(getWorkspacePlan(workspaceId));
    dispatch(setLastOpenedWorkspace(workspaceId));
    dispatch(setSelectedEntityIds([]));
  }, [workspaceId]);

  return (
    <>
      <Head heading={t('file-manager:FILE_MANAGER.TITLE')} />
      <AuthCheck>
        <AppLayout>
          <FileManagerContainer>
            <FileManagerNavigationLayout entityFinder displayTypeFilter>
              <FileManagerFiles
                entities={filteredEntities}
                entityDisplayType={displayType}
              />
            </FileManagerNavigationLayout>
          </FileManagerContainer>
        </AppLayout>
      </AuthCheck>
    </>
  );
}
