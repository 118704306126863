import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Entity,
  entityUnsubscribe,
  filesProvider,
  getEntityMetadata,
  getEntityParent,
  getWorkspacePermissions,
  removeDeletedEntity,
  restoreFile,
  setDisplayDistributeEntityModal,
  setDisplayMetadataModal,
  setDisplayMoveEntityModal,
  setDisplayRemoveEntityModal,
  setLastOpenedEntity,
  toggleDisplayPermissionsModal,
  toggleDisplayPreviewFileModal,
  toggleDisplayRenameEntityModal,
  useAppDispatch,
  userEntitySubscribe,
} from '@/lib/store';
import { useParams } from '@tanstack/react-router';
import { AnimatePresence, motion } from 'framer-motion';
import {
  Bell,
  BellRing,
  Copy,
  Download,
  Eye,
  Forward,
  List,
  Lock,
  MoreVertical,
  Pen,
  Share2,
  Trash2,
  Undo2,
} from 'lucide-react';
import { toast } from 'sonner';

interface FileActionsDropdownProps {
  entity: Entity;
  layoutType?: 'trash';
}

export function FileManagerDropdown({
  entity,
  layoutType,
}: FileActionsDropdownProps) {
  const { workspaceId } = useParams({
    strict: false,
  });
  const dispatch = useAppDispatch();
  const handleDownloadClick = async (ev: React.MouseEvent) => {
    // TODO: Move to translation files
    ev.preventDefault();
    toast.info(
      // t('file-manager:FILE_MANAGER.TOASTS.DOWNLOADING_ENTITY'),
      `Downloading ${entity.name}`,
    );

    try {
      const fileContents = await filesProvider.fetchFile(entity.id);
      if (!fileContents) throw new Error('Failed to download file');

      // Create and trigger download
      const url = URL.createObjectURL(fileContents);
      const link = document.createElement('a');
      link.href = url;
      link.download = entity.name ?? 'prividox-file';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success(`Downloaded ${entity.name}`);
    } catch (error) {
      console.error(error);
      toast.error(`Failed to download ${entity.name}`);
    }
  };

  const handleDropdownClick = () => {
    const id = entity.fileId ?? entity.folderId;
    if (!id) return;
    dispatch(setLastOpenedEntity(entity));
    dispatch(getEntityParent(id));
  };

  const generateCopyLink = (entity: Entity) => {
    if (!entity.fileId && !entity.folderId) return null;
    return `?action=copy&entity=${entity.fileId ?? entity.folderId}`;
  };

  const handleMetadataClick = (entityId: string) => {
    dispatch(getEntityMetadata(entityId));
    dispatch(setDisplayMetadataModal(true));
  };

  const handleRestoringFile = (entityId: string) => {
    dispatch(restoreFile(entityId));
  };

  const handleRestoreEntity = (entityId: string) => {
    dispatch(removeDeletedEntity(entityId));
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild data-no-preview>
        <Button
          variant="ghost"
          size="icon"
          className="h-8 w-8"
          onClick={handleDropdownClick}
        >
          <MoreVertical className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>

      <AnimatePresence>
        <DropdownMenuContent align="end" className="w-56" asChild forceMount>
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.15, ease: 'easeOut' }}
          >
            {layoutType === 'trash' ? (
              // Trash Actions
              <>
                <DropdownMenuItem
                  onClick={() => {
                    const id = entity.fileId || entity.folderId;
                    if (id) {
                      handleRestoringFile(id);
                      handleRestoreEntity(entity.id);
                    }
                  }}
                >
                  <Undo2 className="mr-2 h-4 w-4" />
                  <span>Restore</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => dispatch(setDisplayRemoveEntityModal(true))}
                  className="text-red-600"
                >
                  <Trash2 className="mr-2 h-4 w-4" />
                  <span>Delete Permanently</span>
                </DropdownMenuItem>
              </>
            ) : (
              // Regular Actions
              <>
                <DropdownMenuItem
                  onClick={() => dispatch(toggleDisplayPreviewFileModal())}
                >
                  <Eye className="mr-2 h-4 w-4" />
                  <span>Details</span>
                </DropdownMenuItem>

                <DropdownMenuItem
                  onClick={() => dispatch(toggleDisplayRenameEntityModal())}
                >
                  <Pen className="mr-2 h-4 w-4" />
                  <span>Rename</span>
                </DropdownMenuItem>

                <DropdownMenuItem
                  onClick={() => {
                    if (entity.subscribed) {
                      dispatch(entityUnsubscribe({ subscribed: entity.id }));
                    } else {
                      dispatch(userEntitySubscribe({ body: entity.id }));
                    }
                  }}
                >
                  {entity.subscribed ? (
                    <BellRing className="mr-2 h-4 w-4" />
                  ) : (
                    <Bell className="mr-2 h-4 w-4" />
                  )}
                  <span>{entity.subscribed ? 'Unsubscribe' : 'Subscribe'}</span>
                </DropdownMenuItem>

                <DropdownMenuSeparator />

                <DropdownMenuItem
                  onClick={() =>
                    dispatch(setDisplayDistributeEntityModal(true))
                  }
                >
                  <Share2 className="mr-2 h-4 w-4" />
                  <span>Share</span>
                </DropdownMenuItem>

                <DropdownMenuItem
                  onClick={() => {
                    dispatch(toggleDisplayPermissionsModal());
                    const id = entity.fileId || entity.folderId;
                    if (id && workspaceId) {
                      dispatch(
                        getWorkspacePermissions({
                          workspaceId,
                          entityId: id,
                        }),
                      );
                    }
                  }}
                >
                  <Lock className="mr-2 h-4 w-4" />
                  <span>Permissions</span>
                </DropdownMenuItem>

                <DropdownMenuSeparator />

                <DropdownMenuItem
                  onClick={async () => {
                    const copyLink = generateCopyLink(entity);
                    if (copyLink) {
                      await navigator.clipboard.writeText(copyLink);
                      toast.success('Link copied to clipboard');
                    }
                  }}
                >
                  <Copy className="mr-2 h-4 w-4" />
                  <span>Copy</span>
                </DropdownMenuItem>

                <DropdownMenuItem
                  onClick={() => dispatch(setDisplayMoveEntityModal(true))}
                >
                  <Forward className="mr-2 h-4 w-4" />
                  <span>Move</span>
                </DropdownMenuItem>

                {entity.type === 'file' && (
                  <DropdownMenuItem onClick={handleDownloadClick}>
                    <Download className="mr-2 h-4 w-4" />
                    <span>Download</span>
                  </DropdownMenuItem>
                )}

                <DropdownMenuItem
                  onClick={() => {
                    const id = entity.fileId || entity.folderId;
                    if (id) handleMetadataClick(id);
                  }}
                >
                  <List className="mr-2 h-4 w-4" />
                  <span>Metadata</span>
                </DropdownMenuItem>

                <DropdownMenuSeparator />

                <DropdownMenuItem
                  onClick={() => dispatch(setDisplayRemoveEntityModal(true))}
                  className="text-red-600"
                >
                  <Trash2 className="mr-2 h-4 w-4" />
                  <span>Delete</span>
                </DropdownMenuItem>
              </>
            )}
          </motion.div>
        </DropdownMenuContent>
      </AnimatePresence>
    </DropdownMenu>
  );
}
