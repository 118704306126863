/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CodeSpec
 */
export interface CodeSpec {
  /**
   * The current TOTP code.
   * @type {number}
   * @memberof CodeSpec
   */
  code: number;
}

/**
 * Check if a given object implements the CodeSpec interface.
 */
export function instanceOfCodeSpec(value: object): value is CodeSpec {
  if (!("code" in value) || value["code"] === undefined) return false;
  return true;
}

export function CodeSpecFromJSON(json: any): CodeSpec {
  return CodeSpecFromJSONTyped(json, false);
}

export function CodeSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CodeSpec {
  if (json == null) {
    return json;
  }
  return {
    code: json["code"],
  };
}

export function CodeSpecToJSON(json: any): CodeSpec {
  return CodeSpecToJSONTyped(json, false);
}

export function CodeSpecToJSONTyped(
  value?: CodeSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    code: value["code"],
  };
}
