/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface BundleSpec
 */
export interface BundleSpec {
  /**
   * The type of bundle to enable for the workspace.
   * @type {string}
   * @memberof BundleSpec
   */
  type: BundleSpecTypeEnum;
  /**
   * The number of times the bundle should be applied to expand the workspace limits.
   * @type {number}
   * @memberof BundleSpec
   */
  count: number;
}

/**
 * @export
 * @enum {string}
 */
export enum BundleSpecTypeEnum {
  users = "users",
  space = "space",
}

/**
 * Check if a given object implements the BundleSpec interface.
 */
export function instanceOfBundleSpec(value: object): value is BundleSpec {
  if (!("type" in value) || value["type"] === undefined) return false;
  if (!("count" in value) || value["count"] === undefined) return false;
  return true;
}

export function BundleSpecFromJSON(json: any): BundleSpec {
  return BundleSpecFromJSONTyped(json, false);
}

export function BundleSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BundleSpec {
  if (json == null) {
    return json;
  }
  return {
    type: json["type"],
    count: json["count"],
  };
}

export function BundleSpecToJSON(json: any): BundleSpec {
  return BundleSpecToJSONTyped(json, false);
}

export function BundleSpecToJSONTyped(
  value?: BundleSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    type: value["type"],
    count: value["count"],
  };
}
