import { Footer } from '@/components/footer.component';
import { Toaster } from '@/components/ui/sonner';
import { createRootRoute, Outlet } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';

export const Route = createRootRoute({
  component: () => (
    <main>
      <Outlet />
      <Footer />
      <TanStackRouterDevtools />
      <Toaster position="top-right" />
    </main>
  ),
});
