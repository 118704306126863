/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface LockSpec
 */
export interface LockSpec {
  /**
   * Whether the file is being locked or unlocked.
   * @type {boolean}
   * @memberof LockSpec
   */
  locked: boolean;
  /**
   * Whether the requested lock will make the file accessible publicly
   * @type {boolean}
   * @memberof LockSpec
   */
  publish?: boolean;
}

/**
 * Check if a given object implements the LockSpec interface.
 */
export function instanceOfLockSpec(value: object): value is LockSpec {
  if (!("locked" in value) || value["locked"] === undefined) return false;
  return true;
}

export function LockSpecFromJSON(json: any): LockSpec {
  return LockSpecFromJSONTyped(json, false);
}

export function LockSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LockSpec {
  if (json == null) {
    return json;
  }
  return {
    locked: json["locked"],
    publish: json["publish"] == null ? undefined : json["publish"],
  };
}

export function LockSpecToJSON(json: any): LockSpec {
  return LockSpecToJSONTyped(json, false);
}

export function LockSpecToJSONTyped(
  value?: LockSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    locked: value["locked"],
    publish: value["publish"],
  };
}
