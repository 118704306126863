/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SearchDocument
 */
export interface SearchDocument {
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  entityId?: string;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  projectId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchDocument
   */
  contents?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  creator?: string;
  /**
   *
   * @type {number}
   * @memberof SearchDocument
   */
  filesize?: number;
  /**
   *
   * @type {number}
   * @memberof SearchDocument
   */
  creationDate?: number;
  /**
   *
   * @type {number}
   * @memberof SearchDocument
   */
  lastUpdated?: number;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  parent?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchDocument
   */
  highlights?: Array<string>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof SearchDocument
   */
  customs?: { [key: string]: string };
  /**
   *
   * @type {number}
   * @memberof SearchDocument
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof SearchDocument
   */
  longitude?: number;
  /**
   *
   * @type {boolean}
   * @memberof SearchDocument
   */
  produce?: boolean;
  /**
   *
   * @type {number}
   * @memberof SearchDocument
   */
  date?: number;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  doctype?: string;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  author?: string;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  recipient?: string;
  /**
   *
   * @type {number}
   * @memberof SearchDocument
   */
  importance?: number;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  relevance?: string;
  /**
   *
   * @type {string}
   * @memberof SearchDocument
   */
  notes?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchDocument
   */
  redactionNotes?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof SearchDocument
   */
  redacted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchDocument
   */
  converted?: boolean;
}

/**
 * Check if a given object implements the SearchDocument interface.
 */
export function instanceOfSearchDocument(
  value: object,
): value is SearchDocument {
  return true;
}

export function SearchDocumentFromJSON(json: any): SearchDocument {
  return SearchDocumentFromJSONTyped(json, false);
}

export function SearchDocumentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SearchDocument {
  if (json == null) {
    return json;
  }
  return {
    entityId: json["entityId"] == null ? undefined : json["entityId"],
    projectId: json["projectId"] == null ? undefined : json["projectId"],
    contents: json["contents"] == null ? undefined : json["contents"],
    name: json["name"] == null ? undefined : json["name"],
    type: json["type"] == null ? undefined : json["type"],
    creator: json["creator"] == null ? undefined : json["creator"],
    filesize: json["filesize"] == null ? undefined : json["filesize"],
    creationDate:
      json["creationDate"] == null ? undefined : json["creationDate"],
    lastUpdated: json["lastUpdated"] == null ? undefined : json["lastUpdated"],
    parent: json["parent"] == null ? undefined : json["parent"],
    highlights: json["highlights"] == null ? undefined : json["highlights"],
    customs: json["customs"] == null ? undefined : json["customs"],
    latitude: json["latitude"] == null ? undefined : json["latitude"],
    longitude: json["longitude"] == null ? undefined : json["longitude"],
    produce: json["produce"] == null ? undefined : json["produce"],
    date: json["date"] == null ? undefined : json["date"],
    doctype: json["doctype"] == null ? undefined : json["doctype"],
    author: json["author"] == null ? undefined : json["author"],
    recipient: json["recipient"] == null ? undefined : json["recipient"],
    importance: json["importance"] == null ? undefined : json["importance"],
    relevance: json["relevance"] == null ? undefined : json["relevance"],
    notes: json["notes"] == null ? undefined : json["notes"],
    redactionNotes:
      json["redactionNotes"] == null ? undefined : json["redactionNotes"],
    redacted: json["redacted"] == null ? undefined : json["redacted"],
    converted: json["converted"] == null ? undefined : json["converted"],
  };
}

export function SearchDocumentToJSON(json: any): SearchDocument {
  return SearchDocumentToJSONTyped(json, false);
}

export function SearchDocumentToJSONTyped(
  value?: SearchDocument | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    entityId: value["entityId"],
    projectId: value["projectId"],
    contents: value["contents"],
    name: value["name"],
    type: value["type"],
    creator: value["creator"],
    filesize: value["filesize"],
    creationDate: value["creationDate"],
    lastUpdated: value["lastUpdated"],
    parent: value["parent"],
    highlights: value["highlights"],
    customs: value["customs"],
    latitude: value["latitude"],
    longitude: value["longitude"],
    produce: value["produce"],
    date: value["date"],
    doctype: value["doctype"],
    author: value["author"],
    recipient: value["recipient"],
    importance: value["importance"],
    relevance: value["relevance"],
    notes: value["notes"],
    redactionNotes: value["redactionNotes"],
    redacted: value["redacted"],
    converted: value["converted"],
  };
}
