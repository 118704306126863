/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PriceSpec
 */
export interface PriceSpec {
  /**
   *
   * @type {number}
   * @memberof PriceSpec
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof PriceSpec
   */
  interval?: string;
  /**
   *
   * @type {string}
   * @memberof PriceSpec
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PriceSpec
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof PriceSpec
   */
  id?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof PriceSpec
   */
  meta?: { [key: string]: string };
  /**
   *
   * @type {boolean}
   * @memberof PriceSpec
   */
  enabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof PriceSpec
   */
  count?: number;
}

/**
 * Check if a given object implements the PriceSpec interface.
 */
export function instanceOfPriceSpec(value: object): value is PriceSpec {
  return true;
}

export function PriceSpecFromJSON(json: any): PriceSpec {
  return PriceSpecFromJSONTyped(json, false);
}

export function PriceSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PriceSpec {
  if (json == null) {
    return json;
  }
  return {
    amount: json["amount"] == null ? undefined : json["amount"],
    interval: json["interval"] == null ? undefined : json["interval"],
    name: json["name"] == null ? undefined : json["name"],
    currency: json["currency"] == null ? undefined : json["currency"],
    id: json["id"] == null ? undefined : json["id"],
    meta: json["meta"] == null ? undefined : json["meta"],
    enabled: json["enabled"] == null ? undefined : json["enabled"],
    count: json["count"] == null ? undefined : json["count"],
  };
}

export function PriceSpecToJSON(json: any): PriceSpec {
  return PriceSpecToJSONTyped(json, false);
}

export function PriceSpecToJSONTyped(
  value?: PriceSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    amount: value["amount"],
    interval: value["interval"],
    name: value["name"],
    currency: value["currency"],
    id: value["id"],
    meta: value["meta"],
    enabled: value["enabled"],
    count: value["count"],
  };
}
