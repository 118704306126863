/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { Group } from "./Group";
import {
  GroupFromJSON,
  GroupFromJSONTyped,
  GroupToJSON,
  GroupToJSONTyped,
} from "./Group";
import type { Project } from "./Project";
import {
  ProjectFromJSON,
  ProjectFromJSONTyped,
  ProjectToJSON,
  ProjectToJSONTyped,
} from "./Project";
import type { User } from "./User";
import {
  UserFromJSON,
  UserFromJSONTyped,
  UserToJSON,
  UserToJSONTyped,
} from "./User";
import type { IPrincipal } from "./IPrincipal";
import {
  IPrincipalFromJSON,
  IPrincipalFromJSONTyped,
  IPrincipalToJSON,
  IPrincipalToJSONTyped,
} from "./IPrincipal";

/**
 *
 * @export
 * @interface Task
 */
export interface Task {
  /**
   *
   * @type {string}
   * @memberof Task
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof Task
   */
  description?: string;
  /**
   *
   * @type {Date}
   * @memberof Task
   */
  created?: Date;
  /**
   *
   * @type {Date}
   * @memberof Task
   */
  updated?: Date;
  /**
   *
   * @type {Date}
   * @memberof Task
   */
  expiry?: Date;
  /**
   *
   * @type {boolean}
   * @memberof Task
   */
  completed?: boolean;
  /**
   *
   * @type {Project}
   * @memberof Task
   */
  project?: Project;
  /**
   *
   * @type {User}
   * @memberof Task
   */
  assigner?: User;
  /**
   *
   * @type {User}
   * @memberof Task
   */
  assignedUser?: User;
  /**
   *
   * @type {Group}
   * @memberof Task
   */
  assignedGroup?: Group;
  /**
   *
   * @type {IPrincipal}
   * @memberof Task
   */
  assignee?: IPrincipal;
  /**
   *
   * @type {User}
   * @memberof Task
   */
  updater?: User;
  /**
   *
   * @type {Set<any>}
   * @memberof Task
   */
  attachments?: Set<any>;
  /**
   *
   * @type {number}
   * @memberof Task
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof Task
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Task
   */
  id?: string;
}

/**
 * Check if a given object implements the Task interface.
 */
export function instanceOfTask(value: object): value is Task {
  return true;
}

export function TaskFromJSON(json: any): Task {
  return TaskFromJSONTyped(json, false);
}

export function TaskFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Task {
  if (json == null) {
    return json;
  }
  return {
    title: json["title"] == null ? undefined : json["title"],
    description: json["description"] == null ? undefined : json["description"],
    created: json["created"] == null ? undefined : new Date(json["created"]),
    updated: json["updated"] == null ? undefined : new Date(json["updated"]),
    expiry: json["expiry"] == null ? undefined : new Date(json["expiry"]),
    completed: json["completed"] == null ? undefined : json["completed"],
    project:
      json["project"] == null ? undefined : ProjectFromJSON(json["project"]),
    assigner:
      json["assigner"] == null ? undefined : UserFromJSON(json["assigner"]),
    assignedUser:
      json["assignedUser"] == null
        ? undefined
        : UserFromJSON(json["assignedUser"]),
    assignedGroup:
      json["assignedGroup"] == null
        ? undefined
        : GroupFromJSON(json["assignedGroup"]),
    assignee:
      json["assignee"] == null
        ? undefined
        : IPrincipalFromJSON(json["assignee"]),
    updater:
      json["updater"] == null ? undefined : UserFromJSON(json["updater"]),
    attachments:
      json["attachments"] == null ? undefined : new Set(json["attachments"]),
    version: json["version"] == null ? undefined : json["version"],
    name: json["name"] == null ? undefined : json["name"],
    id: json["id"] == null ? undefined : json["id"],
  };
}

export function TaskToJSON(json: any): Task {
  return TaskToJSONTyped(json, false);
}

export function TaskToJSONTyped(
  value?: Task | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    title: value["title"],
    description: value["description"],
    created:
      value["created"] == null ? undefined : value["created"].toISOString(),
    updated:
      value["updated"] == null ? undefined : value["updated"].toISOString(),
    expiry: value["expiry"] == null ? undefined : value["expiry"].toISOString(),
    completed: value["completed"],
    project: ProjectToJSON(value["project"]),
    assigner: UserToJSON(value["assigner"]),
    assignedUser: UserToJSON(value["assignedUser"]),
    assignedGroup: GroupToJSON(value["assignedGroup"]),
    assignee: IPrincipalToJSON(value["assignee"]),
    updater: UserToJSON(value["updater"]),
    attachments:
      value["attachments"] == null
        ? undefined
        : Array.from(value["attachments"] as Set<any>),
    version: value["version"],
    name: value["name"],
    id: value["id"],
  };
}
