/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  NameSpec,
  PagerGroupInfo,
  ResultMemberInformation,
} from "../models/index";
import {
  NameSpecFromJSON,
  NameSpecToJSON,
  PagerGroupInfoFromJSON,
  PagerGroupInfoToJSON,
  ResultMemberInformationFromJSON,
  ResultMemberInformationToJSON,
} from "../models/index";

export interface AddGroupMemberRequest {
  group: string;
  body: string;
}

export interface CreateGroupRequest {
  workspaceId: string;
  nameSpec: NameSpec;
}

export interface DeleteGroupRequest {
  workspaceId: string;
  group: string;
}

export interface GetDefaultGroupRequest {
  workspaceId: string;
}

export interface GetGroupMemberCountRequest {
  group: string;
}

export interface GetGroupMembersRequest {
  group: string;
}

export interface GetGroupNameRequest {
  group: string;
}

export interface GetGroupsRequest {
  workspaceId: string;
  sort?: string;
  desc?: boolean;
  start?: string;
  end?: string;
}

export interface RemoveGroupMemberRequest {
  group: string;
  user: string;
}

export interface RenameGroupRequest {
  group: string;
  nameSpec: NameSpec;
}

export interface SetDefaultGroupRequest {
  workspaceId: string;
  body: string;
}

/**
 *
 */
export class GroupsApi extends runtime.BaseAPI {
  /**
   * Adds the specified user as a member of the specified group.
   * Add Group Member
   */
  async addGroupMemberRaw(
    requestParameters: AddGroupMemberRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["group"] == null) {
      throw new runtime.RequiredError(
        "group",
        'Required parameter "group" was null or undefined when calling addGroupMember().',
      );
    }

    if (requestParameters["body"] == null) {
      throw new runtime.RequiredError(
        "body",
        'Required parameter "body" was null or undefined when calling addGroupMember().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/group/{group}/members`.replace(
          `{${"group"}}`,
          encodeURIComponent(String(requestParameters["group"])),
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters["body"] as any,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Adds the specified user as a member of the specified group.
   * Add Group Member
   */
  async addGroupMember(
    requestParameters: AddGroupMemberRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.addGroupMemberRaw(requestParameters, initOverrides);
  }

  /**
   * Creates a new group part of the specified workspace.
   * Create Group
   */
  async createGroupRaw(
    requestParameters: CreateGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (requestParameters["workspaceId"] == null) {
      throw new runtime.RequiredError(
        "workspaceId",
        'Required parameter "workspaceId" was null or undefined when calling createGroup().',
      );
    }

    if (requestParameters["nameSpec"] == null) {
      throw new runtime.RequiredError(
        "nameSpec",
        'Required parameter "nameSpec" was null or undefined when calling createGroup().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/groups`.replace(
          `{${"workspaceId"}}`,
          encodeURIComponent(String(requestParameters["workspaceId"])),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: NameSpecToJSON(requestParameters["nameSpec"]),
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get("content-type"))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Creates a new group part of the specified workspace.
   * Create Group
   */
  async createGroup(
    requestParameters: CreateGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.createGroupRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Deletes the specified group.
   * Delete Group
   */
  async deleteGroupRaw(
    requestParameters: DeleteGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["workspaceId"] == null) {
      throw new runtime.RequiredError(
        "workspaceId",
        'Required parameter "workspaceId" was null or undefined when calling deleteGroup().',
      );
    }

    if (requestParameters["group"] == null) {
      throw new runtime.RequiredError(
        "group",
        'Required parameter "group" was null or undefined when calling deleteGroup().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/groups/{group}`
          .replace(
            `{${"workspaceId"}}`,
            encodeURIComponent(String(requestParameters["workspaceId"])),
          )
          .replace(
            `{${"group"}}`,
            encodeURIComponent(String(requestParameters["group"])),
          ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Deletes the specified group.
   * Delete Group
   */
  async deleteGroup(
    requestParameters: DeleteGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteGroupRaw(requestParameters, initOverrides);
  }

  /**
   * Retrieves the default group of the workspace.
   * Get Default Group
   */
  async getDefaultGroupRaw(
    requestParameters: GetDefaultGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (requestParameters["workspaceId"] == null) {
      throw new runtime.RequiredError(
        "workspaceId",
        'Required parameter "workspaceId" was null or undefined when calling getDefaultGroup().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/groups/default`.replace(
          `{${"workspaceId"}}`,
          encodeURIComponent(String(requestParameters["workspaceId"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get("content-type"))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves the default group of the workspace.
   * Get Default Group
   */
  async getDefaultGroup(
    requestParameters: GetDefaultGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.getDefaultGroupRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves the member count of the specified group.
   * Get Member Count
   */
  async getGroupMemberCountRaw(
    requestParameters: GetGroupMemberCountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<number>> {
    if (requestParameters["group"] == null) {
      throw new runtime.RequiredError(
        "group",
        'Required parameter "group" was null or undefined when calling getGroupMemberCount().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/group/{group}/count`.replace(
          `{${"group"}}`,
          encodeURIComponent(String(requestParameters["group"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get("content-type"))) {
      return new runtime.JSONApiResponse<number>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves the member count of the specified group.
   * Get Member Count
   */
  async getGroupMemberCount(
    requestParameters: GetGroupMemberCountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<number> {
    const response = await this.getGroupMemberCountRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves the list of members of the specified group.
   * Get Members
   */
  async getGroupMembersRaw(
    requestParameters: GetGroupMembersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultMemberInformation>> {
    if (requestParameters["group"] == null) {
      throw new runtime.RequiredError(
        "group",
        'Required parameter "group" was null or undefined when calling getGroupMembers().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/group/{group}/members`.replace(
          `{${"group"}}`,
          encodeURIComponent(String(requestParameters["group"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultMemberInformationFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves the list of members of the specified group.
   * Get Members
   */
  async getGroupMembers(
    requestParameters: GetGroupMembersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultMemberInformation> {
    const response = await this.getGroupMembersRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves the name of the specified group.
   * Get Group Name
   */
  async getGroupNameRaw(
    requestParameters: GetGroupNameRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (requestParameters["group"] == null) {
      throw new runtime.RequiredError(
        "group",
        'Required parameter "group" was null or undefined when calling getGroupName().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/group/{group}/name`.replace(
          `{${"group"}}`,
          encodeURIComponent(String(requestParameters["group"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get("content-type"))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves the name of the specified group.
   * Get Group Name
   */
  async getGroupName(
    requestParameters: GetGroupNameRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.getGroupNameRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves the groups of the workspace.
   * Get Groups
   */
  async getGroupsRaw(
    requestParameters: GetGroupsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PagerGroupInfo>> {
    if (requestParameters["workspaceId"] == null) {
      throw new runtime.RequiredError(
        "workspaceId",
        'Required parameter "workspaceId" was null or undefined when calling getGroups().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters["sort"] != null) {
      queryParameters["sort"] = requestParameters["sort"];
    }

    if (requestParameters["desc"] != null) {
      queryParameters["desc"] = requestParameters["desc"];
    }

    if (requestParameters["start"] != null) {
      queryParameters["start"] = requestParameters["start"];
    }

    if (requestParameters["end"] != null) {
      queryParameters["end"] = requestParameters["end"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/groups`.replace(
          `{${"workspaceId"}}`,
          encodeURIComponent(String(requestParameters["workspaceId"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PagerGroupInfoFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves the groups of the workspace.
   * Get Groups
   */
  async getGroups(
    requestParameters: GetGroupsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PagerGroupInfo> {
    const response = await this.getGroupsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Removes the specified user from the specified group.
   * Remove Group Member
   */
  async removeGroupMemberRaw(
    requestParameters: RemoveGroupMemberRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["group"] == null) {
      throw new runtime.RequiredError(
        "group",
        'Required parameter "group" was null or undefined when calling removeGroupMember().',
      );
    }

    if (requestParameters["user"] == null) {
      throw new runtime.RequiredError(
        "user",
        'Required parameter "user" was null or undefined when calling removeGroupMember().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/group/{group}/members/{user}`
          .replace(
            `{${"group"}}`,
            encodeURIComponent(String(requestParameters["group"])),
          )
          .replace(
            `{${"user"}}`,
            encodeURIComponent(String(requestParameters["user"])),
          ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Removes the specified user from the specified group.
   * Remove Group Member
   */
  async removeGroupMember(
    requestParameters: RemoveGroupMemberRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.removeGroupMemberRaw(requestParameters, initOverrides);
  }

  /**
   * Updates the name of the specified group
   * Rename Group
   */
  async renameGroupRaw(
    requestParameters: RenameGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["group"] == null) {
      throw new runtime.RequiredError(
        "group",
        'Required parameter "group" was null or undefined when calling renameGroup().',
      );
    }

    if (requestParameters["nameSpec"] == null) {
      throw new runtime.RequiredError(
        "nameSpec",
        'Required parameter "nameSpec" was null or undefined when calling renameGroup().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/group/{group}/name`.replace(
          `{${"group"}}`,
          encodeURIComponent(String(requestParameters["group"])),
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: NameSpecToJSON(requestParameters["nameSpec"]),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates the name of the specified group
   * Rename Group
   */
  async renameGroup(
    requestParameters: RenameGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.renameGroupRaw(requestParameters, initOverrides);
  }

  /**
   * Makes the specified group the default of the workspace.
   * Set Default Group
   */
  async setDefaultGroupRaw(
    requestParameters: SetDefaultGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["workspaceId"] == null) {
      throw new runtime.RequiredError(
        "workspaceId",
        'Required parameter "workspaceId" was null or undefined when calling setDefaultGroup().',
      );
    }

    if (requestParameters["body"] == null) {
      throw new runtime.RequiredError(
        "body",
        'Required parameter "body" was null or undefined when calling setDefaultGroup().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/workspaces/{workspaceId}/groups/default`.replace(
          `{${"workspaceId"}}`,
          encodeURIComponent(String(requestParameters["workspaceId"])),
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters["body"] as any,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Makes the specified group the default of the workspace.
   * Set Default Group
   */
  async setDefaultGroup(
    requestParameters: SetDefaultGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.setDefaultGroupRaw(requestParameters, initOverrides);
  }
}
