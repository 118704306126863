import { createFileRoute, Navigate, useParams } from "@tanstack/react-router";

export const Route = createFileRoute("/$workspaceId/")({
  component: RouteComponent,
});

function RouteComponent() {
  const { workspaceId } = useParams({ strict: false });
  return <Navigate to={`/${workspaceId}/dashboard`} />;
}
