/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MemberInfo
 */
export interface MemberInfo {
  /**
   *
   * @type {string}
   * @memberof MemberInfo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof MemberInfo
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof MemberInfo
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof MemberInfo
   */
  accessLevel?: string;
  /**
   *
   * @type {boolean}
   * @memberof MemberInfo
   */
  online?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MemberInfo
   */
  active?: boolean;
}

/**
 * Check if a given object implements the MemberInfo interface.
 */
export function instanceOfMemberInfo(value: object): value is MemberInfo {
  return true;
}

export function MemberInfoFromJSON(json: any): MemberInfo {
  return MemberInfoFromJSONTyped(json, false);
}

export function MemberInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MemberInfo {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"] == null ? undefined : json["id"],
    email: json["email"] == null ? undefined : json["email"],
    name: json["name"] == null ? undefined : json["name"],
    accessLevel: json["accessLevel"] == null ? undefined : json["accessLevel"],
    online: json["online"] == null ? undefined : json["online"],
    active: json["active"] == null ? undefined : json["active"],
  };
}

export function MemberInfoToJSON(json: any): MemberInfo {
  return MemberInfoToJSONTyped(json, false);
}

export function MemberInfoToJSONTyped(
  value?: MemberInfo | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    email: value["email"],
    name: value["name"],
    accessLevel: value["accessLevel"],
    online: value["online"],
    active: value["active"],
  };
}
