import { AppLayout } from '@/components/app-layout.component';
import { AuthCheck } from '@/components/auth-check.component';
import { Head } from '@/components/head.component';
import { DashboardFeed } from '@/containers/$workspaceId/dashboard/dashboard-feed-table.component';
import { DashboardHeader } from '@/containers/$workspaceId/dashboard/dashboard-header.component';
import { setDisplayDashboardFinder } from '@/lib/store';
import { useAppDispatch } from '@/lib/store/hooks';
import { getMetadataKey, getWorkspaceMetadata } from '@/lib/store/workspace';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/$workspaceId/dashboard')({
  component: RouteComponent,
});

function RouteComponent() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams({
    strict: false,
  });

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(getWorkspaceMetadata(workspaceId));
    dispatch(getMetadataKey(workspaceId));
    return () => {
      dispatch(setDisplayDashboardFinder(false));
    };
  }, [dispatch, workspaceId]);
  return (
    <>
      <Head heading={t('common:COMMON.DASHBOARD')} />

      <AuthCheck>
        <AppLayout>
          <div className="flex-1 p-6">
            <DashboardHeader />
            <DashboardFeed workspaceId={workspaceId} />
          </div>
        </AppLayout>
      </AuthCheck>
    </>
  );
}
