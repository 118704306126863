import {
  fetchAccessLevelForUser,
  filesProvider,
  getChildEntities,
  getEntityParents,
  getGrantedVerbs,
  getWorkspaceEntities,
  protectionTag,
  setDisplayConvertConfirm,
  setDisplayDistributeEntityModal,
  toggleDisplayPreviewFileModal,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProtectionSpecLevelEnum } from '@oproma/prividox-orchestration-open-api';
import { useParams } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import * as yup from 'yup';
import { usePinEntities } from './use-pin-entities.hook';

const securityLevelSchema = yup
  .object({
    securityLevel: yup.string(),
  })
  .required();

type SecurityLevelFormValues = yup.InferType<typeof securityLevelSchema>;

export const useFilePreview = () => {
  const dispatch = useAppDispatch();
  const { workspaceId, entityId } = useParams({ strict: false });
  const { pinnedWorkspaces, togglePinnedWorkspace } = usePinEntities();

  const { accessLevel, metadata } = useAppSelector((state) => state.workspace);
  const { displayPreviewFileModal, lastOpenedEntity, changedSecurityLevel } =
    useAppSelector((state) => state.fileManager);
  const { verbs } = useAppSelector((state) => state.permissions);
  const { mobileMode } = useAppSelector((state) => state.layout);

  const openedWorkspace = useAppSelector((state) =>
    state.workspaces.workspaces.find(
      (workspace) => workspace.id === state.workspaces.lastOpenedWorkspace,
    ),
  );

  const securityLevel = lastOpenedEntity?.level;

  const { watch, setValue } = useForm<SecurityLevelFormValues>({
    resolver: yupResolver(securityLevelSchema),
    defaultValues: {},
  });
  const changeableSecurityLevel = watch('securityLevel');

  useEffect(() => {
    if (workspaceId || openedWorkspace?.id) {
      dispatch(fetchAccessLevelForUser(workspaceId ?? openedWorkspace?.id));
    }
  }, [openedWorkspace?.id, workspaceId]);

  useEffect(() => {
    if (lastOpenedEntity?.id) {
      dispatch(getGrantedVerbs({ entity: lastOpenedEntity.id }));
      dispatch(getEntityParents(lastOpenedEntity.id));
    }
  }, [lastOpenedEntity?.id]);

  const togglePreviewModal = () => dispatch(toggleDisplayPreviewFileModal());

  const handleLevelChange = () => {
    if (lastOpenedEntity) {
      dispatch(
        protectionTag({
          entity: lastOpenedEntity.id,
          protectionSpec: {
            level: changeableSecurityLevel as ProtectionSpecLevelEnum,
          },
        }),
      );

      if (changedSecurityLevel) {
        toast.success(`Security level changed to ${changeableSecurityLevel}`);
      }

      if (entityId) {
        dispatch(getChildEntities({ entityId }));
      } else {
        dispatch(getWorkspaceEntities(workspaceId as string));
      }
    }
  };

  const handleDownload = async () => {
    if (!lastOpenedEntity) return;

    try {
      toast.info(`Downloading ${lastOpenedEntity.name}`);

      const fileContents = await filesProvider.fetchFile(lastOpenedEntity.id);

      if (!fileContents) throw new Error('Failed to download file');

      const url = URL.createObjectURL(fileContents);
      const link = document.createElement('a');
      link.href = url;
      link.download = lastOpenedEntity.name ?? 'prividox-file';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success(`Downloaded ${lastOpenedEntity.name}`);
    } catch (error) {
      console.error(error);
      toast.error(`Failed to download ${lastOpenedEntity.name}`);
    }
  };

  const handleConvert = () => dispatch(setDisplayConvertConfirm(true));

  const handleShare = () => dispatch(setDisplayDistributeEntityModal(true));

  // Calculate permissions and states
  const hasRedactionPermission =
    !mobileMode &&
    metadata?.allowRedactions &&
    openedWorkspace?.allowRedaction &&
    verbs.items?.includes('Write') &&
    accessLevel?.toString() !== '-128';

  const canEditSecurityLevel =
    lastOpenedEntity?.type === 'file' &&
    openedWorkspace?.access === 'MIXED' &&
    ['127', '126', '0'].includes(accessLevel?.toString() ?? '');

  const isPDF = lastOpenedEntity?.contentType === 'application/pdf';
  const isOfficeDocument = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
  ].includes(lastOpenedEntity?.contentType ?? '');

  const isTextDocument =
    lastOpenedEntity?.contentType?.startsWith('text') ||
    (lastOpenedEntity?.contentType?.startsWith('application') && !isPDF);

  const isImage =
    lastOpenedEntity?.contentType?.startsWith('image') ||
    lastOpenedEntity?.contentType?.startsWith('img');

  return {
    displayPreviewModal: displayPreviewFileModal,
    lastOpenedEntity,
    openedWorkspace,
    securityLevel,
    pinnedWorkspaces,
    hasRedactionPermission,
    canEditSecurityLevel,
    isPDF,
    isOfficeDocument,
    isTextDocument,
    isImage,
    setValue,
    changeableSecurityLevel,
    togglePreviewModal,
    handleLevelChange,
    handleDownload,
    handleConvert,
    handleShare,
    togglePinnedWorkspace,
  };
};
