/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Child
 */
export interface Child {
  /**
   *
   * @type {string}
   * @memberof Child
   */
  folderId?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  fileId?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  created?: string;
  /**
   *
   * @type {number}
   * @memberof Child
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof Child
   */
  tsize?: number;
  /**
   *
   * @type {boolean}
   * @memberof Child
   */
  deleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Child
   */
  uploading?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Child
   */
  subscribed?: boolean;
  /**
   *
   * @type {number}
   * @memberof Child
   */
  modified?: number;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  locktype?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  creator?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  creatorname?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  editor?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  editorname?: string;
  /**
   *
   * @type {number}
   * @memberof Child
   */
  numversion?: number;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  contentType?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  author?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  geoLocation?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  timeCreation?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  notes?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  view?: string;
  /**
   *
   * @type {number}
   * @memberof Child
   */
  comments?: number;
  /**
   *
   * @type {boolean}
   * @memberof Child
   */
  produce?: boolean;
  /**
   *
   * @type {string}
   * @memberof Child
   */
  level?: string;
  /**
   *
   * @type {boolean}
   * @memberof Child
   */
  encrypted?: boolean;
}

/**
 * Check if a given object implements the Child interface.
 */
export function instanceOfChild(value: object): value is Child {
  return true;
}

export function ChildFromJSON(json: any): Child {
  return ChildFromJSONTyped(json, false);
}

export function ChildFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Child {
  if (json == null) {
    return json;
  }
  return {
    folderId: json["folderId"] == null ? undefined : json["folderId"],
    fileId: json["fileId"] == null ? undefined : json["fileId"],
    name: json["name"] == null ? undefined : json["name"],
    created: json["created"] == null ? undefined : json["created"],
    size: json["size"] == null ? undefined : json["size"],
    tsize: json["tsize"] == null ? undefined : json["tsize"],
    deleted: json["deleted"] == null ? undefined : json["deleted"],
    uploading: json["uploading"] == null ? undefined : json["uploading"],
    subscribed: json["subscribed"] == null ? undefined : json["subscribed"],
    modified: json["modified"] == null ? undefined : json["modified"],
    locktype: json["locktype"] == null ? undefined : json["locktype"],
    creator: json["creator"] == null ? undefined : json["creator"],
    creatorname: json["creatorname"] == null ? undefined : json["creatorname"],
    editor: json["editor"] == null ? undefined : json["editor"],
    editorname: json["editorname"] == null ? undefined : json["editorname"],
    numversion: json["numversion"] == null ? undefined : json["numversion"],
    contentType: json["contentType"] == null ? undefined : json["contentType"],
    author: json["author"] == null ? undefined : json["author"],
    title: json["title"] == null ? undefined : json["title"],
    geoLocation:
      json["geo_location"] == null ? undefined : json["geo_location"],
    timeCreation:
      json["time_creation"] == null ? undefined : json["time_creation"],
    notes: json["notes"] == null ? undefined : json["notes"],
    type: json["type"] == null ? undefined : json["type"],
    view: json["view"] == null ? undefined : json["view"],
    comments: json["comments"] == null ? undefined : json["comments"],
    produce: json["produce"] == null ? undefined : json["produce"],
    level: json["level"] == null ? undefined : json["level"],
    encrypted: json["encrypted"] == null ? undefined : json["encrypted"],
  };
}

export function ChildToJSON(json: any): Child {
  return ChildToJSONTyped(json, false);
}

export function ChildToJSONTyped(
  value?: Child | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    folderId: value["folderId"],
    fileId: value["fileId"],
    name: value["name"],
    created: value["created"],
    size: value["size"],
    tsize: value["tsize"],
    deleted: value["deleted"],
    uploading: value["uploading"],
    subscribed: value["subscribed"],
    modified: value["modified"],
    locktype: value["locktype"],
    creator: value["creator"],
    creatorname: value["creatorname"],
    editor: value["editor"],
    editorname: value["editorname"],
    numversion: value["numversion"],
    contentType: value["contentType"],
    author: value["author"],
    title: value["title"],
    geo_location: value["geoLocation"],
    time_creation: value["timeCreation"],
    notes: value["notes"],
    type: value["type"],
    view: value["view"],
    comments: value["comments"],
    produce: value["produce"],
    level: value["level"],
    encrypted: value["encrypted"],
  };
}
