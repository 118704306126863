/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ApplicationOptions
 */
export interface ApplicationOptions {
  /**
   *
   * @type {boolean}
   * @memberof ApplicationOptions
   */
  allowProjectCreation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationOptions
   */
  allowAccountCreation?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationOptions
   */
  stripePubKey?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationOptions
   */
  grecaptchaKey?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationOptions
   */
  mapKey?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationOptions
   */
  siteId?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationOptions
   */
  sessionTimeout?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationOptions
   */
  validatePasswordScript?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationOptions
   */
  validatePasswordTemplate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationOptions
   */
  passwordValidationAPI?: string;
}

/**
 * Check if a given object implements the ApplicationOptions interface.
 */
export function instanceOfApplicationOptions(
  value: object,
): value is ApplicationOptions {
  return true;
}

export function ApplicationOptionsFromJSON(json: any): ApplicationOptions {
  return ApplicationOptionsFromJSONTyped(json, false);
}

export function ApplicationOptionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ApplicationOptions {
  if (json == null) {
    return json;
  }
  return {
    allowProjectCreation:
      json["allowProjectCreation"] == null
        ? undefined
        : json["allowProjectCreation"],
    allowAccountCreation:
      json["allowAccountCreation"] == null
        ? undefined
        : json["allowAccountCreation"],
    stripePubKey:
      json["stripePubKey"] == null ? undefined : json["stripePubKey"],
    grecaptchaKey:
      json["grecaptchaKey"] == null ? undefined : json["grecaptchaKey"],
    mapKey: json["mapKey"] == null ? undefined : json["mapKey"],
    siteId: json["siteId"] == null ? undefined : json["siteId"],
    sessionTimeout:
      json["sessionTimeout"] == null ? undefined : json["sessionTimeout"],
    validatePasswordScript:
      json["validatePasswordScript"] == null
        ? undefined
        : json["validatePasswordScript"],
    validatePasswordTemplate:
      json["validatePasswordTemplate"] == null
        ? undefined
        : json["validatePasswordTemplate"],
    passwordValidationAPI:
      json["passwordValidationAPI"] == null
        ? undefined
        : json["passwordValidationAPI"],
  };
}

export function ApplicationOptionsToJSON(json: any): ApplicationOptions {
  return ApplicationOptionsToJSONTyped(json, false);
}

export function ApplicationOptionsToJSONTyped(
  value?: ApplicationOptions | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    allowProjectCreation: value["allowProjectCreation"],
    allowAccountCreation: value["allowAccountCreation"],
    stripePubKey: value["stripePubKey"],
    grecaptchaKey: value["grecaptchaKey"],
    mapKey: value["mapKey"],
    siteId: value["siteId"],
    sessionTimeout: value["sessionTimeout"],
    validatePasswordScript: value["validatePasswordScript"],
    validatePasswordTemplate: value["validatePasswordTemplate"],
    passwordValidationAPI: value["passwordValidationAPI"],
  };
}
