/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { Group } from "./Group";
import {
  GroupFromJSON,
  GroupFromJSONTyped,
  GroupToJSON,
  GroupToJSONTyped,
} from "./Group";
import type { Task } from "./Task";
import {
  TaskFromJSON,
  TaskFromJSONTyped,
  TaskToJSON,
  TaskToJSONTyped,
} from "./Task";
import type { Folder } from "./Folder";
import {
  FolderFromJSON,
  FolderFromJSONTyped,
  FolderToJSON,
  FolderToJSONTyped,
} from "./Folder";

/**
 *
 * @export
 * @interface Project
 */
export interface Project {
  /**
   *
   * @type {string}
   * @memberof Project
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  subscriptionId?: string;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  visible?: boolean;
  /**
   *
   * @type {Date}
   * @memberof Project
   */
  disabledDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof Project
   */
  expiry?: Date;
  /**
   *
   * @type {Folder}
   * @memberof Project
   */
  root?: Folder;
  /**
   *
   * @type {Folder}
   * @memberof Project
   */
  trash?: Folder;
  /**
   *
   * @type {Group}
   * @memberof Project
   */
  defaultGroup?: Group;
  /**
   *
   * @type {Set<Group>}
   * @memberof Project
   */
  groups?: Set<Group>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof Project
   */
  members?: { [key: string]: string };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof Project
   */
  monthlyMaxSpaceUsage?: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof Project
   */
  monthlyMaxSpaceTimestamp?: { [key: string]: number };
  /**
   *
   * @type {number}
   * @memberof Project
   */
  softLimit?: number;
  /**
   *
   * @type {number}
   * @memberof Project
   */
  bytesLimit?: number;
  /**
   *
   * @type {number}
   * @memberof Project
   */
  fileCountLimit?: number;
  /**
   *
   * @type {number}
   * @memberof Project
   */
  folderCountLimit?: number;
  /**
   *
   * @type {number}
   * @memberof Project
   */
  memberCountLimit?: number;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  expansionAllowed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  publicationAllowed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  enhancedSecurityForced?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  folderProductionEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  redactionsEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  reviewEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  conversionsEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  globalEntityPublicationAllowed?: boolean;
  /**
   *
   * @type {Set<string>}
   * @memberof Project
   */
  customEntityDefinitions?: Set<string>;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  adminNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  clientName?: string;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  projectType?: ProjectProjectTypeEnum;
  /**
   *
   * @type {Set<Task>}
   * @memberof Project
   */
  tasks?: Set<Task>;
  /**
   *
   * @type {{ [key: string]: Set<string>; }}
   * @memberof Project
   */
  webhooks?: { [key: string]: Set<string> };
  /**
   *
   * @type {number}
   * @memberof Project
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  id?: string;
  /**
   *
   * @type {Project}
   * @memberof Project
   */
  project?: Project;
  /**
   *
   * @type {number}
   * @memberof Project
   */
  totalSize?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum ProjectProjectTypeEnum {
  OPEN = "OPEN",
  MIXED = "MIXED",
}
/**
 * @export
 * @enum {string}
 */
export enum ProjectWebhooksEnum {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  SESSION_REFRESHED = "SESSION_REFRESHED",
  ACCOUNT_CREATED = "ACCOUNT_CREATED",
  ACCOUNT_ACTIVATED = "ACCOUNT_ACTIVATED",
  EMAIL_CHANGE_REQUESTED = "EMAIL_CHANGE_REQUESTED",
  EMAIL_CHANGED = "EMAIL_CHANGED",
  UPDATED_PASSWORD = "UPDATED_PASSWORD",
  UPDATED_DISPLAY_NAME = "UPDATED_DISPLAY_NAME",
  APPLICATION_ACCESS_GRANTED = "APPLICATION_ACCESS_GRANTED",
  APPLICATION_ACCESS_REVOKED = "APPLICATION_ACCESS_REVOKED",
  CREATED = "CREATED",
  CHILD_ADDED = "CHILD_ADDED",
  CHILD_REMOVED = "CHILD_REMOVED",
  DOWNLOADED = "DOWNLOADED",
  PREVIEWED = "PREVIEWED",
  MOVED = "MOVED",
  COPIED = "COPIED",
  DELETED = "DELETED",
  TRASHED = "TRASHED",
  RESTORED = "RESTORED",
  COMMENTED = "COMMENTED",
  CARD_ADDED = "CARD_ADDED",
  CARD_REMOVED = "CARD_REMOVED",
  DEFAULT_CARD_CHANGED = "DEFAULT_CARD_CHANGED",
  LOCKED = "LOCKED",
  PUBLISHED = "PUBLISHED",
  UNLOCKED = "UNLOCKED",
  RENAMED = "RENAMED",
  METADATA_SET = "METADATA_SET",
  METADATA_DELETED = "METADATA_DELETED",
  PERMISSION_SET = "PERMISSION_SET",
  PERMISSION_DELETED = "PERMISSION_DELETED",
  NOTIFICATION_PROVIDER_ADDED = "NOTIFICATION_PROVIDER_ADDED",
  NOTIFICATION_PROVIDER_REMOVED = "NOTIFICATION_PROVIDER_REMOVED",
  PAYMENT_PLAN_UPDATED = "PAYMENT_PLAN_UPDATED",
  PROJECT_LEFT = "PROJECT_LEFT",
  CUSTOM_ENTITY_ADDED = "CUSTOM_ENTITY_ADDED",
  CUSTOM_ENTITY_REMOVED = "CUSTOM_ENTITY_REMOVED",
  TRASH_EMPTIED = "TRASH_EMPTIED",
  MEMBER_ADDED = "MEMBER_ADDED",
  MEMBER_REMOVED = "MEMBER_REMOVED",
  DEFAULT_GROUP_SET = "DEFAULT_GROUP_SET",
  PASSWORD_RESET_REQUESTED = "PASSWORD_RESET_REQUESTED",
  TEST = "TEST",
  SCRIPT_NOTIFICATION = "SCRIPT_NOTIFICATION",
  CARD_UPDATED = "CARD_UPDATED",
  NOTIFICATION_TYPE_TOGGLED = "NOTIFICATION_TYPE_TOGGLED",
  ACCESS_LEVEL_SET = "ACCESS_LEVEL_SET",
  REDACTED = "REDACTED",
  UPDATED = "UPDATED",
  COMPLETED = "COMPLETED",
  PERMISSION_ASSIGNED = "PERMISSION_ASSIGNED",
  FAILED_LOGIN = "FAILED_LOGIN",
}

/**
 * Check if a given object implements the Project interface.
 */
export function instanceOfProject(value: object): value is Project {
  return true;
}

export function ProjectFromJSON(json: any): Project {
  return ProjectFromJSONTyped(json, false);
}

export function ProjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Project {
  if (json == null) {
    return json;
  }
  return {
    name: json["name"] == null ? undefined : json["name"],
    subscriptionId:
      json["subscriptionId"] == null ? undefined : json["subscriptionId"],
    enabled: json["enabled"] == null ? undefined : json["enabled"],
    visible: json["visible"] == null ? undefined : json["visible"],
    disabledDate:
      json["disabledDate"] == null ? undefined : new Date(json["disabledDate"]),
    expiry: json["expiry"] == null ? undefined : new Date(json["expiry"]),
    root: json["root"] == null ? undefined : FolderFromJSON(json["root"]),
    trash: json["trash"] == null ? undefined : FolderFromJSON(json["trash"]),
    defaultGroup:
      json["defaultGroup"] == null
        ? undefined
        : GroupFromJSON(json["defaultGroup"]),
    groups:
      json["groups"] == null
        ? undefined
        : new Set((json["groups"] as Array<any>).map(GroupFromJSON)),
    members: json["members"] == null ? undefined : json["members"],
    monthlyMaxSpaceUsage:
      json["monthlyMaxSpaceUsage"] == null
        ? undefined
        : json["monthlyMaxSpaceUsage"],
    monthlyMaxSpaceTimestamp:
      json["monthlyMaxSpaceTimestamp"] == null
        ? undefined
        : json["monthlyMaxSpaceTimestamp"],
    softLimit: json["softLimit"] == null ? undefined : json["softLimit"],
    bytesLimit: json["bytesLimit"] == null ? undefined : json["bytesLimit"],
    fileCountLimit:
      json["fileCountLimit"] == null ? undefined : json["fileCountLimit"],
    folderCountLimit:
      json["folderCountLimit"] == null ? undefined : json["folderCountLimit"],
    memberCountLimit:
      json["memberCountLimit"] == null ? undefined : json["memberCountLimit"],
    expansionAllowed:
      json["expansionAllowed"] == null ? undefined : json["expansionAllowed"],
    publicationAllowed:
      json["publicationAllowed"] == null
        ? undefined
        : json["publicationAllowed"],
    enhancedSecurityForced:
      json["enhancedSecurityForced"] == null
        ? undefined
        : json["enhancedSecurityForced"],
    folderProductionEnabled:
      json["folderProductionEnabled"] == null
        ? undefined
        : json["folderProductionEnabled"],
    redactionsEnabled:
      json["redactionsEnabled"] == null ? undefined : json["redactionsEnabled"],
    reviewEnabled:
      json["reviewEnabled"] == null ? undefined : json["reviewEnabled"],
    conversionsEnabled:
      json["conversionsEnabled"] == null
        ? undefined
        : json["conversionsEnabled"],
    globalEntityPublicationAllowed:
      json["globalEntityPublicationAllowed"] == null
        ? undefined
        : json["globalEntityPublicationAllowed"],
    customEntityDefinitions:
      json["customEntityDefinitions"] == null
        ? undefined
        : new Set(json["customEntityDefinitions"]),
    adminNumber: json["adminNumber"] == null ? undefined : json["adminNumber"],
    clientName: json["clientName"] == null ? undefined : json["clientName"],
    projectType: json["projectType"] == null ? undefined : json["projectType"],
    tasks:
      json["tasks"] == null
        ? undefined
        : new Set((json["tasks"] as Array<any>).map(TaskFromJSON)),
    webhooks: json["webhooks"] == null ? undefined : json["webhooks"],
    version: json["version"] == null ? undefined : json["version"],
    id: json["id"] == null ? undefined : json["id"],
    project:
      json["project"] == null ? undefined : ProjectFromJSON(json["project"]),
    totalSize: json["totalSize"] == null ? undefined : json["totalSize"],
  };
}

export function ProjectToJSON(json: any): Project {
  return ProjectToJSONTyped(json, false);
}

export function ProjectToJSONTyped(
  value?: Project | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value["name"],
    subscriptionId: value["subscriptionId"],
    enabled: value["enabled"],
    visible: value["visible"],
    disabledDate:
      value["disabledDate"] == null
        ? undefined
        : value["disabledDate"].toISOString(),
    expiry: value["expiry"] == null ? undefined : value["expiry"].toISOString(),
    root: FolderToJSON(value["root"]),
    trash: FolderToJSON(value["trash"]),
    defaultGroup: GroupToJSON(value["defaultGroup"]),
    groups:
      value["groups"] == null
        ? undefined
        : Array.from(value["groups"] as Set<any>).map(GroupToJSON),
    members: value["members"],
    monthlyMaxSpaceUsage: value["monthlyMaxSpaceUsage"],
    monthlyMaxSpaceTimestamp: value["monthlyMaxSpaceTimestamp"],
    softLimit: value["softLimit"],
    bytesLimit: value["bytesLimit"],
    fileCountLimit: value["fileCountLimit"],
    folderCountLimit: value["folderCountLimit"],
    memberCountLimit: value["memberCountLimit"],
    expansionAllowed: value["expansionAllowed"],
    publicationAllowed: value["publicationAllowed"],
    enhancedSecurityForced: value["enhancedSecurityForced"],
    folderProductionEnabled: value["folderProductionEnabled"],
    redactionsEnabled: value["redactionsEnabled"],
    reviewEnabled: value["reviewEnabled"],
    conversionsEnabled: value["conversionsEnabled"],
    globalEntityPublicationAllowed: value["globalEntityPublicationAllowed"],
    customEntityDefinitions:
      value["customEntityDefinitions"] == null
        ? undefined
        : Array.from(value["customEntityDefinitions"] as Set<any>),
    adminNumber: value["adminNumber"],
    clientName: value["clientName"],
    projectType: value["projectType"],
    tasks:
      value["tasks"] == null
        ? undefined
        : Array.from(value["tasks"] as Set<any>).map(TaskToJSON),
    webhooks: value["webhooks"],
    version: value["version"],
    id: value["id"],
    project: ProjectToJSON(value["project"]),
    totalSize: value["totalSize"],
  };
}
