/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MemberInformation
 */
export interface MemberInformation {
  /**
   *
   * @type {string}
   * @memberof MemberInformation
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof MemberInformation
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof MemberInformation
   */
  name?: string;
}

/**
 * Check if a given object implements the MemberInformation interface.
 */
export function instanceOfMemberInformation(
  value: object,
): value is MemberInformation {
  return true;
}

export function MemberInformationFromJSON(json: any): MemberInformation {
  return MemberInformationFromJSONTyped(json, false);
}

export function MemberInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MemberInformation {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"] == null ? undefined : json["id"],
    email: json["email"] == null ? undefined : json["email"],
    name: json["name"] == null ? undefined : json["name"],
  };
}

export function MemberInformationToJSON(json: any): MemberInformation {
  return MemberInformationToJSONTyped(json, false);
}

export function MemberInformationToJSONTyped(
  value?: MemberInformation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    email: value["email"],
    name: value["name"],
  };
}
