/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as WorkspaceIdIndexImport } from './routes/$workspaceId/index'
import { Route as WorkspaceIdTasksImport } from './routes/$workspaceId/tasks'
import { Route as WorkspaceIdOptionsImport } from './routes/$workspaceId/options'
import { Route as WorkspaceIdMembersImport } from './routes/$workspaceId/members'
import { Route as WorkspaceIdGroupsImport } from './routes/$workspaceId/groups'
import { Route as WorkspaceIdDashboardImport } from './routes/$workspaceId/dashboard'
import { Route as WorkspaceIdFileManagerIndexImport } from './routes/$workspaceId/file-manager/index'
import { Route as WorkspaceIdFileManagerTrashImport } from './routes/$workspaceId/file-manager/trash'
import { Route as WorkspaceIdFileManagerStarredImport } from './routes/$workspaceId/file-manager/starred'
import { Route as WorkspaceIdFileManagerEntitiesIndexImport } from './routes/$workspaceId/file-manager/entities/index'
import { Route as WorkspaceIdFileManagerEntitiesEntityIdImport } from './routes/$workspaceId/file-manager/entities/$entityId'

// Create Virtual Routes

const IndexLazyImport = createFileRoute('/')()
const PreferencesIndexLazyImport = createFileRoute('/preferences/')()
const PreferencesProfileLazyImport = createFileRoute('/preferences/profile')()
const AuthResetPasswordLazyImport = createFileRoute('/auth/reset-password')()
const AuthResetEmailLazyImport = createFileRoute('/auth/reset-email')()
const AuthRegisterLazyImport = createFileRoute('/auth/register')()
const AuthOnboardLazyImport = createFileRoute('/auth/onboard')()
const AuthLogoutLazyImport = createFileRoute('/auth/logout')()
const AuthLoginLazyImport = createFileRoute('/auth/login')()
const AuthLockLazyImport = createFileRoute('/auth/lock')()

// Create/Update Routes

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const PreferencesIndexLazyRoute = PreferencesIndexLazyImport.update({
  id: '/preferences/',
  path: '/preferences/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/preferences/index.lazy').then((d) => d.Route),
)

const WorkspaceIdIndexRoute = WorkspaceIdIndexImport.update({
  id: '/$workspaceId/',
  path: '/$workspaceId/',
  getParentRoute: () => rootRoute,
} as any)

const PreferencesProfileLazyRoute = PreferencesProfileLazyImport.update({
  id: '/preferences/profile',
  path: '/preferences/profile',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/preferences/profile.lazy').then((d) => d.Route),
)

const AuthResetPasswordLazyRoute = AuthResetPasswordLazyImport.update({
  id: '/auth/reset-password',
  path: '/auth/reset-password',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/auth/reset-password.lazy').then((d) => d.Route),
)

const AuthResetEmailLazyRoute = AuthResetEmailLazyImport.update({
  id: '/auth/reset-email',
  path: '/auth/reset-email',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/auth/reset-email.lazy').then((d) => d.Route),
)

const AuthRegisterLazyRoute = AuthRegisterLazyImport.update({
  id: '/auth/register',
  path: '/auth/register',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/auth/register.lazy').then((d) => d.Route))

const AuthOnboardLazyRoute = AuthOnboardLazyImport.update({
  id: '/auth/onboard',
  path: '/auth/onboard',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/auth/onboard.lazy').then((d) => d.Route))

const AuthLogoutLazyRoute = AuthLogoutLazyImport.update({
  id: '/auth/logout',
  path: '/auth/logout',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/auth/logout.lazy').then((d) => d.Route))

const AuthLoginLazyRoute = AuthLoginLazyImport.update({
  id: '/auth/login',
  path: '/auth/login',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/auth/login.lazy').then((d) => d.Route))

const AuthLockLazyRoute = AuthLockLazyImport.update({
  id: '/auth/lock',
  path: '/auth/lock',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/auth/lock.lazy').then((d) => d.Route))

const WorkspaceIdTasksRoute = WorkspaceIdTasksImport.update({
  id: '/$workspaceId/tasks',
  path: '/$workspaceId/tasks',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceIdOptionsRoute = WorkspaceIdOptionsImport.update({
  id: '/$workspaceId/options',
  path: '/$workspaceId/options',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceIdMembersRoute = WorkspaceIdMembersImport.update({
  id: '/$workspaceId/members',
  path: '/$workspaceId/members',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceIdGroupsRoute = WorkspaceIdGroupsImport.update({
  id: '/$workspaceId/groups',
  path: '/$workspaceId/groups',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceIdDashboardRoute = WorkspaceIdDashboardImport.update({
  id: '/$workspaceId/dashboard',
  path: '/$workspaceId/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceIdFileManagerIndexRoute =
  WorkspaceIdFileManagerIndexImport.update({
    id: '/$workspaceId/file-manager/',
    path: '/$workspaceId/file-manager/',
    getParentRoute: () => rootRoute,
  } as any)

const WorkspaceIdFileManagerTrashRoute =
  WorkspaceIdFileManagerTrashImport.update({
    id: '/$workspaceId/file-manager/trash',
    path: '/$workspaceId/file-manager/trash',
    getParentRoute: () => rootRoute,
  } as any)

const WorkspaceIdFileManagerStarredRoute =
  WorkspaceIdFileManagerStarredImport.update({
    id: '/$workspaceId/file-manager/starred',
    path: '/$workspaceId/file-manager/starred',
    getParentRoute: () => rootRoute,
  } as any)

const WorkspaceIdFileManagerEntitiesIndexRoute =
  WorkspaceIdFileManagerEntitiesIndexImport.update({
    id: '/$workspaceId/file-manager/entities/',
    path: '/$workspaceId/file-manager/entities/',
    getParentRoute: () => rootRoute,
  } as any)

const WorkspaceIdFileManagerEntitiesEntityIdRoute =
  WorkspaceIdFileManagerEntitiesEntityIdImport.update({
    id: '/$workspaceId/file-manager/entities/$entityId',
    path: '/$workspaceId/file-manager/entities/$entityId',
    getParentRoute: () => rootRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/dashboard': {
      id: '/$workspaceId/dashboard'
      path: '/$workspaceId/dashboard'
      fullPath: '/$workspaceId/dashboard'
      preLoaderRoute: typeof WorkspaceIdDashboardImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/groups': {
      id: '/$workspaceId/groups'
      path: '/$workspaceId/groups'
      fullPath: '/$workspaceId/groups'
      preLoaderRoute: typeof WorkspaceIdGroupsImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/members': {
      id: '/$workspaceId/members'
      path: '/$workspaceId/members'
      fullPath: '/$workspaceId/members'
      preLoaderRoute: typeof WorkspaceIdMembersImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/options': {
      id: '/$workspaceId/options'
      path: '/$workspaceId/options'
      fullPath: '/$workspaceId/options'
      preLoaderRoute: typeof WorkspaceIdOptionsImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/tasks': {
      id: '/$workspaceId/tasks'
      path: '/$workspaceId/tasks'
      fullPath: '/$workspaceId/tasks'
      preLoaderRoute: typeof WorkspaceIdTasksImport
      parentRoute: typeof rootRoute
    }
    '/auth/lock': {
      id: '/auth/lock'
      path: '/auth/lock'
      fullPath: '/auth/lock'
      preLoaderRoute: typeof AuthLockLazyImport
      parentRoute: typeof rootRoute
    }
    '/auth/login': {
      id: '/auth/login'
      path: '/auth/login'
      fullPath: '/auth/login'
      preLoaderRoute: typeof AuthLoginLazyImport
      parentRoute: typeof rootRoute
    }
    '/auth/logout': {
      id: '/auth/logout'
      path: '/auth/logout'
      fullPath: '/auth/logout'
      preLoaderRoute: typeof AuthLogoutLazyImport
      parentRoute: typeof rootRoute
    }
    '/auth/onboard': {
      id: '/auth/onboard'
      path: '/auth/onboard'
      fullPath: '/auth/onboard'
      preLoaderRoute: typeof AuthOnboardLazyImport
      parentRoute: typeof rootRoute
    }
    '/auth/register': {
      id: '/auth/register'
      path: '/auth/register'
      fullPath: '/auth/register'
      preLoaderRoute: typeof AuthRegisterLazyImport
      parentRoute: typeof rootRoute
    }
    '/auth/reset-email': {
      id: '/auth/reset-email'
      path: '/auth/reset-email'
      fullPath: '/auth/reset-email'
      preLoaderRoute: typeof AuthResetEmailLazyImport
      parentRoute: typeof rootRoute
    }
    '/auth/reset-password': {
      id: '/auth/reset-password'
      path: '/auth/reset-password'
      fullPath: '/auth/reset-password'
      preLoaderRoute: typeof AuthResetPasswordLazyImport
      parentRoute: typeof rootRoute
    }
    '/preferences/profile': {
      id: '/preferences/profile'
      path: '/preferences/profile'
      fullPath: '/preferences/profile'
      preLoaderRoute: typeof PreferencesProfileLazyImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/': {
      id: '/$workspaceId/'
      path: '/$workspaceId'
      fullPath: '/$workspaceId'
      preLoaderRoute: typeof WorkspaceIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/preferences/': {
      id: '/preferences/'
      path: '/preferences'
      fullPath: '/preferences'
      preLoaderRoute: typeof PreferencesIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/file-manager/starred': {
      id: '/$workspaceId/file-manager/starred'
      path: '/$workspaceId/file-manager/starred'
      fullPath: '/$workspaceId/file-manager/starred'
      preLoaderRoute: typeof WorkspaceIdFileManagerStarredImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/file-manager/trash': {
      id: '/$workspaceId/file-manager/trash'
      path: '/$workspaceId/file-manager/trash'
      fullPath: '/$workspaceId/file-manager/trash'
      preLoaderRoute: typeof WorkspaceIdFileManagerTrashImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/file-manager/': {
      id: '/$workspaceId/file-manager/'
      path: '/$workspaceId/file-manager'
      fullPath: '/$workspaceId/file-manager'
      preLoaderRoute: typeof WorkspaceIdFileManagerIndexImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/file-manager/entities/$entityId': {
      id: '/$workspaceId/file-manager/entities/$entityId'
      path: '/$workspaceId/file-manager/entities/$entityId'
      fullPath: '/$workspaceId/file-manager/entities/$entityId'
      preLoaderRoute: typeof WorkspaceIdFileManagerEntitiesEntityIdImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/file-manager/entities/': {
      id: '/$workspaceId/file-manager/entities/'
      path: '/$workspaceId/file-manager/entities'
      fullPath: '/$workspaceId/file-manager/entities'
      preLoaderRoute: typeof WorkspaceIdFileManagerEntitiesIndexImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '/$workspaceId/dashboard': typeof WorkspaceIdDashboardRoute
  '/$workspaceId/groups': typeof WorkspaceIdGroupsRoute
  '/$workspaceId/members': typeof WorkspaceIdMembersRoute
  '/$workspaceId/options': typeof WorkspaceIdOptionsRoute
  '/$workspaceId/tasks': typeof WorkspaceIdTasksRoute
  '/auth/lock': typeof AuthLockLazyRoute
  '/auth/login': typeof AuthLoginLazyRoute
  '/auth/logout': typeof AuthLogoutLazyRoute
  '/auth/onboard': typeof AuthOnboardLazyRoute
  '/auth/register': typeof AuthRegisterLazyRoute
  '/auth/reset-email': typeof AuthResetEmailLazyRoute
  '/auth/reset-password': typeof AuthResetPasswordLazyRoute
  '/preferences/profile': typeof PreferencesProfileLazyRoute
  '/$workspaceId': typeof WorkspaceIdIndexRoute
  '/preferences': typeof PreferencesIndexLazyRoute
  '/$workspaceId/file-manager/starred': typeof WorkspaceIdFileManagerStarredRoute
  '/$workspaceId/file-manager/trash': typeof WorkspaceIdFileManagerTrashRoute
  '/$workspaceId/file-manager': typeof WorkspaceIdFileManagerIndexRoute
  '/$workspaceId/file-manager/entities/$entityId': typeof WorkspaceIdFileManagerEntitiesEntityIdRoute
  '/$workspaceId/file-manager/entities': typeof WorkspaceIdFileManagerEntitiesIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '/$workspaceId/dashboard': typeof WorkspaceIdDashboardRoute
  '/$workspaceId/groups': typeof WorkspaceIdGroupsRoute
  '/$workspaceId/members': typeof WorkspaceIdMembersRoute
  '/$workspaceId/options': typeof WorkspaceIdOptionsRoute
  '/$workspaceId/tasks': typeof WorkspaceIdTasksRoute
  '/auth/lock': typeof AuthLockLazyRoute
  '/auth/login': typeof AuthLoginLazyRoute
  '/auth/logout': typeof AuthLogoutLazyRoute
  '/auth/onboard': typeof AuthOnboardLazyRoute
  '/auth/register': typeof AuthRegisterLazyRoute
  '/auth/reset-email': typeof AuthResetEmailLazyRoute
  '/auth/reset-password': typeof AuthResetPasswordLazyRoute
  '/preferences/profile': typeof PreferencesProfileLazyRoute
  '/$workspaceId': typeof WorkspaceIdIndexRoute
  '/preferences': typeof PreferencesIndexLazyRoute
  '/$workspaceId/file-manager/starred': typeof WorkspaceIdFileManagerStarredRoute
  '/$workspaceId/file-manager/trash': typeof WorkspaceIdFileManagerTrashRoute
  '/$workspaceId/file-manager': typeof WorkspaceIdFileManagerIndexRoute
  '/$workspaceId/file-manager/entities/$entityId': typeof WorkspaceIdFileManagerEntitiesEntityIdRoute
  '/$workspaceId/file-manager/entities': typeof WorkspaceIdFileManagerEntitiesIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/$workspaceId/dashboard': typeof WorkspaceIdDashboardRoute
  '/$workspaceId/groups': typeof WorkspaceIdGroupsRoute
  '/$workspaceId/members': typeof WorkspaceIdMembersRoute
  '/$workspaceId/options': typeof WorkspaceIdOptionsRoute
  '/$workspaceId/tasks': typeof WorkspaceIdTasksRoute
  '/auth/lock': typeof AuthLockLazyRoute
  '/auth/login': typeof AuthLoginLazyRoute
  '/auth/logout': typeof AuthLogoutLazyRoute
  '/auth/onboard': typeof AuthOnboardLazyRoute
  '/auth/register': typeof AuthRegisterLazyRoute
  '/auth/reset-email': typeof AuthResetEmailLazyRoute
  '/auth/reset-password': typeof AuthResetPasswordLazyRoute
  '/preferences/profile': typeof PreferencesProfileLazyRoute
  '/$workspaceId/': typeof WorkspaceIdIndexRoute
  '/preferences/': typeof PreferencesIndexLazyRoute
  '/$workspaceId/file-manager/starred': typeof WorkspaceIdFileManagerStarredRoute
  '/$workspaceId/file-manager/trash': typeof WorkspaceIdFileManagerTrashRoute
  '/$workspaceId/file-manager/': typeof WorkspaceIdFileManagerIndexRoute
  '/$workspaceId/file-manager/entities/$entityId': typeof WorkspaceIdFileManagerEntitiesEntityIdRoute
  '/$workspaceId/file-manager/entities/': typeof WorkspaceIdFileManagerEntitiesIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/$workspaceId/dashboard'
    | '/$workspaceId/groups'
    | '/$workspaceId/members'
    | '/$workspaceId/options'
    | '/$workspaceId/tasks'
    | '/auth/lock'
    | '/auth/login'
    | '/auth/logout'
    | '/auth/onboard'
    | '/auth/register'
    | '/auth/reset-email'
    | '/auth/reset-password'
    | '/preferences/profile'
    | '/$workspaceId'
    | '/preferences'
    | '/$workspaceId/file-manager/starred'
    | '/$workspaceId/file-manager/trash'
    | '/$workspaceId/file-manager'
    | '/$workspaceId/file-manager/entities/$entityId'
    | '/$workspaceId/file-manager/entities'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/$workspaceId/dashboard'
    | '/$workspaceId/groups'
    | '/$workspaceId/members'
    | '/$workspaceId/options'
    | '/$workspaceId/tasks'
    | '/auth/lock'
    | '/auth/login'
    | '/auth/logout'
    | '/auth/onboard'
    | '/auth/register'
    | '/auth/reset-email'
    | '/auth/reset-password'
    | '/preferences/profile'
    | '/$workspaceId'
    | '/preferences'
    | '/$workspaceId/file-manager/starred'
    | '/$workspaceId/file-manager/trash'
    | '/$workspaceId/file-manager'
    | '/$workspaceId/file-manager/entities/$entityId'
    | '/$workspaceId/file-manager/entities'
  id:
    | '__root__'
    | '/'
    | '/$workspaceId/dashboard'
    | '/$workspaceId/groups'
    | '/$workspaceId/members'
    | '/$workspaceId/options'
    | '/$workspaceId/tasks'
    | '/auth/lock'
    | '/auth/login'
    | '/auth/logout'
    | '/auth/onboard'
    | '/auth/register'
    | '/auth/reset-email'
    | '/auth/reset-password'
    | '/preferences/profile'
    | '/$workspaceId/'
    | '/preferences/'
    | '/$workspaceId/file-manager/starred'
    | '/$workspaceId/file-manager/trash'
    | '/$workspaceId/file-manager/'
    | '/$workspaceId/file-manager/entities/$entityId'
    | '/$workspaceId/file-manager/entities/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  WorkspaceIdDashboardRoute: typeof WorkspaceIdDashboardRoute
  WorkspaceIdGroupsRoute: typeof WorkspaceIdGroupsRoute
  WorkspaceIdMembersRoute: typeof WorkspaceIdMembersRoute
  WorkspaceIdOptionsRoute: typeof WorkspaceIdOptionsRoute
  WorkspaceIdTasksRoute: typeof WorkspaceIdTasksRoute
  AuthLockLazyRoute: typeof AuthLockLazyRoute
  AuthLoginLazyRoute: typeof AuthLoginLazyRoute
  AuthLogoutLazyRoute: typeof AuthLogoutLazyRoute
  AuthOnboardLazyRoute: typeof AuthOnboardLazyRoute
  AuthRegisterLazyRoute: typeof AuthRegisterLazyRoute
  AuthResetEmailLazyRoute: typeof AuthResetEmailLazyRoute
  AuthResetPasswordLazyRoute: typeof AuthResetPasswordLazyRoute
  PreferencesProfileLazyRoute: typeof PreferencesProfileLazyRoute
  WorkspaceIdIndexRoute: typeof WorkspaceIdIndexRoute
  PreferencesIndexLazyRoute: typeof PreferencesIndexLazyRoute
  WorkspaceIdFileManagerStarredRoute: typeof WorkspaceIdFileManagerStarredRoute
  WorkspaceIdFileManagerTrashRoute: typeof WorkspaceIdFileManagerTrashRoute
  WorkspaceIdFileManagerIndexRoute: typeof WorkspaceIdFileManagerIndexRoute
  WorkspaceIdFileManagerEntitiesEntityIdRoute: typeof WorkspaceIdFileManagerEntitiesEntityIdRoute
  WorkspaceIdFileManagerEntitiesIndexRoute: typeof WorkspaceIdFileManagerEntitiesIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  WorkspaceIdDashboardRoute: WorkspaceIdDashboardRoute,
  WorkspaceIdGroupsRoute: WorkspaceIdGroupsRoute,
  WorkspaceIdMembersRoute: WorkspaceIdMembersRoute,
  WorkspaceIdOptionsRoute: WorkspaceIdOptionsRoute,
  WorkspaceIdTasksRoute: WorkspaceIdTasksRoute,
  AuthLockLazyRoute: AuthLockLazyRoute,
  AuthLoginLazyRoute: AuthLoginLazyRoute,
  AuthLogoutLazyRoute: AuthLogoutLazyRoute,
  AuthOnboardLazyRoute: AuthOnboardLazyRoute,
  AuthRegisterLazyRoute: AuthRegisterLazyRoute,
  AuthResetEmailLazyRoute: AuthResetEmailLazyRoute,
  AuthResetPasswordLazyRoute: AuthResetPasswordLazyRoute,
  PreferencesProfileLazyRoute: PreferencesProfileLazyRoute,
  WorkspaceIdIndexRoute: WorkspaceIdIndexRoute,
  PreferencesIndexLazyRoute: PreferencesIndexLazyRoute,
  WorkspaceIdFileManagerStarredRoute: WorkspaceIdFileManagerStarredRoute,
  WorkspaceIdFileManagerTrashRoute: WorkspaceIdFileManagerTrashRoute,
  WorkspaceIdFileManagerIndexRoute: WorkspaceIdFileManagerIndexRoute,
  WorkspaceIdFileManagerEntitiesEntityIdRoute:
    WorkspaceIdFileManagerEntitiesEntityIdRoute,
  WorkspaceIdFileManagerEntitiesIndexRoute:
    WorkspaceIdFileManagerEntitiesIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/$workspaceId/dashboard",
        "/$workspaceId/groups",
        "/$workspaceId/members",
        "/$workspaceId/options",
        "/$workspaceId/tasks",
        "/auth/lock",
        "/auth/login",
        "/auth/logout",
        "/auth/onboard",
        "/auth/register",
        "/auth/reset-email",
        "/auth/reset-password",
        "/preferences/profile",
        "/$workspaceId/",
        "/preferences/",
        "/$workspaceId/file-manager/starred",
        "/$workspaceId/file-manager/trash",
        "/$workspaceId/file-manager/",
        "/$workspaceId/file-manager/entities/$entityId",
        "/$workspaceId/file-manager/entities/"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/$workspaceId/dashboard": {
      "filePath": "$workspaceId/dashboard.tsx"
    },
    "/$workspaceId/groups": {
      "filePath": "$workspaceId/groups.tsx"
    },
    "/$workspaceId/members": {
      "filePath": "$workspaceId/members.tsx"
    },
    "/$workspaceId/options": {
      "filePath": "$workspaceId/options.tsx"
    },
    "/$workspaceId/tasks": {
      "filePath": "$workspaceId/tasks.tsx"
    },
    "/auth/lock": {
      "filePath": "auth/lock.lazy.tsx"
    },
    "/auth/login": {
      "filePath": "auth/login.lazy.tsx"
    },
    "/auth/logout": {
      "filePath": "auth/logout.lazy.tsx"
    },
    "/auth/onboard": {
      "filePath": "auth/onboard.lazy.tsx"
    },
    "/auth/register": {
      "filePath": "auth/register.lazy.tsx"
    },
    "/auth/reset-email": {
      "filePath": "auth/reset-email.lazy.tsx"
    },
    "/auth/reset-password": {
      "filePath": "auth/reset-password.lazy.tsx"
    },
    "/preferences/profile": {
      "filePath": "preferences/profile.lazy.tsx"
    },
    "/$workspaceId/": {
      "filePath": "$workspaceId/index.tsx"
    },
    "/preferences/": {
      "filePath": "preferences/index.lazy.tsx"
    },
    "/$workspaceId/file-manager/starred": {
      "filePath": "$workspaceId/file-manager/starred.tsx"
    },
    "/$workspaceId/file-manager/trash": {
      "filePath": "$workspaceId/file-manager/trash.tsx"
    },
    "/$workspaceId/file-manager/": {
      "filePath": "$workspaceId/file-manager/index.tsx"
    },
    "/$workspaceId/file-manager/entities/$entityId": {
      "filePath": "$workspaceId/file-manager/entities/$entityId.tsx"
    },
    "/$workspaceId/file-manager/entities/": {
      "filePath": "$workspaceId/file-manager/entities/index.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
