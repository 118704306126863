/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  ActivateAccountSpec,
  CreateAccountSpec,
  LoginSpec,
  LogoutSpec,
  SubmitEmailUpdateSpec,
  ValidateLoginSpec,
} from "../models/index";
import {
  ActivateAccountSpecFromJSON,
  ActivateAccountSpecToJSON,
  CreateAccountSpecFromJSON,
  CreateAccountSpecToJSON,
  LoginSpecFromJSON,
  LoginSpecToJSON,
  LogoutSpecFromJSON,
  LogoutSpecToJSON,
  SubmitEmailUpdateSpecFromJSON,
  SubmitEmailUpdateSpecToJSON,
  ValidateLoginSpecFromJSON,
  ValidateLoginSpecToJSON,
} from "../models/index";

export interface ActivateAccountRequest {
  user: string;
  activateAccountSpec: ActivateAccountSpec;
}

export interface CreateAccountRequest {
  createAccountSpec: CreateAccountSpec;
}

export interface DeleteLinkedAppRequest {
  consumerId: string;
}

export interface LoginRequest {
  loginSpec: LoginSpec;
}

export interface LogoutRequest {
  logoutSpec?: LogoutSpec;
}

export interface RefreshSessionRequest {
  submitEmailUpdateSpec: SubmitEmailUpdateSpec;
}

export interface SubmitEmailChangeRequest {
  token: string;
  submitEmailUpdateSpec: SubmitEmailUpdateSpec;
}

export interface ValidateEmailChangeRequest {
  token: string;
  submitEmailUpdateSpec: SubmitEmailUpdateSpec;
}

export interface ValidateSessionRequest {
  validateLoginSpec: ValidateLoginSpec;
}

/**
 *
 */
export class AuthenticationApi extends runtime.BaseAPI {
  /**
   * Activates the specified user\'s account
   * Activate Account
   */
  async activateAccountRaw(
    requestParameters: ActivateAccountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["user"] == null) {
      throw new runtime.RequiredError(
        "user",
        'Required parameter "user" was null or undefined when calling activateAccount().',
      );
    }

    if (requestParameters["activateAccountSpec"] == null) {
      throw new runtime.RequiredError(
        "activateAccountSpec",
        'Required parameter "activateAccountSpec" was null or undefined when calling activateAccount().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/auth/activate/{user}`.replace(
          `{${"user"}}`,
          encodeURIComponent(String(requestParameters["user"])),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ActivateAccountSpecToJSON(
          requestParameters["activateAccountSpec"],
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Activates the specified user\'s account
   * Activate Account
   */
  async activateAccount(
    requestParameters: ActivateAccountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.activateAccountRaw(requestParameters, initOverrides);
  }

  /**
   * Creates a new user account.
   * Create Account
   */
  async createAccountRaw(
    requestParameters: CreateAccountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (requestParameters["createAccountSpec"] == null) {
      throw new runtime.RequiredError(
        "createAccountSpec",
        'Required parameter "createAccountSpec" was null or undefined when calling createAccount().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/auth/create`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CreateAccountSpecToJSON(requestParameters["createAccountSpec"]),
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get("content-type"))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Creates a new user account.
   * Create Account
   */
  async createAccount(
    requestParameters: CreateAccountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.createAccountRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Revokes the access for the specified third party app from the current user\'s account.
   * Delete Linked App
   */
  async deleteLinkedAppRaw(
    requestParameters: DeleteLinkedAppRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["consumerId"] == null) {
      throw new runtime.RequiredError(
        "consumerId",
        'Required parameter "consumerId" was null or undefined when calling deleteLinkedApp().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/auth/apps/{consumerId}`.replace(
          `{${"consumerId"}}`,
          encodeURIComponent(String(requestParameters["consumerId"])),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Revokes the access for the specified third party app from the current user\'s account.
   * Delete Linked App
   */
  async deleteLinkedApp(
    requestParameters: DeleteLinkedAppRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteLinkedAppRaw(requestParameters, initOverrides);
  }

  /**
   * Retrieves a list of the third-party apps linked to the current user\'s account.
   * List Linked Apps
   */
  async listLinkedApplicationsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: string }>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/auth/apps`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Retrieves a list of the third-party apps linked to the current user\'s account.
   * List Linked Apps
   */
  async listLinkedApplications(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: string }> {
    const response = await this.listLinkedApplicationsRaw(initOverrides);
    return await response.value();
  }

  /**
   * Authenticates a user, setting an authentication cookie in the response.
   * Login
   */
  async loginRaw(
    requestParameters: LoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["loginSpec"] == null) {
      throw new runtime.RequiredError(
        "loginSpec",
        'Required parameter "loginSpec" was null or undefined when calling login().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/auth/login`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: LoginSpecToJSON(requestParameters["loginSpec"]),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Authenticates a user, setting an authentication cookie in the response.
   * Login
   */
  async login(
    requestParameters: LoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.loginRaw(requestParameters, initOverrides);
  }

  /**
   * Signs out the specified user session, defaulting to the current one if none is specified.
   * Logout
   */
  async logoutRaw(
    requestParameters: LogoutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/auth/logout`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: LogoutSpecToJSON(requestParameters["logoutSpec"]),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Signs out the specified user session, defaulting to the current one if none is specified.
   * Logout
   */
  async logout(
    requestParameters: LogoutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.logoutRaw(requestParameters, initOverrides);
  }

  /**
   * Refreshes the current user session.
   * Ping Session
   */
  async pingSessionRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<boolean>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/auth/ping`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get("content-type"))) {
      return new runtime.JSONApiResponse<boolean>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Refreshes the current user session.
   * Ping Session
   */
  async pingSession(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<boolean> {
    const response = await this.pingSessionRaw(initOverrides);
    return await response.value();
  }

  /**
   * Refreshes the current user session, possibly updating the session cookie with a new ID.
   * Refresh Session
   */
  async refreshSessionRaw(
    requestParameters: RefreshSessionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["submitEmailUpdateSpec"] == null) {
      throw new runtime.RequiredError(
        "submitEmailUpdateSpec",
        'Required parameter "submitEmailUpdateSpec" was null or undefined when calling refreshSession().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/auth/refresh`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SubmitEmailUpdateSpecToJSON(
          requestParameters["submitEmailUpdateSpec"],
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Refreshes the current user session, possibly updating the session cookie with a new ID.
   * Refresh Session
   */
  async refreshSession(
    requestParameters: RefreshSessionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.refreshSessionRaw(requestParameters, initOverrides);
  }

  /**
   * Initiates the email update process by sending a link to the current user\'s email address.
   * Request Email Change
   */
  async requestEmailChangeRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/email/update`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Initiates the email update process by sending a link to the current user\'s email address.
   * Request Email Change
   */
  async requestEmailChange(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.requestEmailChangeRaw(initOverrides);
  }

  /**
   * Completes the email update process.
   * Submit Email Change
   */
  async submitEmailChangeRaw(
    requestParameters: SubmitEmailChangeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["token"] == null) {
      throw new runtime.RequiredError(
        "token",
        'Required parameter "token" was null or undefined when calling submitEmailChange().',
      );
    }

    if (requestParameters["submitEmailUpdateSpec"] == null) {
      throw new runtime.RequiredError(
        "submitEmailUpdateSpec",
        'Required parameter "submitEmailUpdateSpec" was null or undefined when calling submitEmailChange().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/email/update/{token}`.replace(
          `{${"token"}}`,
          encodeURIComponent(String(requestParameters["token"])),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SubmitEmailUpdateSpecToJSON(
          requestParameters["submitEmailUpdateSpec"],
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Completes the email update process.
   * Submit Email Change
   */
  async submitEmailChange(
    requestParameters: SubmitEmailChangeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.submitEmailChangeRaw(requestParameters, initOverrides);
  }

  /**
   * Retrieves the duration left to the current user session, without refreshing it.
   * Test Session
   */
  async testSessionRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<number>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/auth/ping`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get("content-type"))) {
      return new runtime.JSONApiResponse<number>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves the duration left to the current user session, without refreshing it.
   * Test Session
   */
  async testSession(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<number> {
    const response = await this.testSessionRaw(initOverrides);
    return await response.value();
  }

  /**
   * Confirms the email update token is still valid.
   * Validate Email Change
   */
  async validateEmailChangeRaw(
    requestParameters: ValidateEmailChangeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<boolean>> {
    if (requestParameters["token"] == null) {
      throw new runtime.RequiredError(
        "token",
        'Required parameter "token" was null or undefined when calling validateEmailChange().',
      );
    }

    if (requestParameters["submitEmailUpdateSpec"] == null) {
      throw new runtime.RequiredError(
        "submitEmailUpdateSpec",
        'Required parameter "submitEmailUpdateSpec" was null or undefined when calling validateEmailChange().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/email/validate/{token}`.replace(
          `{${"token"}}`,
          encodeURIComponent(String(requestParameters["token"])),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SubmitEmailUpdateSpecToJSON(
          requestParameters["submitEmailUpdateSpec"],
        ),
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get("content-type"))) {
      return new runtime.JSONApiResponse<boolean>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Confirms the email update token is still valid.
   * Validate Email Change
   */
  async validateEmailChange(
    requestParameters: ValidateEmailChangeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<boolean> {
    const response = await this.validateEmailChangeRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Re-authenticates the current user to confirm their identity, associating a secondary token with the request for use with finance requests.
   * Validate Session
   */
  async validateSessionRaw(
    requestParameters: ValidateSessionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    if (requestParameters["validateLoginSpec"] == null) {
      throw new runtime.RequiredError(
        "validateLoginSpec",
        'Required parameter "validateLoginSpec" was null or undefined when calling validateSession().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/auth/validate`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ValidateLoginSpecToJSON(requestParameters["validateLoginSpec"]),
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get("content-type"))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Re-authenticates the current user to confirm their identity, associating a secondary token with the request for use with finance requests.
   * Validate Session
   */
  async validateSession(
    requestParameters: ValidateSessionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<string> {
    const response = await this.validateSessionRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
