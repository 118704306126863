/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { Project } from "./Project";
import {
  ProjectFromJSON,
  ProjectFromJSONTyped,
  ProjectToJSON,
  ProjectToJSONTyped,
} from "./Project";

/**
 *
 * @export
 * @interface Folder
 */
export interface Folder {
  /**
   *
   * @type {Set<Folder>}
   * @memberof Folder
   */
  folders?: Set<Folder>;
  /**
   *
   * @type {string}
   * @memberof Folder
   */
  name?: string;
  /**
   *
   * @type {Set<any>}
   * @memberof Folder
   */
  files?: Set<any>;
  /**
   *
   * @type {Folder}
   * @memberof Folder
   */
  parent?: Folder;
  /**
   *
   * @type {string}
   * @memberof Folder
   */
  createdById?: string;
  /**
   *
   * @type {Project}
   * @memberof Folder
   */
  rootOf?: Project;
  /**
   *
   * @type {Project}
   * @memberof Folder
   */
  trashOf?: Project;
  /**
   *
   * @type {boolean}
   * @memberof Folder
   */
  deleted?: boolean;
  /**
   *
   * @type {number}
   * @memberof Folder
   */
  lastModified?: number;
  /**
   *
   * @type {number}
   * @memberof Folder
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof Folder
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof Folder
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof Folder
   */
  id?: string;
  /**
   *
   * @type {boolean}
   * @memberof Folder
   */
  root?: boolean;
  /**
   *
   * @type {Project}
   * @memberof Folder
   */
  project?: Project;
  /**
   *
   * @type {number}
   * @memberof Folder
   */
  totalSubfolderCount?: number;
  /**
   *
   * @type {number}
   * @memberof Folder
   */
  totalSubfileCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof Folder
   */
  trashCan?: boolean;
  /**
   *
   * @type {Set<string>}
   * @memberof Folder
   */
  allDescendantIDs?: Set<string>;
  /**
   *
   * @type {boolean}
   * @memberof Folder
   */
  allChildrenDeleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Folder
   */
  deletedDirect?: boolean;
}

/**
 * Check if a given object implements the Folder interface.
 */
export function instanceOfFolder(value: object): value is Folder {
  return true;
}

export function FolderFromJSON(json: any): Folder {
  return FolderFromJSONTyped(json, false);
}

export function FolderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Folder {
  if (json == null) {
    return json;
  }
  return {
    folders:
      json["folders"] == null
        ? undefined
        : new Set((json["folders"] as Array<any>).map(FolderFromJSON)),
    name: json["name"] == null ? undefined : json["name"],
    files: json["files"] == null ? undefined : new Set(json["files"]),
    parent: json["parent"] == null ? undefined : FolderFromJSON(json["parent"]),
    createdById: json["createdById"] == null ? undefined : json["createdById"],
    rootOf:
      json["rootOf"] == null ? undefined : ProjectFromJSON(json["rootOf"]),
    trashOf:
      json["trashOf"] == null ? undefined : ProjectFromJSON(json["trashOf"]),
    deleted: json["deleted"] == null ? undefined : json["deleted"],
    lastModified:
      json["lastModified"] == null ? undefined : json["lastModified"],
    size: json["size"] == null ? undefined : json["size"],
    totalSize: json["totalSize"] == null ? undefined : json["totalSize"],
    version: json["version"] == null ? undefined : json["version"],
    id: json["id"] == null ? undefined : json["id"],
    root: json["root"] == null ? undefined : json["root"],
    project:
      json["project"] == null ? undefined : ProjectFromJSON(json["project"]),
    totalSubfolderCount:
      json["totalSubfolderCount"] == null
        ? undefined
        : json["totalSubfolderCount"],
    totalSubfileCount:
      json["totalSubfileCount"] == null ? undefined : json["totalSubfileCount"],
    trashCan: json["trashCan"] == null ? undefined : json["trashCan"],
    allDescendantIDs:
      json["allDescendantIDs"] == null
        ? undefined
        : new Set(json["allDescendantIDs"]),
    allChildrenDeleted:
      json["allChildrenDeleted"] == null
        ? undefined
        : json["allChildrenDeleted"],
    deletedDirect:
      json["deletedDirect"] == null ? undefined : json["deletedDirect"],
  };
}

export function FolderToJSON(json: any): Folder {
  return FolderToJSONTyped(json, false);
}

export function FolderToJSONTyped(
  value?: Folder | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    folders:
      value["folders"] == null
        ? undefined
        : Array.from(value["folders"] as Set<any>).map(FolderToJSON),
    name: value["name"],
    files:
      value["files"] == null
        ? undefined
        : Array.from(value["files"] as Set<any>),
    parent: FolderToJSON(value["parent"]),
    createdById: value["createdById"],
    rootOf: ProjectToJSON(value["rootOf"]),
    trashOf: ProjectToJSON(value["trashOf"]),
    deleted: value["deleted"],
    lastModified: value["lastModified"],
    size: value["size"],
    totalSize: value["totalSize"],
    version: value["version"],
    id: value["id"],
    root: value["root"],
    project: ProjectToJSON(value["project"]),
    totalSubfolderCount: value["totalSubfolderCount"],
    totalSubfileCount: value["totalSubfileCount"],
    trashCan: value["trashCan"],
    allDescendantIDs:
      value["allDescendantIDs"] == null
        ? undefined
        : Array.from(value["allDescendantIDs"] as Set<any>),
    allChildrenDeleted: value["allChildrenDeleted"],
    deletedDirect: value["deletedDirect"],
  };
}
