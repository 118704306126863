import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Card } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  getActivityContent,
  getActivityState,
} from '@/lib/hooks/use-notification.hook';
import { useTranslation } from '@/lib/i18n';
import {
  Entity,
  getEntityNotifications,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { NotificationMessage } from '@oproma/prividox-orchestration-open-api';
import { formatDistanceToNow } from 'date-fns';
import {
  ArrowUpDown,
  Bell,
  CirclePlus,
  Clock,
  Copy,
  Download,
  Eye,
  FileUp,
  History,
  Loader2,
  Lock,
  MessageSquare,
  PenTool,
  RefreshCw,
  Tag,
  Trash2,
  Unlock,
  UserPlus,
} from 'lucide-react';
import { useEffect } from 'react';

interface ActivityStreamTabProps {
  entity: Entity;
}

export const ActivityStreamTab = ({ entity }: ActivityStreamTabProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { entityNotifications, loading } = useAppSelector(
    (state) => state.fileManager,
  );

  useEffect(() => {
    if (entity?.id) {
      dispatch(getEntityNotifications(entity.id));
    }
  }, [dispatch, entity?.id]);

  const activities = entityNotifications?.items || [];

  // Activity icon mapping
  const getActivityIcon = (activity: NotificationMessage) => {
    switch (activity.eventType) {
      case 'CREATED':
        return <FileUp className="h-4 w-4" />;
      case 'COMMENTED':
        return <MessageSquare className="h-4 w-4" />;
      case 'METADATA_SET':
      case 'METADATA_DELETED':
        return <Tag className="h-4 w-4" />;
      case 'RENAMED':
        return <PenTool className="h-4 w-4" />;
      case 'MOVED':
        return <ArrowUpDown className="h-4 w-4" />;
      case 'COPIED':
        return <Copy className="h-4 w-4" />;
      case 'RESTORED':
        return <RefreshCw className="h-4 w-4" />;
      case 'DOWNLOADED':
        return <Download className="h-4 w-4" />;
      case 'PREVIEWED':
        return <Eye className="h-4 w-4" />;
      case 'TRASHED':
        return <Trash2 className="h-4 w-4" />;
      case 'LOCKED':
        return <Lock className="h-4 w-4" />;
      case 'UNLOCKED':
        return <Unlock className="h-4 w-4" />;
      case 'CHILD_ADDED':
        return <CirclePlus className="h-4 w-4" />;
      case 'MEMBER_ADDED':
        return <UserPlus className="h-4 w-4" />;
      default:
        return <History className="h-4 w-4" />;
    }
  };

  // Get badge color based on activity type
  const getActivityBadgeColor = (activity: NotificationMessage) => {
    switch (activity.eventType) {
      case 'CREATED':
        return 'bg-green-100 text-green-800';
      case 'COMMENTED':
        return 'bg-blue-100 text-blue-800';
      case 'METADATA_SET':
      case 'METADATA_DELETED':
        return 'bg-purple-100 text-purple-800';
      case 'RENAMED':
        return 'bg-indigo-100 text-indigo-800';
      case 'MOVED':
        return 'bg-orange-100 text-orange-800';
      case 'COPIED':
        return 'bg-cyan-100 text-cyan-800';
      case 'RESTORED':
        return 'bg-emerald-100 text-emerald-800';
      case 'DOWNLOADED':
        return 'bg-teal-100 text-teal-800';
      case 'PREVIEWED':
        return 'bg-violet-100 text-violet-800';
      case 'TRASHED':
      case 'DELETED':
        return 'bg-red-100 text-red-800';
      case 'LOCKED':
      case 'UNLOCKED':
        return 'bg-amber-100 text-amber-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="flex flex-col h-full">
      {/* Activities List */}
      <ScrollArea className="flex-1">
        <div className="p-4 space-y-4">
          {loading ? (
            <div className="flex justify-center items-center py-8">
              <Loader2 className="w-6 h-6 animate-spin text-muted-foreground" />
            </div>
          ) : activities.length > 0 ? (
            activities.map((activity) => {
              // Use the provided hooks to parse the activity
              const state = getActivityState(activity);
              const { key } = getActivityContent(activity, state);
              const activityContent = t(
                `NOTIFICATION_BODIES.${key}`,
                key,
                state,
              );

              return (
                <ActivityItem
                  key={`${activity.entityId}-${activity.time}`}
                  activity={activity}
                  content={activityContent}
                  icon={getActivityIcon(activity)}
                  badgeClass={getActivityBadgeColor(activity)}
                />
              );
            })
          ) : (
            <div className="text-center py-8 text-muted-foreground">
              <Bell className="w-12 h-12 mx-auto mb-3 text-muted-foreground/50" />
              <p>No activity recorded for this file</p>
            </div>
          )}
        </div>
      </ScrollArea>
    </div>
  );
};

interface ActivityItemProps {
  activity: NotificationMessage;
  content: string;
  icon: React.ReactNode;
  badgeClass: string;
}

const ActivityItem = ({
  activity,
  content,
  icon,
  badgeClass,
}: ActivityItemProps) => {
  return (
    <div className="flex gap-4">
      <Avatar className="w-8 h-8">
        <AvatarImage src={undefined} />
        <AvatarFallback>
          {activity.agentName?.[0]?.toUpperCase() || 'U'}
        </AvatarFallback>
      </Avatar>

      <div className="flex-1 space-y-2">
        <Card className="p-3">
          <div className="space-y-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <span className="font-medium">{activity.agentName}</span>
                <Badge variant="outline" className={badgeClass}>
                  <span className="flex items-center gap-1">
                    {icon}
                    <span className="text-xs">{activity.eventType}</span>
                  </span>
                </Badge>
              </div>
            </div>

            <p className="text-sm text-muted-foreground">{content}</p>
          </div>
        </Card>

        {/* Activity Timestamp */}
        <div className="flex items-center text-xs text-muted-foreground">
          <Clock className="w-3 h-3 mr-1" />
          <span>
            {formatDistanceToNow(new Date(activity.time || Date.now()))} ago
          </span>
        </div>
      </div>
    </div>
  );
};
