import { useTranslation } from '@/lib/i18n';
import { moveEntity, useAppDispatch } from '@/lib/store';
import { useDrag, useDrop } from 'react-dnd';
import { toast } from 'sonner';

export const useEntityDragDrop = (
  entityId: string,
  isFolder: boolean,
  parentEntityId?: string,
) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [{ isDragging }, drag] = useDrag({
    type: 'entity',
    item: { id: entityId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'entity',
    drop: (item: { id: string }) => {
      const droppedEntityId = item.id;
      if (droppedEntityId === entityId) return;

      if (!droppedEntityId || !entityId) {
        toast.error(t('file-manager:FILE_MANAGER.TOASTS.LOCATION_MOVE_ERROR'));
        return;
      }

      dispatch(
        moveEntity({
          entity: droppedEntityId,
          destination: entityId,
          folderId: parentEntityId,
        }),
      );
    },
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop(),
      isOver: !!monitor.isOver(),
    }),
  });

  const dragDropRef = (element: HTMLElement | null) => {
    drag(element);
    drop(element);
  };

  return {
    isDragging,
    canDrop,
    isOver,
    dragDropRef,
  };
};
