/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { Project } from "./Project";
import {
  ProjectFromJSON,
  ProjectFromJSONTyped,
  ProjectToJSON,
  ProjectToJSONTyped,
} from "./Project";
import type { User } from "./User";
import {
  UserFromJSON,
  UserFromJSONTyped,
  UserToJSON,
  UserToJSONTyped,
} from "./User";

/**
 *
 * @export
 * @interface Group
 */
export interface Group {
  /**
   *
   * @type {Set<User>}
   * @memberof Group
   */
  members?: Set<User>;
  /**
   *
   * @type {Project}
   * @memberof Group
   */
  project?: Project;
  /**
   *
   * @type {string}
   * @memberof Group
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof Group
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof Group
   */
  id?: string;
}

/**
 * Check if a given object implements the Group interface.
 */
export function instanceOfGroup(value: object): value is Group {
  return true;
}

export function GroupFromJSON(json: any): Group {
  return GroupFromJSONTyped(json, false);
}

export function GroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Group {
  if (json == null) {
    return json;
  }
  return {
    members:
      json["members"] == null
        ? undefined
        : new Set((json["members"] as Array<any>).map(UserFromJSON)),
    project:
      json["project"] == null ? undefined : ProjectFromJSON(json["project"]),
    name: json["name"] == null ? undefined : json["name"],
    version: json["version"] == null ? undefined : json["version"],
    id: json["id"] == null ? undefined : json["id"],
  };
}

export function GroupToJSON(json: any): Group {
  return GroupToJSONTyped(json, false);
}

export function GroupToJSONTyped(
  value?: Group | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    members:
      value["members"] == null
        ? undefined
        : Array.from(value["members"] as Set<any>).map(UserToJSON),
    project: ProjectToJSON(value["project"]),
    name: value["name"],
    version: value["version"],
    id: value["id"],
  };
}
