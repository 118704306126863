/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ValueSpec
 */
export interface ValueSpec {
  /**
   * The value to set.
   * @type {string}
   * @memberof ValueSpec
   */
  value: string;
}

/**
 * Check if a given object implements the ValueSpec interface.
 */
export function instanceOfValueSpec(value: object): value is ValueSpec {
  if (!("value" in value) || value["value"] === undefined) return false;
  return true;
}

export function ValueSpecFromJSON(json: any): ValueSpec {
  return ValueSpecFromJSONTyped(json, false);
}

export function ValueSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ValueSpec {
  if (json == null) {
    return json;
  }
  return {
    value: json["value"],
  };
}

export function ValueSpecToJSON(json: any): ValueSpec {
  return ValueSpecToJSONTyped(json, false);
}

export function ValueSpecToJSONTyped(
  value?: ValueSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    value: value["value"],
  };
}
