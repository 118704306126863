/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DirectPermissionResult
 */
export interface DirectPermissionResult {
  /**
   *
   * @type {string}
   * @memberof DirectPermissionResult
   */
  entityId?: string;
  /**
   *
   * @type {string}
   * @memberof DirectPermissionResult
   */
  permission?: DirectPermissionResultPermissionEnum;
  /**
   *
   * @type {string}
   * @memberof DirectPermissionResult
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DirectPermissionResult
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof DirectPermissionResult
   */
  effective?: DirectPermissionResultEffectiveEnum;
  /**
   *
   * @type {string}
   * @memberof DirectPermissionResult
   */
  source?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum DirectPermissionResultPermissionEnum {
  INHERITED = "INHERITED",
  NO_ACCESS = "NO_ACCESS",
  VIEW = "VIEW",
  MODIFY = "MODIFY",
  FULL_CONTROL = "FULL_CONTROL",
}
/**
 * @export
 * @enum {string}
 */
export enum DirectPermissionResultEffectiveEnum {
  INHERITED = "INHERITED",
  NO_ACCESS = "NO_ACCESS",
  VIEW = "VIEW",
  MODIFY = "MODIFY",
  FULL_CONTROL = "FULL_CONTROL",
}

/**
 * Check if a given object implements the DirectPermissionResult interface.
 */
export function instanceOfDirectPermissionResult(
  value: object,
): value is DirectPermissionResult {
  return true;
}

export function DirectPermissionResultFromJSON(
  json: any,
): DirectPermissionResult {
  return DirectPermissionResultFromJSONTyped(json, false);
}

export function DirectPermissionResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DirectPermissionResult {
  if (json == null) {
    return json;
  }
  return {
    entityId: json["entityId"] == null ? undefined : json["entityId"],
    permission: json["permission"] == null ? undefined : json["permission"],
    name: json["name"] == null ? undefined : json["name"],
    email: json["email"] == null ? undefined : json["email"],
    effective: json["effective"] == null ? undefined : json["effective"],
    source: json["source"] == null ? undefined : json["source"],
  };
}

export function DirectPermissionResultToJSON(
  json: any,
): DirectPermissionResult {
  return DirectPermissionResultToJSONTyped(json, false);
}

export function DirectPermissionResultToJSONTyped(
  value?: DirectPermissionResult | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    entityId: value["entityId"],
    permission: value["permission"],
    name: value["name"],
    email: value["email"],
    effective: value["effective"],
    source: value["source"],
  };
}
