import { useAppSelector, WorkspaceMember } from "@/lib/store";
import { useCallback, useEffect, useState } from "react";

export const useMembersFiltering = (initialMembers: WorkspaceMember[]) => {
  const [filteredMembers, setFilteredMembers] = useState(initialMembers);
  const {
    memberQuery,
    memberSortOrder,
    memberSortValue,
    permissionLevelFilter,
  } = useAppSelector((state) => state.members);

  const isWorkspaceOwner = (member: WorkspaceMember) => {
    return member.accessLevel?.toString() === "127";
  };

  const processMembers = useCallback(() => {
    let result = [...initialMembers];

    // Apply search filter
    if (memberQuery) {
      result = result.filter(
        (member) =>
          member.name?.toLowerCase().includes(memberQuery.toLowerCase()) ||
          member.email?.toLowerCase().includes(memberQuery.toLowerCase())
      );
    }

    // Apply permission filter
    if (permissionLevelFilter) {
      result = result.filter(
        (member) =>
          member.accessLevel?.toString() === permissionLevelFilter.toString()
      );
    }

    // Apply sorting
    if (memberSortValue) {
      result.sort((a, b) => {
        if (memberSortValue === "workspaceOwner") {
          const aIsOwner = isWorkspaceOwner(a);
          const bIsOwner = isWorkspaceOwner(b);
          if (aIsOwner === bIsOwner && a.name && b.name) {
            return a.name.localeCompare(b.name);
          }
          return memberSortOrder === "asc"
            ? aIsOwner
              ? -1
              : 1
            : aIsOwner
              ? 1
              : -1;
        } else {
          const sortKey = memberSortValue as keyof WorkspaceMember;
          const valueA = a[sortKey];
          const valueB = b[sortKey];
          if (valueA == null || valueB == null) return 0;
          const compareResult = String(valueA).localeCompare(String(valueB));
          return memberSortOrder === "asc" ? compareResult : -compareResult;
        }
      });
    }

    return result;
  }, [
    initialMembers,
    memberQuery,
    permissionLevelFilter,
    memberSortOrder,
    memberSortValue,
  ]);

  useEffect(() => {
    setFilteredMembers(processMembers());
  }, [processMembers]);

  return { filteredMembers, setFilteredMembers };
};
