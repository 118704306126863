import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Entity,
  getExtractedMetadata,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { cn } from '@/lib/utils';
import {
  ArrowUpDown,
  Download,
  FileX,
  Loader2,
  ScanEye,
  Search,
  X,
} from 'lucide-react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

interface ExtractedMetadataTabProps {
  entity: Entity;
}

interface MetadataItem {
  key: string;
  value: string;
}

export const ExtractedMetadataTab = ({ entity }: ExtractedMetadataTabProps) => {
  const dispatch = useAppDispatch();
  const { loading, entityExtractedMetadata } = useAppSelector(
    (state) => state.fileManager,
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [metadataItems, setMetadataItems] = useState<MetadataItem[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'asc' | 'desc';
  }>({
    key: 'key',
    direction: 'asc',
  });

  useEffect(() => {
    if (entity?.id) {
      dispatch(getExtractedMetadata(entity.id));
    }
  }, [dispatch, entity?.id]);

  // Extract metadata when entityExtractedMetadata changes
  useEffect(() => {
    if (entityExtractedMetadata) {
      const items: MetadataItem[] = [];
      // Converting the metadata object to an array of key-value pairs
      Object.entries(entityExtractedMetadata).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          items.push({
            key,
            value:
              typeof value === 'object' ? JSON.stringify(value) : String(value),
          });
        }
      });
      setMetadataItems(items);
    }
  }, [entityExtractedMetadata]);

  const handleSort = (key: string) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === 'asc'
          ? 'desc'
          : 'asc',
    }));
  };

  const sortedMetadata = [...metadataItems]
    .filter((item) => {
      if (!searchTerm) return true;
      return (
        item.key.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.value.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .sort((a, b) => {
      if (sortConfig.key === 'key') {
        return sortConfig.direction === 'asc'
          ? a.key.localeCompare(b.key)
          : b.key.localeCompare(a.key);
      } else {
        return sortConfig.direction === 'asc'
          ? a.value.localeCompare(b.value)
          : b.value.localeCompare(a.value);
      }
    });

  const handleExportMetadata = () => {
    // Convert metadata to CSV
    const csvContent = [
      'Key,Value',
      ...metadataItems.map(
        (item) =>
          `"${item.key.replace(/"/g, '""')}","${item.value.replace(/"/g, '""')}"`,
      ),
    ].join('\n');

    // Create blob and download link
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${entity.name || 'file'}-extracted-metadata.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    toast.success('Extracted metadata exported successfully');
  };

  return (
    <div className="flex flex-col h-full max-h-[calc(100vh-150px)]">
      <div className="p-4 border-b flex flex-wrap justify-between items-center gap-4 sticky top-0 bg-background z-10">
        <div className="flex items-center gap-2">
          <ScanEye className="h-5 w-5 text-primary" />
          <h2 className="text-lg font-semibold">Extracted Metadata</h2>
          <Badge variant="outline" className="ml-2">
            {metadataItems.length}
          </Badge>
        </div>

        <div className="flex items-center gap-2">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search metadata"
              className="pl-9 w-[200px]"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm && (
              <Button
                variant="ghost"
                size="sm"
                className="absolute right-1 top-1/2 -translate-y-1/2 h-6 w-6 p-0"
                onClick={() => setSearchTerm('')}
              >
                <X className="h-4 w-4" />
              </Button>
            )}
          </div>

          <Button
            variant="outline"
            size="sm"
            onClick={handleExportMetadata}
            disabled={metadataItems.length === 0}
          >
            <Download className="h-4 w-4 mr-2" />
            Export
          </Button>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center py-8 flex-1">
          <Loader2 className="w-6 h-6 animate-spin text-primary" />
        </div>
      ) : metadataItems.length === 0 ? (
        <div className="flex flex-col items-center justify-center py-12 flex-1 text-muted-foreground">
          <FileX className="w-12 h-12 mb-4" />
          <p className="text-lg font-medium">No extracted metadata found</p>
          <p className="text-sm">
            This file type may not support metadata extraction
          </p>
        </div>
      ) : (
        <ScrollArea className="flex-1 h-full">
          <div className="p-4">
            <Card className="overflow-hidden">
              <div className="overflow-auto max-h-[calc(100vh-250px)]">
                <Table>
                  <TableHeader className="sticky top-0 bg-muted z-10">
                    <TableRow>
                      <TableHead
                        onClick={() => handleSort('key')}
                        className="cursor-pointer whitespace-nowrap"
                      >
                        <div className="flex items-center">
                          Key
                          <ArrowUpDown
                            className={cn(
                              'ml-2 h-4 w-4',
                              sortConfig.key === 'key' &&
                                sortConfig.direction === 'desc' &&
                                'rotate-180 transform',
                            )}
                          />
                        </div>
                      </TableHead>
                      <TableHead
                        onClick={() => handleSort('value')}
                        className="cursor-pointer whitespace-nowrap"
                      >
                        <div className="flex items-center">
                          Value
                          <ArrowUpDown
                            className={cn(
                              'ml-2 h-4 w-4',
                              sortConfig.key === 'value' &&
                                sortConfig.direction === 'desc' &&
                                'rotate-180 transform',
                            )}
                          />
                        </div>
                      </TableHead>
                      <TableHead className="w-[100px] text-right">
                        Actions
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {sortedMetadata.map((item) => (
                      <TableRow key={item.key}>
                        <TableCell className="font-medium whitespace-nowrap">
                          {item.key}
                        </TableCell>
                        <TableCell className="max-w-md">
                          <div className="max-w-md truncate" title={item.value}>
                            {item.value}
                          </div>
                        </TableCell>
                        <TableCell className="text-right">
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button
                                variant="ghost"
                                size="sm"
                                className="h-8 w-8 p-0"
                              >
                                <span className="sr-only">Open menu</span>
                                <ArrowUpDown className="h-4 w-4" />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                              <DropdownMenuItem
                                onClick={() => {
                                  navigator.clipboard.writeText(item.value);
                                  toast.success('Value copied to clipboard');
                                }}
                              >
                                Copy value
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() => {
                                  navigator.clipboard.writeText(item.key);
                                  toast.success('Key copied to clipboard');
                                }}
                              >
                                Copy key
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Card>
          </div>
        </ScrollArea>
      )}
    </div>
  );
};
