import { SecretCodeResponse } from "@oproma/prividox-orchestration-open-api";

export enum UserErrorCodesEnum {
  EDIT_NAME_FAILED = "EDIT_NAME_FAILED",
  EDIT_PHONE_FAILED = "EDIT_PHONE_FAILED",
  EDIT_TAGLINE_FAILED = "EDIT_TAGLINE_FAILED",
  EDIT_LOCATION_FAILED = "EDIT_LOCATION_FAILED",
  EDIT_PASSWORD_FAILED = "EDIT_PASSWORD_FAILED",
  EDIT_TIME_FORMAT_FAILED = "EDIT_TIME_FORMAT_FAILED",
  EDIT_DATE_FORMAT_FAILED = "EDIT_DATE_FORMAT_FAILED",
  USER_UNSUBSCRIBE_FAILED = "USER_UNSUBSCRIBE_FAILED",
  USER_SUBSCRIPTION_FAILED = "USER_SUBSCRIPTION_FAILED",
  GET_USER_METADATA_FAILED = "GET_USER_METADATA_FAILED",
  GET_USER_SESSIONS_FAILED = "GET_USER_SESSIONS_FAILED",
  EDIT_SYSTEM_LANGUAGE_FAILED = "EDIT_SYSTEM_LANGUAGE_FAILED",
  GET_MFA_BACKUP_CODES_FAILED = "GET_MFA_BACKUP_CODES_FAILED ",
  VALIDATE_MFA_CODE_FAILED = "VALIDATE_MFA_CODE_FAILED",
  EDIT_EMAIL_NOTIFICATION_PROVIDER_FAILED = "EDIT_EMAIL_NOTIFICATION_PROVIDER_FAILED",
}

// Utility type
type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export interface UserResponseSpec {
  subscribed: boolean;
  "releaseNotes.versionCollapsed": string;
  "app.settings.theme": string;
  "app.settings.timeFormat": string;
  "app.settings.dateFormat": string;
  presence: boolean;
  address: string;
  phone: string;
  "app.settings.collapseNavbar": string;
  name: string;
  tagline: string;
  id: string;
  type: string;
  email: string;
  lang: string;
  enhancedSecurity: boolean;
}

export interface IUserState extends Nullable<UserResponseSpec> {
  readonly loading: boolean;
  readonly changedPassword: boolean;
  readonly timeFormat: string;
  readonly changedPhone: boolean;
  readonly pinnedWorkspaces: string[];
  readonly taskColumns: string[];
  readonly sessions: Record<string, Record<string, string>>;
  readonly mfa: SecretCodeResponse | null;
  readonly validMFACode: boolean | null;
  readonly changedEmailNotificationProvider: boolean;
  readonly changedSystemLanguage: boolean;
  readonly changedLocation: boolean;
  readonly changedTagline: boolean;
  readonly changedDateFormat: boolean;
  readonly changedTimeFormat: boolean;
  readonly changedName: boolean;
  readonly error: {
    code: UserErrorCodesEnum;
    message: string;
  } | null;
}
