/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 * Object describing the current search query.
 * @export
 * @interface QuerySpec
 */
export interface QuerySpec {
  /**
   * The query as entered by the user.
   * @type {string}
   * @memberof QuerySpec
   */
  q: string;
  /**
   * The ID of the workspace within which to perform the search.
   * @type {string}
   * @memberof QuerySpec
   */
  p: string;
  /**
   * The ID of the folder within which to search.
   * @type {string}
   * @memberof QuerySpec
   */
  f: string;
  /**
   * The language in which to perform the search. One of 'en' or 'fr'.
   * @type {string}
   * @memberof QuerySpec
   */
  lang: string;
  /**
   * Whether the search should include descendents.
   * @type {boolean}
   * @memberof QuerySpec
   */
  recursive?: boolean;
  /**
   * The filters to apply to the query.
   * @type {{ [key: string]: string; }}
   * @memberof QuerySpec
   */
  fq: { [key: string]: string };
  /**
   * The list of entities to exclude when performing a bulk operations.
   * @type {Array<string>}
   * @memberof QuerySpec
   */
  not?: Array<string>;
}

/**
 * Check if a given object implements the QuerySpec interface.
 */
export function instanceOfQuerySpec(value: object): value is QuerySpec {
  if (!("q" in value) || value["q"] === undefined) return false;
  if (!("p" in value) || value["p"] === undefined) return false;
  if (!("f" in value) || value["f"] === undefined) return false;
  if (!("lang" in value) || value["lang"] === undefined) return false;
  if (!("fq" in value) || value["fq"] === undefined) return false;
  return true;
}

export function QuerySpecFromJSON(json: any): QuerySpec {
  return QuerySpecFromJSONTyped(json, false);
}

export function QuerySpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): QuerySpec {
  if (json == null) {
    return json;
  }
  return {
    q: json["q"],
    p: json["p"],
    f: json["f"],
    lang: json["lang"],
    recursive: json["recursive"] == null ? undefined : json["recursive"],
    fq: json["fq"],
    not: json["not"] == null ? undefined : json["not"],
  };
}

export function QuerySpecToJSON(json: any): QuerySpec {
  return QuerySpecToJSONTyped(json, false);
}

export function QuerySpecToJSONTyped(
  value?: QuerySpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    q: value["q"],
    p: value["p"],
    f: value["f"],
    lang: value["lang"],
    recursive: value["recursive"],
    fq: value["fq"],
    not: value["not"],
  };
}
