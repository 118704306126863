/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { ProjectInformation } from "./ProjectInformation";
import {
  ProjectInformationFromJSON,
  ProjectInformationFromJSONTyped,
  ProjectInformationToJSON,
  ProjectInformationToJSONTyped,
} from "./ProjectInformation";

/**
 *
 * @export
 * @interface ResultProjectInformation
 */
export interface ResultProjectInformation {
  /**
   *
   * @type {Array<ProjectInformation>}
   * @memberof ResultProjectInformation
   */
  items?: Array<ProjectInformation>;
}

/**
 * Check if a given object implements the ResultProjectInformation interface.
 */
export function instanceOfResultProjectInformation(
  value: object,
): value is ResultProjectInformation {
  return true;
}

export function ResultProjectInformationFromJSON(
  json: any,
): ResultProjectInformation {
  return ResultProjectInformationFromJSONTyped(json, false);
}

export function ResultProjectInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultProjectInformation {
  if (json == null) {
    return json;
  }
  return {
    items:
      json["items"] == null
        ? undefined
        : (json["items"] as Array<any>).map(ProjectInformationFromJSON),
  };
}

export function ResultProjectInformationToJSON(
  json: any,
): ResultProjectInformation {
  return ResultProjectInformationToJSONTyped(json, false);
}

export function ResultProjectInformationToJSONTyped(
  value?: ResultProjectInformation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    items:
      value["items"] == null
        ? undefined
        : (value["items"] as Array<any>).map(ProjectInformationToJSON),
  };
}
