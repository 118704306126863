import { useTasksBoardDrag } from "@/lib/hooks/use-tasks-board-drag.hook";
import { useAppSelector } from "@/lib/store";
import { useParams } from "@tanstack/react-router";
import { motion } from "framer-motion";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { TasksBoardColumn } from "./tasks-board-column.component";

export const TasksBoard = () => {
  const { workspaceId } = useParams({ strict: false });
  const { columnOrder, columns, tasks } = useAppSelector(
    (state) => state.tasks
  );
  const { handleDragEnd } = useTasksBoardDrag(
    columns,
    columnOrder,
    workspaceId
  );

  return (
    <div className="p-6">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mb-6"
      >
        <h1 className="text-2xl font-semibold text-gray-900">Task Board</h1>
        <p className="text-sm text-gray-500">
          Manage and organize your team's tasks
        </p>
      </motion.div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable
          droppableId="all-columns"
          direction="horizontal"
          type="column"
        >
          {(provided) => (
            <div
              className="flex gap-6 overflow-x-auto pb-6 min-h-[calc(100vh-12rem)]"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {columnOrder.map((columnId, index) => {
                const column = columns.find((col) => col.id === columnId);
                if (!column) return null;

                return (
                  <TasksBoardColumn
                    key={column.id}
                    column={column}
                    tasks={tasks}
                    index={index}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
