import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import authEn from "./translations/auth/en.json";
import authFr from "./translations/auth/fr.json";
import commonEn from "./translations/common/en.json";
import commonFr from "./translations/common/fr.json";
// TODO: move translations to each own file
import en from "./translations/en.json";
import fileManagerEn from "./translations/file-manager/en.json";
import fileManagerFr from "./translations/file-manager/fr.json";
import footerEn from "./translations/footer/en.json";
import footerFr from "./translations/footer/fr.json";
import fr from "./translations/fr.json";
import onboardEn from "./translations/onboard/en.json";
import onboardFr from "./translations/onboard/fr.json";
import resetEmailEn from "./translations/reset-email/en.json";
import resetEmailFr from "./translations/reset-email/fr.json";

export const NAMESPACES = {
  DEFAULT: "translation",
  COMMON: "common",
  AUTH: "auth",
  ONBOARD: "onboard",
  FOOTER: "footer",
  RESET_EMAIL: "reset-email",
  FILE_MANAGER: "file-manager",
} as const;

export type TranslationNamespace = (typeof NAMESPACES)[keyof typeof NAMESPACES];

export const resources = {
  "en-US": {
    [NAMESPACES.COMMON]: commonEn,
    [NAMESPACES.AUTH]: authEn,
    [NAMESPACES.DEFAULT]: en,
    [NAMESPACES.ONBOARD]: onboardEn,
    [NAMESPACES.FOOTER]: footerEn,
    [NAMESPACES.RESET_EMAIL]: resetEmailEn,
    [NAMESPACES.FILE_MANAGER]: fileManagerEn,
  },
  "fr-FR": {
    [NAMESPACES.COMMON]: commonFr,
    [NAMESPACES.AUTH]: authFr,
    [NAMESPACES.DEFAULT]: fr,
    [NAMESPACES.ONBOARD]: onboardFr,
    [NAMESPACES.FOOTER]: footerFr,
    [NAMESPACES.RESET_EMAIL]: resetEmailFr,
    [NAMESPACES.FILE_MANAGER]: fileManagerFr,
  },
} as const;

export type CompatibleLanguages = keyof typeof resources;

const i18nConfig = {
  resources,
  fallbackLng: "en-US" as CompatibleLanguages,
  defaultNS: NAMESPACES.COMMON,
  ns: Object.values(NAMESPACES),
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ["localStorage", "navigator"],
    caches: ["localStorage"],
  },
  react: {
    useSuspense: true,
  },
};
export const isCompatibleLanguage = (
  lang: string
): lang is CompatibleLanguages => {
  return lang in resources;
};

// Helper function to get available languages
export const fetchCompatibleLanguages = (): CompatibleLanguages[] => {
  return Object.keys(resources) as CompatibleLanguages[];
};

export const registerTranslations = async () => {
  await i18n.use(LanguageDetector).use(initReactI18next).init(i18nConfig);

  return i18n;
};

export { t } from "i18next";
export * from "./hooks";
