/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { TaskInformation } from "./TaskInformation";
import {
  TaskInformationFromJSON,
  TaskInformationFromJSONTyped,
  TaskInformationToJSON,
  TaskInformationToJSONTyped,
} from "./TaskInformation";

/**
 *
 * @export
 * @interface ResultTaskInformation
 */
export interface ResultTaskInformation {
  /**
   *
   * @type {Array<TaskInformation>}
   * @memberof ResultTaskInformation
   */
  items?: Array<TaskInformation>;
}

/**
 * Check if a given object implements the ResultTaskInformation interface.
 */
export function instanceOfResultTaskInformation(
  value: object,
): value is ResultTaskInformation {
  return true;
}

export function ResultTaskInformationFromJSON(
  json: any,
): ResultTaskInformation {
  return ResultTaskInformationFromJSONTyped(json, false);
}

export function ResultTaskInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultTaskInformation {
  if (json == null) {
    return json;
  }
  return {
    items:
      json["items"] == null
        ? undefined
        : (json["items"] as Array<any>).map(TaskInformationFromJSON),
  };
}

export function ResultTaskInformationToJSON(json: any): ResultTaskInformation {
  return ResultTaskInformationToJSONTyped(json, false);
}

export function ResultTaskInformationToJSONTyped(
  value?: ResultTaskInformation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    items:
      value["items"] == null
        ? undefined
        : (value["items"] as Array<any>).map(TaskInformationToJSON),
  };
}
