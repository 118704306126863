/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 * The set of redactions to apply to the document
 * @export
 * @interface Redaction
 */
export interface Redaction {
  /**
   *
   * @type {number}
   * @memberof Redaction
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof Redaction
   */
  top?: number;
  /**
   *
   * @type {number}
   * @memberof Redaction
   */
  left?: number;
  /**
   *
   * @type {number}
   * @memberof Redaction
   */
  width?: number;
  /**
   *
   * @type {number}
   * @memberof Redaction
   */
  height?: number;
  /**
   *
   * @type {string}
   * @memberof Redaction
   */
  user?: string;
  /**
   *
   * @type {string}
   * @memberof Redaction
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Redaction
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof Redaction
   */
  notes?: string;
}

/**
 * Check if a given object implements the Redaction interface.
 */
export function instanceOfRedaction(value: object): value is Redaction {
  return true;
}

export function RedactionFromJSON(json: any): Redaction {
  return RedactionFromJSONTyped(json, false);
}

export function RedactionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Redaction {
  if (json == null) {
    return json;
  }
  return {
    page: json["page"] == null ? undefined : json["page"],
    top: json["top"] == null ? undefined : json["top"],
    left: json["left"] == null ? undefined : json["left"],
    width: json["width"] == null ? undefined : json["width"],
    height: json["height"] == null ? undefined : json["height"],
    user: json["user"] == null ? undefined : json["user"],
    name: json["name"] == null ? undefined : json["name"],
    type: json["type"] == null ? undefined : json["type"],
    notes: json["notes"] == null ? undefined : json["notes"],
  };
}

export function RedactionToJSON(json: any): Redaction {
  return RedactionToJSONTyped(json, false);
}

export function RedactionToJSONTyped(
  value?: Redaction | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    page: value["page"],
    top: value["top"],
    left: value["left"],
    width: value["width"],
    height: value["height"],
    user: value["user"],
    name: value["name"],
    type: value["type"],
    notes: value["notes"],
  };
}
