import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardHeader } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import {
  copyEntity,
  EntityActionsEnum,
  FileWithPath,
  setDisplayCreateFileModal,
  setDisplayCreateFolderModal,
  setEntityQuery,
  setQueuedFiles,
  toggleDisplayMobileNavbar,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { useLocation, useParams } from '@tanstack/react-router';
import { FolderPlus, Menu, Plus, Search } from 'lucide-react';
import { useEffect, useLayoutEffect } from 'react';
import { toast } from 'sonner';
import { fetchFilesRecursively } from './dropzone.util';
import { FileManagerDisplayTypeFilter } from './file-manager-display-type-filter.component';
import { FileManagerFiles } from './file-manager-files.component';

interface FileManagerNavigationLayoutProps {
  title?: React.ReactNode;
  entityFinder?: boolean;
  displayTypeFilter?: boolean;
  children?: React.ReactNode;
}

export const FileManagerNavigationLayout = ({
  title,
  entityFinder,
  displayTypeFilter,
  children,
}: FileManagerNavigationLayoutProps) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { entityId } = useParams({
    strict: false,
  });

  const { entities, entityQuery, bulkAction, queuedFiles } = useAppSelector(
    (state) => state.fileManager,
  );

  const isTrashPage = pathname.includes('/trash');

  const handleCreateFolder = () => dispatch(setDisplayCreateFolderModal(true));
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEntityQuery(event.target.value));
  };

  // Paste handler
  useEffect(() => {
    if (bulkAction) return;

    const handlePaste = (ev: ClipboardEvent) => {
      const pastedContent = ev.clipboardData?.getData('text');
      if (entityId && pastedContent) {
        const urlSearchParams = new URLSearchParams(pastedContent);
        const action = urlSearchParams.get('action');
        const entity = urlSearchParams.get('entity');

        if (action === EntityActionsEnum.COPY && entity) {
          dispatch(copyEntity({ entity, destination: entityId }));
          toast.success('Entity pasted successfully');
        }
      } else {
        toast.error('Invalid paste action');
      }
    };

    window.addEventListener('paste', handlePaste);
    return () => window.removeEventListener('paste', handlePaste);
  }, [entityId, dispatch]);

  // Drag and drop handler
  useEffect(() => {
    const handleDragOver = (event: DragEvent) => event.preventDefault();

    const handleDrop = async (evt: DragEvent) => {
      evt.preventDefault();
      if (!evt.dataTransfer?.items) return;

      const files: FileWithPath[] = await fetchFilesRecursively(
        evt.dataTransfer.items,
      );

      // Filter for unique files
      const uniqueFiles = files.filter(
        (file) => !queuedFiles.some((queued) => queued.name === file.name),
      );

      if (uniqueFiles.length > 0) {
        dispatch(setDisplayCreateFileModal(true));
        dispatch(setQueuedFiles([...queuedFiles, ...uniqueFiles]));
      }
    };

    document.body.addEventListener('dragover', handleDragOver);
    document.body.addEventListener('drop', handleDrop);

    return () => {
      document.body.removeEventListener('dragover', handleDragOver);
      document.body.removeEventListener('drop', handleDrop);
    };
  }, [queuedFiles]);

  // Reset query on mount
  useLayoutEffect(() => {
    dispatch(setEntityQuery(''));
  }, []);

  // Filter entities based on search
  const filteredEntities = entities.filter((item) =>
    item.name?.toLowerCase().includes(entityQuery.toLowerCase()),
  );

  return (
    <div className="space-y-4">
      {/* Header Section */}
      <Card>
        <CardHeader className="p-4">
          <div className="flex items-center justify-between gap-4">
            {/* Search Bar */}
            {entityFinder && (
              <div className="flex-1 relative">
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                <Input
                  placeholder="Find files and folders"
                  value={entityQuery}
                  onChange={handleSearch}
                  className="pl-9"
                />
              </div>
            )}

            {/* Desktop Actions */}
            {!isTrashPage && (
              <div className="hidden md:flex items-center gap-2">
                <Button
                  variant="outline"
                  onClick={handleCreateFolder}
                  className="flex items-center gap-2"
                >
                  <FolderPlus className="h-4 w-4" />
                  Create Folder
                </Button>
              </div>
            )}

            {/* Mobile Actions */}
            <div className="flex md:hidden items-center gap-2">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => dispatch(toggleDisplayMobileNavbar())}
              >
                <Menu className="h-5 w-5" />
              </Button>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="icon">
                    <Plus className="h-5 w-5" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem onClick={handleCreateFolder}>
                    <FolderPlus className="h-4 w-4 mr-2" />
                    Create Folder
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </CardHeader>
      </Card>

      {/* Content Section */}
      <div className="space-y-4">
        {/* Title and Filters */}
        <div className="flex items-center justify-between">
          <div>
            {entityQuery === '' ? (
              title
            ) : (
              <div className="flex items-center gap-2">
                <h2 className="text-lg font-semibold">Search Results</h2>
                <Badge variant="secondary">{entityQuery}</Badge>
              </div>
            )}
          </div>

          {/* Display Type Toggle */}
          {(displayTypeFilter || entityQuery !== '') && (
            <FileManagerDisplayTypeFilter />
          )}
        </div>

        {/* Content */}
        {entityQuery === '' ? (
          children
        ) : (
          <FileManagerFiles entities={filteredEntities} />
        )}
      </div>
    </div>
  );
};
