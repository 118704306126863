import { Book, ExternalLink } from "lucide-react";
import { Button } from "../ui/button";

export const ChangelogBanner = () => {
  return (
    <div className="bg-gradient-to-r from-blue-50 to-blue-100 dark:from-blue-900/20 dark:to-blue-900/10 border border-blue-200/30 rounded-lg py-2 px-4 flex items-center justify-between mr-4">
      <div className="flex items-center gap-2">
        <Book className="h-4 w-4 text-blue-500" />
        <span className="text-sm">
          Checkout the knowledge base for Prividox!{" "}
          <span className="text-muted-foreground">
            Browse the latest tutorials and guides
          </span>
        </span>
      </div>
      <a
        href="https://kb.prividox.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button variant="ghost" size="icon" className="h-6 w-6">
          <ExternalLink className="h-4 w-4" />
        </Button>
      </a>
    </div>
  );
};
