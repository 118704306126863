/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ErrorsSpec
 */
export interface ErrorsSpec {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ErrorsSpec
   */
  errors?: { [key: string]: string };
}

/**
 * Check if a given object implements the ErrorsSpec interface.
 */
export function instanceOfErrorsSpec(value: object): value is ErrorsSpec {
  return true;
}

export function ErrorsSpecFromJSON(json: any): ErrorsSpec {
  return ErrorsSpecFromJSONTyped(json, false);
}

export function ErrorsSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ErrorsSpec {
  if (json == null) {
    return json;
  }
  return {
    errors: json["errors"] == null ? undefined : json["errors"],
  };
}

export function ErrorsSpecToJSON(json: any): ErrorsSpec {
  return ErrorsSpecToJSONTyped(json, false);
}

export function ErrorsSpecToJSONTyped(
  value?: ErrorsSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    errors: value["errors"],
  };
}
