import {
  GetGroupsRequest,
  NameSpec,
} from "@oproma/prividox-orchestration-open-api";
import { groupsApi } from "./config";

const fetchWorkspaceGroups = async (payload: GetGroupsRequest) => {
  const response = await groupsApi.getGroups(payload);
  return response;
};

const fetchGroupMembers = async (group: string) => {
  const response = await groupsApi.getGroupMembers({
    group,
  });
  return response;
};

const createGroupMember = async (group: string, body: string) => {
  const response = await groupsApi.addGroupMember({
    group,
    body,
  });
  return response;
};

const deleteGroupMember = async (group: string, user: string) => {
  const response = await groupsApi.removeGroupMember({
    group,
    user,
  });
  return response;
};

const deleteGroup = async (workspaceId: string, group: string) => {
  const response = await groupsApi.deleteGroup({
    workspaceId,
    group,
  });
  return response;
};

const renameGroup = async (group: string, nameSpec: NameSpec) => {
  const response = await groupsApi.renameGroupRaw({
    group,
    nameSpec,
  });
  return response.raw.ok;
};

const patchDefaultGroup = async (workspaceId: string, body: string) => {
  const response = await groupsApi.setDefaultGroup({
    workspaceId,
    body,
  });
  return response;
};

const createGroup = async (workspaceId: string, nameSpec: NameSpec) => {
  const response = await groupsApi.createGroup({
    workspaceId,
    nameSpec,
  });
  return response;
};

export const groupsProvider = {
  fetchWorkspaceGroups,
  fetchGroupMembers,
  deleteGroupMember,
  renameGroup,
  deleteGroup,
  createGroupMember,
  patchDefaultGroup,
  createGroup,
};
