import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Progress } from '@/components/ui/progress';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import {
  setDisplayMobileNavbar,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { cn, prettyBytes } from '@/lib/utils';
import { Link, useLocation, useParams } from '@tanstack/react-router';
import {
  CreditCard,
  FolderOpen,
  HardDrive,
  LayoutDashboard,
  Settings,
  Star,
  Trash2,
} from 'lucide-react';
import { useRef } from 'react';
import { FileTree } from './async-file-tree.component';

// File Manager Container
export const FileManagerContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="container mx-auto p-6">
      <div className="grid grid-cols-12 gap-6">
        {/* Sidebar */}
        <div className="col-span-12 lg:col-span-3">
          <FileManagerNavbar />
        </div>

        {/* Main Content */}
        <div className="col-span-12 lg:col-span-9">
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

// Navbar Component
export const FileManagerNavbar = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { workspaceId } = useParams({
    strict: false,
  });
  const navbarRef = useRef<HTMLDivElement>(null);

  const { plan, diskUsage, metadata } = useAppSelector(
    (state) => state.workspace,
  );
  const { displayMobileNavbar } = useAppSelector((state) => state.fileManager);

  const navItems = [
    {
      id: 'dashboard',
      icon: <LayoutDashboard className="h-4 w-4" />,
      text: 'Explore',
      link: '',
    },
    {
      id: 'home',
      icon: <FolderOpen className="h-4 w-4" />,
      text: 'Files & Folders',
      link: 'entities',
    },
    {
      id: 'starred',
      icon: <Star className="h-4 w-4" />,
      text: 'Starred',
      link: 'starred',
    },
    {
      id: 'trash',
      icon: <Trash2 className="h-4 w-4" />,
      text: 'Trash',
      link: 'trash',
    },
  ];

  const usedStorage = diskUsage?.size ?? 0;
  const totalStorage = metadata?.['bytes-limit'] ?? 0;
  const storagePercentage = (usedStorage / totalStorage) * 100;

  console.log(storagePercentage);

  const NavContent = () => (
    <div className="space-y-6" ref={navbarRef}>
      {/* Navigation Items */}
      <Card>
        <CardContent className="p-3">
          <nav className="space-y-1">
            {navItems.map((item) => {
              const isActive =
                item.link === ''
                  ? pathname === `/${workspaceId}/file-manager` ||
                    pathname === `/${workspaceId}/file-manager/`
                  : pathname.includes(
                      `/${workspaceId}/file-manager/${item.link}`,
                    );

              return (
                <Link
                  key={item.id}
                  to={`/${workspaceId}/file-manager/${item.link}`}
                  className={cn(
                    'flex items-center gap-3 rounded-lg px-3 py-2 text-sm transition-all hover:bg-accent',
                    isActive
                      ? 'bg-accent text-accent-foreground'
                      : 'text-muted-foreground',
                  )}
                >
                  {item.icon}
                  <span>{item.text}</span>
                </Link>
              );
            })}
          </nav>
        </CardContent>
      </Card>

      <FileTree />

      {/* Storage Section */}
      <Card>
        <CardHeader>
          <CardTitle className="text-sm flex items-center gap-2">
            <HardDrive className="h-4 w-4" />
            <span className="font-normal">Storage</span>
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <Progress
            value={storagePercentage}
            max={100}
            className="h-2 w-full bg-gray-200"
            indicatorClassName="bg-primary"
          />

          <div className="text-sm text-muted-foreground">
            {prettyBytes(usedStorage)} of {prettyBytes(totalStorage)}
          </div>
          <Link
            to={`/${workspaceId}/options`}
            className="inline-flex items-center gap-1 text-sm  hover:text-primary/80"
          >
            <Settings className="h-3 w-3" />
            Edit Storage
          </Link>
        </CardContent>
      </Card>

      {/* Plan Information */}
      <Card>
        <CardHeader className="pb-2">
          <CardTitle className="text-sm flex items-center gap-2">
            <CreditCard className="h-4 w-4" />
            <span className="font-normal">Plan</span>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-1">
            <div className="flex items-center gap-2">
              <span className="font-medium">
                {plan?.meta?.['package-i18n-name'] ?? 'Plan Not Found'}
              </span>
            </div>
            <p className="text-xs text-muted-foreground">
              {plan?.meta?.['package-i18n-desc'] ??
                'Please refresh to load plan details'}
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  return (
    <>
      {/* Desktop Navigation */}
      <div className="hidden lg:block sticky top-6">
        <NavContent />
      </div>

      {/* Mobile Navigation */}
      <Sheet
        open={displayMobileNavbar}
        onOpenChange={(open) => dispatch(setDisplayMobileNavbar(open))}
      >
        <SheetContent side="left" className="w-80">
          <SheetHeader>
            <SheetTitle>File Manager</SheetTitle>
          </SheetHeader>
          <NavContent />
        </SheetContent>
      </Sheet>
    </>
  );
};
