import { AppLayout } from '@/components/app-layout.component';
import { AuthCheck } from '@/components/auth-check.component';
import { Head } from '@/components/head.component';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { TasksBoard } from '@/containers/$workspaceId/tasks/tasks-board.component';
import { NAMESPACES, useTranslation } from '@/lib/i18n';
import {
  getTasks,
  getWorkspaceEntities,
  getWorkspaceMembers,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { motion } from 'framer-motion';
import { Kanban, LayoutGrid, Pencil, Plus, Users } from 'lucide-react';
import { useEffect, useState } from 'react';

export const Route = createFileRoute('/$workspaceId/tasks')({
  component: TasksPage,
});

function TasksPage() {
  const { t } = useTranslation(NAMESPACES.COMMON);
  const dispatch = useAppDispatch();
  const { workspaceId } = useParams({ strict: false });
  const [createTaskOpen, setCreateTaskOpen] = useState(false);
  const [createColumnOpen, setCreateColumnOpen] = useState(false);

  const { tasks, columns } = useAppSelector((state) => state.tasks);
  const { members } = useAppSelector((state) => state.members);

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(getTasks(workspaceId));
    dispatch(getWorkspaceMembers(workspaceId));
    dispatch(getWorkspaceEntities(workspaceId));
  }, [workspaceId, dispatch]);

  return (
    <>
      <Head heading={t('common:COMMON.TASKS')} />
      <AuthCheck>
        <AppLayout>
          <div className="space-y-6 p-6">
            {/* Stats Grid */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              {[
                {
                  title: 'Tasks',
                  value: tasks.length,
                  icon: Pencil,
                },
                {
                  title: 'Categories',
                  value: columns.length,
                  icon: LayoutGrid,
                },
                {
                  title: 'Boards',
                  value: columns.filter((c) => c.tasks.length > 0).length,
                  icon: Kanban,
                },
                {
                  title: 'Members',
                  value: members.length,
                  icon: Users,
                },
              ].map((stat, index) => (
                <motion.div
                  key={stat.title}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="p-6 rounded-2xl  border border-gray-200 shadow-sm"
                >
                  <div className="flex items-center gap-4">
                    <div className={`p-3 rounded-xl bg-primary/10`}>
                      <stat.icon className={`h-6 w-6 text-primary`} />
                    </div>
                    <div>
                      <div className="text-2xl font-semibold text-gray-900">
                        {stat.value}
                      </div>
                      <div className="text-sm text-gray-600">{stat.title}</div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>

            {/* Actions Bar */}
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold text-gray-900">
                Task Board
              </h2>
              <div className="flex items-center gap-2">
                <Dialog open={createTaskOpen} onOpenChange={setCreateTaskOpen}>
                  <DialogTrigger asChild>
                    <Button variant="outline">
                      <Plus className="h-4 w-4 mr-2" />
                      Create Task
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Create New Task</DialogTitle>
                    </DialogHeader>
                    {/* <CreateTaskForm
                      onSuccess={() => setCreateTaskOpen(false)}
                    /> */}
                  </DialogContent>
                </Dialog>

                <Dialog
                  open={createColumnOpen}
                  onOpenChange={setCreateColumnOpen}
                >
                  <DialogTrigger asChild>
                    <Button className="text-white">
                      <Plus className="h-4 w-4 mr-2" />
                      Create Column
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Create New Category</DialogTitle>
                    </DialogHeader>
                    {/* <CreateColumnForm
                      onSuccess={() => setCreateColumnOpen(false)}
                    /> */}
                  </DialogContent>
                </Dialog>
              </div>
            </div>

            {/* Task Board */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="rounded-2xl  border border-gray-200 shadow-sm p-6"
            >
              {/* Task */}
              <TasksBoard />
            </motion.div>
          </div>
        </AppLayout>
      </AuthCheck>
    </>
  );
}
