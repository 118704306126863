import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { Pagination } from '@/components/ui/pagination';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useMembersFiltering } from '@/lib/hooks/use-members-filtering.hook';
import {
  deleteWorkspaceMember,
  requestPasswordReset,
  setDisplayOnboardMembersModal,
  setMemberQuery,
  setMemberSortValue,
  setPermissionLevelFilter,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { useParams } from '@tanstack/react-router';
import { motion } from 'framer-motion';
import {
  Activity,
  Mail,
  RefreshCw,
  Search,
  SendHorizonal,
  Settings,
  Shield,
  Trash2,
  UserCheck,
  UserMinus,
  UserPlus,
  Users,
} from 'lucide-react';
import { useMemo, useState } from 'react';
import { toast } from 'sonner';

export const MembersContainer = () => {
  const { workspaceId } = useParams({ strict: false });
  const dispatch = useAppDispatch();
  const { members } = useAppSelector((state) => state.members);
  const { currentPaginatedPage, entriesPerPaginatedPage } = useAppSelector(
    (state) => state.members,
  );
  const { memberQuery, memberSortValue, permissionLevelFilter } =
    useAppSelector((state) => state.members);
  const { id: currentUserId } = useAppSelector((state) => state.user);

  const [memberToRemove, setMemberToRemove] = useState<string | null>(null);
  const [bulkSelectedMembers, setBulkSelectedMembers] = useState<string[]>([]);

  const { filteredMembers, setFilteredMembers } = useMembersFiltering(members);
  const paginatedMembers = useMemo(() => {
    const startIndex = (currentPaginatedPage - 1) * entriesPerPaginatedPage;
    const endIndex = startIndex + entriesPerPaginatedPage;
    return filteredMembers.slice(startIndex, endIndex);
  }, [filteredMembers, currentPaginatedPage, entriesPerPaginatedPage]);

  const stats = useMemo(
    () => ({
      totalMembers: members.length,
      activeMembers: members.filter((m) => m.online).length,
      owners: members.filter((m) => m.accessLevel?.toString() === '127').length,
    }),
    [members],
  );

  const toggleOnboardMemberModal = () => {
    dispatch(setDisplayOnboardMembersModal(true));
  };

  const handleSendPasswordReset = (email: string | undefined) => {
    if (!email) return;

    dispatch(requestPasswordReset(email))
      .unwrap()
      .then(() => {
        toast.success('Password reset email has been sent successfully.');
      })
      .catch((error) => {
        toast.error(`Failed to send password reset: ${error}`);
      });
  };

  const handleRemoveMember = (memberId: string) => {
    if (!workspaceId || !memberId) return;

    dispatch(
      deleteWorkspaceMember({
        workspaceId,
        user: memberId,
      }),
    )
      .unwrap()
      .then(() => {
        toast.success('Member has been removed successfully.');
        setMemberToRemove(null);
      })
      .catch((error) => {
        toast.error(`Failed to remove member: ${error}`);
      });
  };

  const handleBulkRemoveMembers = () => {
    if (!workspaceId || bulkSelectedMembers.length === 0) return;

    // Process each member removal in sequence
    Promise.all(
      bulkSelectedMembers.map((memberId) =>
        dispatch(
          deleteWorkspaceMember({
            workspaceId,
            user: memberId,
          }),
        ).unwrap(),
      ),
    )
      .then(() => {
        toast.success(
          `${bulkSelectedMembers.length} members removed successfully.`,
        );
        setBulkSelectedMembers([]);
      })
      .catch((error) => {
        toast.error(`Failed to remove some members: ${error}`);
      });
  };

  const handleMemberSelection = (memberId: string, checked: boolean) => {
    if (checked) {
      setBulkSelectedMembers((prev) => [...prev, memberId]);
    } else {
      setBulkSelectedMembers((prev) => prev.filter((id) => id !== memberId));
    }

    // Update the UI state in filtered members
    const newMembers = [...filteredMembers];
    const index = newMembers.findIndex((m) => m.id === memberId);
    if (index !== -1) {
      newMembers[index] = {
        ...newMembers[index],
        checked,
      };
      setFilteredMembers(newMembers);
    }
  };
  return (
    <div className="p-6 space-y-6">
      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="p-6 rounded-2xl bg-white border border-gray-200 shadow-sm"
        >
          <div className="flex items-center gap-4">
            <div className="p-3 rounded-xl bg-primary/10">
              <Users className="h-6 w-6 text-primary" />
            </div>
            <div>
              <div className="text-2xl font-semibold text-gray-900">
                {stats.totalMembers}
              </div>
              <div className="text-sm text-gray-600">Total Members</div>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="p-6 rounded-2xl bg-white border border-gray-200 shadow-sm"
        >
          <div className="flex items-center gap-4">
            <div className="p-3 rounded-xl bg-primary/10">
              <Activity className="h-6 w-6 text-primary" />
            </div>
            <div>
              <div className="text-2xl font-semibold text-gray-900">
                {stats.activeMembers}
              </div>
              <div className="text-sm text-gray-600">Active Members</div>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="p-6 rounded-2xl bg-white border border-gray-200 shadow-sm"
        >
          <div className="flex items-center gap-4">
            <div className="p-3 rounded-xl bg-primary/10">
              <Shield className="h-6 w-6 text-primary" />
            </div>
            <div>
              <div className="text-2xl font-semibold text-gray-900">
                {stats.owners}
              </div>
              <div className="text-sm text-gray-600">Workspace Owners</div>
            </div>
          </div>
        </motion.div>
      </div>
      {/* Bulk Actions (when members are selected) */}
      {bulkSelectedMembers.length > 0 && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="flex items-center justify-between p-4 rounded-xl bg-primary/5 border border-primary/20"
        >
          <div className="text-sm font-medium">
            {bulkSelectedMembers.length} members selected
          </div>
          <div className="flex gap-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                // Clear all selections
                setBulkSelectedMembers([]);
                const newMembers = filteredMembers.map((m) => ({
                  ...m,
                  checked: false,
                }));
                setFilteredMembers(newMembers);
              }}
            >
              Clear
            </Button>
            <Button
              variant="destructive"
              size="sm"
              onClick={handleBulkRemoveMembers}
            >
              <UserMinus className="h-4 w-4 mr-2" />
              Remove Selected
            </Button>
          </div>
        </motion.div>
      )}

      {/* Main Content Grid */}
      <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
        {/* Members List - Spans 8 columns */}
        <div className="md:col-span-8 space-y-4">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold text-gray-900">Members</h2>
            <Button className="text-white" onClick={toggleOnboardMemberModal}>
              <UserPlus className="h-4 w-4 mr-2" />
              Onboard Member
            </Button>
          </div>

          {/* Members Cards */}
          <div className="space-y-4">
            {paginatedMembers.map((member, index) => (
              <motion.div
                key={member.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.05 }}
                className="p-4 rounded-xl bg-white border border-gray-200 hover:border-primary/50 transition-all"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <Checkbox
                      className="text-white"
                      checked={member.checked}
                      disabled={member.id === currentUserId}
                      onCheckedChange={(checked) => {
                        handleMemberSelection(member.id!, !!checked);
                      }}
                    />
                    <Avatar>
                      {member.thumbnail ? (
                        <AvatarImage src={member.thumbnail} alt={member.name} />
                      ) : (
                        <AvatarFallback className="bg-primary/10 text-primary">
                          {member.name?.charAt(0).toUpperCase()}
                        </AvatarFallback>
                      )}
                    </Avatar>
                    <div>
                      <div className="font-medium text-gray-900">
                        {member.name} {member.id === currentUserId && '(You)'}
                      </div>
                      <div className="text-sm text-gray-500 flex items-center gap-2">
                        <Mail className="h-3.5 w-3.5" />
                        {member.email}
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center gap-3">
                    {member.online && (
                      <Badge variant="success" className="font-medium">
                        <UserCheck className="h-3.5 w-3.5 mr-1" />
                        Online
                      </Badge>
                    )}
                    {member.accessLevel?.toString() === '127' && (
                      <Badge variant="secondary" className="font-medium">
                        <Shield className="h-3.5 w-3.5 mr-1" />
                        Owner
                      </Badge>
                    )}
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="sm">
                          <Settings className="h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        {member.active ? (
                          <DropdownMenuItem
                            onClick={() =>
                              handleSendPasswordReset(member.email)
                            }
                          >
                            <SendHorizonal className="h-4 w-4 mr-2" />
                            Send Password Reset
                          </DropdownMenuItem>
                        ) : (
                          <DropdownMenuItem
                            onClick={() =>
                              handleSendPasswordReset(member.email)
                            }
                          >
                            <RefreshCw className="h-4 w-4 mr-2" />
                            Resend Invitation
                          </DropdownMenuItem>
                        )}

                        {member.id !== currentUserId && (
                          <>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                              onClick={() => setMemberToRemove(member.id!)}
                              className="text-destructive focus:text-destructive"
                            >
                              <Trash2 className="h-4 w-4 mr-2" />
                              Remove Member
                            </DropdownMenuItem>
                          </>
                        )}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
              </motion.div>
            ))}

            {filteredMembers.length === 0 && (
              <div className="p-8 text-center text-gray-500">
                No members match your current filters.
              </div>
            )}
          </div>

          {/* Pagination */}
          {filteredMembers.length > entriesPerPaginatedPage && (
            <Pagination className="justify-center">
              {/* Existing pagination logic */}
            </Pagination>
          )}
        </div>

        {/* Filters Sidebar - Spans 4 columns */}
        <div className="md:col-span-4 space-y-4">
          <div className="p-6 rounded-2xl bg-white border border-gray-200 shadow-sm">
            <div className="space-y-4">
              <h3 className="font-medium text-gray-900">Filters</h3>
              <div className="space-y-4">
                <div className="relative">
                  <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
                  <Input
                    placeholder="Find members..."
                    value={memberQuery}
                    onChange={(e) => dispatch(setMemberQuery(e.target.value))}
                    className="pl-9"
                  />
                </div>

                <Select
                  value={memberSortValue}
                  onValueChange={(value) => dispatch(setMemberSortValue(value))}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Sort by" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="name">Name</SelectItem>
                    <SelectItem value="email">Email</SelectItem>
                    <SelectItem value="workspaceOwner">Role</SelectItem>
                  </SelectContent>
                </Select>

                <Select
                  value={permissionLevelFilter?.toString() || '-1'}
                  onValueChange={(value) =>
                    dispatch(
                      setPermissionLevelFilter(
                        value === '-1' ? null : Number(value),
                      ),
                    )
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Filter by permission" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="-1">Every Member</SelectItem>
                    <SelectItem value="127">Owners</SelectItem>
                    <SelectItem value="0">Regular Members</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Remove Member Confirmation Dialog */}
      <AlertDialog
        open={!!memberToRemove}
        onOpenChange={(open) => !open && setMemberToRemove(null)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Remove Member</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to remove this member from the workspace?
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
              onClick={() =>
                memberToRemove && handleRemoveMember(memberToRemove)
              }
            >
              Remove
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};
