/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { FolderInformation } from "./FolderInformation";
import {
  FolderInformationFromJSON,
  FolderInformationFromJSONTyped,
  FolderInformationToJSON,
  FolderInformationToJSONTyped,
} from "./FolderInformation";

/**
 *
 * @export
 * @interface ResultFolderInformation
 */
export interface ResultFolderInformation {
  /**
   *
   * @type {Array<FolderInformation>}
   * @memberof ResultFolderInformation
   */
  items?: Array<FolderInformation>;
}

/**
 * Check if a given object implements the ResultFolderInformation interface.
 */
export function instanceOfResultFolderInformation(
  value: object,
): value is ResultFolderInformation {
  return true;
}

export function ResultFolderInformationFromJSON(
  json: any,
): ResultFolderInformation {
  return ResultFolderInformationFromJSONTyped(json, false);
}

export function ResultFolderInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultFolderInformation {
  if (json == null) {
    return json;
  }
  return {
    items:
      json["items"] == null
        ? undefined
        : (json["items"] as Array<any>).map(FolderInformationFromJSON),
  };
}

export function ResultFolderInformationToJSON(
  json: any,
): ResultFolderInformation {
  return ResultFolderInformationToJSONTyped(json, false);
}

export function ResultFolderInformationToJSONTyped(
  value?: ResultFolderInformation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    items:
      value["items"] == null
        ? undefined
        : (value["items"] as Array<any>).map(FolderInformationToJSON),
  };
}
