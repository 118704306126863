import { useAppSelector } from '@/lib/store';
import { cn } from '@/lib/utils';
import { Link, useLocation } from '@tanstack/react-router';
import { motion } from 'framer-motion';
import { ChevronRight, Home } from 'lucide-react';
import { Fragment, useMemo } from 'react';
import { Button } from '../ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';

interface BreadcrumbItem {
  label: string;
  path: string;
  isWorkspace?: boolean;
}

export const Breadcrumbs = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const { workspaces } = useAppSelector((state) => state.workspaces);

  // Build cumulative paths and labels with workspace names
  const breadcrumbs: BreadcrumbItem[] = useMemo(() => {
    return pathSegments.map((segment, index) => {
      // Check if this segment is a workspace ID
      const workspace = workspaces.find((w) => w.id === segment);

      return {
        label: workspace
          ? workspace.name || segment
          : segment.replace(/-/g, ' '),
        path: '/' + pathSegments.slice(0, index + 1).join('/'),
        isWorkspace: !!workspace,
      };
    });
  }, [pathSegments, workspaces]);

  return (
    <TooltipProvider delayDuration={0}>
      <nav aria-label="Breadcrumb" className="flex items-center">
        <ol className="flex items-center space-x-2">
          {/* Home Link */}
          <li>
            <Tooltip>
              <TooltipTrigger asChild>
                <Link to="/">
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-8 w-8 rounded-full hover:bg-primary/10"
                  >
                    <Home className="h-4 w-4 text-muted-foreground" />
                  </Button>
                </Link>
              </TooltipTrigger>
              <TooltipContent>Home</TooltipContent>
            </Tooltip>
          </li>

          {/* Breadcrumb Items */}
          {breadcrumbs.map((item, index) => (
            <Fragment key={item.path}>
              {/* Separator */}
              <li className="flex items-center">
                <ChevronRight className="h-4 w-4 text-muted-foreground/50" />
              </li>

              {/* Breadcrumb Item */}
              <li>
                <motion.div
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Link to={item.path}>
                    <Button
                      variant="ghost"
                      className={cn(
                        'px-2 py-1 h-auto text-sm transition-colors',
                        'hover:bg-primary/10 hover:text-primary rounded-md',
                        item.isWorkspace && 'font-medium',
                        !item.isWorkspace && 'capitalize',
                        index === breadcrumbs.length - 1
                          ? 'text-foreground font-medium pointer-events-none'
                          : 'text-muted-foreground',
                      )}
                    >
                      {item.label}
                    </Button>
                  </Link>
                </motion.div>
              </li>
            </Fragment>
          ))}
        </ol>
      </nav>
    </TooltipProvider>
  );
};
