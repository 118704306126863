import { Badge } from '@/components/ui/badge';
import { Card } from '@/components/ui/card';
import { Entity } from '@/lib/store';
import { prettyBytes } from '@/lib/utils';
import { Clock, HardDrive, User } from 'lucide-react';
import Moment from 'react-moment';
import { FileManagerBreadcrumbs } from '../file-manager-breadcrumbs.component';

interface PreviewTabProps {
  entity: Entity;
}

export const PreviewTab = ({ entity }: PreviewTabProps) => {
  return (
    <div className="space-y-6">
      {/* File Location Card */}
      <Card className="relative overflow-hidden">
        <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-primary-500 to-primary-400" />
        <div className="p-4">
          <h3 className="text-sm font-medium text-muted-foreground mb-2">
            Location
          </h3>
          <FileManagerBreadcrumbs type="preview" />
        </div>
      </Card>

      {/* File Details Grid */}
      <div className="grid grid-cols-2 gap-4">
        {/* Basic Info */}
        <Card className="col-span-2">
          <div className="p-4 space-y-4">
            <div className="flex items-start justify-between">
              <div>
                <h3 className="font-medium">{entity.name}</h3>
                <p className="text-sm text-muted-foreground">
                  {entity.type?.toUpperCase()}
                </p>
              </div>
              <Badge variant="outline">v{entity.numversion ?? '1'}</Badge>
            </div>

            <div className="flex items-center gap-2 text-sm text-muted-foreground">
              <HardDrive className="w-4 h-4" />
              <span>{prettyBytes(entity.size ?? 0)}</span>
            </div>
          </div>
        </Card>

        {/* Creator Info */}
        <Card className="col-span-2">
          <div className="p-4 space-y-2">
            <div className="flex items-center gap-2 text-sm text-muted-foreground mb-3">
              <User className="w-4 h-4" />
              <span>Creator</span>
            </div>

            <div className="flex items-center gap-3">
              <div className="w-8 h-8 rounded-full bg-gradient-to-br from-primary-500 to-primary-400 flex items-center justify-center text-white font-medium">
                {entity.creatorname?.[0]?.toUpperCase()}
              </div>
              <div>
                <p className="font-medium">{entity.creatorname}</p>
                <p className="text-xs text-muted-foreground">Owner</p>
              </div>
            </div>
          </div>
        </Card>

        {/* Timestamps */}
        <Card className="col-span-2">
          <div className="p-4 space-y-4">
            <div className="flex items-center gap-2 text-sm text-muted-foreground">
              <Clock className="w-4 h-4" />
              <span>Dates</span>
            </div>

            <div className="space-y-2">
              <div className="flex flex-col">
                <span className="text-xs text-muted-foreground">Created</span>
                <Moment format="LLL" className="text-sm">
                  {parseInt(entity.created, 10)}
                </Moment>
              </div>

              <div className="flex flex-col">
                <span className="text-xs text-muted-foreground">Modified</span>
                <Moment format="LLL" className="text-sm">
                  {entity.modified}
                </Moment>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
