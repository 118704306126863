/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CreateAccountSpec
 */
export interface CreateAccountSpec {
  /**
   * The email to associate with the user.
   * @type {string}
   * @memberof CreateAccountSpec
   */
  email: string;
  /**
   * The password to associate with the user.
   * @type {string}
   * @memberof CreateAccountSpec
   */
  pass: string;
  /**
   * The display name to associate with the user.
   * @type {string}
   * @memberof CreateAccountSpec
   */
  name: string;
  /**
   * The recaptcha token used to validate the initiator of the request is human.
   * @type {string}
   * @memberof CreateAccountSpec
   */
  recaptcha: string;
  /**
   * The language in which to send out the invitation email. Should be one of 'en', 'fr', 'en_fr', or 'fr_en'.
   * @type {string}
   * @memberof CreateAccountSpec
   */
  lang?: string;
  /**
   * The OAuth client_id of the application initiating the invite. The application will redirect to that consumer's app instead of the application root.
   * @type {string}
   * @memberof CreateAccountSpec
   */
  clientId?: string;
}

/**
 * Check if a given object implements the CreateAccountSpec interface.
 */
export function instanceOfCreateAccountSpec(
  value: object,
): value is CreateAccountSpec {
  if (!("email" in value) || value["email"] === undefined) return false;
  if (!("pass" in value) || value["pass"] === undefined) return false;
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("recaptcha" in value) || value["recaptcha"] === undefined) return false;
  return true;
}

export function CreateAccountSpecFromJSON(json: any): CreateAccountSpec {
  return CreateAccountSpecFromJSONTyped(json, false);
}

export function CreateAccountSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateAccountSpec {
  if (json == null) {
    return json;
  }
  return {
    email: json["email"],
    pass: json["pass"],
    name: json["name"],
    recaptcha: json["recaptcha"],
    lang: json["lang"] == null ? undefined : json["lang"],
    clientId: json["clientId"] == null ? undefined : json["clientId"],
  };
}

export function CreateAccountSpecToJSON(json: any): CreateAccountSpec {
  return CreateAccountSpecToJSONTyped(json, false);
}

export function CreateAccountSpecToJSONTyped(
  value?: CreateAccountSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    email: value["email"],
    pass: value["pass"],
    name: value["name"],
    recaptcha: value["recaptcha"],
    lang: value["lang"],
    clientId: value["clientId"],
  };
}
