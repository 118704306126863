/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface NotificationProviderInfo
 */
export interface NotificationProviderInfo {
  /**
   *
   * @type {string}
   * @memberof NotificationProviderInfo
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof NotificationProviderInfo
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof NotificationProviderInfo
   */
  value?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationProviderInfo
   */
  label?: string;
}

/**
 * Check if a given object implements the NotificationProviderInfo interface.
 */
export function instanceOfNotificationProviderInfo(
  value: object,
): value is NotificationProviderInfo {
  return true;
}

export function NotificationProviderInfoFromJSON(
  json: any,
): NotificationProviderInfo {
  return NotificationProviderInfoFromJSONTyped(json, false);
}

export function NotificationProviderInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): NotificationProviderInfo {
  if (json == null) {
    return json;
  }
  return {
    name: json["name"] == null ? undefined : json["name"],
    enabled: json["enabled"] == null ? undefined : json["enabled"],
    value: json["value"] == null ? undefined : json["value"],
    label: json["label"] == null ? undefined : json["label"],
  };
}

export function NotificationProviderInfoToJSON(
  json: any,
): NotificationProviderInfo {
  return NotificationProviderInfoToJSONTyped(json, false);
}

export function NotificationProviderInfoToJSONTyped(
  value?: NotificationProviderInfo | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value["name"],
    enabled: value["enabled"],
    value: value["value"],
    label: value["label"],
  };
}
