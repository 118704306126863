import md5 from "md5";
import { useEffect, useState } from "react";

export const useGravatar = (email: string | null) => {
  const [gravatarUrl, setGravatarUrl] = useState<string>("");

  useEffect(() => {
    if (email) {
      const hash = md5(email.toLowerCase().trim());
      setGravatarUrl(`https://www.gravatar.com/avatar/${hash}?s=100`);
    }
  }, [email]);

  return gravatarUrl;
};

export const generateGravatarUrl = (email: string | null) => {
  if (email) {
    const hash = md5(email.toLowerCase().trim());
    return `https://www.gravatar.com/avatar/${hash}?s=100`;
  }
  return "";
};
