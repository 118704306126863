/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TokenSpec
 */
export interface TokenSpec {
  /**
   * The card ID obtained from Stripe.
   * @type {string}
   * @memberof TokenSpec
   */
  id: string;
}

/**
 * Check if a given object implements the TokenSpec interface.
 */
export function instanceOfTokenSpec(value: object): value is TokenSpec {
  if (!("id" in value) || value["id"] === undefined) return false;
  return true;
}

export function TokenSpecFromJSON(json: any): TokenSpec {
  return TokenSpecFromJSONTyped(json, false);
}

export function TokenSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TokenSpec {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
  };
}

export function TokenSpecToJSON(json: any): TokenSpec {
  return TokenSpecToJSONTyped(json, false);
}

export function TokenSpecToJSONTyped(
  value?: TokenSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
  };
}
