import { SetNotificationTypeStatusRequest } from "@oproma/prividox-orchestration-open-api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notificationsService } from "../api";
import {
  INotificationState,
  NotificationPreferenceTypes,
  NotificationsErrorCodesEnum,
} from "./types";

const initialState: INotificationState = {
  notifications: [],
  notificationPreferences: null,
  changedNotificationPreferences: null,
  loading: false,
  error: null,
};

export const getNotifications = createAsyncThunk(
  "@@notifications/getNotifications",
  async (_, { rejectWithValue }) => {
    try {
      const notificationsResponse =
        await notificationsService.fetchNotifications();

      if (!notificationsResponse.items) {
        return rejectWithValue("Failed to retrive notifications.");
      }

      return notificationsResponse.items.map((notification) => ({
        ...notification,
        icon: "curve-down-right",
        iconStyle: "bg-warning-dim",
        time: notification.time,
      }));
    } catch (e) {
      console.error(e);
      return rejectWithValue("Failed to authenticate user. Please try again.");
    }
  },
);

export const getNotificationPreferences = createAsyncThunk(
  "@@notifications/getNotificationPreferences",
  async (_, { rejectWithValue }) => {
    try {
      const response =
        (await notificationsService.fetchNotificationPreferences()) as NotificationPreferenceTypes;
      return response;
    } catch (e) {
      console.error(e);
      return rejectWithValue("Failed to authenticate user. Please try again.");
    }
  },
);

export const editNotificationPreference = createAsyncThunk(
  "@@notifications/setAppLinkNotification",
  async (
    { type, body }: SetNotificationTypeStatusRequest,
    { rejectWithValue },
  ) => {
    try {
      const response = await notificationsService.patchNotificationPreference({
        type,
        body,
      });
      return {
        [type]: response,
      } as NotificationPreferenceTypes;
    } catch (e) {
      console.error((e as Error).message);
      return rejectWithValue(
        "Failed to edit notification preference. Please try again.",
      );
    }
  },
);

const notificationsSlice = createSlice({
  name: "@@notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: NotificationsErrorCodesEnum.GET_NOTIFICATIONS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(getNotificationPreferences.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNotificationPreferences.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: NotificationsErrorCodesEnum.GET_NOTIFICATION_PREFERENCES_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getNotificationPreferences.fulfilled, (state, action) => {
        state.notificationPreferences = action.payload;
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(editNotificationPreference.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editNotificationPreference.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: NotificationsErrorCodesEnum.EDIT_APP_LINK_NOTIFICATION_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(editNotificationPreference.fulfilled, (state, action) => {
        state.changedNotificationPreferences = {
          ...state.changedNotificationPreferences,
          ...action.payload,
        };
        state.loading = false;
        state.error = null;
      });
  },
});

export const notificationsReducer = notificationsSlice.reducer;
