/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { SearchDocument } from "./SearchDocument";
import {
  SearchDocumentFromJSON,
  SearchDocumentFromJSONTyped,
  SearchDocumentToJSON,
  SearchDocumentToJSONTyped,
} from "./SearchDocument";

/**
 *
 * @export
 * @interface SearchResults
 */
export interface SearchResults {
  /**
   *
   * @type {string}
   * @memberof SearchResults
   */
  nextCursorMark?: string;
  /**
   *
   * @type {string}
   * @memberof SearchResults
   */
  message?: string;
  /**
   *
   * @type {number}
   * @memberof SearchResults
   */
  numFound?: number;
  /**
   *
   * @type {Array<SearchDocument>}
   * @memberof SearchResults
   */
  results?: Array<SearchDocument>;
  /**
   *
   * @type {{ [key: string]: { [key: string]: number; }; }}
   * @memberof SearchResults
   */
  facets?: { [key: string]: { [key: string]: number } };
}

/**
 * Check if a given object implements the SearchResults interface.
 */
export function instanceOfSearchResults(value: object): value is SearchResults {
  return true;
}

export function SearchResultsFromJSON(json: any): SearchResults {
  return SearchResultsFromJSONTyped(json, false);
}

export function SearchResultsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SearchResults {
  if (json == null) {
    return json;
  }
  return {
    nextCursorMark:
      json["nextCursorMark"] == null ? undefined : json["nextCursorMark"],
    message: json["message"] == null ? undefined : json["message"],
    numFound: json["numFound"] == null ? undefined : json["numFound"],
    results:
      json["results"] == null
        ? undefined
        : (json["results"] as Array<any>).map(SearchDocumentFromJSON),
    facets: json["facets"] == null ? undefined : json["facets"],
  };
}

export function SearchResultsToJSON(json: any): SearchResults {
  return SearchResultsToJSONTyped(json, false);
}

export function SearchResultsToJSONTyped(
  value?: SearchResults | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    nextCursorMark: value["nextCursorMark"],
    message: value["message"],
    numFound: value["numFound"],
    results:
      value["results"] == null
        ? undefined
        : (value["results"] as Array<any>).map(SearchDocumentToJSON),
    facets: value["facets"],
  };
}
