/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { ProduceInformation } from "./ProduceInformation";
import {
  ProduceInformationFromJSON,
  ProduceInformationFromJSONTyped,
  ProduceInformationToJSON,
  ProduceInformationToJSONTyped,
} from "./ProduceInformation";

/**
 *
 * @export
 * @interface ResultProduceInformation
 */
export interface ResultProduceInformation {
  /**
   *
   * @type {Array<ProduceInformation>}
   * @memberof ResultProduceInformation
   */
  items?: Array<ProduceInformation>;
}

/**
 * Check if a given object implements the ResultProduceInformation interface.
 */
export function instanceOfResultProduceInformation(
  value: object,
): value is ResultProduceInformation {
  return true;
}

export function ResultProduceInformationFromJSON(
  json: any,
): ResultProduceInformation {
  return ResultProduceInformationFromJSONTyped(json, false);
}

export function ResultProduceInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultProduceInformation {
  if (json == null) {
    return json;
  }
  return {
    items:
      json["items"] == null
        ? undefined
        : (json["items"] as Array<any>).map(ProduceInformationFromJSON),
  };
}

export function ResultProduceInformationToJSON(
  json: any,
): ResultProduceInformation {
  return ResultProduceInformationToJSONTyped(json, false);
}

export function ResultProduceInformationToJSONTyped(
  value?: ResultProduceInformation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    items:
      value["items"] == null
        ? undefined
        : (value["items"] as Array<any>).map(ProduceInformationToJSON),
  };
}
