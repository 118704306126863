/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UpdatePlanSpec
 */
export interface UpdatePlanSpec {
  /**
   * The ID of the new plan to associate with the workspace
   * @type {string}
   * @memberof UpdatePlanSpec
   */
  plan: string;
}

/**
 * Check if a given object implements the UpdatePlanSpec interface.
 */
export function instanceOfUpdatePlanSpec(
  value: object,
): value is UpdatePlanSpec {
  if (!("plan" in value) || value["plan"] === undefined) return false;
  return true;
}

export function UpdatePlanSpecFromJSON(json: any): UpdatePlanSpec {
  return UpdatePlanSpecFromJSONTyped(json, false);
}

export function UpdatePlanSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdatePlanSpec {
  if (json == null) {
    return json;
  }
  return {
    plan: json["plan"],
  };
}

export function UpdatePlanSpecToJSON(json: any): UpdatePlanSpec {
  return UpdatePlanSpecToJSONTyped(json, false);
}

export function UpdatePlanSpecToJSONTyped(
  value?: UpdatePlanSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    plan: value["plan"],
  };
}
