/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ActivateAccountSpec
 */
export interface ActivateAccountSpec {
  /**
   * The password to associate with the user. Required when activating an invited user.
   * @type {string}
   * @memberof ActivateAccountSpec
   */
  pass?: string;
  /**
   * The display name to associate with the user. Required when activating an invited user.
   * @type {string}
   * @memberof ActivateAccountSpec
   */
  name?: string;
}

/**
 * Check if a given object implements the ActivateAccountSpec interface.
 */
export function instanceOfActivateAccountSpec(
  value: object,
): value is ActivateAccountSpec {
  return true;
}

export function ActivateAccountSpecFromJSON(json: any): ActivateAccountSpec {
  return ActivateAccountSpecFromJSONTyped(json, false);
}

export function ActivateAccountSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivateAccountSpec {
  if (json == null) {
    return json;
  }
  return {
    pass: json["pass"] == null ? undefined : json["pass"],
    name: json["name"] == null ? undefined : json["name"],
  };
}

export function ActivateAccountSpecToJSON(json: any): ActivateAccountSpec {
  return ActivateAccountSpecToJSONTyped(json, false);
}

export function ActivateAccountSpecToJSONTyped(
  value?: ActivateAccountSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    pass: value["pass"],
    name: value["name"],
  };
}
