/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { TextLocation } from "./TextLocation";
import {
  TextLocationFromJSON,
  TextLocationFromJSONTyped,
  TextLocationToJSON,
  TextLocationToJSONTyped,
} from "./TextLocation";

/**
 *
 * @export
 * @interface PdfStats
 */
export interface PdfStats {
  /**
   *
   * @type {Array<{ [key: string]: number; }>}
   * @memberof PdfStats
   */
  pages?: Array<{ [key: string]: number }>;
  /**
   *
   * @type {number}
   * @memberof PdfStats
   */
  pageCount?: number;
  /**
   *
   * @type {{ [key: string]: Array<TextLocation>; }}
   * @memberof PdfStats
   */
  text?: { [key: string]: Array<TextLocation> };
  /**
   *
   * @type {Set<string>}
   * @memberof PdfStats
   */
  entities?: Set<string>;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof PdfStats
   */
  keyedEntities?: { [key: string]: Array<string> };
}

/**
 * Check if a given object implements the PdfStats interface.
 */
export function instanceOfPdfStats(value: object): value is PdfStats {
  return true;
}

export function PdfStatsFromJSON(json: any): PdfStats {
  return PdfStatsFromJSONTyped(json, false);
}

export function PdfStatsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PdfStats {
  if (json == null) {
    return json;
  }
  return {
    pages: json["pages"] == null ? undefined : json["pages"],
    pageCount: json["pageCount"] == null ? undefined : json["pageCount"],
    text: json["text"] == null ? undefined : json["text"],
    entities: json["entities"] == null ? undefined : new Set(json["entities"]),
    keyedEntities:
      json["keyedEntities"] == null ? undefined : json["keyedEntities"],
  };
}

export function PdfStatsToJSON(json: any): PdfStats {
  return PdfStatsToJSONTyped(json, false);
}

export function PdfStatsToJSONTyped(
  value?: PdfStats | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    pages: value["pages"],
    pageCount: value["pageCount"],
    text: value["text"],
    entities:
      value["entities"] == null
        ? undefined
        : Array.from(value["entities"] as Set<any>),
    keyedEntities: value["keyedEntities"],
  };
}
