import {
  ResetPasswordSpec,
  UpdateNameSpec,
  UpdatePasswordSpec,
} from "@oproma/prividox-orchestration-open-api";
import { UserResponseSpec } from "../user/types";
import { userApi } from "./config";
import { fetchMetadata } from "./metadata";

// User
const fetchUser = (userId: string) => {
  // TODO: Fix explicit type casting
  return fetchMetadata<UserResponseSpec>(userId);
};

const fetchUserPresenceStatus = (user: string) => {
  return userApi.isOnline({
    user,
  });
};

const patchUserSubscription = (entityId: string) => {
  return userApi.subscribe({
    body: entityId,
  });
};

const removeSubscription = (entityId: string) => {
  return userApi.unsubscribe({
    subscribed: entityId,
  });
};

const fetchUserSessions = () => {
  return userApi.listSessions({});
};

const requestPasswordReset = (resetPasswordSpec: ResetPasswordSpec) => {
  return userApi.requestPasswordReset({
    resetPasswordSpec,
  });
};

const patchNameOfUser = (updateNameSpec: UpdateNameSpec) => {
  return userApi.updateName({
    updateNameSpec,
  });
};

export const patchUserPassword = (updatePasswordSpec: UpdatePasswordSpec) => {
  return userApi.updatePassword({
    updatePasswordSpec,
  });
};

const fetchLoginCount = () => {
  return userApi.getLoginCount();
};

const fetchMFABackupCodes = () => {
  return userApi.getSecretCode();
};

const validaMFACode = (code: number) => {
  return userApi.validateCode({
    codeSpec: {
      code,
    },
  });
};

const requireMFA = () => {
  return userApi.requireSecretCode();
};

export const userService = {
  fetchUser,
  requireMFA,
  validaMFACode,
  fetchLoginCount,
  patchNameOfUser,
  fetchUserSessions,
  patchUserPassword,
  removeSubscription,
  fetchMFABackupCodes,
  patchUserSubscription,
  fetchUserPresenceStatus,
  requestPasswordReset,
};
