import { AppLayout } from '@/components/app-layout.component';
import { AuthCheck } from '@/components/auth-check.component';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { WorkspaceOptionsGeneralTab } from '@/containers/$workspaceId/options/workspace-options-general-tab.component';
import {
  getPaymentTransactions,
  getWorkspaceBundles,
  getWorkspaceGroups,
  getWorkspaceMetadata,
  getWorkspacePermissions,
  getWorkspacePlan,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { findUpper, prettyBytes } from '@/lib/utils';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import {
  Calendar,
  Clock,
  Copy,
  HardDrive,
  Plus,
  Shield,
  Shield as ShieldIcon,
} from 'lucide-react';
import { useEffect } from 'react';
import { toast } from 'sonner';

export const Route = createFileRoute('/$workspaceId/options')({
  component: RouteComponent,
});

function RouteComponent() {
  const { workspaceId } = useParams({
    strict: false,
  });
  // const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { metadata: workspaceMetadata } = useAppSelector(
    (state) => state.workspace,
  );
  const [workspace] = useAppSelector((state) =>
    state.workspaces.workspaces.filter((w) => w.id === workspaceId),
  );
  const { groups } = useAppSelector((state) => state.groups);

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(getPaymentTransactions({}));
    dispatch(getWorkspacePlan(workspaceId));
    dispatch(getWorkspaceBundles(workspaceId));
    dispatch(getWorkspaceMetadata(workspaceId));
    dispatch(getWorkspacePermissions({ workspaceId, entityId: null }));
    dispatch(getWorkspaceGroups({ workspaceId }));
  }, [workspaceId, dispatch]);

  const copyToClipboard = () => {
    if (!workspaceId) return;
    navigator.clipboard.writeText(workspaceId);
    toast.success('Copied to clipboard!');
  };
  return (
    <AuthCheck>
      <AppLayout>
        <div className="container mx-auto p-6">
          {/* Header */}
          <div className="flex items-center justify-between mb-8">
            <div>
              <h1 className="text-2xl font-bold">Workspace Options</h1>
              <p className="text-muted-foreground">
                Manage settings and permissions for your workspace
              </p>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-6">
            {/* Main Content */}
            <div className="col-span-12 lg:col-span-8">
              <Card className="mb-6">
                <Tabs defaultValue="general" className="w-full">
                  <TabsList className="w-full">
                    <TabsTrigger value="general" className="w-full">
                      <Shield className="w-4 h-4 mr-2" />
                      General
                    </TabsTrigger>
                  </TabsList>

                  <TabsContent value="general">
                    <CardContent className="pt-6">
                      <WorkspaceOptionsGeneralTab workspaceId={workspaceId} />
                    </CardContent>
                  </TabsContent>
                </Tabs>
              </Card>
            </div>

            {/* Sidebar */}
            <div className="col-span-12 lg:col-span-4 space-y-6">
              {/* Workspace Info Card */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <Card>
                  <CardContent className="pt-6">
                    <div className="flex flex-col items-center text-center">
                      <Avatar className="h-20 w-20 mb-4">
                        <AvatarFallback className="text-lg">
                          {findUpper(workspaceMetadata?.name)}
                        </AvatarFallback>
                      </Avatar>
                      <Badge className="mb-2">Workspace</Badge>
                      <h3 className="text-xl font-semibold mb-1">
                        {workspaceMetadata?.name}
                      </h3>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={copyToClipboard}
                              className="text-xs text-muted-foreground flex items-center gap-1"
                            >
                              {workspaceId}
                              <Copy className="h-3 w-3 ml-1" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>Click to copy ID</TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>

                    {/* Stats Grid */}
                    <div className="grid grid-cols-3 gap-4 mt-6">
                      <div className="text-center">
                        <div className="text-2xl font-bold">
                          {workspace?.membersLimit}
                        </div>
                        <div className="text-xs text-muted-foreground">
                          Max Members
                        </div>
                      </div>
                      <div className="text-center">
                        <div className="text-2xl font-bold">
                          {workspace?.fileCount}
                        </div>
                        <div className="text-xs text-muted-foreground">
                          Files
                        </div>
                      </div>
                      <div className="text-center">
                        <div className="text-2xl font-bold">
                          {groups?.length}
                        </div>
                        <div className="text-xs text-muted-foreground">
                          Groups
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </motion.div>

              {/* Metadata Card */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
              >
                <Card>
                  <CardHeader>
                    <CardTitle>Metadata</CardTitle>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <div className="flex items-center gap-2 text-sm text-muted-foreground mb-1">
                          <Calendar className="h-4 w-4" />
                          Created
                        </div>
                        <div className="text-sm">
                          {workspaceMetadata?.created &&
                            format(new Date(workspaceMetadata.created), 'PPP')}
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center gap-2 text-sm text-muted-foreground mb-1">
                          <Clock className="h-4 w-4" />
                          Modified
                        </div>
                        <div className="text-sm">
                          {workspaceMetadata?.modified &&
                            format(new Date(workspaceMetadata.modified), 'PPP')}
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center gap-2 text-sm text-muted-foreground mb-1">
                          <HardDrive className="h-4 w-4" />
                          Storage
                        </div>
                        <div className="text-sm">
                          {prettyBytes(workspaceMetadata?.['bytes-limit'] ?? 0)}
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center gap-2 text-sm text-muted-foreground mb-1">
                          <ShieldIcon className="h-4 w-4" />
                          Permission
                        </div>
                        <div className="text-sm">
                          {workspaceMetadata?.isAdmin ? 'Admin' : 'Regular'}
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </motion.div>

              {/* Groups Card */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <Card>
                  <CardHeader>
                    <div className="flex items-center justify-between">
                      <CardTitle>Groups</CardTitle>
                      <Button variant="ghost" size="icon">
                        <Plus className="h-4 w-4" />
                      </Button>
                    </div>
                    <CardDescription>
                      Workspace groups and their members
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <ScrollArea className="h-[200px]">
                      <div className="flex flex-wrap gap-2">
                        {groups?.map((group) => (
                          <Badge
                            key={group.id}
                            variant="secondary"
                            className="cursor-pointer hover:bg-secondary/80"
                          >
                            {group.name}
                          </Badge>
                        ))}
                      </div>
                    </ScrollArea>
                  </CardContent>
                </Card>
              </motion.div>
            </div>
          </div>

          {/* Modals */}
          {/* <BundlesModal />
      <ChangePlanModal />
      <EditCreditCardModal />
      <OnboardCreditCardModal /> */}
        </div>
      </AppLayout>
    </AuthCheck>
  );
}
