/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  DiskUsageSpec,
  PermissionReportRow,
  PublishedItemSpec,
  ResultFileInfo,
  ResultLRUInfo,
  ResultNotificationMessage,
  ResultProduceInformation,
} from "../models/index";
import {
  DiskUsageSpecFromJSON,
  PermissionReportRowFromJSON,
  PublishedItemSpecFromJSON,
  ResultFileInfoFromJSON,
  ResultLRUInfoFromJSON,
  ResultNotificationMessageFromJSON,
  ResultProduceInformationFromJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface GetDiskUsageLRURequest {
  project: string;
  count?: number;
}

export interface GetDiskUsageLargestRequest {
  project: string;
  count?: number;
}

export interface GetDiskUsageTotalRequest {
  project: string;
}

export interface GetDiskUsageTrashRequest {
  project: string;
}

export interface GetEffectivePermissionsRequest {
  project: string;
  user: string;
  folders?: boolean;
}

export interface GetMemberActivityRequest {
  project: string;
  user: string;
  start?: number;
  end?: number;
}

export interface GetPublishedItemsRequest {
  project: string;
}

export interface GetTeamHistoryRequest {
  project: string;
}

export interface GetToProduceRequest {
  project: string;
}

export interface GetWorkspaceActivityRequest {
  project: string;
  start?: number;
  end?: number;
  count?: number;
}

/**
 *
 */
export class ReportsApi extends runtime.BaseAPI {
  /**
   * Retrieves the least recently used items of the workspace.
   * Get LRU
   */
  async getDiskUsageLRURaw(
    requestParameters: GetDiskUsageLRURequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResultLRUInfo>> {
    if (requestParameters["project"] == null) {
      throw new runtime.RequiredError(
        "project",
        'Required parameter "project" was null or undefined when calling getDiskUsageLRU().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters["count"] != null) {
      queryParameters["count"] = requestParameters["count"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/reports/{project}/usage/lru`.replace(
          `{${"project"}}`,
          encodeURIComponent(String(requestParameters["project"]))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultLRUInfoFromJSON(jsonValue)
    );
  }

  /**
   * Retrieves the least recently used items of the workspace.
   * Get LRU
   */
  async getDiskUsageLRU(
    requestParameters: GetDiskUsageLRURequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResultLRUInfo> {
    const response = await this.getDiskUsageLRURaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Retrieves the report of the largest files in the workspace.
   * Get Largest Files
   */
  async getDiskUsageLargestRaw(
    requestParameters: GetDiskUsageLargestRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResultFileInfo>> {
    if (requestParameters["project"] == null) {
      throw new runtime.RequiredError(
        "project",
        'Required parameter "project" was null or undefined when calling getDiskUsageLargest().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters["count"] != null) {
      queryParameters["count"] = requestParameters["count"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/reports/{project}/usage/largest`.replace(
          `{${"project"}}`,
          encodeURIComponent(String(requestParameters["project"]))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultFileInfoFromJSON(jsonValue)
    );
  }

  /**
   * Retrieves the report of the largest files in the workspace.
   * Get Largest Files
   */
  async getDiskUsageLargest(
    requestParameters: GetDiskUsageLargestRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResultFileInfo> {
    const response = await this.getDiskUsageLargestRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Retrieves the total disk usage report.
   * Get Total Disk Usage
   */
  async getDiskUsageTotalRaw(
    requestParameters: GetDiskUsageTotalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DiskUsageSpec>> {
    if (requestParameters["project"] == null) {
      throw new runtime.RequiredError(
        "project",
        'Required parameter "project" was null or undefined when calling getDiskUsageTotal().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/reports/{project}/usage/total`.replace(
          `{${"project"}}`,
          encodeURIComponent(String(requestParameters["project"]))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DiskUsageSpecFromJSON(jsonValue)
    );
  }

  /**
   * Retrieves the total disk usage report.
   * Get Total Disk Usage
   */
  async getDiskUsageTotal(
    requestParameters: GetDiskUsageTotalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DiskUsageSpec> {
    const response = await this.getDiskUsageTotalRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Retrieves the trash disk usage report.
   * Get Trash Disk Usage
   */
  async getDiskUsageTrashRaw(
    requestParameters: GetDiskUsageTrashRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DiskUsageSpec>> {
    if (requestParameters["project"] == null) {
      throw new runtime.RequiredError(
        "project",
        'Required parameter "project" was null or undefined when calling getDiskUsageTrash().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/reports/{project}/usage/trash`.replace(
          `{${"project"}}`,
          encodeURIComponent(String(requestParameters["project"]))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DiskUsageSpecFromJSON(jsonValue)
    );
  }

  /**
   * Retrieves the trash disk usage report.
   * Get Trash Disk Usage
   */
  async getDiskUsageTrash(
    requestParameters: GetDiskUsageTrashRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DiskUsageSpec> {
    const response = await this.getDiskUsageTrashRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Retrieves the effective permissions report
   * Get Effective Permissions
   */
  async getEffectivePermissionsRaw(
    requestParameters: GetEffectivePermissionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PermissionReportRow>> {
    if (requestParameters["project"] == null) {
      throw new runtime.RequiredError(
        "project",
        'Required parameter "project" was null or undefined when calling getEffectivePermissions().'
      );
    }

    if (requestParameters["user"] == null) {
      throw new runtime.RequiredError(
        "user",
        'Required parameter "user" was null or undefined when calling getEffectivePermissions().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters["folders"] != null) {
      queryParameters["folders"] = requestParameters["folders"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/reports/{project}/permissions/{user}`
          .replace(
            `{${"project"}}`,
            encodeURIComponent(String(requestParameters["project"]))
          )
          .replace(
            `{${"user"}}`,
            encodeURIComponent(String(requestParameters["user"]))
          ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PermissionReportRowFromJSON(jsonValue)
    );
  }

  /**
   * Retrieves the effective permissions report
   * Get Effective Permissions
   */
  async getEffectivePermissions(
    requestParameters: GetEffectivePermissionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PermissionReportRow> {
    const response = await this.getEffectivePermissionsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Retrieves the member activity report.
   * Get Member Activity
   */
  async getMemberActivityRaw(
    requestParameters: GetMemberActivityRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResultNotificationMessage>> {
    if (requestParameters["project"] == null) {
      throw new runtime.RequiredError(
        "project",
        'Required parameter "project" was null or undefined when calling getMemberActivity().'
      );
    }

    if (requestParameters["user"] == null) {
      throw new runtime.RequiredError(
        "user",
        'Required parameter "user" was null or undefined when calling getMemberActivity().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters["start"] != null) {
      queryParameters["start"] = requestParameters["start"];
    }

    if (requestParameters["end"] != null) {
      queryParameters["end"] = requestParameters["end"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/reports/{project}/{user}`
          .replace(
            `{${"project"}}`,
            encodeURIComponent(String(requestParameters["project"]))
          )
          .replace(
            `{${"user"}}`,
            encodeURIComponent(String(requestParameters["user"]))
          ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultNotificationMessageFromJSON(jsonValue)
    );
  }

  /**
   * Retrieves the member activity report.
   * Get Member Activity
   */
  async getMemberActivity(
    requestParameters: GetMemberActivityRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResultNotificationMessage> {
    const response = await this.getMemberActivityRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Retrieves the report of published entities.
   * Get Published Items
   */
  async getPublishedItemsRaw(
    requestParameters: GetPublishedItemsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<{ [key: string]: PublishedItemSpec }>> {
    if (requestParameters["project"] == null) {
      throw new runtime.RequiredError(
        "project",
        'Required parameter "project" was null or undefined when calling getPublishedItems().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/reports/{project}/published`.replace(
          `{${"project"}}`,
          encodeURIComponent(String(requestParameters["project"]))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      runtime.mapValues(jsonValue, PublishedItemSpecFromJSON)
    );
  }

  /**
   * Retrieves the report of published entities.
   * Get Published Items
   */
  async getPublishedItems(
    requestParameters: GetPublishedItemsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<{ [key: string]: PublishedItemSpec }> {
    const response = await this.getPublishedItemsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Retrieves the history of the team.
   * Get Team History
   */
  async getTeamHistoryRaw(
    requestParameters: GetTeamHistoryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResultNotificationMessage>> {
    if (requestParameters["project"] == null) {
      throw new runtime.RequiredError(
        "project",
        'Required parameter "project" was null or undefined when calling getTeamHistory().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/reports/{project}/team`.replace(
          `{${"project"}}`,
          encodeURIComponent(String(requestParameters["project"]))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultNotificationMessageFromJSON(jsonValue)
    );
  }

  /**
   * Retrieves the history of the team.
   * Get Team History
   */
  async getTeamHistory(
    requestParameters: GetTeamHistoryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResultNotificationMessage> {
    const response = await this.getTeamHistoryRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Retrieves the report of entities to produce.
   * Get To Produce
   */
  async getToProduceRaw(
    requestParameters: GetToProduceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResultProduceInformation>> {
    if (requestParameters["project"] == null) {
      throw new runtime.RequiredError(
        "project",
        'Required parameter "project" was null or undefined when calling getToProduce().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/reports/{project}/produce`.replace(
          `{${"project"}}`,
          encodeURIComponent(String(requestParameters["project"]))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultProduceInformationFromJSON(jsonValue)
    );
  }

  /**
   * Retrieves the report of entities to produce.
   * Get To Produce
   */
  async getToProduce(
    requestParameters: GetToProduceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResultProduceInformation> {
    const response = await this.getToProduceRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Retrieves the member activity report.
   * Get Workspace Activity
   */
  async getWorkspaceActivityRaw(
    requestParameters: GetWorkspaceActivityRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResultNotificationMessage>> {
    if (requestParameters["project"] == null) {
      throw new runtime.RequiredError(
        "project",
        'Required parameter "project" was null or undefined when calling getWorkspaceActivity().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters["start"] != null) {
      queryParameters["start"] = requestParameters["start"];
    }

    if (requestParameters["end"] != null) {
      queryParameters["end"] = requestParameters["end"];
    }

    if (requestParameters["count"] != null) {
      queryParameters["count"] = requestParameters["count"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/reports/{project}`.replace(
          `{${"project"}}`,
          encodeURIComponent(String(requestParameters["project"]))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultNotificationMessageFromJSON(jsonValue)
    );
  }

  /**
   * Retrieves the member activity report.
   * Get Workspace Activity
   */
  async getWorkspaceActivity(
    requestParameters: GetWorkspaceActivityRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResultNotificationMessage> {
    const response = await this.getWorkspaceActivityRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
