/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { QuerySpec } from "./QuerySpec";
import {
  QuerySpecFromJSON,
  QuerySpecFromJSONTyped,
  QuerySpecToJSON,
  QuerySpecToJSONTyped,
} from "./QuerySpec";

/**
 *
 * @export
 * @interface BatchUpdateSpec
 */
export interface BatchUpdateSpec {
  /**
   *
   * @type {QuerySpec}
   * @memberof BatchUpdateSpec
   */
  query: QuerySpec;
  /**
   * The set of metadata to apply to the entities matching the query..
   * @type {{ [key: string]: string; }}
   * @memberof BatchUpdateSpec
   */
  meta: { [key: string]: string };
}

/**
 * Check if a given object implements the BatchUpdateSpec interface.
 */
export function instanceOfBatchUpdateSpec(
  value: object,
): value is BatchUpdateSpec {
  if (!("query" in value) || value["query"] === undefined) return false;
  if (!("meta" in value) || value["meta"] === undefined) return false;
  return true;
}

export function BatchUpdateSpecFromJSON(json: any): BatchUpdateSpec {
  return BatchUpdateSpecFromJSONTyped(json, false);
}

export function BatchUpdateSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BatchUpdateSpec {
  if (json == null) {
    return json;
  }
  return {
    query: QuerySpecFromJSON(json["query"]),
    meta: json["meta"],
  };
}

export function BatchUpdateSpecToJSON(json: any): BatchUpdateSpec {
  return BatchUpdateSpecToJSONTyped(json, false);
}

export function BatchUpdateSpecToJSONTyped(
  value?: BatchUpdateSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    query: QuerySpecToJSON(value["query"]),
    meta: value["meta"],
  };
}
