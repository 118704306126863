/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { QuerySpec } from "./QuerySpec";
import {
  QuerySpecFromJSON,
  QuerySpecFromJSONTyped,
  QuerySpecToJSON,
  QuerySpecToJSONTyped,
} from "./QuerySpec";

/**
 *
 * @export
 * @interface BulkOperationSpec
 */
export interface BulkOperationSpec {
  /**
   *
   * @type {QuerySpec}
   * @memberof BulkOperationSpec
   */
  query: QuerySpec;
  /**
   * The operation to perform in bulk. One of 'move' or 'copy'.
   * @type {string}
   * @memberof BulkOperationSpec
   */
  operation: BulkOperationSpecOperationEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum BulkOperationSpecOperationEnum {
  move = "move",
  copy = "copy",
}

/**
 * Check if a given object implements the BulkOperationSpec interface.
 */
export function instanceOfBulkOperationSpec(
  value: object,
): value is BulkOperationSpec {
  if (!("query" in value) || value["query"] === undefined) return false;
  if (!("operation" in value) || value["operation"] === undefined) return false;
  return true;
}

export function BulkOperationSpecFromJSON(json: any): BulkOperationSpec {
  return BulkOperationSpecFromJSONTyped(json, false);
}

export function BulkOperationSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BulkOperationSpec {
  if (json == null) {
    return json;
  }
  return {
    query: QuerySpecFromJSON(json["query"]),
    operation: json["operation"],
  };
}

export function BulkOperationSpecToJSON(json: any): BulkOperationSpec {
  return BulkOperationSpecToJSONTyped(json, false);
}

export function BulkOperationSpecToJSONTyped(
  value?: BulkOperationSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    query: QuerySpecToJSON(value["query"]),
    operation: value["operation"],
  };
}
