import { useAppSelector } from '@/lib/store';
import { FileManagerFiles } from './file-manager-files.component';
import { FileManagerQuickNavigation } from './quick-naviation.component';

export const FileManagerExplorer = () => {
  const { entities, displayType } = useAppSelector(
    (state) => state.fileManager,
  );
  const filteredEntities = [...entities.filter((entity) => !entity.deleted)];
  return (
    <>
      <FileManagerQuickNavigation />

      <FileManagerFiles
        entities={filteredEntities}
        entityDisplayType={displayType}
      />
    </>
  );
};
