import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import {
  editEntityMetadata,
  Entity,
  getEntityMetadata,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { cn } from '@/lib/utils';
import {
  ArrowUpDown,
  Download,
  FileUp,
  Loader2,
  PlusCircle,
  Search,
  Tag,
  Trash2,
  X,
} from 'lucide-react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

interface MetadataTabProps {
  entity: Entity;
}

interface MetadataItem {
  key: string;
  value: string;
}

// List of core system metadata that shouldn't be deleted
const SYSTEM_METADATA_KEYS = [
  'id',
  'name',
  'type',
  'contentType',
  'size',
  'tsize',
  'created',
  'modified',
  'creator',
  'creatorname',
  'editor',
  'editorname',
  'numversion',
  'uploading',
  'importance',
  'comments',
  'level',
  'deleted',
  'locktype',
  'subscribed',
  'encrypted',
];

export const MetadataTab = ({ entity }: MetadataTabProps) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.fileManager);
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddMetadataOpen, setIsAddMetadataOpen] = useState(false);
  const [newMetadataKey, setNewMetadataKey] = useState('');
  const [newMetadataValue, setNewMetadataValue] = useState('');
  const [metadataItems, setMetadataItems] = useState<MetadataItem[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'asc' | 'desc';
  }>({
    key: 'key',
    direction: 'asc',
  });

  useEffect(() => {
    if (entity?.id) {
      dispatch(getEntityMetadata(entity.id));
    }
  }, [dispatch, entity?.id]);

  // Extract metadata when entity changes
  useEffect(() => {
    if (entity?.metadata) {
      const items: MetadataItem[] = [];
      // Converting the metadata object to an array of key-value pairs
      Object.entries(entity.metadata).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          items.push({
            key,
            value:
              typeof value === 'object' ? JSON.stringify(value) : String(value),
          });
        }
      });
      setMetadataItems(items);
    }
  }, [entity?.metadata]);

  const handleSort = (key: string) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === 'asc'
          ? 'desc'
          : 'asc',
    }));
  };

  const sortedMetadata = [...metadataItems]
    .filter((item) => {
      if (!searchTerm) return true;
      return (
        item.key.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.value.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .sort((a, b) => {
      if (sortConfig.key === 'key') {
        return sortConfig.direction === 'asc'
          ? a.key.localeCompare(b.key)
          : b.key.localeCompare(a.key);
      } else {
        return sortConfig.direction === 'asc'
          ? a.value.localeCompare(b.value)
          : b.value.localeCompare(a.value);
      }
    });

  const handleAddMetadata = () => {
    if (!newMetadataKey.trim() || !entity?.id) {
      toast.error('Metadata key cannot be empty');
      return;
    }

    // Check if key already exists
    if (metadataItems.some((item) => item.key === newMetadataKey)) {
      toast.error('This metadata key already exists');
      return;
    }

    dispatch(
      editEntityMetadata({
        entityId: entity.id,
        key: newMetadataKey,
        value: newMetadataValue,
      }),
    )
      .unwrap()
      .then(() => {
        setNewMetadataKey('');
        setNewMetadataValue('');
        setIsAddMetadataOpen(false);

        // Refresh metadata
        dispatch(getEntityMetadata(entity.id));
      })
      .catch((error) => {
        console.error(error);
        toast.error('Failed to add metadata');
      });
  };

  const handleDeleteMetadata = (key: string) => {
    if (!entity?.id) return;

    if (SYSTEM_METADATA_KEYS.includes(key)) {
      toast.error('Cannot delete system metadata');
      return;
    }

    dispatch(
      editEntityMetadata({
        entityId: entity.id,
        key,
        value: '', // Empty value to delete
      }),
    )
      .unwrap()
      .then(() => {
        toast.success('Metadata deleted successfully');
        // Refresh metadata
        dispatch(getEntityMetadata(entity.id));
      })
      .catch((error) => {
        console.error(error);
        toast.error('Failed to delete metadata');
      });
  };

  const handleExportMetadata = () => {
    // Convert metadata to CSV
    const csvContent = [
      'Key,Value',
      ...metadataItems.map(
        (item) =>
          `"${item.key.replace(/"/g, '""')}","${item.value.replace(/"/g, '""')}"`,
      ),
    ].join('\n');

    // Create blob and download link
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${entity.name || 'file'}-metadata.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    toast.success('Metadata exported successfully');
  };

  return (
    <div className="flex flex-col h-full max-h-[calc(100vh-150px)]">
      <div className="p-4 border-b flex flex-wrap justify-between items-center gap-4 sticky top-0 bg-background z-10">
        <div className="flex items-center gap-2">
          <Tag className="h-5 w-5 text-primary" />
          <h2 className="text-lg font-semibold">Metadata</h2>
          <Badge variant="outline" className="ml-2">
            {metadataItems.length}
          </Badge>
        </div>

        <div className="flex items-center gap-2">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search metadata"
              className="pl-9 w-[200px]"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm && (
              <Button
                variant="ghost"
                size="sm"
                className="absolute right-1 top-1/2 -translate-y-1/2 h-6 w-6 p-0"
                onClick={() => setSearchTerm('')}
              >
                <X className="h-4 w-4" />
              </Button>
            )}
          </div>

          <Dialog open={isAddMetadataOpen} onOpenChange={setIsAddMetadataOpen}>
            <DialogTrigger asChild>
              <Button variant="outline" size="sm">
                <PlusCircle className="h-4 w-4 mr-2" />
                Create Metadata
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add Metadata</DialogTitle>
                <DialogDescription>
                  Add custom metadata to this file
                </DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4">
                <div className="grid gap-2">
                  <Label htmlFor="metadata-key">Key</Label>
                  <Input
                    id="metadata-key"
                    placeholder="Enter metadata key"
                    value={newMetadataKey}
                    onChange={(e) => setNewMetadataKey(e.target.value)}
                  />
                </div>
                <div className="grid gap-2">
                  <Label htmlFor="metadata-value">Value</Label>
                  <Input
                    id="metadata-value"
                    placeholder="Enter metadata value"
                    value={newMetadataValue}
                    onChange={(e) => setNewMetadataValue(e.target.value)}
                  />
                </div>
              </div>
              <DialogFooter>
                <Button
                  variant="outline"
                  onClick={() => setIsAddMetadataOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleAddMetadata}
                  disabled={!newMetadataKey.trim() || loading}
                >
                  {loading ? (
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  ) : (
                    <PlusCircle className="h-4 w-4 mr-2" />
                  )}
                  Add
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          <Button
            variant="outline"
            size="sm"
            onClick={handleExportMetadata}
            disabled={metadataItems.length === 0}
          >
            <Download className="h-4 w-4 mr-2" />
            Export
          </Button>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center py-8 flex-1">
          <Loader2 className="w-6 h-6 animate-spin text-primary" />
        </div>
      ) : metadataItems.length === 0 ? (
        <div className="flex flex-col items-center justify-center py-12 flex-1 text-muted-foreground">
          <FileUp className="w-12 h-12 mb-4" />
          <p className="text-lg font-medium">No metadata found</p>
          <p className="text-sm">Add metadata to enhance file information</p>
        </div>
      ) : (
        <ScrollArea className="flex-1 h-full">
          <div className="p-4">
            <Card className="overflow-hidden">
              <div className="overflow-auto max-h-[calc(100vh-250px)]">
                <Table>
                  <TableHeader className="sticky top-0 bg-muted z-10">
                    <TableRow>
                      <TableHead
                        onClick={() => handleSort('key')}
                        className="cursor-pointer whitespace-nowrap"
                      >
                        <div className="flex items-center">
                          Key
                          <ArrowUpDown
                            className={cn(
                              'ml-2 h-4 w-4',
                              sortConfig.key === 'key' &&
                                sortConfig.direction === 'desc' &&
                                'rotate-180 transform',
                            )}
                          />
                        </div>
                      </TableHead>
                      <TableHead
                        onClick={() => handleSort('value')}
                        className="cursor-pointer whitespace-nowrap"
                      >
                        <div className="flex items-center">
                          Value
                          <ArrowUpDown
                            className={cn(
                              'ml-2 h-4 w-4',
                              sortConfig.key === 'value' &&
                                sortConfig.direction === 'desc' &&
                                'rotate-180 transform',
                            )}
                          />
                        </div>
                      </TableHead>
                      <TableHead className="w-[100px] text-right">
                        Actions
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {sortedMetadata.map((item) => (
                      <TableRow key={item.key}>
                        <TableCell className="font-medium whitespace-nowrap">
                          <div className="flex items-center gap-2">
                            {SYSTEM_METADATA_KEYS.includes(item.key) && (
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger>
                                    <Badge variant="secondary" className="h-5">
                                      System
                                    </Badge>
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    <p>System metadata cannot be deleted</p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            )}
                            <span>{item.key}</span>
                          </div>
                        </TableCell>
                        <TableCell className="max-w-md truncate">
                          <div className="max-w-md truncate" title={item.value}>
                            {item.value}
                          </div>
                        </TableCell>
                        <TableCell className="text-right">
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button
                                variant="ghost"
                                size="sm"
                                className="h-8 w-8 p-0"
                              >
                                <span className="sr-only">Open menu</span>
                                <ArrowUpDown className="h-4 w-4" />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                              <DropdownMenuItem
                                onClick={() => {
                                  navigator.clipboard.writeText(item.value);
                                  toast.success('Value copied to clipboard');
                                }}
                              >
                                Copy value
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() => {
                                  navigator.clipboard.writeText(item.key);
                                  toast.success('Key copied to clipboard');
                                }}
                              >
                                Copy key
                              </DropdownMenuItem>
                              {!SYSTEM_METADATA_KEYS.includes(item.key) && (
                                <DropdownMenuItem
                                  className="text-red-600"
                                  onClick={() => handleDeleteMetadata(item.key)}
                                >
                                  <Trash2 className="h-4 w-4 mr-2" />
                                  Delete
                                </DropdownMenuItem>
                              )}
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Card>
          </div>
        </ScrollArea>
      )}
    </div>
  );
};
