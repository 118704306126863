import { NAMESPACES, useTranslation } from '@/lib/i18n';
import {
  authenticationProvider,
  getCreditCardCount,
  getCreditCards,
  getNotifications,
  getPricingPlans,
  getUserMetadata,
  getWorkspaces,
  refreshAuth,
  setExpiredAuth,
  setIsAuthenticated,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { cn } from '@/lib/utils';
import { Link, useNavigate } from '@tanstack/react-router';
import { Loader2 } from 'lucide-react';
import { ReactNode, useEffect, useState } from 'react';
import { toast } from 'sonner';
import { Button } from './ui/button';

type AuthCheckProps = {
  children: ReactNode;
  fallback?: ReactNode;
};

export const AuthCheck = ({ children, fallback }: AuthCheckProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(NAMESPACES.AUTH);
  // const { pathname } = useLocation();
  const { expiredAuth, isAuthenticated, uid } = useAppSelector(
    (state) => state.auth,
  );

  const checkAuthStatus = async () => {
    try {
      const timeLeft = await authenticationProvider.pingUser();
      if (timeLeft <= 60000) {
        // TODO: Replaice with translation file
        toast.info(t('auth:AUTH.EXPIRY.TEXT'), {
          action: <ExtendAuthButton onSuccess={() => toast.dismiss()} />,
        });
      }
      if (timeLeft <= 0) {
        dispatch(setExpiredAuth(true));
        dispatch(setIsAuthenticated(false));
        toast.dismiss();
        return navigate({
          to: '/auth/lock',
          // from: pathname.split("?")[0],
          replace: true,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch(setExpiredAuth(true));
      dispatch(setIsAuthenticated(false));
      toast.dismiss();
      return navigate({
        to: '/auth/lock',
        // from: pathname.split("?")[0],
        replace: true,
      });
    }
  };

  useEffect(() => {
    if (!isAuthenticated && !expiredAuth) {
      navigate({
        to: '/auth/login',
        // from: pathname.split("?")[0],
        replace: true,
      });
    } else {
      console.log('Dispatching user metadata and workspaces related actions.');
      dispatch(getWorkspaces());
      dispatch(getNotifications());
      dispatch(getUserMetadata(uid));
      dispatch(getPricingPlans());
      dispatch(getCreditCards());
      dispatch(getCreditCardCount());
      checkAuthStatus(); // Call it right when component mounts
      const intervalId = setInterval(checkAuthStatus, 600000); // Call every 10 minutes
      return () => clearInterval(intervalId); // Clear the interval when the component unmounts
    }
  }, [navigate, isAuthenticated, expiredAuth]);

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <>{fallback}</> || <Link to="/auth/login">{t('auth:AUTH.FALLBACK')}</Link>
  );
};

const ExtendAuthButton = ({ onSuccess }: { onSuccess: () => void }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleExtend = async () => {
    try {
      setLoading(true);
      await dispatch(refreshAuth()).unwrap();
      onSuccess();
    } catch (error) {
      console.error('Error extending session:', error);
      toast.error('Failed to extend session. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      size="sm"
      variant="default"
      onClick={handleExtend}
      disabled={loading}
      className={cn(
        'bg-blue-500 hover:bg-blue-600 text-white',
        'min-w-[100px] relative',
      )}
    >
      {loading ? (
        <>
          <Loader2 className="h-4 w-4 animate-spin mr-2" />
          <span>Extending...</span>
        </>
      ) : (
        <span>{t('auth:AUTH.EXPIRY.BUTTON')}</span>
      )}
    </Button>
  );
};
