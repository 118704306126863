import {
  Entity,
  setLastOpenedEntity,
  setLastSelectedEntityId,
  setSelectedEntityIds,
  useAppDispatch,
  useAppSelector,
} from "@/lib/store";
import { useCallback, useEffect } from "react";

export const useEntitySelection = (entities: Entity[]) => {
  const dispatch = useAppDispatch();
  const { lastSelectedEntityId, selectedEntityIds } = useAppSelector(
    (state) => state.fileManager
  );
  const deletedEntities = useAppSelector(
    (state) => state.workspace.deletedEntities
  );

  useEffect(() => {
    if (
      selectedEntityIds.some((entityId) =>
        deletedEntities.some((deletion) => deletion.entityId === entityId)
      )
    ) {
      dispatch(setSelectedEntityIds([]));
    }
  }, [deletedEntities, selectedEntityIds, dispatch]);

  const handleSelectEntity = useCallback(
    (entityId: string, isShiftClick: boolean, isCtrlClick: boolean) => {
      if (isShiftClick && lastSelectedEntityId) {
        const startIndex = entities.findIndex(
          (entity) => entity.id === lastSelectedEntityId
        );
        const endIndex = entities.findIndex((entity) => entity.id === entityId);

        const range = entities.slice(
          Math.min(startIndex, endIndex),
          Math.max(startIndex, endIndex) + 1
        );
        const newSelectedIds = range.map((entity) => entity.id);

        dispatch(
          setSelectedEntityIds(
            Array.from(new Set([...selectedEntityIds, ...newSelectedIds]))
          )
        );
      } else if (isCtrlClick) {
        if (selectedEntityIds.includes(entityId)) {
          dispatch(
            setSelectedEntityIds(
              selectedEntityIds.filter((id) => id !== entityId)
            )
          );
        } else {
          dispatch(setSelectedEntityIds([...selectedEntityIds, entityId]));
        }
      } else {
        dispatch(setSelectedEntityIds([entityId]));
      }

      dispatch(setLastSelectedEntityId(entityId));

      const lastOpenedEntity = entities.find(
        (entity) => entity.id === entityId
      );
      if (lastOpenedEntity) {
        dispatch(setLastOpenedEntity(lastOpenedEntity));
      }
    },
    [entities, lastSelectedEntityId, selectedEntityIds, dispatch]
  );

  return {
    selectedEntityIds,
    handleSelectEntity,
  };
};
