import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { useTranslation } from "@/lib/i18n";
import {
  editWorkspaceName,
  getWorkspacePlan,
  toggleDisplayBundlesModal,
  toggleDisplayChangePlanModal,
  useAppDispatch,
  useAppSelector,
} from "@/lib/store";
import { zodResolver } from "@hookform/resolvers/zod";
import { motion } from "framer-motion";
import { CreditCard, Loader2, Package, Settings } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

const workspaceSchema = z.object({
  workspaceName: z.string().min(1, "Workspace name is required"),
});

type WorkspaceFormValues = z.infer<typeof workspaceSchema>;

export const WorkspaceOptionsGeneralTab = ({
  workspaceId,
}: {
  workspaceId: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const activePlan = useAppSelector((state) => state.workspace.plan !== null);
  const { metadata: workspaceMetadata, loading } = useAppSelector(
    (state) => state.workspace
  );

  const form = useForm<WorkspaceFormValues>({
    resolver: zodResolver(workspaceSchema),
    defaultValues: {
      workspaceName: workspaceMetadata?.name || "",
    },
  });

  useEffect(() => {
    if (workspaceMetadata?.name) {
      form.setValue("workspaceName", workspaceMetadata.name);
    }
  }, [workspaceMetadata]);

  useEffect(() => {
    if (workspaceId) {
      dispatch(getWorkspacePlan(workspaceId));
    }
  }, [workspaceId]);

  const onSubmit = (data: WorkspaceFormValues) => {
    dispatch(
      editWorkspaceName({
        workspaceId: workspaceId as string,
        workspaceName: data.workspaceName,
      })
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* General Settings Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Settings className="h-5 w-5" />
              General Settings
            </CardTitle>
            <CardDescription>
              Configure basic workspace properties and preferences
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-4"
              >
                <FormField
                  control={form.control}
                  name="workspaceName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Workspace Name</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Enter workspace name" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button type="submit" disabled={loading}>
                  {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                  Save Changes
                </Button>
              </form>
            </Form>
          </CardContent>
        </Card>
      </motion.div>

      {/* Plan & Billing Section */}
      {activePlan && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
        >
          <Card>
            <CardHeader>
              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  <CardTitle className="flex items-center gap-2">
                    <CreditCard className="h-5 w-5" />
                    Plan & Billing
                  </CardTitle>
                  <CardDescription>
                    Manage your subscription plan and payment details
                  </CardDescription>
                </div>
                <div className="flex items-center gap-3">
                  <Button
                    variant="outline"
                    onClick={() => dispatch(toggleDisplayBundlesModal())}
                  >
                    <Package className="h-4 w-4 mr-2" />
                    View Bundles
                  </Button>
                  <Button
                    onClick={() => dispatch(toggleDisplayChangePlanModal())}
                  >
                    Change Plan
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="space-y-6">
                <ManagePlans />
                <Separator />
                <PaymentHistory />
                <Separator />
                <CreditCards />
              </div>
            </CardContent>
          </Card>
        </motion.div>
      )}
    </div>
  );
};

// Child Components with modern styling
const ManagePlans = () => {
  const plan = useAppSelector((state) => state.workspace.plan);

  if (!plan) return null;

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Current Plan</h3>
      <div className="grid gap-4 md:grid-cols-2">
        <Card>
          <CardContent className="pt-6">
            <div className="text-2xl font-bold">{plan.name}</div>
            <p className="text-sm text-muted-foreground mt-1">
              {plan.meta?.["package-i18n-description"]}
            </p>
            <div className="mt-4">
              <div className="text-sm text-muted-foreground">Storage Limit</div>
              <div className="text-lg font-medium">
                {plan.meta?.["storage-limit"]} GB
              </div>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent className="pt-6">
            <div className="text-2xl font-bold">
              {plan.currency}
              {plan.amount}/mo
            </div>
            <p className="text-sm text-muted-foreground mt-1">
              Billing {plan.interval}ly
            </p>
            <div className="mt-4">
              <div className="text-sm text-muted-foreground">Members Limit</div>
              <div className="text-lg font-medium">
                {plan.meta?.["members-limit"]} users
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

const PaymentHistory = () => {
  const transactions = useAppSelector(
    (state) => state.finance.paymentTransactions
  );

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Payment History</h3>
      <div className="rounded-md border">
        <table className="min-w-full divide-y divide-border">
          <thead>
            <tr className="bg-muted/50">
              <th className="px-4 py-3 text-left text-sm font-medium">Date</th>
              <th className="px-4 py-3 text-left text-sm font-medium">
                Description
              </th>
              <th className="px-4 py-3 text-right text-sm font-medium">
                Amount
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-border">
            {transactions.map((transaction, i) => (
              <tr key={i}>
                <td className="px-4 py-3 text-sm">
                  {transaction.created
                    ? new Date(transaction.created * 1000).toLocaleDateString()
                    : "N/A"}
                </td>
                <td className="px-4 py-3 text-sm">{transaction.description}</td>
                <td className="px-4 py-3 text-sm text-right">
                  {transaction.amount
                    ? `${transaction.currency} ${transaction.amount / 100}`
                    : "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const CreditCards = () => {
  const cards = useAppSelector((state) => state.finance.creditCards);

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold">Payment Methods</h3>
        <Button variant="outline" size="sm">
          Add Card
        </Button>
      </div>
      <div className="grid gap-4">
        {cards.map((card, i) => (
          <Card key={i}>
            <CardContent className="pt-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div className="rounded-lg bg-muted p-2">
                    <CreditCard className="h-6 w-6" />
                  </div>
                  <div>
                    <div className="font-medium">
                      •••• •••• •••• {card.last4}
                    </div>
                    <div className="text-sm text-muted-foreground">
                      Expires {card.expMonth}/{card.expYear}
                    </div>
                  </div>
                </div>
                <Button variant="ghost" size="sm">
                  Edit
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};
