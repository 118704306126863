import { AppLayout } from '@/components/app-layout.component';
import { AuthCheck } from '@/components/auth-check.component';
import { Head } from '@/components/head.component';
import { FileManagerContainer } from '@/containers/$workspaceId/file-manager/file-manager-container.component';
import { FileManagerFiles } from '@/containers/$workspaceId/file-manager/file-manager-files.component';
import { useStarredEntities } from '@/lib/hooks/use-starred-entities.hook';
import { useTranslation } from '@/lib/i18n';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { Loader2 } from 'lucide-react';

export const Route = createFileRoute('/$workspaceId/file-manager/starred')({
  component: RouteComponent,
});

function RouteComponent() {
  const { t } = useTranslation();
  const { workspaceId } = useParams({ strict: false });
  const { starredEntities, loading } = useStarredEntities(
    workspaceId as string,
  );
  return (
    <>
      <Head heading={t('file-manager:FILE_MANAGER.TITLE')} />
      <AuthCheck>
        <AppLayout>
          <FileManagerContainer>
            {loading ? (
              <div className="flex items-center justify-center h-full">
                <Loader2 className="h-4 w-4 animate-spin mr-2" />
              </div>
            ) : (
              <FileManagerFiles
                entities={starredEntities}
                entityDisplayType="list"
              />
            )}
          </FileManagerContainer>
        </AppLayout>
      </AuthCheck>
    </>
  );
}
