import {
  MemberInfo,
  NotificationMessage,
} from "@oproma/prividox-orchestration-open-api";
import { UserResponseSpec } from "../user/types";

export enum MembersErrorCodesEnum {
  GET_WORKSPACE_MEMBER_FAILED = "GET_WORKSPACE_MEMBER_FAILED",
  GET_WORKSPACE_MEMBER_REPORT_FAILED = "GET_WORKSPACE_MEMBER_REPORT_FAILED",
  GET_WORKSPACE_MEMBERS_FAILED = "GET_WORKSPACE_MEMBERS_FAILED",
  CREATE_WORKSPACE_MEMBERS_FAILED = "CREATE_WORKSPACE_MEMBERS_FAILED",
  EDIT_WORKSPACE_MEMBER_FAILED = "EDIT_WORKSPACE_MEMBER_FAILED",
  DELETE_WORKSPACE_MEMBER_FAILED = "DELETE_WORKSPACE_MEMBER_FAILED",
}

export type SortOrder = "asc" | "desc";

export interface WorkspaceMember extends MemberInfo {
  // Custom fields
  thumbnailBackground: string;
  thumbnail: string;
  checked: boolean;
}

export interface IMembersState {
  readonly member: UserResponseSpec | null;
  readonly members: WorkspaceMember[];
  readonly memberQuery: string;
  readonly permissionLevelFilter: number | null;
  readonly memberSortOrder: SortOrder;
  readonly memberSortValue: string;
  readonly currentPaginatedPage: number;
  readonly entriesPerPaginatedPage: number;
  readonly onboardedMembers: string[];
  readonly lastOpenedMember: string | null;
  readonly memberReport: NotificationMessage[];
  readonly memberActivityContent: [string, NotificationMessage][];
  readonly displayMemberFinder: boolean;
  readonly displayMobileMembersTable: boolean;
  readonly displayOnboardMembersModal: boolean;
  readonly displayEditMemberModal: boolean;
  readonly displayRemoveMemberModal: boolean;
  readonly displayBulkRemoveMemberModal: boolean;
  readonly toastMessage: string | null;
  readonly deletedMemberMessage: boolean;
  readonly loading: boolean;
  readonly error: {
    code: MembersErrorCodesEnum;
    message: string;
  } | null;
}
