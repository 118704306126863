/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type { BatchUpdateSpec, ValueSpec } from "../models/index";
import {
  BatchUpdateSpecFromJSON,
  BatchUpdateSpecToJSON,
  ValueSpecFromJSON,
  ValueSpecToJSON,
} from "../models/index";

export interface DeleteMetadataRequest {
  entity: string;
  key: string;
}

export interface GetEntityMetadataRequest {
  entity: string;
}

export interface GetInternalMetadataRequest {
  entity: string;
}

export interface GetMetadataKeyRequest {
  entity: string;
  key: string;
}

export interface SetBatchMetadataRequest {
  batchUpdateSpec: BatchUpdateSpec;
}

export interface SetMetadataRequest {
  entity: string;
  key: string;
  valueSpec: ValueSpec;
}

export interface SetMultipleMetadataRequest {
  entity: string;
  requestBody: { [key: string]: string };
}

/**
 *
 */
export class MetadataApi extends runtime.BaseAPI {
  /**
   * Deletes the value associated to the specified metadata key for the specified entity.
   * Delete Metadata
   */
  async deleteMetadataRaw(
    requestParameters: DeleteMetadataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["entity"] == null) {
      throw new runtime.RequiredError(
        "entity",
        'Required parameter "entity" was null or undefined when calling deleteMetadata().',
      );
    }

    if (requestParameters["key"] == null) {
      throw new runtime.RequiredError(
        "key",
        'Required parameter "key" was null or undefined when calling deleteMetadata().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/metadata/{entity}/{key}`
          .replace(
            `{${"entity"}}`,
            encodeURIComponent(String(requestParameters["entity"])),
          )
          .replace(
            `{${"key"}}`,
            encodeURIComponent(String(requestParameters["key"])),
          ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Deletes the value associated to the specified metadata key for the specified entity.
   * Delete Metadata
   */
  async deleteMetadata(
    requestParameters: DeleteMetadataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteMetadataRaw(requestParameters, initOverrides);
  }

  /**
   * Retrieves metadata about the specified entity
   * Get Entity Metadata
   */
  async getEntityMetadataRaw(
    requestParameters: GetEntityMetadataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: object }>> {
    if (requestParameters["entity"] == null) {
      throw new runtime.RequiredError(
        "entity",
        'Required parameter "entity" was null or undefined when calling getEntityMetadata().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/metadata/{entity}`.replace(
          `{${"entity"}}`,
          encodeURIComponent(String(requestParameters["entity"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Retrieves metadata about the specified entity
   * Get Entity Metadata
   */
  async getEntityMetadata(
    requestParameters: GetEntityMetadataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: object }> {
    const response = await this.getEntityMetadataRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves the intrinsic metadata of the specified file.
   * Get Internal Metadata
   */
  async getInternalMetadataRaw(
    requestParameters: GetInternalMetadataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: string }>> {
    if (requestParameters["entity"] == null) {
      throw new runtime.RequiredError(
        "entity",
        'Required parameter "entity" was null or undefined when calling getInternalMetadata().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/metadata/{entity}/internal`.replace(
          `{${"entity"}}`,
          encodeURIComponent(String(requestParameters["entity"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Retrieves the intrinsic metadata of the specified file.
   * Get Internal Metadata
   */
  async getInternalMetadata(
    requestParameters: GetInternalMetadataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: string }> {
    const response = await this.getInternalMetadataRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves the value associated to the specified metadata key for the specified entity.
   * Get Specific Metadata
   */
  async getMetadataKeyRaw(
    requestParameters: GetMetadataKeyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ValueSpec>> {
    if (requestParameters["entity"] == null) {
      throw new runtime.RequiredError(
        "entity",
        'Required parameter "entity" was null or undefined when calling getMetadataKey().',
      );
    }

    if (requestParameters["key"] == null) {
      throw new runtime.RequiredError(
        "key",
        'Required parameter "key" was null or undefined when calling getMetadataKey().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/metadata/{entity}/{key}`
          .replace(
            `{${"entity"}}`,
            encodeURIComponent(String(requestParameters["entity"])),
          )
          .replace(
            `{${"key"}}`,
            encodeURIComponent(String(requestParameters["key"])),
          ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ValueSpecFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves the value associated to the specified metadata key for the specified entity.
   * Get Specific Metadata
   */
  async getMetadataKey(
    requestParameters: GetMetadataKeyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ValueSpec> {
    const response = await this.getMetadataKeyRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves metadata about the current user
   * Get Own Metadata
   */
  async getOwnMetadataRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: object }>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/metadata`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Retrieves metadata about the current user
   * Get Own Metadata
   */
  async getOwnMetadata(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: object }> {
    const response = await this.getOwnMetadataRaw(initOverrides);
    return await response.value();
  }

  /**
   * Associates the provided metadata with all entities matching the provided search query.
   * Set Batch Metadata
   */
  async setBatchMetadataRaw(
    requestParameters: SetBatchMetadataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<{ [key: string]: { [key: string]: string } }>
  > {
    if (requestParameters["batchUpdateSpec"] == null) {
      throw new runtime.RequiredError(
        "batchUpdateSpec",
        'Required parameter "batchUpdateSpec" was null or undefined when calling setBatchMetadata().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/metadata`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: BatchUpdateSpecToJSON(requestParameters["batchUpdateSpec"]),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Associates the provided metadata with all entities matching the provided search query.
   * Set Batch Metadata
   */
  async setBatchMetadata(
    requestParameters: SetBatchMetadataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: { [key: string]: string } }> {
    const response = await this.setBatchMetadataRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Sets the provided metadata value for the specified entity.
   * Set Metadata
   */
  async setMetadataRaw(
    requestParameters: SetMetadataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["entity"] == null) {
      throw new runtime.RequiredError(
        "entity",
        'Required parameter "entity" was null or undefined when calling setMetadata().',
      );
    }

    if (requestParameters["key"] == null) {
      throw new runtime.RequiredError(
        "key",
        'Required parameter "key" was null or undefined when calling setMetadata().',
      );
    }

    if (requestParameters["valueSpec"] == null) {
      throw new runtime.RequiredError(
        "valueSpec",
        'Required parameter "valueSpec" was null or undefined when calling setMetadata().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/metadata/{entity}/{key}`
          .replace(
            `{${"entity"}}`,
            encodeURIComponent(String(requestParameters["entity"])),
          )
          .replace(
            `{${"key"}}`,
            encodeURIComponent(String(requestParameters["key"])),
          ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: ValueSpecToJSON(requestParameters["valueSpec"]),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Sets the provided metadata value for the specified entity.
   * Set Metadata
   */
  async setMetadata(
    requestParameters: SetMetadataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.setMetadataRaw(requestParameters, initOverrides);
  }

  /**
   * Associates the provided metadata with the specified entity.
   * Set Multiple Metadata
   */
  async setMultipleMetadataRaw(
    requestParameters: SetMultipleMetadataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: string }>> {
    if (requestParameters["entity"] == null) {
      throw new runtime.RequiredError(
        "entity",
        'Required parameter "entity" was null or undefined when calling setMultipleMetadata().',
      );
    }

    if (requestParameters["requestBody"] == null) {
      throw new runtime.RequiredError(
        "requestBody",
        'Required parameter "requestBody" was null or undefined when calling setMultipleMetadata().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/metadata/{entity}`.replace(
          `{${"entity"}}`,
          encodeURIComponent(String(requestParameters["entity"])),
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters["requestBody"],
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Associates the provided metadata with the specified entity.
   * Set Multiple Metadata
   */
  async setMultipleMetadata(
    requestParameters: SetMultipleMetadataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: string }> {
    const response = await this.setMultipleMetadataRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
