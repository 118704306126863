/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UpdateNameSpec
 */
export interface UpdateNameSpec {
  /**
   * The new display name to give to the user.
   * @type {string}
   * @memberof UpdateNameSpec
   */
  name: string;
}

/**
 * Check if a given object implements the UpdateNameSpec interface.
 */
export function instanceOfUpdateNameSpec(
  value: object,
): value is UpdateNameSpec {
  if (!("name" in value) || value["name"] === undefined) return false;
  return true;
}

export function UpdateNameSpecFromJSON(json: any): UpdateNameSpec {
  return UpdateNameSpecFromJSONTyped(json, false);
}

export function UpdateNameSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdateNameSpec {
  if (json == null) {
    return json;
  }
  return {
    name: json["name"],
  };
}

export function UpdateNameSpecToJSON(json: any): UpdateNameSpec {
  return UpdateNameSpecToJSONTyped(json, false);
}

export function UpdateNameSpecToJSONTyped(
  value?: UpdateNameSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value["name"],
  };
}
