import { ScrollArea } from "@/components/ui/scroll-area";
import { Entity, filesProvider } from "@/lib/store";
import hljs from "highlight.js";
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";

interface TextPreviewProps {
  entity: Entity;
}

export const TextPreview = ({ entity }: TextPreviewProps) => {
  const [content, setContent] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        setLoading(true);
        const response = await filesProvider.previewFile({
          entity: entity.id,
        });
        setContent(response);
      } catch (error) {
        console.error("Failed to fetch preview:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchContent();
  }, [entity]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader2 className="w-8 h-8 animate-spin text-muted-foreground" />
      </div>
    );
  }

  const renderContent = () => {
    switch (entity.contentType) {
      case "application/javascript":
      case "text/x-config":
        return (
          <div className="rounded-lg overflow-hidden">
            <pre className="p-4 bg-zinc-950">
              <code
                className="language-javascript"
                dangerouslySetInnerHTML={{
                  __html: hljs.highlight(content ?? "No Preview", {
                    language: "javascript",
                  }).value,
                }}
              />
            </pre>
          </div>
        );
      case "text/markdown":
      case "text/plain":
        return (
          <pre className="p-4 whitespace-pre-wrap font-mono text-sm">
            {content ?? "Preview couldn't be generated"}
          </pre>
        );
      default:
        return (
          <div className="flex items-center justify-center h-full text-muted-foreground">
            Preview not available
          </div>
        );
    }
  };

  return (
    <ScrollArea className="h-full w-full">
      <div className="p-4">{renderContent()}</div>
    </ScrollArea>
  );
};
