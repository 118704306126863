import {
  Column,
  editTaskMetadata,
  setColumnOrder,
  setColumns,
  useAppDispatch,
} from '@/lib/store';
import { useCallback } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { toast } from 'sonner';

export const useTasksBoardDrag = (
  columns: Column[],
  columnOrder: string[],
  workspaceId?: string,
) => {
  const dispatch = useAppDispatch();

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination, draggableId, type } = result;

      if (
        !destination ||
        (destination.droppableId === source.droppableId &&
          destination.index === source.index)
      ) {
        return;
      }

      // Handle column reordering
      if (type === 'column') {
        const newColumnOrder = Array.from(columnOrder);
        newColumnOrder.splice(source.index, 1);
        newColumnOrder.splice(destination.index, 0, draggableId);
        dispatch(setColumnOrder(newColumnOrder));
        return;
      }

      // Handle task movement
      const home = columns.find((col) => col.id === source.droppableId);
      const foreign = columns.find((col) => col.id === destination.droppableId);

      if (!home || !foreign) return;

      if (home === foreign) {
        // Same column movement
        const tasks = Array.from(home.tasks);
        tasks.splice(source.index, 1);
        tasks.splice(destination.index, 0, draggableId);

        const index = columns.findIndex((col) => col.id === home.id);
        if (index === -1) return;

        const newColumns = Array.from(columns);
        newColumns[index].tasks = tasks;

        // Update task metadata
        tasks.forEach((taskId, order) => {
          if (!workspaceId) return;
          dispatch(
            editTaskMetadata({
              taskId,
              workspaceId,
              columnTheme: home.theme,
              order: order.toString(),
              columnId: home.id,
              columnName: home.name,
              columnOrder: index.toString(),
            }),
          );
        });

        dispatch(setColumns(newColumns));
        toast.success('Task moved successfully');
      } else {
        // Cross-column movement
        const homeTasks = Array.from(home.tasks);
        const foreignTasks = Array.from(foreign.tasks);

        homeTasks.splice(source.index, 1);
        foreignTasks.splice(destination.index, 0, draggableId);

        const homeIndex = columns.findIndex((col) => col.id === home.id);
        const foreignIndex = columns.findIndex((col) => col.id === foreign.id);

        if (homeIndex === -1 || foreignIndex === -1) return;

        const newColumns = Array.from(columns);
        newColumns[homeIndex] = { ...newColumns[homeIndex], tasks: homeTasks };
        newColumns[foreignIndex] = {
          ...newColumns[foreignIndex],
          tasks: foreignTasks,
        };

        // Update task metadata for both columns
        [homeTasks, foreignTasks].forEach((tasks, index) => {
          const column = newColumns[index === 0 ? homeIndex : foreignIndex];
          tasks.forEach((taskId, order) => {
            if (!workspaceId) return;
            dispatch(
              editTaskMetadata({
                taskId,
                workspaceId,
                columnTheme: column.theme,
                order: order.toString(),
                columnId: column.id,
                columnName: column.name,
                columnOrder: index.toString(),
              }),
            );
          });
        });

        dispatch(setColumns(newColumns));
        toast.success('Task moved to new column');
      }
    },
    [columns, columnOrder, workspaceId, dispatch],
  );

  return { handleDragEnd };
};
