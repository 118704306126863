/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Progress } from '@/components/ui/progress';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { FileTreeNode, useCreateFile } from '@/lib/hooks/use-create-file.hook';
import { useUploadModalVisibility } from '@/lib/hooks/use-upload-modal-visibility.hook';
import { NAMESPACES, useTranslation } from '@/lib/i18n';
import { cn, prettyBytes } from '@/lib/utils';
import { useParams } from '@tanstack/react-router';
import { AnimatePresence, motion } from 'framer-motion';
import {
  AlertCircle,
  CheckIcon,
  FileIcon,
  FolderIcon,
  FolderUp,
  Loader2,
  Minimize2,
  Shield,
  X,
} from 'lucide-react';
import { memo } from 'react';

interface FileTreeProps {
  node: FileTreeNode;
  onRemove: (filepath: string, index: number) => void;
  index: number;
  processUid: string;
  cancelUpload: (processUid: string) => void;
}

const FileTreeNodes = ({
  node,
  onRemove,
  index,
  processUid,
  cancelUpload,
}: FileTreeProps) => {
  const { uploadStates } = useCreateFile();
  const uploadState = uploadStates[processUid];
  const isUploading = uploadState?.status === 'uploading';

  const renderFileItem = (fileNode: FileTreeNode) => (
    <div className="flex items-center gap-2 p-2">
      <div className="p-2 rounded-lg bg-muted">
        <FileIcon className="h-4 w-4 text-muted-foreground" />
      </div>
      <div className="flex-1 min-w-0">
        <span className="text-sm font-medium truncate">{fileNode.name}</span>
        <div className="text-xs text-muted-foreground">
          {prettyBytes(fileNode.file?.size || 0)}
        </div>
      </div>
      {uploadState && (
        <div className="flex items-center gap-2">
          {isUploading ? (
            <>
              <Progress value={uploadState.progress} className="w-24" />
              <Button
                size="icon"
                variant="ghost"
                className="h-8 w-8"
                onClick={() => cancelUpload(processUid)}
              >
                <X className="h-4 w-4" />
              </Button>
            </>
          ) : uploadState.status === 'completed' ? (
            <CheckIcon className="h-4 w-4 text-primary" />
          ) : uploadState.status === 'error' ? (
            <AlertCircle className="h-4 w-4 text-destructive" />
          ) : null}
        </div>
      )}
      {!uploadState && (
        <Button
          size="icon"
          variant="ghost"
          className="h-8 w-8"
          onClick={() => onRemove(fileNode.filepath!, index)}
        >
          <X className="h-4 w-4" />
        </Button>
      )}
    </div>
  );

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      className={cn(
        'relative rounded-lg border border-border/50',
        'transition-all duration-200',
        isUploading && 'bg-primary/5',
      )}
    >
      {node.type === 'folder' ? (
        <div className="space-y-2">
          {/* Folder Header */}
          <div className="flex items-center gap-2 p-3 bg-muted/50 rounded-t-lg">
            <div className="p-2 rounded-lg bg-muted">
              <FolderIcon className="h-4 w-4 text-muted-foreground" />
            </div>
            <span className="text-sm font-medium">{node.name}</span>
          </div>

          {/* Nested Content */}
          {node.children && node.children.length > 0 && (
            <div className="pl-4 pr-2 pb-2 space-y-2">
              {node.children.map((child, childIndex) => (
                <div
                  key={child.filepath || childIndex}
                  className="border-l-2 border-border/30 pl-4"
                >
                  {child.type === 'folder' ? (
                    <FileTreeNodes
                      node={child}
                      onRemove={onRemove}
                      index={index}
                      processUid={processUid}
                      cancelUpload={cancelUpload}
                    />
                  ) : (
                    renderFileItem(child)
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        renderFileItem(node)
      )}
    </motion.div>
  );
};

export const CreateFileModal = memo(() => {
  const { t } = useTranslation(NAMESPACES.FILE_MANAGER);
  const { entityId, workspaceId } = useParams({ strict: false });
  const { isVisible, isEnabled } = useUploadModalVisibility();

  const {
    isMinimized,
    isDragging,
    uploading,
    isUploadEnabled,
    queuedFiles,
    totalProgress,
    isAnyFileUploading,
    accessLevel,
    openedWorkspace,
    dropzoneRef,
    fileInputRef,
    watch,
    setValue,
    buildFileTree,
    handleDrop,
    handleFileSelect,
    persistFiles,
    removeFromList,
    cancelUpload,
    cancelAllUploads,
    toggleMinimize,
    setIsDragging,
    publishableUids,
  } = useCreateFile(entityId, workspaceId);

  const securityLevels = [
    {
      value: 'NONE',
      label: t('file-manager:FILE_MANAGER.FILE_PREVIEW.SECURITY_LEVEL.NONE'),
    },
    {
      value: 'PROTECTED_A',
      label: t(
        'file-manager:FILE_MANAGER.FILE_PREVIEW.SECURITY_LEVEL.PROTECTED_A',
      ),
    },
    {
      value: 'PROTECTED_B',
      label: t(
        'file-manager:FILE_MANAGER.FILE_PREVIEW.SECURITY_LEVEL.PROTECTED_B',
      ),
    },
  ];

  if (!isVisible) {
    return null;
  }

  const MinimizedUploader = () => {
    const status = {
      icon: isAnyFileUploading ? Loader2 : FolderUp,
      className: isAnyFileUploading ? 'animate-spin' : '',
      title: isAnyFileUploading
        ? t('file-manager:FILE_MANAGER.UPLOAD_MODAL.UPLOADING')
        : t('file-manager:FILE_MANAGER.UPLOAD_MODAL.TITLE'),
    };

    return (
      <div
        className={cn(
          'fixed bottom-4 right-4 flex items-center gap-2 p-2 rounded-full shadow-lg',
          'bg-primary text-primary-foreground',
          'transition-colors hover:bg-primary/90 z-9999999',
          (!isEnabled || !isUploadEnabled) && 'opacity-50 cursor-not-allowed',
          isEnabled ? 'cursor-pointer' : 'cursor-not-allowed',
        )}
        onClick={isEnabled && isUploadEnabled ? toggleMinimize : undefined}
        title={
          !isEnabled
            ? t('file-manager:FILE_MANAGER.ERRORS.DISABLED_LOCATION')
            : !isUploadEnabled
              ? t('file-manager:FILE_MANAGER.ERRORS.INVALID_LOCATION')
              : status.title
        }
      >
        <status.icon className={cn('h-8 w-8 text-white', status.className)} />
        {queuedFiles.length > 0 && (
          <span className="min-w-[1.5rem] h-6 flex items-center justify-center rounded-full bg-primary-foreground/20">
            {queuedFiles.length}
          </span>
        )}
        {isAnyFileUploading && (
          <div className="absolute -bottom-1 left-0 w-full h-1 bg-primary-foreground/20 rounded-full overflow-hidden">
            <div
              className="h-full bg-primary-foreground/40 transition-all duration-300"
              style={{ width: `${totalProgress}%` }}
            />
          </div>
        )}
      </div>
    );
  };

  if (isMinimized) {
    return <MinimizedUploader />;
  }

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      className={cn(
        'fixed bottom-4 right-4 w-[480px] rounded-xl border bg-background shadow-lg',
        !isEnabled && 'opacity-50 pointer-events-none',
      )}
    >
      {/* Header */}
      <div className="relative px-6 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="p-2 rounded-lg bg-primary/10">
              <FolderUp className="h-5 w-5 text-primary" />
            </div>
            <div>
              <h2 className="text-lg font-semibold">
                {t('file-manager:FILE_MANAGER.UPLOAD_MODAL.TITLE')}
              </h2>
              <p className="text-sm text-muted-foreground">
                {t('file-manager:FILE_MANAGER.UPLOAD_MODAL.SUBTITLE')}
              </p>
            </div>
          </div>
          <Button
            variant="ghost"
            size="icon"
            onClick={toggleMinimize}
            className="h-8 w-8"
          >
            <Minimize2 className="h-4 w-4" />
          </Button>
        </div>
        <Separator className="mt-4" />
      </div>

      {/* Content */}
      <div className="p-6 space-y-6">
        {!isEnabled ? (
          <Alert variant="destructive">
            <AlertDescription>
              {t('file-manager:FILE_MANAGER.ERRORS.DISABLED_LOCATION')}
            </AlertDescription>
          </Alert>
        ) : !isUploadEnabled ? (
          <Alert variant="destructive">
            <AlertDescription>
              {t('file-manager:FILE_MANAGER.ERRORS.INVALID_LOCATION')}
            </AlertDescription>
          </Alert>
        ) : (
          <>
            {/* Security Level Selector */}
            {openedWorkspace?.access === 'MIXED' &&
              (accessLevel?.toString() === '127' ||
                accessLevel?.toString() === '126' ||
                accessLevel?.toString() === '0') && (
                <div className="flex items-center gap-2">
                  <Shield className="h-4 w-4 text-muted-foreground" />
                  <Select
                    value={watch('securityLevel')}
                    onValueChange={(value) => setValue('securityLevel', value)}
                  >
                    <SelectTrigger className="w-[180px]">
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      {securityLevels.map((level) => (
                        <SelectItem key={level.value} value={level.value}>
                          {t(level.label)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}

            {/* Dropzone */}
            <div
              ref={dropzoneRef}
              className={cn(
                'relative flex flex-col items-center justify-center gap-4',
                'p-8 border-2 border-dashed rounded-lg',
                'transition-colors duration-200',
                isDragging ? 'border-primary bg-primary/5' : 'border-border',
                'hover:border-primary hover:bg-primary/5',
              )}
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={() => setIsDragging(true)}
              onDragLeave={() => setIsDragging(false)}
              onClick={() => fileInputRef.current?.click()}
            >
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileSelect}
                className="hidden"
                accept="*"
                multiple
                // @ts-expect-error
                webkitdirectory="true"
                // mozdirectory="true"
                // directory="true"
              />
              <FolderUp className="h-8 w-8 text-muted-foreground" />
              <p
                className="text-center text-sm text-muted-foreground"
                dangerouslySetInnerHTML={{
                  __html: t(
                    'file-manager:FILE_MANAGER.UPLOAD_MODAL.PLACEHOLDER',
                  ),
                }}
              />
            </div>

            {/* File List */}
            {queuedFiles.length > 0 && (
              <>
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium">
                    {t('file-manager:FILE_MANAGER.UPLOAD_MODAL.HEADING')}
                  </h3>
                  <span className="text-sm text-muted-foreground">
                    {queuedFiles.length} files
                  </span>
                </div>
                <ScrollArea className="h-[300px] pr-4">
                  <AnimatePresence>
                    <div className="space-y-4">
                      {buildFileTree(queuedFiles).map((node, index) => (
                        <FileTreeNodes
                          key={node.filepath || index}
                          node={node}
                          onRemove={removeFromList}
                          index={index}
                          processUid={publishableUids[index]}
                          cancelUpload={cancelUpload}
                        />
                      ))}
                    </div>
                  </AnimatePresence>
                </ScrollArea>
              </>
            )}

            {/* Actions */}
            <div className="flex items-center justify-end gap-2">
              {isAnyFileUploading && (
                <Button variant="destructive" onClick={cancelAllUploads}>
                  Cancel All
                </Button>
              )}
              <Button
                onClick={persistFiles}
                disabled={uploading || queuedFiles.length === 0}
                className="min-w-[100px] text-white"
              >
                {uploading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    {t('file-manager:FILE_MANAGER.UPLOAD_MODAL.UPLOADING')}
                  </>
                ) : (
                  t('file-manager:FILE_MANAGER.UPLOAD_MODAL.UPLOAD_BUTTON')
                )}
              </Button>
            </div>
          </>
        )}
      </div>
    </motion.div>
  );
});
