/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  ResultCardInfo,
  ResultPlanInformation,
  TokenSpec,
  TransactionList,
  UpdateCardSpec,
  ValidateCouponSpec,
} from "../models/index";
import {
  ResultCardInfoFromJSON,
  ResultCardInfoToJSON,
  ResultPlanInformationFromJSON,
  ResultPlanInformationToJSON,
  TokenSpecFromJSON,
  TokenSpecToJSON,
  TransactionListFromJSON,
  TransactionListToJSON,
  UpdateCardSpecFromJSON,
  UpdateCardSpecToJSON,
  ValidateCouponSpecFromJSON,
  ValidateCouponSpecToJSON,
} from "../models/index";

export interface AddCCardRequest {
  token: string;
  tokenSpec: TokenSpec;
}

export interface DeleteCCardRequest {
  card: string;
  token?: string;
}

export interface ListCCardsRequest {
  token: string;
}

export interface ListTransactionRequest {
  token: string;
  start?: string;
  end?: string;
}

export interface UpdateCCardRequest {
  card: string;
  token: string;
  updateCardSpec: UpdateCardSpec;
}

export interface ValidateCouponRequest {
  validateCouponSpec: ValidateCouponSpec;
}

/**
 *
 */
export class FinanceApi extends runtime.BaseAPI {
  /**
   * Adds a new credit card to be associated with the current user\'s account.
   * Add Card
   */
  async addCCardRaw(
    requestParameters: AddCCardRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["token"] == null) {
      throw new runtime.RequiredError(
        "token",
        'Required parameter "token" was null or undefined when calling addCCard().',
      );
    }

    if (requestParameters["tokenSpec"] == null) {
      throw new runtime.RequiredError(
        "tokenSpec",
        'Required parameter "tokenSpec" was null or undefined when calling addCCard().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters["token"] != null) {
      headerParameters["token"] = String(requestParameters["token"]);
    }

    const response = await this.request(
      {
        path: `/finance/cards`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: TokenSpecToJSON(requestParameters["tokenSpec"]),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Adds a new credit card to be associated with the current user\'s account.
   * Add Card
   */
  async addCCard(
    requestParameters: AddCCardRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.addCCardRaw(requestParameters, initOverrides);
  }

  /**
   * Unlinks the specified credit card from the current user\'s account.
   * Delete Card
   */
  async deleteCCardRaw(
    requestParameters: DeleteCCardRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["card"] == null) {
      throw new runtime.RequiredError(
        "card",
        'Required parameter "card" was null or undefined when calling deleteCCard().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters["token"] != null) {
      headerParameters["token"] = String(requestParameters["token"]);
    }

    const response = await this.request(
      {
        path: `/finance/cards/{card}`.replace(
          `{${"card"}}`,
          encodeURIComponent(String(requestParameters["card"])),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Unlinks the specified credit card from the current user\'s account.
   * Delete Card
   */
  async deleteCCard(
    requestParameters: DeleteCCardRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteCCardRaw(requestParameters, initOverrides);
  }

  /**
   * Retrieves a count of the number of credit cards associated with the current user.
   * Get Card Count
   */
  async getCCardCountRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<number>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/finance/cards/count`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get("content-type"))) {
      return new runtime.JSONApiResponse<number>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Retrieves a count of the number of credit cards associated with the current user.
   * Get Card Count
   */
  async getCCardCount(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<number> {
    const response = await this.getCCardCountRaw(initOverrides);
    return await response.value();
  }

  /**
   * Retrieves a list of credit cards associated with the current user\'s account.
   * List Cards
   */
  async listCCardsRaw(
    requestParameters: ListCCardsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultCardInfo>> {
    if (requestParameters["token"] == null) {
      throw new runtime.RequiredError(
        "token",
        'Required parameter "token" was null or undefined when calling listCCards().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters["token"] != null) {
      headerParameters["token"] = String(requestParameters["token"]);
    }

    const response = await this.request(
      {
        path: `/finance/cards`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultCardInfoFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves a list of credit cards associated with the current user\'s account.
   * List Cards
   */
  async listCCards(
    requestParameters: ListCCardsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultCardInfo> {
    const response = await this.listCCardsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Retrieves a list of available workspace plans.
   * List Plans
   */
  async listPlansRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultPlanInformation>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/finance/plans`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultPlanInformationFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves a list of available workspace plans.
   * List Plans
   */
  async listPlans(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultPlanInformation> {
    const response = await this.listPlansRaw(initOverrides);
    return await response.value();
  }

  /**
   * Retrieves a list of transactions associated with the current user\'s account.
   * List Transactions
   */
  async listTransactionRaw(
    requestParameters: ListTransactionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TransactionList>> {
    if (requestParameters["token"] == null) {
      throw new runtime.RequiredError(
        "token",
        'Required parameter "token" was null or undefined when calling listTransaction().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters["start"] != null) {
      queryParameters["start"] = requestParameters["start"];
    }

    if (requestParameters["end"] != null) {
      queryParameters["end"] = requestParameters["end"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters["token"] != null) {
      headerParameters["token"] = String(requestParameters["token"]);
    }

    const response = await this.request(
      {
        path: `/finance/transactions`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TransactionListFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves a list of transactions associated with the current user\'s account.
   * List Transactions
   */
  async listTransaction(
    requestParameters: ListTransactionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TransactionList> {
    const response = await this.listTransactionRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Updates the specified credit card with the provided information.
   * Update Card
   */
  async updateCCardRaw(
    requestParameters: UpdateCCardRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["card"] == null) {
      throw new runtime.RequiredError(
        "card",
        'Required parameter "card" was null or undefined when calling updateCCard().',
      );
    }

    if (requestParameters["token"] == null) {
      throw new runtime.RequiredError(
        "token",
        'Required parameter "token" was null or undefined when calling updateCCard().',
      );
    }

    if (requestParameters["updateCardSpec"] == null) {
      throw new runtime.RequiredError(
        "updateCardSpec",
        'Required parameter "updateCardSpec" was null or undefined when calling updateCCard().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters["token"] != null) {
      headerParameters["token"] = String(requestParameters["token"]);
    }

    const response = await this.request(
      {
        path: `/finance/cards/{card}`.replace(
          `{${"card"}}`,
          encodeURIComponent(String(requestParameters["card"])),
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateCardSpecToJSON(requestParameters["updateCardSpec"]),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates the specified credit card with the provided information.
   * Update Card
   */
  async updateCCard(
    requestParameters: UpdateCCardRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateCCardRaw(requestParameters, initOverrides);
  }

  /**
   * Validates the provided coupon code.
   * Validate Coupon
   */
  async validateCouponRaw(
    requestParameters: ValidateCouponRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<boolean>> {
    if (requestParameters["validateCouponSpec"] == null) {
      throw new runtime.RequiredError(
        "validateCouponSpec",
        'Required parameter "validateCouponSpec" was null or undefined when calling validateCoupon().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/finance/coupons/validate`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ValidateCouponSpecToJSON(requestParameters["validateCouponSpec"]),
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get("content-type"))) {
      return new runtime.JSONApiResponse<boolean>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Validates the provided coupon code.
   * Validate Coupon
   */
  async validateCoupon(
    requestParameters: ValidateCouponRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<boolean> {
    const response = await this.validateCouponRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
