import { useLocation } from '@tanstack/react-router';

interface UploadModalState {
  isVisible: boolean;
  isEnabled: boolean;
}

export const useUploadModalVisibility = (): UploadModalState => {
  const { pathname } = useLocation();

  // Routes where the upload modal should be hidden
  const hiddenRoutes = [
    '/',
    '/preferences',
    '/preferences/personal',
    '/preferences/notifications',
    '/preferences/devices',
    '/preferences/privacy',
  ];

  // Routes where the upload modal should be disabled (visible but not interactive)
  const disabledRoutes = ['/trash', '/starred'];

  const isVisible = !hiddenRoutes.some(
    (route) => pathname === route || pathname.startsWith(`${route}/`),
  );

  const isEnabled = !disabledRoutes.some(
    (route) => pathname === route || pathname.startsWith(`${route}/`),
  );

  return {
    isVisible,
    isEnabled,
  };
};
