/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface NameSpec
 */
export interface NameSpec {
  /**
   * The name to give to the group.
   * @type {string}
   * @memberof NameSpec
   */
  name: string;
}

/**
 * Check if a given object implements the NameSpec interface.
 */
export function instanceOfNameSpec(value: object): value is NameSpec {
  if (!("name" in value) || value["name"] === undefined) return false;
  return true;
}

export function NameSpecFromJSON(json: any): NameSpec {
  return NameSpecFromJSONTyped(json, false);
}

export function NameSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): NameSpec {
  if (json == null) {
    return json;
  }
  return {
    name: json["name"],
  };
}

export function NameSpecToJSON(json: any): NameSpec {
  return NameSpecToJSONTyped(json, false);
}

export function NameSpecToJSONTyped(
  value?: NameSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value["name"],
  };
}
