import { Button } from "@/components/ui/button";
import { NAMESPACES, useTranslation } from "@/lib/i18n";
import {
  toggleDisplayPreviewFileModal,
  useAppDispatch,
  useAppSelector,
} from "@/lib/store";
import { Link, useNavigate, useParams } from "@tanstack/react-router";
import { ChevronRight, Home } from "lucide-react";

interface BreadcrumbsProps {
  type: "preview" | "navigation";
}

export const FileManagerBreadcrumbs = ({ type }: BreadcrumbsProps) => {
  const { t } = useTranslation(NAMESPACES.FILE_MANAGER);
  const { workspaceId } = useParams({ strict: false });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { entityParents } = useAppSelector((state) => state.fileManager);
  const reversedEntityParents = [...entityParents].reverse();

  console.log(reversedEntityParents);

  const handleNavigate = (entityId: string) => {
    if (type === "preview") {
      dispatch(toggleDisplayPreviewFileModal());
    }
    navigate({
      to: `/${workspaceId}/file-manager/entities/${entityId}`,
    });
  };

  return (
    <nav
      aria-label="breadcrumbs"
      className="flex items-center space-x-1 text-sm"
    >
      {reversedEntityParents.map((entity, index) => (
        <div key={entity.id} className="flex items-center">
          {index > 0 && (
            <ChevronRight className="w-4 h-4 mx-1 text-muted-foreground" />
          )}

          {type === "preview" ? (
            <Button
              variant="link"
              className="h-auto p-0 text-sm font-medium"
              onClick={() => handleNavigate(entity.id!)}
            >
              {index === 0 ? (
                <span className="flex items-center gap-1">
                  <Home className="w-4 h-4" />
                  {t("file-manager:FILE_MANAGER.TITLE")}
                </span>
              ) : (
                entity.name
              )}
            </Button>
          ) : (
            <Link
              to={`/${workspaceId}/file-manager/entities/${entity.id}`}
              className="text-sm font-medium hover:text-primary"
            >
              {index === 0 ? (
                <span className="flex items-center gap-1">
                  <Home className="w-4 h-4" />
                  {t("file-manager:FILE_MANAGER.TITLE")}
                </span>
              ) : (
                entity.name
              )}
            </Link>
          )}
        </div>
      ))}
    </nav>
  );
};
