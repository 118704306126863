/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CardInfo
 */
export interface CardInfo {
  /**
   *
   * @type {string}
   * @memberof CardInfo
   */
  brand?: string;
  /**
   *
   * @type {number}
   * @memberof CardInfo
   */
  expMonth?: number;
  /**
   *
   * @type {number}
   * @memberof CardInfo
   */
  expYear?: number;
  /**
   *
   * @type {string}
   * @memberof CardInfo
   */
  last4?: string;
  /**
   *
   * @type {string}
   * @memberof CardInfo
   */
  cardToken?: string;
  /**
   *
   * @type {boolean}
   * @memberof CardInfo
   */
  def?: boolean;
}

/**
 * Check if a given object implements the CardInfo interface.
 */
export function instanceOfCardInfo(value: object): value is CardInfo {
  return true;
}

export function CardInfoFromJSON(json: any): CardInfo {
  return CardInfoFromJSONTyped(json, false);
}

export function CardInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CardInfo {
  if (json == null) {
    return json;
  }
  return {
    brand: json["brand"] == null ? undefined : json["brand"],
    expMonth: json["exp_month"] == null ? undefined : json["exp_month"],
    expYear: json["exp_year"] == null ? undefined : json["exp_year"],
    last4: json["last4"] == null ? undefined : json["last4"],
    cardToken: json["cardToken"] == null ? undefined : json["cardToken"],
    def: json["def"] == null ? undefined : json["def"],
  };
}

export function CardInfoToJSON(json: any): CardInfo {
  return CardInfoToJSONTyped(json, false);
}

export function CardInfoToJSONTyped(
  value?: CardInfo | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    brand: value["brand"],
    exp_month: value["expMonth"],
    exp_year: value["expYear"],
    last4: value["last4"],
    cardToken: value["cardToken"],
    def: value["def"],
  };
}
