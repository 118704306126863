/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  ProviderKeySpec,
  ResultNotificationMessage,
  ResultNotificationProviderInfo,
} from "../models/index";
import {
  ProviderKeySpecFromJSON,
  ProviderKeySpecToJSON,
  ResultNotificationMessageFromJSON,
  ResultNotificationMessageToJSON,
  ResultNotificationProviderInfoFromJSON,
  ResultNotificationProviderInfoToJSON,
} from "../models/index";

export interface AddNotificationProviderRequest {
  provider: string;
  providerKeySpec: ProviderKeySpec;
}

export interface ListActivitiesRequest {
  before?: number;
}

export interface RemoveNotificationProviderRequest {
  provider: string;
}

export interface SetNotificationTypeStatusRequest {
  type: string;
  body: boolean;
}

export interface TestNotificationProviderRequest {
  provider: string;
  providerKeySpec: ProviderKeySpec;
}

/**
 *
 */
export class NotificationsApi extends runtime.BaseAPI {
  /**
   * Associates the provided notification provider with the current user.
   * Add Notification Provider
   */
  async addNotificationProviderRaw(
    requestParameters: AddNotificationProviderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["provider"] == null) {
      throw new runtime.RequiredError(
        "provider",
        'Required parameter "provider" was null or undefined when calling addNotificationProvider().',
      );
    }

    if (requestParameters["providerKeySpec"] == null) {
      throw new runtime.RequiredError(
        "providerKeySpec",
        'Required parameter "providerKeySpec" was null or undefined when calling addNotificationProvider().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/notifications/providers/{provider}`.replace(
          `{${"provider"}}`,
          encodeURIComponent(String(requestParameters["provider"])),
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: ProviderKeySpecToJSON(requestParameters["providerKeySpec"]),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Associates the provided notification provider with the current user.
   * Add Notification Provider
   */
  async addNotificationProvider(
    requestParameters: AddNotificationProviderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.addNotificationProviderRaw(requestParameters, initOverrides);
  }

  /**
   * Retrieves the current user\'s activity stream/notifications.
   * Get Activity Stream
   */
  async listActivitiesRaw(
    requestParameters: ListActivitiesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultNotificationMessage>> {
    const queryParameters: any = {};

    if (requestParameters["before"] != null) {
      queryParameters["before"] = requestParameters["before"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/notifications`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultNotificationMessageFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves the current user\'s activity stream/notifications.
   * Get Activity Stream
   */
  async listActivities(
    requestParameters: ListActivitiesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultNotificationMessage> {
    const response = await this.listActivitiesRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves a list of the available notification providers.
   * Get Notification Providers
   */
  async listNotificationProvidersRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResultNotificationProviderInfo>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/notifications/providers`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResultNotificationProviderInfoFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves a list of the available notification providers.
   * Get Notification Providers
   */
  async listNotificationProviders(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResultNotificationProviderInfo> {
    const response = await this.listNotificationProvidersRaw(initOverrides);
    return await response.value();
  }

  /**
   * Retrieves a list of notification type handled by the application.
   * List Notification Types
   */
  async listNotificationTypesRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: boolean }>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/notifications/types`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Retrieves a list of notification type handled by the application.
   * List Notification Types
   */
  async listNotificationTypes(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: boolean }> {
    const response = await this.listNotificationTypesRaw(initOverrides);
    return await response.value();
  }

  /**
   * Removes the specified provider the current user\'s account.
   * Remove Notification Provider
   */
  async removeNotificationProviderRaw(
    requestParameters: RemoveNotificationProviderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["provider"] == null) {
      throw new runtime.RequiredError(
        "provider",
        'Required parameter "provider" was null or undefined when calling removeNotificationProvider().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/notifications/providers/{provider}`.replace(
          `{${"provider"}}`,
          encodeURIComponent(String(requestParameters["provider"])),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Removes the specified provider the current user\'s account.
   * Remove Notification Provider
   */
  async removeNotificationProvider(
    requestParameters: RemoveNotificationProviderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.removeNotificationProviderRaw(requestParameters, initOverrides);
  }

  /**
   * Enables or disables notifications of the specified type.
   * Enable/Disable Notification Type
   */
  async setNotificationTypeStatusRaw(
    requestParameters: SetNotificationTypeStatusRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["type"] == null) {
      throw new runtime.RequiredError(
        "type",
        'Required parameter "type" was null or undefined when calling setNotificationTypeStatus().',
      );
    }

    if (requestParameters["body"] == null) {
      throw new runtime.RequiredError(
        "body",
        'Required parameter "body" was null or undefined when calling setNotificationTypeStatus().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/notifications/types/{type}`.replace(
          `{${"type"}}`,
          encodeURIComponent(String(requestParameters["type"])),
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters["body"] as any,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Enables or disables notifications of the specified type.
   * Enable/Disable Notification Type
   */
  async setNotificationTypeStatus(
    requestParameters: SetNotificationTypeStatusRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.setNotificationTypeStatusRaw(requestParameters, initOverrides);
  }

  /**
   * Tests the provided notification provider.
   * Test Notification Provider
   */
  async testNotificationProviderRaw(
    requestParameters: TestNotificationProviderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["provider"] == null) {
      throw new runtime.RequiredError(
        "provider",
        'Required parameter "provider" was null or undefined when calling testNotificationProvider().',
      );
    }

    if (requestParameters["providerKeySpec"] == null) {
      throw new runtime.RequiredError(
        "providerKeySpec",
        'Required parameter "providerKeySpec" was null or undefined when calling testNotificationProvider().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/notifications/providers/{provider}`.replace(
          `{${"provider"}}`,
          encodeURIComponent(String(requestParameters["provider"])),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ProviderKeySpecToJSON(requestParameters["providerKeySpec"]),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Tests the provided notification provider.
   * Test Notification Provider
   */
  async testNotificationProvider(
    requestParameters: TestNotificationProviderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.testNotificationProviderRaw(requestParameters, initOverrides);
  }
}
