import {
  createFolder,
  FolderTypeEnum,
  resetErrorMessage,
  setDisplayCreateFolderModal,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { Calendar, Folder, Image } from 'lucide-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export type FolderType = {
  id: number;
  value: FolderTypeEnum;
  label: string;
  icon: JSX.Element;
  description: string;
};

export const folderTypes: FolderType[] = [
  {
    id: 1,
    value: FolderTypeEnum.GENERAL,
    label: 'General',
    icon: <Folder className="text-primary" />,
    description: 'General folder',
  },
  {
    id: 2,
    value: FolderTypeEnum.CALENDAR,
    label: 'Calendar',
    icon: <Calendar />,
    description: 'Calendar folder',
  },
  {
    id: 3,
    value: FolderTypeEnum.GALLERY,
    label: 'Gallery',
    icon: <Image />,
    description: 'Gallery folder',
  },
];

const createFolderSchema = yup.object({
  name: yup
    .string()
    .matches(/^[^\s/\\":?<>|*\r\n][^/\\":?<>|*\r\n]*$/, 'Invalid folder name')
    .trim()
    .required('Folder name is required'),
  folderType: yup
    .mixed<FolderTypeEnum>()
    .oneOf(Object.values(FolderTypeEnum))
    .required('Folder type is required'),
  ignore: yup.boolean().default(false),
});

export type CreateFolderFormValues = yup.InferType<typeof createFolderSchema>;

export const useCreateFolder = (
  entityId: string | undefined,
  rootFolder: string | undefined,
) => {
  const dispatch = useAppDispatch();
  const { displayCreateFolderModal, error } = useAppSelector(
    (state) => state.fileManager,
  );

  const form = useForm<CreateFolderFormValues>({
    resolver: yupResolver(createFolderSchema),
    defaultValues: {
      folderType: folderTypes[0].value,
      ignore: false,
    },
  });

  const onSubmit = (values: CreateFolderFormValues) => {
    try {
      const entity = entityId ?? rootFolder;

      if (!entity) {
        console.error('Entity not found');
        return;
      }

      dispatch(
        createFolder({
          entity,
          newItemSpec: {
            name: values.name,
            ignore: values.ignore,
          },
          type: values.folderType,
        }),
      );
    } catch (err) {
      console.error(err);
    }
  };

  const closeModal = () => {
    dispatch(setDisplayCreateFolderModal(false));
    dispatch(resetErrorMessage(null));
    form.reset();
  };

  useEffect(() => {
    if (!displayCreateFolderModal) {
      form.reset();
    }
  }, [displayCreateFolderModal]);

  return {
    form,
    error,
    isOpen: displayCreateFolderModal,
    onSubmit: form.handleSubmit(onSubmit),
    closeModal,
  };
};
