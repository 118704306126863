import { useMemo } from "react";

import { NotificationMessage } from "@oproma/prividox-orchestration-open-api";
import { NAMESPACES, useTranslation } from "../i18n";
import { NotificationMessages } from "../store";

export const useNotificationState = (
  notification: NotificationMessage | NotificationMessages
) => {
  return useMemo(
    () => ({
      agent: notification.agentName,
      child: notification.meta?.CHILD_NAME,
      project: notification.projectName,
      oldValue: notification.meta?.OLD_VALUE,
      newValue: notification.meta?.NEW_VALUE,
      from: notification.meta?.FROM,
      to: notification.meta?.TO,
      email: notification.meta?.EMAIL,
      childName: notification.meta?.CHILD_NAME,
      key: notification.meta?.KEY,
      deleted: notification.meta?.DELETED,
      version: notification.meta?.VERSION,
      message: notification.meta?.MESSAGE,
      location: notification.meta?.LOCATION,
      childType: notification.meta?.CHILD_TYPE,
      public: notification.meta?.PUBLIC,
      type: notification.meta?.TYPE,
      fromName: notification.meta?.FROM_NAME,
      toName: notification.meta?.TO_NAME,
      copy: notification.meta?.COPY,
      cardId: notification.meta?.CARD_ID,
      verb: notification.meta?.VERB,
      grant: notification.meta?.GRANT,
      added: notification.meta?.ADDED,
      removed: notification.meta?.REMOVED,
      name: notification.meta?.NAME || "",
    }),
    [notification]
  );
};

export const useNotificationContent = (
  notification: NotificationMessage | NotificationMessages,
  state: Record<string, string | undefined>
) => {
  const { t } = useTranslation(NAMESPACES.DEFAULT);

  return useMemo(() => {
    let key: string | null = null;

    switch (notification.eventType) {
      case "CREATED":
        key = `${notification.eventType}_${notification.entityType}`;
        break;
      case "CREATED_TASK":
        key = `${notification.eventType}_${notification.entityType}`;
        break;
      case "CHILD_REMOVED":
        if (
          notification.meta?.CHILD_NAME?.startsWith(
            `/${notification.projectName}/.Trash/`
          )
        ) {
          key = "CHILD_TRASHED";
        }
      // fallthrough
      case "CHILD_ADDED":
        if (!key) {
          key = `${notification.eventType}_${notification.entityType}`;
        }
        if (notification.entityType === "FOLDER") {
          key += `_${notification.meta?.CHILD_TYPE}`;
        }
        state.CHILD = notification.meta?.CHILD_NAME;
        break;
      case "COMMENTED":
        state.message = notification.meta?.MESSAGE;
        break;
      case "METADATA_SET":
        state.key = notification.meta?.KEY;
        state.newValue = notification.meta?.NEW_VALUE;
        if (notification.meta?.OLD_VALUE) {
          state.OLD_VALUE = notification.meta?.OLD_VALUE;
          key = "METADATA_SET_OLD";
        }
        break;
      case "METADATA_DELETED":
        state.key = notification.meta?.KEY;
        state.OLD_VALUE = notification.meta?.OLD_VALUE;
        break;
      case "EMAIL_CHANGED":
      case "UPDATED_DISPLAY_NAME":
      case "RENAMED":
        state.newValue = notification.meta?.NEW_VALUE;
        state.OLD_VALUE = notification.meta?.OLD_VALUE;
        key = `${notification.eventType}_${notification.entityType}`;
        break;
      case "MOVED":
        state.to = notification.meta?.TO_NAME;
        state.from = notification.meta?.FROM_NAME;
        key = `${notification.eventType}_${notification.entityType}`;
        break;
      case "DEFAULT_GROUP_SET":
        state.to = notification.meta?.TO_NAME;
        state.from = notification.meta?.FROM_NAME;
        break;
      case "COPIED":
      case "RESTORED":
        state.to = notification.meta?.TO_NAME;
        key = `${notification.eventType}_${notification.entityType}`;
        break;
      case "CUSTOM_ENTITY_ADDED":
      case "CUSTOM_ENTITY_REMOVED":
        state.CHILD = notification.meta?.CHILD_NAME;
        break;
      case "MEMBER_ADDED":
      case "MEMBER_REMOVED":
        state.CHILD = notification.meta?.CHILD_NAME;
        state.email = notification.meta?.EMAIL;
        break;
      case "DOWNLOADED":
      case "PREVIEWED":
      case "TRASHED":
      case "DELETED":
        key = `${notification.eventType}_${notification.entityType}`;
        break;

      case "LOCKED":
      case "PUBLISHED":
      case "UNLOCKED": {
        state.public = notification.meta?.PUBLIC;
        state.from = notification.meta?.FROM || "unknown IP";
        state.location = notification.meta?.FROM_NAME || "";
        const childType = notification.meta?.CHILD_TYPE || "";
        if (childType !== "") {
          state.childType = childType;
          key = `${notification.eventType}_${childType}`;
        }
        break;
      }
      case "CARD_ADDED":
      case "CARD_REMOVED":
      case "DEFAULT_CARD_CHANGED":
      case "PASSWORD_RESET_REQUESTED":
      case "TEST":
      case "SCRIPT_NOTIFICATION":
      case "CARD_UPDATED":
      case "NOTIFICATION_TYPE_TOGGLED":
      case "LOGIN":
      case "LOGOUT":
      case "SESSION_REFRESHED":
      case "ACCOUNT_CREATED":
      case "ACCOUNT_ACTIVATED":
      case "EMAIL_CHANGE_REQUESTED":
      case "UPDATED_PASSWORD":
      case "NOTIFICATION_PROVIDER_ADDED":
      case "NOTIFICATION_PROVIDER_REMOVED":
      case "APPLICATION_ACCESS_GRANTED":
      case "APPLICATION_ACCESS_REVOKED":
      case "PAYMENT_PLAN_UPDATED":
      case "PROJECT_LEFT":
      case "TRASH_EMPTIED":
      case "REDACTED":
      default:
        break;
    }

    if (!key) {
      key = notification.eventType as string;
    }

    return t(`NOTIFICATION_BODIES.${key}`, `NOTIFICATION_BODIES.${key}`, state);
  }, [t, notification, state]);
};

export const getActivityState = (activity: NotificationMessage) => {
  return {
    agent: activity.agentName,
    child: activity.meta?.CHILD_NAME,
    project: activity.projectName,
    oldValue: activity.meta?.OLD_VALUE,
    newValue: activity.meta?.NEW_VALUE,
    from: activity.meta?.FROM,
    to: activity.meta?.TO,
    email: activity.meta?.EMAIL,
    childName: activity.meta?.CHILD_NAME,
    key: activity.meta?.KEY,
    deleted: activity.meta?.DELETED,
    version: activity.meta?.VERSION,
    message: activity.meta?.MESSAGE,
    location: activity.meta?.LOCATION,
    childType: activity.meta?.CHILD_TYPE,
    public: activity.meta?.PUBLIC,
    type: activity.meta?.TYPE,
    fromName: activity.meta?.FROM_NAME,
    toName: activity.meta?.TO_NAME,
    copy: activity.meta?.COPY,
    cardId: activity.meta?.CARD_ID,
    verb: activity.meta?.VERB,
    grant: activity.meta?.GRANT,
    added: activity.meta?.ADDED,
    removed: activity.meta?.REMOVED,
    name: activity.meta?.NAME || "",
  };
};

export function getActivityContent(
  notification: NotificationMessage,
  state: Record<string, string | undefined>
) {
  let key: string | null = null;

  switch (notification.eventType) {
    case "CREATED":
      key = `${notification.eventType}_${notification.entityType}`;
      break;
    case "CREATED_TASK":
      key = `${notification.eventType}_${notification.entityType}`;
      break;
    case "CHILD_REMOVED":
      if (
        notification.meta?.CHILD_NAME?.startsWith(
          `/${notification.projectName}/.Trash/`
        )
      ) {
        key = "CHILD_TRASHED";
      }
    // fallthrough
    case "CHILD_ADDED":
      if (!key) {
        key = `${notification.eventType}_${notification.entityType}`;
      }
      if (notification.entityType === "FOLDER") {
        key += `_${notification.meta?.CHILD_TYPE}`;
      }
      state.CHILD = notification.meta?.CHILD_NAME;
      break;
    case "COMMENTED":
      state.message = notification.meta?.MESSAGE;
      break;
    case "METADATA_SET":
      state.key = notification.meta?.KEY;
      state.newValue = notification.meta?.NEW_VALUE;
      if (notification.meta?.OLD_VALUE) {
        state.OLD_VALUE = notification.meta?.OLD_VALUE;
        key = "METADATA_SET_OLD";
      }
      break;
    case "METADATA_DELETED":
      state.key = notification.meta?.KEY;
      state.OLD_VALUE = notification.meta?.OLD_VALUE;
      break;
    case "EMAIL_CHANGED":
    case "UPDATED_DISPLAY_NAME":
    case "RENAMED":
      state.newValue = notification.meta?.NEW_VALUE;
      state.OLD_VALUE = notification.meta?.OLD_VALUE;
      key = `${notification.eventType}_${notification.entityType}`;
      break;
    case "MOVED":
      state.to = notification.meta?.TO_NAME;
      state.from = notification.meta?.FROM_NAME;
      key = `${notification.eventType}_${notification.entityType}`;
      break;
    case "DEFAULT_GROUP_SET":
      state.to = notification.meta?.TO_NAME;
      state.from = notification.meta?.FROM_NAME;
      break;
    case "COPIED":
    case "RESTORED":
      state.to = notification.meta?.TO_NAME;
      key = `${notification.eventType}_${notification.entityType}`;
      break;
    case "CUSTOM_ENTITY_ADDED":
    case "CUSTOM_ENTITY_REMOVED":
      state.CHILD = notification.meta?.CHILD_NAME;
      break;
    case "MEMBER_ADDED":
    case "MEMBER_REMOVED":
      state.CHILD = notification.meta?.CHILD_NAME;
      state.email = notification.meta?.EMAIL;
      break;
    case "DOWNLOADED":
    case "PREVIEWED":
    case "TRASHED":
    case "DELETED":
      key = `${notification.eventType}_${notification.entityType}`;
      break;

    case "LOCKED":
    case "PUBLISHED":
    case "UNLOCKED": {
      state.public = notification.meta?.PUBLIC;
      state.from = notification.meta?.FROM || "unknown IP";
      state.location = notification.meta?.FROM_NAME || "";
      const childType = notification.meta?.CHILD_TYPE || "";
      if (childType !== "") {
        state.childType = childType;
        key = `${notification.eventType}_${childType}`;
      }
      break;
    }
    case "CARD_ADDED":
    case "CARD_REMOVED":
    case "DEFAULT_CARD_CHANGED":
    case "PASSWORD_RESET_REQUESTED":
    case "TEST":
    case "SCRIPT_NOTIFICATION":
    case "CARD_UPDATED":
    case "NOTIFICATION_TYPE_TOGGLED":
    case "LOGIN":
    case "LOGOUT":
    case "SESSION_REFRESHED":
    case "ACCOUNT_CREATED":
    case "ACCOUNT_ACTIVATED":
    case "EMAIL_CHANGE_REQUESTED":
    case "UPDATED_PASSWORD":
    case "NOTIFICATION_PROVIDER_ADDED":
    case "NOTIFICATION_PROVIDER_REMOVED":
    case "APPLICATION_ACCESS_GRANTED":
    case "APPLICATION_ACCESS_REVOKED":
    case "PAYMENT_PLAN_UPDATED":
    case "PROJECT_LEFT":
    case "TRASH_EMPTIED":
    case "REDACTED":
    default:
      break;
  }

  if (!key) {
    key = notification.eventType as string;
  }

  return { key, state };
}
