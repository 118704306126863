import { Alert, AlertDescription } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Switch } from "@/components/ui/switch";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { NAMESPACES, useTranslation } from "@/lib/i18n";
import { cn, currencyFormatter, prettyBytes } from "@/lib/utils";
import { PlanInformation } from "@oproma/prividox-orchestration-open-api";
import { AnimatePresence, motion } from "framer-motion";
import { Dictionary } from "lodash";
import { AlertCircle, Check, X } from "lucide-react";
import { useState } from "react";

interface PricingPlansProps {
  plans: Record<string, Dictionary<PlanInformation[]>>;
  selectedPlan?: string;
  handlePlanSelection: (plan?: string) => void;
  errorMessage?: string;
}

interface FeatureItemProps {
  label: string;
  isIncluded: boolean;
}

const FeatureItem = ({ label, isIncluded }: FeatureItemProps) => (
  <div className="flex items-center justify-between">
    <span className="text-xs text-muted-foreground">{label}</span>
    <div
      className={cn(
        "h-4 w-4 rounded-full flex items-center justify-center",
        isIncluded ? "bg-primary/10" : "bg-destructive/10"
      )}
    >
      {isIncluded ? (
        <Check className="h-2.5 w-2.5 text-primary" />
      ) : (
        <X className="h-2.5 w-2.5 text-destructive" />
      )}
    </div>
  </div>
);
export const PricingPlans = ({
  plans,
  selectedPlan,
  handlePlanSelection,
  errorMessage,
}: PricingPlansProps) => {
  const { t } = useTranslation(NAMESPACES.DEFAULT);
  const [billingFrequency, setBillingFrequency] = useState<"month" | "year">(
    "month"
  );
  const [activeTab, setActiveTab] = useState(
    Object.keys(plans[billingFrequency])[0]
  );

  const getPricingPlanTranslation = (planName?: string) => {
    const [name, membersLimit, storageLimit] = planName?.split("-") ?? [];
    return t(`PLANS.${name.toUpperCase()}_MEMBERS_STORAGE`, name, {
      membersLimit: Number(membersLimit),
      storageLimit: Number(storageLimit),
    });
  };

  return (
    <div className="space-y-4">
      {errorMessage && (
        <Alert variant="destructive" className="py-2">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{errorMessage}</AlertDescription>
        </Alert>
      )}

      {/* Billing Toggle */}
      <div className="flex items-center justify-center gap-3">
        <span
          className={cn(
            "text-sm",
            billingFrequency === "month"
              ? "text-foreground"
              : "text-muted-foreground"
          )}
        >
          Monthly
        </span>
        <Switch
          checked={billingFrequency === "year"}
          onCheckedChange={(checked) =>
            setBillingFrequency(checked ? "year" : "month")
          }
        />
        <div className="flex items-center gap-1.5">
          <span
            className={cn(
              "text-sm",
              billingFrequency === "year"
                ? "text-foreground"
                : "text-muted-foreground"
            )}
          >
            Yearly
          </span>
          <Badge
            variant="secondary"
            className="bg-primary/10 text-primary text-xs"
          >
            -20%
          </Badge>
        </div>
      </div>

      <Tabs
        value={activeTab}
        onValueChange={setActiveTab}
        className="space-y-4"
      >
        <TabsList className="w-full">
          {Object.keys(plans[billingFrequency]).map((planType) => (
            <TabsTrigger key={planType} value={planType} className="flex-1">
              {t(`PLANS.${planType.toUpperCase()}`, planType)}
            </TabsTrigger>
          ))}
        </TabsList>

        <ScrollArea className="h-[450px] pr-4 -mr-4">
          <AnimatePresence mode="wait">
            {Object.entries(plans[billingFrequency]).map(
              ([planType, plans]) => (
                <TabsContent key={planType} value={planType} className="mt-0">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {plans.map((plan) => (
                      <motion.div
                        key={plan.id}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.15 }}
                      >
                        <div
                          className={cn(
                            "rounded-lg border bg-card p-4 transition-all",
                            "hover:border-primary/50 cursor-pointer",
                            selectedPlan === plan.id &&
                              "ring-1 ring-primary border-primary"
                          )}
                          onClick={() => handlePlanSelection(plan.id)}
                        >
                          {/* Plan Header */}
                          <div className="space-y-3">
                            <div>
                              <h3 className="font-medium">
                                {getPricingPlanTranslation(
                                  plan.meta?.["package-i18n-name"]
                                )}
                              </h3>
                              <p className="text-xs text-muted-foreground mt-0.5">
                                {plan.meta?.["package-i18n-desc"]}
                              </p>
                            </div>

                            <div className="grid grid-cols-2 gap-3 py-2 border-y">
                              <div>
                                <p className="text-lg font-semibold">
                                  {plan.meta?.["members-limit"]}
                                </p>
                                <p className="text-xs text-muted-foreground">
                                  Max Members
                                </p>
                              </div>
                              <div>
                                <p className="text-lg font-semibold">
                                  {prettyBytes(
                                    parseInt(
                                      plan.meta?.["bytes-limit"] ?? "0",
                                      10
                                    )
                                  )}
                                </p>
                                <p className="text-xs text-muted-foreground">
                                  Storage
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* Features */}
                          <div className="grid grid-cols-2 gap-x-4 gap-y-1.5 py-3">
                            <FeatureItem
                              label="Conversions"
                              isIncluded={
                                plan.meta?.["conversions-allowed"] === "true"
                              }
                            />
                            <FeatureItem
                              label="Expansion"
                              isIncluded={
                                plan.meta?.["expansion-allowed"] === "true"
                              }
                            />
                            <FeatureItem
                              label="Produce"
                              isIncluded={
                                plan.meta?.["produce-allowed"] === "true"
                              }
                            />
                            <FeatureItem
                              label="Publish"
                              isIncluded={
                                plan.meta?.["publish-allowed"] === "true"
                              }
                            />
                            <FeatureItem
                              label="Redactions"
                              isIncluded={
                                plan.meta?.["redactions-allowed"] === "true"
                              }
                            />
                            <FeatureItem
                              label="Review"
                              isIncluded={
                                plan.meta?.["review-allowed"] === "true"
                              }
                            />
                          </div>

                          {/* Price */}
                          <div className="text-center pt-2 border-t">
                            <span className="text-xl font-bold">
                              ${currencyFormatter(plan.amount ?? -1)}{" "}
                              {plan.currency?.toUpperCase()}
                            </span>
                            <span className="text-sm text-muted-foreground">
                              /{billingFrequency}
                            </span>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </TabsContent>
              )
            )}
          </AnimatePresence>
        </ScrollArea>
      </Tabs>
    </div>
  );
};
