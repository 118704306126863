import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { Separator } from '@/components/ui/separator';
import { NAMESPACES, useTranslation } from '@/lib/i18n';
import {
  deleteWorkspace,
  setDisplayDeleteWorkspaceModal,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { AlertTriangle, Trash2 } from 'lucide-react';

export const DeleteWorkspaceModal = () => {
  const dispatch = useAppDispatch();
  const { lastOpenedWorkspace } = useAppSelector((state) => state.workspaces);
  const { t } = useTranslation(NAMESPACES.DEFAULT);
  const { displayWorkspaceModal } = useAppSelector((state) => state.workspaces);

  const handleClose = () => {
    dispatch(setDisplayDeleteWorkspaceModal(false));
  };

  const handleConfirm = () => {
    if (lastOpenedWorkspace) {
      dispatch(deleteWorkspace(lastOpenedWorkspace));
      handleClose();
    }
  };

  return (
    <Dialog open={displayWorkspaceModal} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[800px]">
        <DialogHeader>
          <div className="relative">
            {/* Background Pattern */}
            <div
              className="absolute inset-0 bg-gradient-to-br from-red-500/10 via-background to-background"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-[linear-gradient(to_right,transparent_0%,#ffffff08_50%,transparent_100%)] h-[1px] top-1/2" />
            </div>

            {/* Content */}
            <div className="relative px-6 py-8 space-y-4">
              {/* Title Section */}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="p-2.5 rounded-xl bg-red-500/10 backdrop-blur-sm">
                    <Trash2 className="h-5 w-5 text-red-500" />
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold">
                      {t('translation:WORKSPACES.REMOVE_WORKSPACE.TITLE')}
                    </h2>
                    <p className="text-sm text-muted-foreground">
                      {t('translation:WORKSPACES.REMOVE_WORKSPACE.SUBHEADING')}
                    </p>
                  </div>
                </div>
              </div>

              {/* Divider */}
              <Separator className="bg-border/40" />
            </div>
          </div>
        </DialogHeader>

        {/* Warning Message */}
        <div className="px-6 py-4">
          <div className="flex items-start gap-4 p-4 rounded-lg bg-red-500/5 border border-red-500/10">
            <div className="p-1">
              <AlertTriangle className="h-5 w-5 text-red-500" />
            </div>
            <div className="space-y-1">
              <p className="text-sm font-medium">
                {t('translation:WORKSPACES.REMOVE_WORKSPACE.HEADING')}
              </p>
            </div>
          </div>
        </div>

        {/* Actions */}
        <div className="px-6 py-4 flex justify-end gap-3">
          <Button variant="outline" onClick={handleClose}>
            {t('translation:MEMBERS.REMOVE_MEMBER.CANCEL')}
          </Button>
          <Button
            variant="destructive"
            onClick={handleConfirm}
            className="gap-2"
          >
            <Trash2 className="h-4 w-4" />
            {t('translation:MEMBERS.REMOVE_MEMBER.CONFIRM')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
