import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { useWizard } from "@/lib/hooks/use-wizard.hook";
import { createWorkspace, useAppDispatch, useAppSelector } from "@/lib/store";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowLeft, Loader2 } from "lucide-react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { PricingPlans } from "./pricing-plans.component";

const formSchema = yup.object({
  pricingPlan: yup.string().required("Please choose a plan"),
});

type FormValues = yup.InferType<typeof formSchema>;

// TODO: Add translations
export const OnboardWorkspacePricingPlansForm = () => {
  const { next, prev } = useWizard();
  const dispatch = useAppDispatch();
  const form = useAppSelector((state) => state.workspaces.createWorkspaceForm);
  const { pricingPlans, loading } = useAppSelector((state) => state.finance);

  const formMethods = useForm<FormValues>({
    resolver: yupResolver(formSchema),
  });

  const selectedPlan = formMethods.watch("pricingPlan");

  const onFormSubmit: SubmitHandler<FormValues> = ({ pricingPlan }) => {
    if (!form?.name) return;
    dispatch(
      createWorkspace({
        name: form.name,
        plan: pricingPlan,
        token: form?.token,
      })
    );
    next();
  };

  const handlePlanSelect = (plan?: string) => {
    if (!plan) return;
    formMethods.setValue("pricingPlan", plan, { shouldValidate: true });
  };

  if (loading) return <Loader2 className="h-5 w-5 animate-spin" />;

  return (
    <Form {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(onFormSubmit)}
        className="space-y-6"
      >
        {/* Header */}
        <div className="space-y-1.5">
          <div className="flex items-center gap-2">
            <Button
              variant="ghost"
              size="icon"
              onClick={prev}
              className="h-9 w-9 rounded-full hover:bg-primary/10 text-muted-foreground hover:text-primary"
            >
              <ArrowLeft className="h-5 w-5" />
              <span className="sr-only">Go back</span>
            </Button>
            <span className="text-sm text-muted-foreground">Back</span>
          </div>
        </div>

        {/* Content */}

        <FormField
          control={formMethods.control}
          name="pricingPlan"
          render={({ field }) => (
            <FormItem className="space-y-3">
              <FormControl>
                <PricingPlans
                  plans={pricingPlans}
                  selectedPlan={field.value}
                  handlePlanSelection={handlePlanSelect}
                  errorMessage={
                    formMethods.formState.errors.pricingPlan?.message
                  }
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* Footer */}
        <Button
          type="submit"
          className="w-full text-white"
          disabled={!selectedPlan || formMethods.formState.isSubmitting}
        >
          Create
        </Button>
      </form>
    </Form>
  );
};
