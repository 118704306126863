import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useFileManagerTrash } from '@/lib/hooks/use-file-manager-trash.hook';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { Calendar as CalendarIcon, Trash2, X } from 'lucide-react';
import { useState } from 'react';
import { FileManagerFiles } from './file-manager-files.component';

export const FileManagerTrash = () => {
  const {
    t,
    dates,
    setDates,
    memberOptions,
    selectedMember,
    filteredTrashEntities,
    filterTrashEntities,
    handleEmptyingTrash,
    resetFilters,
    handleSelectChange,
    displayMobileRecoveryFilter,
  } = useFileManagerTrash();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  return (
    <div className="h-full flex flex-col lg:flex-row gap-6 p-6">
      {/* Filters Panel */}
      <div
        className={cn(
          'w-full lg:w-80 flex-shrink-0',
          'bg-card rounded-lg border shadow-sm',
          displayMobileRecoveryFilter ? 'block' : 'hidden lg:block',
        )}
      >
        <div className="p-4 space-y-6">
          {/* Empty Trash Button */}
          <Button
            variant="destructive"
            className="w-full"
            onClick={() => setShowConfirmDialog(true)}
          >
            <Trash2 className="h-4 w-4 mr-2" />
            {t('file-manager:FILE_MANAGER.TRASH.EMPTY')}
          </Button>

          {/* Date Range Selectors */}
          <div className="space-y-4">
            {/* From Date */}
            <div className="space-y-2">
              <label className="text-sm font-medium">
                {t('file-manager:FILE_MANAGER.TRASH.FROM')}
              </label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    className="w-full justify-start text-left font-normal"
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {dates.from ? format(dates.from, 'PPP') : 'Pick a date'}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={dates.from}
                    onSelect={(date) => setDates({ ...dates, from: date })}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </div>

            {/* To Date */}
            <div className="space-y-2">
              <label className="text-sm font-medium">
                {t('file-manager:FILE_MANAGER.TRASH.TO')}
              </label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    className="w-full justify-start text-left font-normal"
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {dates.to ? format(dates.to, 'PPP') : 'Pick a date'}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={dates.to}
                    onSelect={(date) => setDates({ ...dates, to: date })}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </div>

            {/* Member Selection */}
            <div className="space-y-2">
              <label className="text-sm font-medium">
                {t('file-manager:FILE_MANAGER.TRASH.DELETED_BY')}
              </label>
              <Select
                value={selectedMember?.value}
                onValueChange={(value) =>
                  handleSelectChange(
                    memberOptions.find((opt) => opt.value === value),
                  )
                }
              >
                <SelectTrigger>
                  <SelectValue
                    placeholder={t(
                      'file-manager:FILE_MANAGER.TRASH.DELETED_PLACEHOLDER',
                    )}
                  />
                </SelectTrigger>
                <SelectContent>
                  {memberOptions.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          {/* Filter Actions */}
          <div className="flex items-center justify-between pt-4 border-t">
            <Button
              variant="ghost"
              size="sm"
              onClick={resetFilters}
              className="text-primary"
            >
              <X className="h-4 w-4 mr-2" />
              Reset
            </Button>
            <Button className="text-white" onClick={filterTrashEntities}>
              Confirm
            </Button>
          </div>
        </div>
      </div>

      {/* Files List */}
      <div className="flex-1 min-w-0">
        <FileManagerFiles
          entities={filteredTrashEntities}
          entityDisplayType="list"
          layoutType="trash"
        />
      </div>

      {/* Confirm Dialog */}
      <Dialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Empty Trash</DialogTitle>
            <DialogDescription>
              Are you sure you would like to empty the trash of all of its
              contents? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="ghost" onClick={() => setShowConfirmDialog(false)}>
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={() => {
                handleEmptyingTrash();
                setShowConfirmDialog(false);
              }}
            >
              Empty Trash
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
