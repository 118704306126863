import { NAMESPACES, useTranslation } from '@/lib/i18n';
import {
  createFile,
  createFolder,
  FileWithPath,
  FolderTypeEnum,
  getChildEntities,
  getEntityMetadata,
  getEntityParents,
  setIsMinimized,
  setQueuedFiles,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { UploadLevelEnum } from '@oproma/prividox-orchestration-open-api';
import { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

export interface FileTreeNode {
  name: string;
  type: 'file' | 'folder';
  file?: FileWithPath;
  filepath?: string;
  level: number;
  children?: FileTreeNode[];
}

const securityLevelSchema = yup
  .object({
    securityLevel: yup.string().default('NONE'),
  })
  .required();

type SecurityLevelFormValues = yup.InferType<typeof securityLevelSchema>;

export const useCreateFile = (entityId?: string, workspaceId?: string) => {
  const { t } = useTranslation(NAMESPACES.FILE_MANAGER);
  const dispatch = useAppDispatch();

  // State
  const [queuedFolders, setQueuedFolders] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);
  const [publishableUids, setPublishableUids] = useState<string[]>([]);
  const [isDragging, setIsDragging] = useState(false);

  // Refs
  const dropzoneRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const abortControllersRef = useRef<{ [key: string]: AbortController }>({});

  // Selectors
  const { queuedFiles, isMinimized } = useAppSelector(
    (state) => state.fileManager,
  );
  const uploadStates = useAppSelector(
    (state) => state.fileManager.uploadStates,
  );
  const { accessLevel } = useAppSelector((state) => state.workspace);
  const openedWorkspace = useAppSelector((state) =>
    state.workspaces.workspaces.find(
      (workspace) => workspace.id === workspaceId,
    ),
  );

  const isUploadEnabled = Boolean(workspaceId || entityId);

  // Form
  const { watch, setValue } = useForm<SecurityLevelFormValues>({
    resolver: yupResolver(securityLevelSchema),
    defaultValues: {
      securityLevel: 'NONE',
    },
  });

  // Computed values
  const totalProgress = useMemo(() => {
    if (!publishableUids.length) return 0;
    const progress = publishableUids.reduce((acc, uid) => {
      return acc + (uploadStates[uid]?.progress || 0);
    }, 0);
    return Math.round(progress / publishableUids.length);
  }, [publishableUids, uploadStates]);

  const isAnyFileUploading = useMemo(() => {
    return publishableUids.some(
      (uid) => uploadStates[uid]?.status === 'uploading',
    );
  }, [publishableUids, uploadStates]);

  // File tree building
  const buildFileTree = (files: FileWithPath[]): FileTreeNode[] => {
    const root: FileTreeNode[] = [];
    const folderMap = new Map<string, FileTreeNode>();

    files.forEach((file) => {
      const filepath = file.filepath || file.name;
      const parts = filepath.split('/');
      let currentPath = '';
      let currentLevel = 0;

      parts.forEach((part, index) => {
        const isFile = index === parts.length - 1;
        currentPath = currentPath ? `${currentPath}/${part}` : part;

        if (isFile) {
          const node: FileTreeNode = {
            name: part,
            type: 'file',
            file,
            filepath,
            level: currentLevel,
          };

          if (parts.length === 1) {
            root.push(node);
          } else {
            const parentPath = parts.slice(0, -1).join('/');
            const parent = folderMap.get(parentPath);
            if (parent) {
              parent.children = parent.children || [];
              parent.children.push(node);
            }
          }
        } else {
          if (!folderMap.has(currentPath)) {
            const node: FileTreeNode = {
              name: part,
              type: 'folder',
              children: [],
              level: currentLevel,
            };
            folderMap.set(currentPath, node);

            if (currentLevel === 0) {
              root.push(node);
            } else {
              const parentPath = parts.slice(0, index).join('/');
              const parent = folderMap.get(parentPath);
              if (parent) {
                parent.children = parent.children || [];
                parent.children.push(node);
              }
            }
          }
        }
        currentLevel++;
      });
    });

    return root;
  };

  // Upload handlers
  const getTargetFolder = async (folderId: string): Promise<string> => {
    try {
      const metadata = await dispatch(getEntityMetadata(folderId)).unwrap();
      return metadata.id ?? folderId;
    } catch (error) {
      console.error('Failed to get entity metadata:', error);
      return folderId;
    }
  };

  const handleFileUpload = async (file: FileWithPath, folderId: string) => {
    const publishUid = uuidv4();
    if (!folderId) return;

    const target = await getTargetFolder(folderId);
    if (!target) return;

    const abortController = new AbortController();
    abortControllersRef.current[publishUid] = abortController;

    try {
      await dispatch(
        createFile({
          processUid: publishUid,
          file,
          metadata: {
            target,
            count: 1,
            encrypted: false,
            filesize: file.size,
            index: 0,
            name: file.name,
            original: target,
            upload: publishUid,
            body: {},
            level: watch('securityLevel') as UploadLevelEnum,
          },
          signal: abortController.signal,
        }),
      ).unwrap();

      setPublishableUids((prev) => [...prev, publishUid]);

      // Refresh the file list
      dispatch(getEntityMetadata(entityId || workspaceId!));
      dispatch(getEntityParents(entityId || workspaceId!));
      dispatch(
        getChildEntities({
          entityId: entityId || workspaceId!,
        }),
      );
    } catch (error) {
      console.error('File upload failed:', error);
    }
  };

  const handleFolderCreation = async (
    parentFolderId: string,
    folderName: string,
  ): Promise<string | null> => {
    try {
      const targetFolder = await getTargetFolder(parentFolderId);
      const result = await dispatch(
        createFolder({
          entity: targetFolder,
          newItemSpec: {
            name: folderName,
          },
          type: FolderTypeEnum.GENERAL,
        }),
      ).unwrap();
      return result;
    } catch (error) {
      console.error('Folder creation failed:', error);
      return null;
    }
  };

  // Event handlers
  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    if (!isUploadEnabled) {
      toast.error(t('file-manager:FILE_MANAGER.ERRORS.INVALID_LOCATION'));
      return;
    }

    const items = Array.from(event.dataTransfer.items);
    if (items.length === 0) return;

    setUploading(true);

    try {
      for (const item of items) {
        const entry = item.webkitGetAsEntry();
        if (!entry) continue;

        if (entry.isFile) {
          const file = await new Promise<File>((resolve) =>
            (entry as FileSystemFileEntry).file(resolve),
          );
          const fileWithPath = Object.assign(file, {
            filepath: file.name,
          }) as FileWithPath;
          dispatch(setQueuedFiles([...queuedFiles, fileWithPath]));
        } else if (entry.isDirectory) {
          await processDirectoryEntry(entry as FileSystemDirectoryEntry);
        }
      }
    } catch (error) {
      console.error('Drop handling failed:', error);
      toast.error(t('file-manager:FILE_MANAGER.ERRORS.DROP_FAILED'));
    } finally {
      setUploading(false);
    }
  };

  // New helper for processing directory entries
  const processDirectoryEntry = async (
    dirEntry: FileSystemDirectoryEntry,
    path = '',
  ) => {
    const dirReader = dirEntry.createReader();

    const readEntries = (): Promise<FileSystemEntry[]> => {
      return new Promise((resolve, reject) => {
        dirReader.readEntries(resolve, reject);
      });
    };

    let entries: FileSystemEntry[] = [];
    let batch: FileSystemEntry[];

    do {
      batch = await readEntries();
      entries = entries.concat(batch);
    } while (batch.length > 0);

    const currentPath = path ? `${path}/${dirEntry.name}` : dirEntry.name;
    setQueuedFolders((prev) => [...new Set([...prev, currentPath])]);

    for (const entry of entries) {
      if (entry.isFile) {
        const file = await new Promise<File>((resolve) =>
          (entry as FileSystemFileEntry).file(resolve),
        );
        const fileWithPath = Object.assign(file, {
          filepath: `${currentPath}/${file.name}`,
        }) as FileWithPath;
        dispatch(setQueuedFiles([...queuedFiles, fileWithPath]));
      } else if (entry.isDirectory) {
        await processDirectoryEntry(
          entry as FileSystemDirectoryEntry,
          currentPath,
        );
      }
    }
  };

  const persistFiles = async () => {
    if (!isUploadEnabled) {
      toast.error(t('file-manager:FILE_MANAGER.ERRORS.INVALID_LOCATION'));
      return;
    }

    if (queuedFiles.length === 0) {
      toast.info(t('file-manager:FILE_MANAGER.UPLOAD_MODAL.NO_FILES'));
      return;
    }

    setUploading(true);

    const rootFolderId = entityId || openedWorkspace?.root || '';
    const folderMap = new Map<string, string>();
    folderMap.set('', rootFolderId);

    try {
      // Create folders first
      await createFolderStructure(folderMap, rootFolderId);
      // Upload files
      await uploadFiles(folderMap, rootFolderId);

      cleanup();
    } catch (error) {
      console.error('Upload failed:', error);
      toast.error(t('file-manager:FILE_MANAGER.ERRORS.UPLOAD_FAILED'));
      cancelAllUploads();
    } finally {
      setUploading(false);
    }
  };

  const createFolderStructure = async (
    folderMap: Map<string, string>,
    rootFolderId: string,
  ) => {
    const sortedFolders = [...new Set(queuedFolders)].sort(
      (a, b) => a.split('/').length - b.split('/').length,
    );

    if (sortedFolders.length > 0) {
      toast.info(t('file-manager:FILE_MANAGER.UPLOAD_MODAL.CREATING_FOLDERS'));
    }

    for (const folderPath of sortedFolders) {
      await createFolderPath(folderPath, folderMap, rootFolderId);
    }
  };

  const createFolderPath = async (
    folderPath: string,
    folderMap: Map<string, string>,
    rootFolderId: string,
  ) => {
    const pathParts = folderPath.split('/');
    let parentPath = '';
    let parentId = rootFolderId;

    for (const currentPart of pathParts) {
      const currentPath = parentPath
        ? `${parentPath}/${currentPart}`
        : currentPart;

      if (!folderMap.has(currentPath)) {
        const newFolderId = await handleFolderCreation(parentId, currentPart);
        if (!newFolderId) {
          throw new Error(`Failed to create folder: ${currentPath}`);
        }
        folderMap.set(currentPath, newFolderId);
      }

      parentId = folderMap.get(currentPath) || rootFolderId;
      parentPath = currentPath;
    }
  };

  const uploadFiles = async (
    folderMap: Map<string, string>,
    rootFolderId: string,
  ) => {
    for (const file of queuedFiles) {
      const filePath = file.filepath || file.name;
      const pathParts = filePath.split('/');

      const fileName = pathParts.pop()!;
      console.log('fileName', fileName);
      const folderPath = pathParts.join('/');
      const parentId = folderMap.get(folderPath) || rootFolderId;

      await handleFileUpload(file, parentId);
    }
  };

  const cleanup = () => {
    setQueuedFolders([]);
    dispatch(setQueuedFiles([]));
    setPublishableUids([]);
  };

  const cancelUpload = (processUid: string) => {
    if (abortControllersRef.current[processUid]) {
      abortControllersRef.current[processUid].abort();
      delete abortControllersRef.current[processUid];
    }
  };

  const cancelAllUploads = () => {
    Object.keys(abortControllersRef.current).forEach(cancelUpload);
  };

  const removeFromList = (filepath: string, index: number) => {
    const updatedFiles = queuedFiles.filter(
      (item) => item.filepath !== filepath,
    );
    dispatch(setQueuedFiles(updatedFiles));

    const updatedUploadIds = [...publishableUids];
    updatedUploadIds.splice(index, 1);
    setPublishableUids(updatedUploadIds);
  };

  const toggleMinimize = () => {
    dispatch(setIsMinimized(!isMinimized));
  };

  const processFiles = async (files: File[]): Promise<FileWithPath[]> => {
    const processedFiles: FileWithPath[] = [];

    for (const file of files) {
      // Handle both regular files and directory files
      const filepath = file.webkitRelativePath || file.name;
      const fileWithPath = Object.assign(file, { filepath }) as FileWithPath;
      processedFiles.push(fileWithPath);

      // If this is part of a directory, add its path to queuedFolders
      if (file.webkitRelativePath) {
        const folderPath = file.webkitRelativePath
          .split('/')
          .slice(0, -1)
          .join('/');
        if (folderPath) {
          setQueuedFolders((prev) => [...new Set([...prev, folderPath])]);
        }
      }
    }

    return processedFiles;
  };

  // File input handlers
  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target.files) return;

    const files = Array.from(event.target.files);
    const processedFiles = await processFiles(files);

    dispatch(setQueuedFiles([...queuedFiles, ...processedFiles]));
  };
  return {
    isMinimized,
    isDragging,
    uploading,
    isUploadEnabled,
    queuedFiles,
    totalProgress,
    isAnyFileUploading,
    uploadStates,
    accessLevel,
    openedWorkspace,
    publishableUids,
    dropzoneRef,
    fileInputRef,
    watch,
    setValue,
    buildFileTree,
    handleDrop,
    handleFileSelect,
    persistFiles,
    removeFromList,
    cancelUpload,
    cancelAllUploads,
    toggleMinimize,
    setIsDragging,
  };
};
