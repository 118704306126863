/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserInformation
 */
export interface UserInformation {
  /**
   *
   * @type {string}
   * @memberof UserInformation
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UserInformation
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserInformation
   */
  online?: boolean;
}

/**
 * Check if a given object implements the UserInformation interface.
 */
export function instanceOfUserInformation(
  value: object,
): value is UserInformation {
  return true;
}

export function UserInformationFromJSON(json: any): UserInformation {
  return UserInformationFromJSONTyped(json, false);
}

export function UserInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserInformation {
  if (json == null) {
    return json;
  }
  return {
    name: json["name"] == null ? undefined : json["name"],
    email: json["email"] == null ? undefined : json["email"],
    online: json["online"] == null ? undefined : json["online"],
  };
}

export function UserInformationToJSON(json: any): UserInformation {
  return UserInformationToJSONTyped(json, false);
}

export function UserInformationToJSONTyped(
  value?: UserInformation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value["name"],
    email: value["email"],
    online: value["online"],
  };
}
