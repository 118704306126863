/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ProduceInformation
 */
export interface ProduceInformation {
  /**
   *
   * @type {string}
   * @memberof ProduceInformation
   */
  path?: string;
  /**
   *
   * @type {string}
   * @memberof ProduceInformation
   */
  parent?: string;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof ProduceInformation
   */
  metadata?: { [key: string]: object };
}

/**
 * Check if a given object implements the ProduceInformation interface.
 */
export function instanceOfProduceInformation(
  value: object,
): value is ProduceInformation {
  return true;
}

export function ProduceInformationFromJSON(json: any): ProduceInformation {
  return ProduceInformationFromJSONTyped(json, false);
}

export function ProduceInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProduceInformation {
  if (json == null) {
    return json;
  }
  return {
    path: json["path"] == null ? undefined : json["path"],
    parent: json["parent"] == null ? undefined : json["parent"],
    metadata: json["metadata"] == null ? undefined : json["metadata"],
  };
}

export function ProduceInformationToJSON(json: any): ProduceInformation {
  return ProduceInformationToJSONTyped(json, false);
}

export function ProduceInformationToJSONTyped(
  value?: ProduceInformation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    path: value["path"],
    parent: value["parent"],
    metadata: value["metadata"],
  };
}
