/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { NotificationMessage } from "./NotificationMessage";
import {
  NotificationMessageFromJSON,
  NotificationMessageFromJSONTyped,
  NotificationMessageToJSON,
  NotificationMessageToJSONTyped,
} from "./NotificationMessage";

/**
 *
 * @export
 * @interface PublishedItemSpec
 */
export interface PublishedItemSpec {
  /**
   *
   * @type {string}
   * @memberof PublishedItemSpec
   */
  path?: string;
  /**
   *
   * @type {string}
   * @memberof PublishedItemSpec
   */
  creator?: string;
  /**
   *
   * @type {string}
   * @memberof PublishedItemSpec
   */
  creatorName?: string;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof PublishedItemSpec
   */
  meta?: { [key: string]: object };
  /**
   *
   * @type {Array<NotificationMessage>}
   * @memberof PublishedItemSpec
   */
  log?: Array<NotificationMessage>;
}

/**
 * Check if a given object implements the PublishedItemSpec interface.
 */
export function instanceOfPublishedItemSpec(
  value: object,
): value is PublishedItemSpec {
  return true;
}

export function PublishedItemSpecFromJSON(json: any): PublishedItemSpec {
  return PublishedItemSpecFromJSONTyped(json, false);
}

export function PublishedItemSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PublishedItemSpec {
  if (json == null) {
    return json;
  }
  return {
    path: json["path"] == null ? undefined : json["path"],
    creator: json["creator"] == null ? undefined : json["creator"],
    creatorName: json["creatorName"] == null ? undefined : json["creatorName"],
    meta: json["meta"] == null ? undefined : json["meta"],
    log:
      json["log"] == null
        ? undefined
        : (json["log"] as Array<any>).map(NotificationMessageFromJSON),
  };
}

export function PublishedItemSpecToJSON(json: any): PublishedItemSpec {
  return PublishedItemSpecToJSONTyped(json, false);
}

export function PublishedItemSpecToJSONTyped(
  value?: PublishedItemSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    path: value["path"],
    creator: value["creator"],
    creatorName: value["creatorName"],
    meta: value["meta"],
    log:
      value["log"] == null
        ? undefined
        : (value["log"] as Array<any>).map(NotificationMessageToJSON),
  };
}
