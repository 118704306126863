/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AccessLevelSpec
 */
export interface AccessLevelSpec {
  /**
   * The access level to give to the member.
   * @type {string}
   * @memberof AccessLevelSpec
   */
  access: string;
}

/**
 * Check if a given object implements the AccessLevelSpec interface.
 */
export function instanceOfAccessLevelSpec(
  value: object,
): value is AccessLevelSpec {
  if (!("access" in value) || value["access"] === undefined) return false;
  return true;
}

export function AccessLevelSpecFromJSON(json: any): AccessLevelSpec {
  return AccessLevelSpecFromJSONTyped(json, false);
}

export function AccessLevelSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AccessLevelSpec {
  if (json == null) {
    return json;
  }
  return {
    access: json["access"],
  };
}

export function AccessLevelSpecToJSON(json: any): AccessLevelSpec {
  return AccessLevelSpecToJSONTyped(json, false);
}

export function AccessLevelSpecToJSONTyped(
  value?: AccessLevelSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    access: value["access"],
  };
}
