import { useAppDispatch, useAppSelector } from '@/lib/store';
import {
  addStarredEntity,
  fetchStarredEntities,
  removeStarredEntity,
} from '@/lib/store/file-manager/starred-entities';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useStarredEntities = (workspaceId: string) => {
  const dispatch = useAppDispatch();
  const { starred } = useAppSelector((state) => state.starredEntities);
  const { entities } = useAppSelector((state) => state.fileManager);
  const [loading, setLoading] = useState(true);

  const starredEntities = useMemo(() => {
    return starred.map((s) => entities.find((e) => e.id === s.entityId));
  }, [starred, entities]);

  useEffect(() => {
    const fetchEntities = async () => {
      try {
        setLoading(true);
        await dispatch(fetchStarredEntities(workspaceId)).unwrap();
      } catch (error) {
        console.error('Failed to fetch starred entities:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEntities();
  }, [dispatch, workspaceId]);

  const isStarred = useCallback(
    (entityId: string) =>
      starred.some(
        (s) => s.entityId === entityId && s.workspaceId === workspaceId,
      ),
    [starred, workspaceId],
  );

  const toggleStar = useCallback(
    (entityId: string) => {
      if (isStarred(entityId)) {
        dispatch(removeStarredEntity({ entityId, workspaceId }));
      } else {
        dispatch(addStarredEntity({ entityId, workspaceId }));
      }
    },
    [dispatch, isStarred, workspaceId],
  );

  return {
    starredEntities: starredEntities,
    isStarred,
    toggleStar,
    loading,
  };
};
