import { Alert, AlertDescription } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  folderTypes,
  useCreateFolder,
} from '@/lib/hooks/use-create-folder.hook';
import { NAMESPACES, useTranslation } from '@/lib/i18n';
import { useAppSelector } from '@/lib/store';
import { cn } from '@/lib/utils';
import { RadioGroup } from '@radix-ui/react-radio-group';
import { Separator } from '@radix-ui/react-separator';
import { useParams } from '@tanstack/react-router';
import { motion } from 'framer-motion';
import { Folder, FolderPlus } from 'lucide-react';
import { RadioGroupItem } from '../../components/ui/radio-group';

export const CreateFolderModal = () => {
  const { t } = useTranslation(NAMESPACES.FILE_MANAGER);
  const { workspaceId, entityId } = useParams({
    strict: false,
  });
  const rootFolder = useAppSelector((state) =>
    state.workspaces.workspaces.find(
      (workspace) => workspace.id === workspaceId,
    ),
  )?.root;

  const { form, error, isOpen, onSubmit, closeModal } = useCreateFolder(
    entityId,
    rootFolder,
  );

  return (
    <Dialog open={isOpen} onOpenChange={closeModal}>
      <DialogContent className="sm:max-w-[640px]">
        <DialogHeader>
          <div className="relative">
            {/* Background Pattern */}
            <div
              className="absolute inset-0 bg-gradient-to-br from-primary/10 via-background to-background"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-[linear-gradient(to_right,transparent_0%,#ffffff08_50%,transparent_100%)] h-[1px] top-1/2" />
            </div>

            {/* Content */}
            <div className="relative px-6 py-8 space-y-4">
              {/* Title Section */}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="p-2.5 rounded-xl bg-primary/10 backdrop-blur-sm">
                    <FolderPlus className="h-5 w-5 text-primary" />
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold">Create New Folder</h2>
                    <p className="text-sm text-muted-foreground">
                      Choose a name and type for your new folder
                    </p>
                  </div>
                </div>
              </div>

              <Separator className="bg-border/40" />
            </div>
          </div>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={onSubmit} className="space-y-6 px-6">
            <motion.div
              className="space-y-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.2 }}
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-foreground">
                      Folder Name
                    </FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Folder className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
                        <Input
                          placeholder="Enter folder name"
                          className="pl-10 bg-background border-border"
                          {...field}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="folderType"
                render={({ field }) => (
                  <FormItem className="space-y-3">
                    <FormLabel>Folder Type</FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="grid grid-cols-1 gap-4"
                      >
                        {folderTypes.map((type) => (
                          <motion.div
                            key={type.id}
                            initial={false}
                            animate={{
                              scale: field.value === type.value ? 1.02 : 1,
                            }}
                            className={cn(
                              'relative flex items-center space-x-4 rounded-lg border border-border p-4 cursor-pointer',
                              field.value === type.value &&
                                'border-primary bg-primary/5',
                            )}
                          >
                            <RadioGroupItem
                              value={type.value}
                              id={type.value}
                              className="absolute right-4"
                            />
                            <div
                              className={cn(
                                'p-2 rounded-lg',
                                field.value === type.value
                                  ? 'bg-primary/10'
                                  : 'bg-muted',
                              )}
                            >
                              {/* {createElement(type.icon, {
                                className: cn(
                                  "h-5 w-5",
                                  field.value === type.value
                                    ? "text-primary"
                                    : "text-muted-foreground"
                                ),
                              })} */}
                              {type.icon}
                            </div>
                            <div className="flex-1">
                              <div className="flex items-center justify-between">
                                <div>
                                  <div className="text-sm font-medium leading-none">
                                    {type.label}
                                  </div>
                                  <div className="text-sm text-muted-foreground mt-1">
                                    {type.description}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </motion.div>
                        ))}
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {error && (
                <Alert
                  variant="destructive"
                  className="bg-destructive/10 border-destructive/20 text-destructive"
                >
                  <AlertDescription>
                    {t(error.message, error.message)}
                  </AlertDescription>
                </Alert>
              )}
            </motion.div>

            <div className="flex justify-end gap-3">
              <Button type="button" variant="outline" onClick={closeModal}>
                Cancel
              </Button>
              <Button type="submit" className="bg-primary text-white">
                {t('file-manager:FILE_MANAGER.FOLDER.TITLE')}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
