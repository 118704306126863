/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Result
 */
export interface Result {
  /**
   *
   * @type {boolean}
   * @memberof Result
   */
  grant?: boolean;
  /**
   *
   * @type {string}
   * @memberof Result
   */
  source?: string;
  /**
   *
   * @type {string}
   * @memberof Result
   */
  verb?: ResultVerbEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ResultVerbEnum {
  Read = "Read",
  Write = "Write",
  Delete = "Delete",
}

/**
 * Check if a given object implements the Result interface.
 */
export function instanceOfResult(value: object): value is Result {
  return true;
}

export function ResultFromJSON(json: any): Result {
  return ResultFromJSONTyped(json, false);
}

export function ResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Result {
  if (json == null) {
    return json;
  }
  return {
    grant: json["grant"] == null ? undefined : json["grant"],
    source: json["source"] == null ? undefined : json["source"],
    verb: json["verb"] == null ? undefined : json["verb"],
  };
}

export function ResultToJSON(json: any): Result {
  return ResultToJSONTyped(json, false);
}

export function ResultToJSONTyped(
  value?: Result | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    grant: value["grant"],
    source: value["source"],
    verb: value["verb"],
  };
}
