/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { MemberInfo } from "./MemberInfo";
import {
  MemberInfoFromJSON,
  MemberInfoFromJSONTyped,
  MemberInfoToJSON,
  MemberInfoToJSONTyped,
} from "./MemberInfo";

/**
 *
 * @export
 * @interface PagerMemberInfo
 */
export interface PagerMemberInfo {
  /**
   *
   * @type {Array<MemberInfo>}
   * @memberof PagerMemberInfo
   */
  list?: Array<MemberInfo>;
  /**
   *
   * @type {boolean}
   * @memberof PagerMemberInfo
   */
  more?: boolean;
}

/**
 * Check if a given object implements the PagerMemberInfo interface.
 */
export function instanceOfPagerMemberInfo(
  value: object,
): value is PagerMemberInfo {
  return true;
}

export function PagerMemberInfoFromJSON(json: any): PagerMemberInfo {
  return PagerMemberInfoFromJSONTyped(json, false);
}

export function PagerMemberInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PagerMemberInfo {
  if (json == null) {
    return json;
  }
  return {
    list:
      json["list"] == null
        ? undefined
        : (json["list"] as Array<any>).map(MemberInfoFromJSON),
    more: json["more"] == null ? undefined : json["more"],
  };
}

export function PagerMemberInfoToJSON(json: any): PagerMemberInfo {
  return PagerMemberInfoToJSONTyped(json, false);
}

export function PagerMemberInfoToJSONTyped(
  value?: PagerMemberInfo | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    list:
      value["list"] == null
        ? undefined
        : (value["list"] as Array<any>).map(MemberInfoToJSON),
    more: value["more"],
  };
}
