import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { retrieveApiErrorMessage } from '@/lib/error';
import {
  WorkspacesErrorCodesEnum,
  toggleDisplayOnboardWorkspaceModal,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { motion } from 'framer-motion';
import { AlertCircle, CheckCircle2, Loader2 } from 'lucide-react';
import { useEffect } from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { toast } from 'sonner';

// TODO: Add translations
export const OnboardWorkspaceCompletedBanner = () => {
  const dispatch = useAppDispatch();
  const { width, height } = useWindowSize();

  const { error, loading, createdWorkspace } = useAppSelector(
    (state) => state.workspaces,
  );

  const toggle = () => {
    dispatch(toggleDisplayOnboardWorkspaceModal());
  };

  useEffect(() => {
    if (error?.code === WorkspacesErrorCodesEnum.CREATE_WORKSPACE_FAILED) {
      toast.error(retrieveApiErrorMessage(error.message));
    }
  }, [error]);

  useEffect(() => {
    if (createdWorkspace) {
      // Wait for confetti animation before closing
      setTimeout(toggle, 3000);
    }
  }, [createdWorkspace]);

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex flex-col items-center justify-center space-y-4 min-h-[200px]"
      >
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
        <p className="text-sm text-muted-foreground">
          Creating your workspace...
        </p>
      </motion.div>
    );
  }

  if (error?.code === WorkspacesErrorCodesEnum.CREATE_WORKSPACE_FAILED) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="p-4"
      >
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Failed to create workspace</AlertTitle>
          <AlertDescription>
            {retrieveApiErrorMessage(error.message)}
          </AlertDescription>
        </Alert>
      </motion.div>
    );
  }

  if (createdWorkspace) {
    return (
      <>
        <Confetti
          width={width}
          height={height}
          numberOfPieces={200}
          recycle={false}
          gravity={0.2}
        />
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          className="flex flex-col items-center justify-center space-y-4 min-h-[200px] p-4"
        >
          <div className="h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center">
            <CheckCircle2 className="h-6 w-6 text-primary" />
          </div>
          <div className="text-center space-y-2">
            <h3 className="text-lg font-semibold">Workspace Created!</h3>
            <p className="text-sm text-muted-foreground">
              Your new workspace is ready. You'll be redirected shortly...
            </p>
          </div>
        </motion.div>
      </>
    );
  }

  return null;
};
