import { AppLayout } from '@/components/app-layout.component';
import { AuthCheck } from '@/components/auth-check.component';
import { Head } from '@/components/head.component';
import { GroupsContainer } from '@/containers/$workspaceId/groups/groups-container.component';
import { useTranslation } from '@/lib/i18n';
import { getWorkspaceGroups, useAppDispatch } from '@/lib/store';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { useEffect } from 'react';

export const Route = createFileRoute('/$workspaceId/groups')({
  component: RouteComponent,
});

function RouteComponent() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams({
    strict: false,
  });

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(
      getWorkspaceGroups({
        workspaceId,
      }),
    );
  }, [dispatch, workspaceId]);
  return (
    <>
      <Head heading={t('common:COMMON.GROUPS')} />
      <AuthCheck>
        <AppLayout>
          <GroupsContainer />
        </AppLayout>
      </AuthCheck>
    </>
  );
}
