/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import {
  Entity,
  filesProvider,
  getEntityVersions,
  setEntityVersion,
  useAppDispatch,
  useAppSelector,
} from '@/lib/store';
import { cn, prettyBytes } from '@/lib/utils';
import { formatDistanceToNow } from 'date-fns';
import {
  ArrowDownToLine,
  Clock,
  FileText,
  History,
  Loader2,
  MoreVertical,
  RotateCcw,
} from 'lucide-react';
import { useEffect } from 'react';
import { toast } from 'sonner';

interface VersionsTabProps {
  entity: Entity;
}

interface Version {
  id: string;
  versionNumber: number;
  authorId: string;
  authorName: string;
  authorAvatar?: string;
  timestamp: Date;
  size: number;
  comment?: string;
  isCurrent: boolean;
  contentType?: string;
}

export const VersionsTab = ({ entity }: VersionsTabProps) => {
  const dispatch = useAppDispatch();
  const { entityVersions, loading } = useAppSelector(
    (state) => state.fileManager,
  );

  useEffect(() => {
    if (entity?.id) {
      dispatch(getEntityVersions(entity.id));
    }
  }, [dispatch, entity?.id]);

  const versions = parseVersions(entityVersions, entity);

  const handleDownloadVersion = async (version: Version) => {
    try {
      if (!entity?.id) return;

      toast.info(`Downloading version ${version.versionNumber}...`);
      const blob = await filesProvider.fetchFile(
        entity.id,
        version.versionNumber,
      );

      if (blob) {
        // Create a download link
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download =
          entity.name ||
          `file-v${version.versionNumber}.${getFileExtension(version.contentType)}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        toast.success(
          `Version ${version.versionNumber} downloaded successfully`,
        );
      }
    } catch (error) {
      console.error('Error downloading version:', error);
      toast.error(`Failed to download version ${version.versionNumber}`);
    }
  };

  const handleRevertVersion = async (version: Version) => {
    try {
      if (!entity?.id) return;

      dispatch(
        setEntityVersion({
          entity: entity.id,
          body: version.versionNumber,
        }),
      );
      toast.success(`Reverted to version ${version.versionNumber}`);

      // Refresh versions after reverting
      dispatch(getEntityVersions(entity.id));
    } catch (error) {
      console.error('Error reverting version:', error);
      toast.error(`Failed to revert to version ${version.versionNumber}`);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (!versions || versions.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-muted-foreground">
        <History className="h-12 w-12 mb-4" />
        <p>No version history available</p>
      </div>
    );
  }

  const currentVersion = versions.find((v) => v.isCurrent) || versions[0];

  return (
    <div className="flex flex-col h-full">
      {/* Current Version Card */}
      <div className="p-4 border-b">
        <div className="space-y-1">
          <h3 className="text-sm font-medium text-muted-foreground">
            Current Version
          </h3>
          <div className="flex items-center gap-2">
            <h2 className="text-2xl font-bold">
              v{currentVersion.versionNumber}
            </h2>
            <Badge variant="secondary" className="text-xs">
              Latest
            </Badge>
          </div>
        </div>
      </div>

      {/* Version List */}
      <ScrollArea className="flex-1">
        <div className="p-4 space-y-6">
          {versions.map((version, index) => (
            <div key={version.id}>
              <div className="flex items-start gap-4">
                {/* Version Timeline Indicator */}
                <div className="flex flex-col items-center gap-2">
                  <div
                    className={cn(
                      'w-8 h-8 rounded-full flex items-center justify-center',
                      version.isCurrent
                        ? 'bg-primary text-primary-foreground'
                        : 'bg-muted',
                    )}
                  >
                    {version.isCurrent ? (
                      <Clock className="w-4 h-4" />
                    ) : (
                      <History className="w-4 h-4" />
                    )}
                  </div>
                  {index !== versions.length - 1 && (
                    <div className="w-0.5 h-full bg-border" />
                  )}
                </div>

                {/* Version Content */}
                <div className="flex-1 space-y-3">
                  {/* Version Header */}
                  <div className="flex items-start justify-between">
                    <div className="space-y-1">
                      <div className="flex items-center gap-2">
                        <span className="font-medium">
                          Version {version.versionNumber}
                        </span>
                        {version.isCurrent && (
                          <Badge variant="outline" className="text-xs">
                            Current
                          </Badge>
                        )}
                      </div>
                      {version.comment && (
                        <p className="text-sm text-muted-foreground">
                          {version.comment}
                        </p>
                      )}
                    </div>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="ghost"
                          size="sm"
                          className="h-8 w-8 p-0"
                        >
                          <MoreVertical className="h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuItem
                          onClick={() => handleDownloadVersion(version)}
                        >
                          <ArrowDownToLine className="w-4 h-4 mr-2" />
                          Download
                        </DropdownMenuItem>
                        {!version.isCurrent && (
                          <>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                              onClick={() => handleRevertVersion(version)}
                              className="text-red-600"
                            >
                              <RotateCcw className="w-4 h-4 mr-2" />
                              Revert to this version
                            </DropdownMenuItem>
                          </>
                        )}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>

                  {/* Version Metadata */}
                  <div className="flex items-center gap-4 text-sm">
                    <div className="flex items-center gap-2">
                      <Avatar className="w-6 h-6">
                        <AvatarImage src={version.authorAvatar} />
                        <AvatarFallback>{version.authorName[0]}</AvatarFallback>
                      </Avatar>
                      <span>{version.authorName}</span>
                    </div>
                    <Separator orientation="vertical" className="h-4" />
                    <span className="text-muted-foreground">
                      {formatDistanceToNow(version.timestamp)} ago
                    </span>
                    <Separator orientation="vertical" className="h-4" />
                    <div className="flex items-center gap-1 text-muted-foreground">
                      <FileText className="w-4 h-4" />
                      {prettyBytes(version.size)}
                    </div>
                  </div>
                </div>
              </div>

              {index !== versions.length - 1 && <div className="h-6" />}
            </div>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
};

function parseVersions(
  entityVersions: { [key: string]: { [key: string]: any } } | null,
  entity: Entity,
): Version[] {
  if (!entityVersions) return [];

  const versions: Version[] = [];
  const currentVersionNum = entity.numversion
    ? parseInt(entity.numversion.toString())
    : 0;

  // Convert the object into an array of versions
  Object.entries(entityVersions).forEach(([versionKey, versionData]) => {
    if (!versionData) return;

    const versionNumber = parseInt(versionData.num?.toString() || versionKey);
    const isCurrent = versionNumber === currentVersionNum;

    versions.push({
      id: versionData.id || `version-${versionKey}`,
      versionNumber,
      authorId: versionData.uploader || '',
      authorName: versionData.uploaderName || 'Unknown User',
      timestamp: new Date(parseInt(versionData.uploaded) || Date.now()),
      size: parseInt(versionData.size) || 0,
      isCurrent,
      contentType: versionData.contentType,
    });
  });

  // Sort by version number (descending)
  return versions.sort((a, b) => b.versionNumber - a.versionNumber);
}

// Helper function to get file extension from content type
function getFileExtension(contentType?: string): string {
  if (!contentType) return '';

  const extensionMap: Record<string, string> = {
    'application/pdf': 'pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'docx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      'pptx',
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'text/plain': 'txt',
    'text/html': 'html',
    'application/json': 'json',
  };

  return extensionMap[contentType] || contentType.split('/').pop() || '';
}
