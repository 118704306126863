import { clsx, type ClassValue } from "clsx";
import i18n from "i18next";
import { twMerge } from "tailwind-merge";
import { PrettyBytesOptions } from "./types";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const findUpper = (string: string | null | undefined) => {
  const extractedString = [];

  if (!string) return;

  for (let i = 0; i < string.length; i++) {
    if (
      string.charAt(i) === string.charAt(i).toUpperCase() &&
      string.charAt(i) !== " "
    ) {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

const BYTE_UNITS = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

const BIBYTE_UNITS = [
  "B",
  "KiB",
  "MiB",
  "GiB",
  "TiB",
  "PiB",
  "EiB",
  "ZiB",
  "YiB",
];

const BIT_UNITS = [
  "b",
  "Kbit",
  "Mbit",
  "Gbit",
  "Tbit",
  "Pbit",
  "Ebit",
  "Zbit",
  "Ybit",
];

const BIBIT_UNITS = [
  "b",
  "Kibit",
  "Mibit",
  "Gibit",
  "Tibit",
  "Pibit",
  "Eibit",
  "Zibit",
  "Yibit",
];

/*
Formats the given number using `Number#toLocaleString`.
- If locale is a string, the value is expected to be a locale-key (for example: `de`).
- If locale is true, the system default locale is used for translation.
- If no value for locale is specified, the number is returned unmodified.
*/
const toLocaleString = (
  number: number,
  locale: string | boolean,
  options: Intl.NumberFormatOptions
) => {
  let result: string | number = number;
  if (typeof locale === "string" || Array.isArray(locale)) {
    result = number.toLocaleString(locale, options);
  } else if (locale === true || options !== undefined) {
    result = number.toLocaleString(undefined, options);
  }

  return result;
};

const getUnits = (language: string, options: PrettyBytesOptions) => {
  const baseUnit = language.startsWith("fr") ? "o" : "B";
  const units = options.bits
    ? options.binary
      ? BIBIT_UNITS
      : BIT_UNITS
    : options.binary
      ? BIBYTE_UNITS
      : BYTE_UNITS;

  return units.map((unit) => unit.replace("B", baseUnit));
};

export const prettyBytes = (
  number: number,
  options: PrettyBytesOptions = {
    bits: false,
    binary: false,
    space: true,
    locale: false,
  }
) => {
  if (!Number.isFinite(number)) {
    throw new TypeError(
      `Expected a finite number, got ${typeof number}: ${number}`
    );
  }

  options = {
    bits: false,
    binary: false,
    space: true,
    ...options,
  };

  const language = i18n.language;
  const UNITS = getUnits(language, options);

  const separator = options.space ? " " : "";

  if (options.signed && number === 0) {
    return ` 0${separator}${UNITS[0]}`;
  }

  const isNegative = number < 0;
  const prefix = isNegative ? "-" : options.signed ? "+" : "";

  if (isNegative) {
    number = -number;
  }

  let localeOptions: Intl.NumberFormatOptions | undefined;

  if (options.minimumFractionDigits !== undefined) {
    localeOptions = { minimumFractionDigits: options.minimumFractionDigits };
  }

  if (options.maximumFractionDigits !== undefined) {
    localeOptions = {
      maximumFractionDigits: options.maximumFractionDigits,
      ...localeOptions,
    };
  }

  if (number < 1) {
    const numberString = toLocaleString(
      number,
      options.locale,
      localeOptions as Intl.NumberFormatOptions
    );
    return prefix + numberString + separator + UNITS[0];
  }

  const exponent = Math.min(
    Math.floor(
      options.binary
        ? Math.log(number) / Math.log(1024)
        : Math.log10(number) / 3
    ),
    UNITS.length - 1
  );
  number /= (options.binary ? 1024 : 1000) ** exponent;

  if (!localeOptions) {
    number = Number(number.toPrecision(3));
  }

  const numberString = toLocaleString(
    Number(number),
    options.locale,
    localeOptions as Intl.NumberFormatOptions
  );

  const unit = UNITS[exponent];

  return prefix + numberString + separator + unit;
};

export const currencyFormatter = (quantity: number) =>
  (quantity / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
