// components/file-manager/display-type-filter.tsx
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Tooltip, TooltipProvider } from "@/components/ui/tooltip";
import { setDisplayType, useAppDispatch, useAppSelector } from "@/lib/store";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import { LayoutGrid, List, Rows } from "lucide-react";
import { createElement } from "react";

interface DisplayTypeOption {
  title: string;
  icon: typeof LayoutGrid;
  value: "grid" | "group" | "list";
  description: string;
}

const displayTypeOptions: DisplayTypeOption[] = [
  {
    title: "Grid View",
    icon: LayoutGrid,
    value: "grid",
    description: "Display items in a grid layout",
  },
  {
    title: "Group View",
    icon: Rows,
    value: "group",
    description: "Group items by type",
  },
  {
    title: "List View",
    icon: List,
    value: "list",
    description: "Show items in a detailed list",
  },
];

interface FileManagerDisplayTypeFilterProps {
  displayTooltip?: boolean;
  variant?: "default" | "compact" | "mobile";
}

export const FileManagerDisplayTypeFilter = ({
  displayTooltip = false,
  variant = "default",
}: FileManagerDisplayTypeFilterProps) => {
  const dispatch = useAppDispatch();
  const { displayType } = useAppSelector((state) => state.fileManager);

  const handleDisplayTypeChange = (type: "grid" | "group" | "list") => {
    dispatch(setDisplayType(type));
  };

  // Mobile dropdown version
  if (variant === "mobile") {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" size="sm">
            <span className="sr-only">View options</span>
            {displayTypeOptions.find((opt) => opt.value === displayType)
              ?.icon && (
              <motion.div
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                className="h-4 w-4"
              >
                {createElement(
                  displayTypeOptions.find((opt) => opt.value === displayType)
                    ?.icon || LayoutGrid
                )}
              </motion.div>
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-48">
          {displayTypeOptions.map((option) => (
            <DropdownMenuItem
              key={option.value}
              onClick={() => handleDisplayTypeChange(option.value)}
              className="flex items-center gap-2"
            >
              <option.icon className="h-4 w-4" />
              <div className="flex flex-col">
                <span className="text-sm font-medium">{option.title}</span>
                <span className="text-xs text-muted-foreground">
                  {option.description}
                </span>
              </div>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }

  // Default button group version
  return (
    <div
      className={cn(
        "inline-flex items-center justify-end rounded-lg border bg-background p-1 shadow-sm",
        variant === "compact" && "scale-90"
      )}
    >
      {displayTypeOptions.map((option) => (
        <TooltipProvider key={option.value}>
          <Tooltip>
            <Button
              variant={displayType === option.value ? "secondary" : "ghost"}
              size="sm"
              onClick={() => handleDisplayTypeChange(option.value)}
              className={cn(
                "px-2.5",
                displayType === option.value && "bg-primary/10"
              )}
            >
              <span className="sr-only">{option.title}</span>
              <motion.div
                initial={false}
                animate={{
                  scale: displayType === option.value ? 1 : 0.9,
                  opacity: displayType === option.value ? 1 : 0.7,
                }}
                className={cn(
                  "h-4 w-4",
                  displayType === option.value && "text-primary"
                )}
              >
                <option.icon />
                {displayTooltip ? option.title : null}
              </motion.div>
            </Button>
          </Tooltip>
        </TooltipProvider>
      ))}
    </div>
  );
};

export const CompactDisplayTypeFilter = (
  props: Omit<FileManagerDisplayTypeFilterProps, "variant">
) => <FileManagerDisplayTypeFilter {...props} variant="compact" />;

export const MobileDisplayTypeFilter = (
  props: Omit<FileManagerDisplayTypeFilterProps, "variant">
) => <FileManagerDisplayTypeFilter {...props} variant="mobile" />;

// Usage example in a component:
export const FileManagerToolbar = () => {
  return (
    <div className="flex items-center justify-between p-4">
      {/* Regular display on desktop */}
      <div className="hidden md:block">
        <FileManagerDisplayTypeFilter displayTooltip />
      </div>

      {/* Compact display on tablets */}
      <div className="hidden sm:block md:hidden">
        <CompactDisplayTypeFilter />
      </div>

      {/* Mobile dropdown on small screens */}
      <div className="block sm:hidden">
        <MobileDisplayTypeFilter />
      </div>
    </div>
  );
};
