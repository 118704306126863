/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ChargeInfo
 */
export interface ChargeInfo {
  /**
   *
   * @type {string}
   * @memberof ChargeInfo
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof ChargeInfo
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof ChargeInfo
   */
  created?: number;
  /**
   *
   * @type {string}
   * @memberof ChargeInfo
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof ChargeInfo
   */
  description?: string;
}

/**
 * Check if a given object implements the ChargeInfo interface.
 */
export function instanceOfChargeInfo(value: object): value is ChargeInfo {
  return true;
}

export function ChargeInfoFromJSON(json: any): ChargeInfo {
  return ChargeInfoFromJSONTyped(json, false);
}

export function ChargeInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ChargeInfo {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"] == null ? undefined : json["id"],
    amount: json["amount"] == null ? undefined : json["amount"],
    created: json["created"] == null ? undefined : json["created"],
    currency: json["currency"] == null ? undefined : json["currency"],
    description: json["description"] == null ? undefined : json["description"],
  };
}

export function ChargeInfoToJSON(json: any): ChargeInfo {
  return ChargeInfoToJSONTyped(json, false);
}

export function ChargeInfoToJSONTyped(
  value?: ChargeInfo | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    amount: value["amount"],
    created: value["created"],
    currency: value["currency"],
    description: value["description"],
  };
}
