import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Column, Task, useAppDispatch } from "@/lib/store";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { motion } from "framer-motion";
import { Calendar, MoreHorizontal, Pencil, Plus, Trash } from "lucide-react";
import { Draggable, Droppable } from "react-beautiful-dnd";

type TasksBoardColumnProps = {
  column: Column;
  tasks: Task[];
  index: number;
};

export const TasksBoardColumn = ({
  column,
  tasks,
  index,
}: TasksBoardColumnProps) => {
  //   const { toggleCreateTaskModal, toggleEditColumnModal } =
  //     useDisplayTaskModals();

  const dispatch = useAppDispatch();
  const toggleCreateTaskModal = () => {
    // dispatch(setDisplay(true));
  };

  const toggleEditColumnModal = () => {
    // dispatch(setDisplay(true));
  };

  return (
    <Draggable draggableId={column.id} index={index}>
      {(provided) => (
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: index * 0.1 }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="flex-shrink-0 w-80"
        >
          <div
            className={cn(
              "rounded-xl bg-gray-50 border border-gray-200",
              "flex flex-col h-full"
            )}
          >
            {/* Column Header */}
            <div
              {...provided.dragHandleProps}
              className={cn(
                "p-4 border-b border-gray-200",
                "flex items-center justify-between"
              )}
            >
              <div className="flex items-center gap-2">
                <h3 className="font-medium text-gray-900">{column.name}</h3>
                <Badge variant="secondary" className="text-xs">
                  {column.tasks.length}
                </Badge>
              </div>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="sm">
                    <MoreHorizontal className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem onClick={toggleEditColumnModal}>
                    <Pencil className="h-4 w-4 mr-2" />
                    Edit Column
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={toggleCreateTaskModal}
                    className="text-primary"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Task
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    onClick={() => {
                      /* handle delete */
                    }}
                    className="text-red-600"
                  >
                    <Trash className="h-4 w-4 mr-2" />
                    Delete Column
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>

            {/* Tasks Area */}
            <Droppable droppableId={column.id} type="task">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={cn(
                    "flex-1 p-4 space-y-3",
                    snapshot.isDraggingOver && "bg-primary/5"
                  )}
                >
                  {column.tasks.map((taskId, index) => (
                    <TasksBoardTask
                      key={taskId}
                      task={tasks.find((t) => t.id === taskId)!}
                      index={index}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            {/* Add Task Button */}
            <div className="p-4 border-t border-gray-200">
              <Button
                variant="ghost"
                className="w-full justify-start text-gray-500 hover:text-primary"
                onClick={toggleCreateTaskModal}
              >
                <Plus className="h-4 w-4 mr-2" />
                Add Task
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </Draggable>
  );
};

type TasksBoardTaskProps = {
  task: Task;
  index: number;
};

export const TasksBoardTask = ({ task, index }: TasksBoardTaskProps) => {
  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={cn(
            "rounded-lg bg-white border border-gray-200 p-4",
            "hover:border-primary/50 transition-colors",
            snapshot.isDragging && "shadow-lg border-primary"
          )}
        >
          <div className="space-y-3">
            <div className="font-medium text-gray-900">{task.name}</div>
            {task.description && (
              <p className="text-sm text-gray-500 line-clamp-2">
                {task.description}
              </p>
            )}

            {/* Task Metadata */}
            <div className="flex items-center gap-4 text-xs text-gray-500">
              {task.metadata.due && (
                <div className="flex items-center gap-1">
                  <Calendar className="h-3.5 w-3.5" />
                  {format(new Date(task.metadata.due), "MMM d")}
                </div>
              )}
              {task.metadata.users?.length > 0 && (
                // <AvatarGroup>
                <>
                  {task.metadata.users.map((user, i) => (
                    <Avatar key={i} className="border-2 border-white w-6 h-6">
                      <AvatarFallback className="text-xs">
                        {user.label?.charAt(0)}
                      </AvatarFallback>
                    </Avatar>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};
