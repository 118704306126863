import { useNavbarNavigation } from '@/lib/hooks/use-navbar-navigation.hook';
import { ArrowLeftToLine, ArrowRightToLine, Search } from 'lucide-react';
import { useEffect, useState } from 'react';
import { LanguageChanger } from '../language-changer.component';
import { Button } from '../ui/button';
import { Breadcrumbs } from './breadcrumbs.component';
import { ChangelogBanner } from './changelog.component';
import { Notifications } from './notifications.component';
import { QueryDialog } from './query-dialog.component';
import { UserDropdown } from './user-dropdown.component';

export const Header = () => {
  const { isCollapsed, setIsCollapsed } = useNavbarNavigation();
  const [displayQueryDialog, setDisplayQueryDialog] = useState(false);

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        setDisplayQueryDialog(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <header className="border-b border-border/40">
      <div className="flex h-20 items-center gap-6 px-6">
        <Button
          variant="ghost"
          size="lg"
          onClick={toggleCollapsed}
          className="shrink-0 h-12 w-12"
        >
          {isCollapsed ? (
            <ArrowRightToLine className="h-6 w-6" />
          ) : (
            <ArrowLeftToLine className="h-6 w-6" />
          )}
        </Button>

        <Breadcrumbs />

        <div className="flex flex-1" />

        <div className="flex items-center gap-4">
          <Button
            variant="outline"
            onClick={() => setDisplayQueryDialog(true)}
            className="gap-2 w-[300px] justify-between text-muted-foreground"
          >
            <div className="flex items-center gap-2">
              <Search className="h-4 w-4" />
              <span>Find</span>
            </div>
            <kbd className="pointer-events-none hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
              <span className="text-xs">⌘</span>K
            </kbd>
          </Button>
          <div className="flex items-center gap-4 pl-4 border-l border-border/40">
            <LanguageChanger />
            {/* <ThemeToggler /> */}
            <Notifications />
            <UserDropdown />
          </div>
        </div>
      </div>

      {/* Changelog Banner */}
      <div className="px-6 pb-4">
        <ChangelogBanner />
      </div>

      <QueryDialog
        open={displayQueryDialog}
        onOpenChange={setDisplayQueryDialog}
      />
    </header>
  );
};
