type LogoProps = {
  className?: string;
  iconOnly?: boolean;
};

export const Logo = ({ className, iconOnly }: LogoProps) => {
  return iconOnly ? (
    <img src="/prividox-logo-icon.svg" alt="prividox" className={className} />
  ) : (
    <img src="/prividox-logo.svg" alt="prividox" className={className} />
  );
};
