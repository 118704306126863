/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AttachmentInformation
 */
export interface AttachmentInformation {
  /**
   *
   * @type {string}
   * @memberof AttachmentInformation
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AttachmentInformation
   */
  contentType?: string;
  /**
   *
   * @type {string}
   * @memberof AttachmentInformation
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AttachmentInformation
   */
  path?: string;
  /**
   *
   * @type {boolean}
   * @memberof AttachmentInformation
   */
  encrypted?: boolean;
}

/**
 * Check if a given object implements the AttachmentInformation interface.
 */
export function instanceOfAttachmentInformation(
  value: object,
): value is AttachmentInformation {
  return true;
}

export function AttachmentInformationFromJSON(
  json: any,
): AttachmentInformation {
  return AttachmentInformationFromJSONTyped(json, false);
}

export function AttachmentInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AttachmentInformation {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"] == null ? undefined : json["id"],
    contentType: json["contentType"] == null ? undefined : json["contentType"],
    name: json["name"] == null ? undefined : json["name"],
    path: json["path"] == null ? undefined : json["path"],
    encrypted: json["encrypted"] == null ? undefined : json["encrypted"],
  };
}

export function AttachmentInformationToJSON(json: any): AttachmentInformation {
  return AttachmentInformationToJSONTyped(json, false);
}

export function AttachmentInformationToJSONTyped(
  value?: AttachmentInformation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    contentType: value["contentType"],
    name: value["name"],
    path: value["path"],
    encrypted: value["encrypted"],
  };
}
