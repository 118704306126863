/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PrincipalInformation
 */
export interface PrincipalInformation {
  /**
   *
   * @type {string}
   * @memberof PrincipalInformation
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PrincipalInformation
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PrincipalInformation
   */
  email?: string;
  /**
   *
   * @type {Array<PrincipalInformation>}
   * @memberof PrincipalInformation
   */
  members?: Array<PrincipalInformation>;
}

/**
 * Check if a given object implements the PrincipalInformation interface.
 */
export function instanceOfPrincipalInformation(
  value: object,
): value is PrincipalInformation {
  return true;
}

export function PrincipalInformationFromJSON(json: any): PrincipalInformation {
  return PrincipalInformationFromJSONTyped(json, false);
}

export function PrincipalInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PrincipalInformation {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"] == null ? undefined : json["id"],
    name: json["name"] == null ? undefined : json["name"],
    email: json["email"] == null ? undefined : json["email"],
    members:
      json["members"] == null
        ? undefined
        : (json["members"] as Array<any>).map(PrincipalInformationFromJSON),
  };
}

export function PrincipalInformationToJSON(json: any): PrincipalInformation {
  return PrincipalInformationToJSONTyped(json, false);
}

export function PrincipalInformationToJSONTyped(
  value?: PrincipalInformation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    name: value["name"],
    email: value["email"],
    members:
      value["members"] == null
        ? undefined
        : (value["members"] as Array<any>).map(PrincipalInformationToJSON),
  };
}
