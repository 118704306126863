/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { GroupInfo } from "./GroupInfo";
import {
  GroupInfoFromJSON,
  GroupInfoFromJSONTyped,
  GroupInfoToJSON,
  GroupInfoToJSONTyped,
} from "./GroupInfo";

/**
 *
 * @export
 * @interface PagerGroupInfo
 */
export interface PagerGroupInfo {
  /**
   *
   * @type {Array<GroupInfo>}
   * @memberof PagerGroupInfo
   */
  list?: Array<GroupInfo>;
  /**
   *
   * @type {boolean}
   * @memberof PagerGroupInfo
   */
  more?: boolean;
}

/**
 * Check if a given object implements the PagerGroupInfo interface.
 */
export function instanceOfPagerGroupInfo(
  value: object,
): value is PagerGroupInfo {
  return true;
}

export function PagerGroupInfoFromJSON(json: any): PagerGroupInfo {
  return PagerGroupInfoFromJSONTyped(json, false);
}

export function PagerGroupInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PagerGroupInfo {
  if (json == null) {
    return json;
  }
  return {
    list:
      json["list"] == null
        ? undefined
        : (json["list"] as Array<any>).map(GroupInfoFromJSON),
    more: json["more"] == null ? undefined : json["more"],
  };
}

export function PagerGroupInfoToJSON(json: any): PagerGroupInfo {
  return PagerGroupInfoToJSONTyped(json, false);
}

export function PagerGroupInfoToJSONTyped(
  value?: PagerGroupInfo | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    list:
      value["list"] == null
        ? undefined
        : (value["list"] as Array<any>).map(GroupInfoToJSON),
    more: value["more"],
  };
}
