import {
  Entity,
  setLastOpenedEntity,
  toggleDisplayPreviewFileModal,
  useAppDispatch,
} from '@/lib/store';
import { useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';

export const useEntityNavigation = (workspaceId: string) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleEntityClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, entity: Entity) => {
      const target = event.target as HTMLElement;
      if (
        target.closest('button') ||
        target.closest('input') ||
        target.closest('[data-no-preview]')
      ) {
        return;
      }

      if (['folder', 'gallery', 'calendar'].includes(entity.type)) {
        navigate({
          to: `/${workspaceId}/file-manager/entities/${entity.folderId}`,
        });
      } else {
        dispatch(setLastOpenedEntity(entity));
        dispatch(toggleDisplayPreviewFileModal());
      }
    },
    [dispatch, navigate, workspaceId],
  );

  return { handleEntityClick };
};
