import { useAppSelector } from "@/lib/store";
import moment from "moment";
import { useCallback } from "react";

export const useEntityDateFormat = () => {
  const { timeFormat } = useAppSelector((state) => state.user);
  const { "app.settings.dateFormat": dateFormat } = useAppSelector(
    (state) => state.user
  );

  const formatDateTime = (
    date: number | undefined,
    format: string | null,
    timeFormat: string | null
  ) => {
    const validFormat = format ?? "";
    const validTimeFormat = timeFormat ?? "";
    const fullFormat = `${validFormat} ${validTimeFormat}`.trim();

    return moment(date).format(fullFormat);
  };

  const formatTime = useCallback(
    (timestamp: number | undefined) => {
      return timeFormat === "HH:mm"
        ? moment(timestamp).format("L HH:mm")
        : moment(timestamp).format("L HH:mm:ss");
    },
    [timeFormat]
  );

  return {
    formatDateTime,
    formatTime,
    dateFormat,
    timeFormat,
  };
};
