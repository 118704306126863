import { AppLayout } from '@/components/app-layout.component';
import { AuthCheck } from '@/components/auth-check.component';
import { Head } from '@/components/head.component';
import { FileManagerContainer } from '@/containers/$workspaceId/file-manager/file-manager-container.component';
import { FileManagerTrash } from '@/containers/$workspaceId/file-manager/file-manager-trash.component';
import { useTranslation } from '@/lib/i18n';
import {
  getTrashEntities,
  getWorkspaceMembers,
  setLastOpenedWorkspace,
  useAppDispatch,
} from '@/lib/store';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { useEffect } from 'react';

export const Route = createFileRoute('/$workspaceId/file-manager/trash')({
  component: RouteComponent,
});

function RouteComponent() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams({ strict: false });

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(getTrashEntities(workspaceId as string));
    dispatch(getWorkspaceMembers(workspaceId as string));
    dispatch(setLastOpenedWorkspace(workspaceId));
  }, [workspaceId]);

  return (
    <>
      <Head heading={t('file-manager:FILE_MANAGER.TRASH.TITLE')} />
      <AuthCheck>
        <AppLayout>
          <FileManagerContainer>
            <FileManagerTrash />
          </FileManagerContainer>
        </AppLayout>
      </AuthCheck>
    </>
  );
}
