import { Entity, baseConfig } from '@/lib/store';

interface MediaPreviewProps {
  entity: Entity;
}

const generatePreviewUrl = (entityId: string) =>
  `${baseConfig.basePath}/download/${entityId}`;

export const MediaPreview = ({ entity }: MediaPreviewProps) => {
  const renderPreview = () => {
    const isImage = [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/svg',
      'image/svg+xml',
    ].includes(entity.contentType ?? '');

    const isVideo = ['video/mp4', 'video/webm', 'video/ogg'].includes(
      entity.contentType ?? '',
    );

    if (isImage) {
      return (
        <div className="flex items-center justify-center h-full p-4">
          <img
            src={generatePreviewUrl(entity.id)}
            alt={entity.name}
            className="max-w-full max-h-full object-contain rounded-lg shadow-lg"
            loading="lazy"
          />
        </div>
      );
    }

    if (isVideo) {
      return (
        <div className="flex items-center justify-center h-full p-4">
          <video
            controls
            className="max-w-full max-h-full rounded-lg shadow-lg"
          >
            <source
              src={generatePreviewUrl(entity.id)}
              type={entity.contentType}
            />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    }

    return (
      <div className="flex items-center justify-center h-full text-muted-foreground">
        Preview not available
      </div>
    );
  };

  return renderPreview();
};
