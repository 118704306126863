import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useWizard } from "@/lib/hooks/use-wizard.hook";
import {
  setCreateWorkpaceFormField,
  useAppDispatch,
  useAppSelector,
} from "@/lib/store";
import { cn } from "@/lib/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { FolderOpenDot, HelpCircle, Lock, Users } from "lucide-react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

const permissionOptions = [
  {
    value: "inherited",
    label: "Inherited",
    description: "Inherit permissions from parent folder",
    icon: FolderOpenDot,
  },
  {
    value: "no-access",
    label: "No Access",
    description: "No access to folder contents",
    icon: Lock,
  },
  {
    value: "view",
    label: "Read",
    description: "View folder contents only",
    icon: Users,
  },
  {
    value: "modify",
    label: "Edit",
    description: "Modify folder contents",
    icon: Users,
  },
  {
    value: "full-control",
    label: "Full Control",
    description: "Complete control over folder",
    icon: Users,
  },
] as const;

const onboardWorkspaceBasicsFormSchema = yup
  .object({
    name: yup
      .string()
      .min(3, "Name must be at least 3 characters")
      .max(24, "Name must be less than 24 characters")
      .required("Name is required"),
    rootFolderPermissions: yup
      .string()
      .required("Root folder permissions are required"),
  })
  .required();

type OnboardWorkspaceBasicsFormValues = yup.InferType<
  typeof onboardWorkspaceBasicsFormSchema
>;

const FormSectionHeader = ({
  icon: Icon,
  title,
  description,
  tooltip,
}: {
  icon: React.ElementType;
  title: string;
  description: string;
  tooltip?: string;
}) => (
  <div className="flex items-start gap-3 mb-4">
    <div className="rounded-xl bg-primary/10 p-2.5 shrink-0">
      <Icon className="h-5 w-5 text-primary" />
    </div>
    <div className="space-y-1 flex-1">
      <div className="flex items-center gap-2">
        <h3 className="text-base font-medium leading-none">{title}</h3>
        {tooltip && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <HelpCircle className="h-4 w-4 text-muted-foreground" />
              </TooltipTrigger>
              <TooltipContent>{tooltip}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      <p className="text-sm text-muted-foreground">{description}</p>
    </div>
  </div>
);

export const OnboardWorkspaceBasicsForm = () => {
  const { currentProcess, next } = useWizard();
  const { name } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const form = useForm<OnboardWorkspaceBasicsFormValues>({
    resolver: yupResolver(onboardWorkspaceBasicsFormSchema),
    defaultValues: {
      name: "",
      rootFolderPermissions: "inherited",
    },
  });

  const onFormSubmit: SubmitHandler<OnboardWorkspaceBasicsFormValues> = (
    values
  ) => {
    dispatch(setCreateWorkpaceFormField(values));
    next();
  };

  return (
    <Form {...form}>
      <form id="workspace-form-1" onSubmit={form.handleSubmit(onFormSubmit)}>
        <div className="grid grid-cols-2 gap-4">
          {/* Workspace Name Section - Spans full width */}
          <Card className="col-span-2 overflow-hidden border bg-gradient-to-br from-background to-muted/50">
            <CardContent className="p-6">
              <FormSectionHeader
                icon={FolderOpenDot}
                title="Name"
                description="Give your workspace a memorable name"
              />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder={`${name}'s workspace`}
                        className="bg-background/50 border-muted-foreground/20 focus:border-primary transition-colors"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
          </Card>

          {/* Permissions Section - Spans full width */}
          <Card className="col-span-2 overflow-hidden border bg-gradient-to-br from-background to-muted/50">
            <CardContent className="p-6">
              <FormSectionHeader
                icon={Lock}
                title="Root Folder Permissions"
                description="Set default access levels for workspace members"
                tooltip="These permissions will be inherited by every new member"
              />
              <FormField
                control={form.control}
                name="rootFolderPermissions"
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="bg-background/50 border-muted-foreground/20 focus:border-primary transition-colors">
                          <SelectValue placeholder="Choose permissions" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {permissionOptions.map((option) => {
                          return (
                            <SelectItem
                              key={option.value}
                              value={option.value}
                              className={cn(
                                "flex items-center gap-3 py-3",
                                "cursor-pointer transition-colors",
                                "focus:bg-muted/50 focus:text-foreground"
                              )}
                            >
                              <div className="flex flex-col gap-0.5">
                                <span className="font-medium">
                                  {option.label}
                                </span>
                                {/* <span className="text-xs text-muted-foreground">
                                  {option.description}
                                </span> */}
                              </div>
                            </SelectItem>
                          );
                        })}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
          </Card>
        </div>
        {/* Footer */}

        <div className="flex justify-between mt-6 pt-4 border-t">
          <Button
            type="submit"
            form={`workspace-form-${currentProcess}`}
            disabled={!form.formState.isValid}
            className="text-white w-full"
          >
            Confirm
          </Button>
        </div>
      </form>
    </Form>
  );
};
