import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useGravatar } from "@/lib/hooks/use-gravatar.hook";
import { logout, useAppDispatch, useAppSelector } from "@/lib/store";
import { cn } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { Bell, Computer, HelpCircle, LogOut, Shield, User } from "lucide-react";

interface MenuItemProps {
  icon: React.ReactNode;
  text: string;
  to?: string;
  onClick?: () => void;
  variant?: "default" | "danger";
}

const MenuItem = ({
  icon,
  text,
  to,
  onClick,
  variant = "default",
}: MenuItemProps) => {
  const content = (
    <>
      {icon}
      <span className={variant === "danger" ? "text-red-600" : ""}>{text}</span>
    </>
  );

  if (to) {
    return (
      <DropdownMenuItem asChild>
        <Link to={to} className="flex items-center gap-2">
          {content}
        </Link>
      </DropdownMenuItem>
    );
  }

  return (
    <DropdownMenuItem
      onClick={onClick}
      className={cn(
        "flex items-center gap-2",
        variant === "danger" && "text-red-600 focus:text-red-600"
      )}
    >
      {content}
    </DropdownMenuItem>
  );
};

export const UserDropdown = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const gravatarUrl = useGravatar(user.email);

  const initials =
    user.name
      ?.split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase() ?? "U";

  const menuItems = [
    {
      icon: <User className="h-4 w-4" />,
      text: "Profile",
      to: "/preferences/profile",
    },
    {
      icon: <Bell className="h-4 w-4" />,
      text: "Notifications",
      to: "/preferences/notifications",
    },
    {
      icon: <Computer className="h-4 w-4" />,
      text: "Devices",
      to: "/preferences/devices",
    },
    { icon: <Shield className="h-4 w-4" />, text: "Privacy", to: "/privacy" },
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="relative h-8 w-8 rounded-full ring-2 ring-primary/10 hover:ring-primary/20 transition-all"
        >
          <Avatar className="h-8 w-8">
            <AvatarImage src={gravatarUrl} alt={user.name ?? ""} />
            <AvatarFallback className="bg-primary/5">{initials}</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-64" align="end" forceMount>
        <DropdownMenuLabel className="font-normal p-4">
          <div className="flex flex-col space-y-1">
            <div className="flex items-center gap-2">
              <Avatar className="h-10 w-10">
                <AvatarImage src={gravatarUrl} alt={user.name ?? ""} />
                <AvatarFallback>{initials}</AvatarFallback>
              </Avatar>
              <div className="flex flex-col">
                <p className="text-sm font-medium leading-none">{user.name}</p>
                <p className="text-xs text-muted-foreground">{user.email}</p>
              </div>
            </div>
            {user.tagline && (
              <p className="text-xs text-muted-foreground pt-2">
                {user.tagline}
              </p>
            )}
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup className="p-1">
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              icon={item.icon}
              text={item.text}
              to={item.to}
            />
          ))}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <div className="p-1">
          <MenuItem
            icon={<HelpCircle className="h-4 w-4" />}
            text="Docs"
            to="https://kb.prividox.com"
          />
          <MenuItem
            icon={<LogOut className="h-4 w-4" />}
            text="Log out"
            onClick={() => dispatch(logout())}
            variant="danger"
          />
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
