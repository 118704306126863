/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { PrincipalInformation } from "./PrincipalInformation";
import {
  PrincipalInformationFromJSON,
  PrincipalInformationFromJSONTyped,
  PrincipalInformationToJSON,
  PrincipalInformationToJSONTyped,
} from "./PrincipalInformation";
import type { AttachmentInformation } from "./AttachmentInformation";
import {
  AttachmentInformationFromJSON,
  AttachmentInformationFromJSONTyped,
  AttachmentInformationToJSON,
  AttachmentInformationToJSONTyped,
} from "./AttachmentInformation";

/**
 *
 * @export
 * @interface TaskInformation
 */
export interface TaskInformation {
  /**
   *
   * @type {string}
   * @memberof TaskInformation
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof TaskInformation
   */
  title?: string;
  /**
   *
   * @type {boolean}
   * @memberof TaskInformation
   */
  completed?: boolean;
  /**
   *
   * @type {string}
   * @memberof TaskInformation
   */
  project?: string;
  /**
   *
   * @type {PrincipalInformation}
   * @memberof TaskInformation
   */
  assigner?: PrincipalInformation;
  /**
   *
   * @type {PrincipalInformation}
   * @memberof TaskInformation
   */
  assignee?: PrincipalInformation;
  /**
   *
   * @type {PrincipalInformation}
   * @memberof TaskInformation
   */
  updater?: PrincipalInformation;
  /**
   *
   * @type {number}
   * @memberof TaskInformation
   */
  created?: number;
  /**
   *
   * @type {number}
   * @memberof TaskInformation
   */
  updated?: number;
  /**
   *
   * @type {string}
   * @memberof TaskInformation
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof TaskInformation
   */
  expiry?: number;
  /**
   *
   * @type {Set<AttachmentInformation>}
   * @memberof TaskInformation
   */
  attachments?: Set<AttachmentInformation>;
}

/**
 * Check if a given object implements the TaskInformation interface.
 */
export function instanceOfTaskInformation(
  value: object,
): value is TaskInformation {
  return true;
}

export function TaskInformationFromJSON(json: any): TaskInformation {
  return TaskInformationFromJSONTyped(json, false);
}

export function TaskInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TaskInformation {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"] == null ? undefined : json["id"],
    title: json["title"] == null ? undefined : json["title"],
    completed: json["completed"] == null ? undefined : json["completed"],
    project: json["project"] == null ? undefined : json["project"],
    assigner:
      json["assigner"] == null
        ? undefined
        : PrincipalInformationFromJSON(json["assigner"]),
    assignee:
      json["assignee"] == null
        ? undefined
        : PrincipalInformationFromJSON(json["assignee"]),
    updater:
      json["updater"] == null
        ? undefined
        : PrincipalInformationFromJSON(json["updater"]),
    created: json["created"] == null ? undefined : json["created"],
    updated: json["updated"] == null ? undefined : json["updated"],
    description: json["description"] == null ? undefined : json["description"],
    expiry: json["expiry"] == null ? undefined : json["expiry"],
    attachments:
      json["attachments"] == null
        ? undefined
        : new Set(
            (json["attachments"] as Array<any>).map(
              AttachmentInformationFromJSON,
            ),
          ),
  };
}

export function TaskInformationToJSON(json: any): TaskInformation {
  return TaskInformationToJSONTyped(json, false);
}

export function TaskInformationToJSONTyped(
  value?: TaskInformation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    title: value["title"],
    completed: value["completed"],
    project: value["project"],
    assigner: PrincipalInformationToJSON(value["assigner"]),
    assignee: PrincipalInformationToJSON(value["assignee"]),
    updater: PrincipalInformationToJSON(value["updater"]),
    created: value["created"],
    updated: value["updated"],
    description: value["description"],
    expiry: value["expiry"],
    attachments:
      value["attachments"] == null
        ? undefined
        : Array.from(value["attachments"] as Set<any>).map(
            AttachmentInformationToJSON,
          ),
  };
}
