/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { Project } from "./Project";
import {
  ProjectFromJSON,
  ProjectFromJSONTyped,
  ProjectToJSON,
  ProjectToJSONTyped,
} from "./Project";

/**
 *
 * @export
 * @interface SystemMessage
 */
export interface SystemMessage {
  /**
   *
   * @type {string}
   * @memberof SystemMessage
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof SystemMessage
   */
  contentFR?: string;
  /**
   *
   * @type {string}
   * @memberof SystemMessage
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof SystemMessage
   */
  titleFR?: string;
  /**
   *
   * @type {string}
   * @memberof SystemMessage
   */
  theme?: SystemMessageThemeEnum;
  /**
   *
   * @type {Date}
   * @memberof SystemMessage
   */
  expiryDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof SystemMessage
   */
  effectiveDate?: Date;
  /**
   *
   * @type {boolean}
   * @memberof SystemMessage
   */
  active?: boolean;
  /**
   *
   * @type {number}
   * @memberof SystemMessage
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof SystemMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SystemMessage
   */
  id?: string;
  /**
   *
   * @type {Project}
   * @memberof SystemMessage
   */
  project?: Project;
}

/**
 * @export
 * @enum {string}
 */
export enum SystemMessageThemeEnum {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  SUCCESS = "SUCCESS",
  DANGER = "DANGER",
  WARNING = "WARNING",
  INFO = "INFO",
  LIGHT = "LIGHT",
  DARK = "DARK",
}

/**
 * Check if a given object implements the SystemMessage interface.
 */
export function instanceOfSystemMessage(value: object): value is SystemMessage {
  return true;
}

export function SystemMessageFromJSON(json: any): SystemMessage {
  return SystemMessageFromJSONTyped(json, false);
}

export function SystemMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SystemMessage {
  if (json == null) {
    return json;
  }
  return {
    content: json["content"] == null ? undefined : json["content"],
    contentFR: json["contentFR"] == null ? undefined : json["contentFR"],
    title: json["title"] == null ? undefined : json["title"],
    titleFR: json["titleFR"] == null ? undefined : json["titleFR"],
    theme: json["theme"] == null ? undefined : json["theme"],
    expiryDate:
      json["expiryDate"] == null ? undefined : new Date(json["expiryDate"]),
    effectiveDate:
      json["effectiveDate"] == null
        ? undefined
        : new Date(json["effectiveDate"]),
    active: json["active"] == null ? undefined : json["active"],
    version: json["version"] == null ? undefined : json["version"],
    name: json["name"] == null ? undefined : json["name"],
    id: json["id"] == null ? undefined : json["id"],
    project:
      json["project"] == null ? undefined : ProjectFromJSON(json["project"]),
  };
}

export function SystemMessageToJSON(json: any): SystemMessage {
  return SystemMessageToJSONTyped(json, false);
}

export function SystemMessageToJSONTyped(
  value?: SystemMessage | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    content: value["content"],
    contentFR: value["contentFR"],
    title: value["title"],
    titleFR: value["titleFR"],
    theme: value["theme"],
    expiryDate:
      value["expiryDate"] == null
        ? undefined
        : value["expiryDate"].toISOString(),
    effectiveDate:
      value["effectiveDate"] == null
        ? undefined
        : value["effectiveDate"].toISOString(),
    active: value["active"],
    version: value["version"],
    name: value["name"],
    id: value["id"],
    project: ProjectToJSON(value["project"]),
  };
}
