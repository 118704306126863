/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { ChargeInfo } from "./ChargeInfo";
import {
  ChargeInfoFromJSON,
  ChargeInfoFromJSONTyped,
  ChargeInfoToJSON,
  ChargeInfoToJSONTyped,
} from "./ChargeInfo";

/**
 *
 * @export
 * @interface TransactionList
 */
export interface TransactionList {
  /**
   *
   * @type {boolean}
   * @memberof TransactionList
   */
  more?: boolean;
  /**
   *
   * @type {Array<ChargeInfo>}
   * @memberof TransactionList
   */
  invoices?: Array<ChargeInfo>;
}

/**
 * Check if a given object implements the TransactionList interface.
 */
export function instanceOfTransactionList(
  value: object,
): value is TransactionList {
  return true;
}

export function TransactionListFromJSON(json: any): TransactionList {
  return TransactionListFromJSONTyped(json, false);
}

export function TransactionListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TransactionList {
  if (json == null) {
    return json;
  }
  return {
    more: json["more"] == null ? undefined : json["more"],
    invoices:
      json["invoices"] == null
        ? undefined
        : (json["invoices"] as Array<any>).map(ChargeInfoFromJSON),
  };
}

export function TransactionListToJSON(json: any): TransactionList {
  return TransactionListToJSONTyped(json, false);
}

export function TransactionListToJSONTyped(
  value?: TransactionList | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    more: value["more"],
    invoices:
      value["invoices"] == null
        ? undefined
        : (value["invoices"] as Array<any>).map(ChargeInfoToJSON),
  };
}
