/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { FileInfo } from "./FileInfo";
import {
  FileInfoFromJSON,
  FileInfoFromJSONTyped,
  FileInfoToJSON,
  FileInfoToJSONTyped,
} from "./FileInfo";

/**
 *
 * @export
 * @interface ResultFileInfo
 */
export interface ResultFileInfo {
  /**
   *
   * @type {Array<FileInfo>}
   * @memberof ResultFileInfo
   */
  items?: Array<FileInfo>;
}

/**
 * Check if a given object implements the ResultFileInfo interface.
 */
export function instanceOfResultFileInfo(
  value: object,
): value is ResultFileInfo {
  return true;
}

export function ResultFileInfoFromJSON(json: any): ResultFileInfo {
  return ResultFileInfoFromJSONTyped(json, false);
}

export function ResultFileInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResultFileInfo {
  if (json == null) {
    return json;
  }
  return {
    items:
      json["items"] == null
        ? undefined
        : (json["items"] as Array<any>).map(FileInfoFromJSON),
  };
}

export function ResultFileInfoToJSON(json: any): ResultFileInfo {
  return ResultFileInfoToJSONTyped(json, false);
}

export function ResultFileInfoToJSONTyped(
  value?: ResultFileInfo | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    items:
      value["items"] == null
        ? undefined
        : (value["items"] as Array<any>).map(FileInfoToJSON),
  };
}
