/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ValidateLoginSpec
 */
export interface ValidateLoginSpec {
  /**
   * The email to use to authenticate the user.
   * @type {string}
   * @memberof ValidateLoginSpec
   */
  email: string;
  /**
   * The password to use to authenticate the user. Minimum length to be increased to 12.
   * @type {string}
   * @memberof ValidateLoginSpec
   */
  pass: string;
  /**
   * The TOTP token to use to authenticate the user.
   * @type {string}
   * @memberof ValidateLoginSpec
   */
  token?: string;
}

/**
 * Check if a given object implements the ValidateLoginSpec interface.
 */
export function instanceOfValidateLoginSpec(
  value: object,
): value is ValidateLoginSpec {
  if (!("email" in value) || value["email"] === undefined) return false;
  if (!("pass" in value) || value["pass"] === undefined) return false;
  return true;
}

export function ValidateLoginSpecFromJSON(json: any): ValidateLoginSpec {
  return ValidateLoginSpecFromJSONTyped(json, false);
}

export function ValidateLoginSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ValidateLoginSpec {
  if (json == null) {
    return json;
  }
  return {
    email: json["email"],
    pass: json["pass"],
    token: json["token"] == null ? undefined : json["token"],
  };
}

export function ValidateLoginSpecToJSON(json: any): ValidateLoginSpec {
  return ValidateLoginSpecToJSONTyped(json, false);
}

export function ValidateLoginSpecToJSONTyped(
  value?: ValidateLoginSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    email: value["email"],
    pass: value["pass"],
    token: value["token"],
  };
}
