/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ProtectionSpec
 */
export interface ProtectionSpec {
  /**
   * The protection level to set.
   * @type {string}
   * @memberof ProtectionSpec
   */
  level: ProtectionSpecLevelEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ProtectionSpecLevelEnum {
  NONE = "NONE",
  PROTECTED_A = "PROTECTED_A",
  PROTECTED_B = "PROTECTED_B",
}

/**
 * Check if a given object implements the ProtectionSpec interface.
 */
export function instanceOfProtectionSpec(
  value: object,
): value is ProtectionSpec {
  if (!("level" in value) || value["level"] === undefined) return false;
  return true;
}

export function ProtectionSpecFromJSON(json: any): ProtectionSpec {
  return ProtectionSpecFromJSONTyped(json, false);
}

export function ProtectionSpecFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProtectionSpec {
  if (json == null) {
    return json;
  }
  return {
    level: json["level"],
  };
}

export function ProtectionSpecToJSON(json: any): ProtectionSpec {
  return ProtectionSpecToJSONTyped(json, false);
}

export function ProtectionSpecToJSONTyped(
  value?: ProtectionSpec | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    level: value["level"],
  };
}
