/* tslint:disable */
/* eslint-disable */
/**
 * HAPEX
 * Oproma inc. API powering PriviDox
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@prividox.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SecretCodeResponse
 */
export interface SecretCodeResponse {
  /**
   *
   * @type {string}
   * @memberof SecretCodeResponse
   */
  url?: string;
  /**
   *
   * @type {Set<number>}
   * @memberof SecretCodeResponse
   */
  codes?: Set<number>;
}

/**
 * Check if a given object implements the SecretCodeResponse interface.
 */
export function instanceOfSecretCodeResponse(
  value: object,
): value is SecretCodeResponse {
  return true;
}

export function SecretCodeResponseFromJSON(json: any): SecretCodeResponse {
  return SecretCodeResponseFromJSONTyped(json, false);
}

export function SecretCodeResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SecretCodeResponse {
  if (json == null) {
    return json;
  }
  return {
    url: json["url"] == null ? undefined : json["url"],
    codes: json["codes"] == null ? undefined : new Set(json["codes"]),
  };
}

export function SecretCodeResponseToJSON(json: any): SecretCodeResponse {
  return SecretCodeResponseToJSONTyped(json, false);
}

export function SecretCodeResponseToJSONTyped(
  value?: SecretCodeResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    url: value["url"],
    codes:
      value["codes"] == null
        ? undefined
        : Array.from(value["codes"] as Set<any>),
  };
}
